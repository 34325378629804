import { useEffect, useState, memo } from 'react'
import moment from 'moment'
import { Box, Card, CardContent, Grid, Typography, useTheme } from '@material-ui/core'
import { useSelector } from 'react-redux'
import DevicesTable from './DevicesTable'
import { europeNum, getDeviceLogs, getExcelLogs } from 'src/utils/general'
import ModalDeviceLogs from 'src/components/ModalDeviceLogs'
import ModalExcelDeviceLogs from 'src/components/ModalExcelDeviceLogs'
import fileDownload from 'js-file-download'
import { useSnackbar } from 'notistack'
import { useParams } from 'react-router'
import { useDispatch } from 'src/store'
import { updatedPlantInfo } from 'src/slices/led/plantView'
import LoadingCard from 'src/components/LoadingCard'

// mi preparo i dati per i log
const logsParams = {
  deviceId: '',
  name: [],
  timeTo: null,
  timeFrom: null
}

// COMPONENTE PRINCIPALE
const Components = () => {
  const { plantId } = useParams()
  const theme = useTheme()
  const { hasError } = useSelector((state) => state.ledPlantView)
  const dispatch = useDispatch()

  useEffect(() => {
    if (plantId && plantId !== 'demo') {
      dispatch(updatedPlantInfo({ plantId }))
    }
    const overviewInterval = setInterval(() => {
      dispatch(updatedPlantInfo({ plantId }))
    }, 60000)
    return () => {
      clearInterval(overviewInterval)
    }
  }, [dispatch, plantId, hasError])

  const { energyMeters, dataloggers } = useSelector((state) => state.ledPlantView)
  const { enqueueSnackbar } = useSnackbar()
  // mi preparo i dati per le tabelle

  // Prendo tutti i deviceTypes
  const allDeviceTypesIds = [...new Set(energyMeters.map(device => device?.deviceType?.uuid))]

  const allDeviceTypes = allDeviceTypesIds.map(deviceTypeId => {
    const currentDeviceType = energyMeters.find(device => device.deviceType?.uuid === deviceTypeId)?.deviceType || undefined
    return currentDeviceType
  })
    .filter(el => el)

  // Prendo tutte le properties uniche
  const allProperties = allDeviceTypes.reduce((props, deviceType) => {
    const currentProps = deviceType.properties || {}

    Object.keys(currentProps).forEach(prop => {
      if (!props[prop]) {
        props[prop] = currentProps[prop]
      }
    })

    return props
  }, {})

  const propertiesColumns = Object.keys(allProperties).map(prop => ({
    value: prop,
    label: allProperties[prop]?.uom ? `${allProperties[prop].displayName} (${allProperties[prop].uom})` : allProperties[prop]?.displayName || '-'
  }))

  const energyMeterColumns = [
    {
      value: 'name',
      label: 'Nome',
      minWidth: 120
    },
    ...propertiesColumns,
    {
      value: 'datalogger',
      label: 'Datalogger',
      minWidth: 120
    },
    {
      value: 'status',
      label: 'Stato',
      minWidth: 100
    },
    {
      value: 'actions',
      label: 'Azioni'
    }
  ]

  const deviceRows = energyMeters.map((device) => {
    const energyMeterDocuments = device.metadata?.documents || []
    const deviceTypeDocuments = device.deviceType?.metadata?.documents || []
    let documents = deviceTypeDocuments.concat(energyMeterDocuments)

    if (documents.length === 0) {
      documents = null
    }

    const currentRow = {
      uuid: '',
      type: 'energyMeter',
      name: device.name,
      status: {
        general: null,
        communication: null
      },
      actions: {
        documents
      }
    }

    Object.keys(device.deviceType?.properties || {}).forEach(property => {
      currentRow[property] = 'N.F.'
    })
    // mi salvo l'uuid
    currentRow.uuid = device.uuid
    // cerco il datalogger
    const dataloggerIndex = dataloggers.findIndex((datalogger) => datalogger.uuid === device.nodeId)
    if (dataloggerIndex > -1) {
      currentRow.datalogger = dataloggers[dataloggerIndex].name
    }
    // controllo se ho dati
    if (device.state && Object.keys(device.state).length > 0) {
      // controllo i dati che ho
      // comunicazione e stato
      if (Object.prototype.hasOwnProperty.call(device, 'lastCommunicationAt') && device.lastCommunicationAt !== 'never') {
        // controllo la differenza del periodo
        const a = moment()
        const b = moment(device.lastCommunicationAt)
        if (a.diff(b, 'minutes') >= 30) {
          currentRow.status.communication = 'offline'
        } else {
          currentRow.status.communication = 'online'
        }
      } else {
        currentRow.status.communication = 'offline'
      }
      if (Object.prototype.hasOwnProperty.call(device.metadata, 'status')) {
        currentRow.status.general = device.metadata.status
      } else {
        currentRow.status.general = 'ok'
      }
      currentRow.status.general = 'ok'
      // altri dati
      Object.keys(device.state).forEach((key) => {
        if (key.toLowerCase().includes('energy')) {
          const finalValue = Number(device.state[key]) / 1000
          currentRow[key] = europeNum(finalValue, 2)
        } else {
          currentRow[key] = europeNum(device.state[key], 2)
        }
      })
    }

    return currentRow
  })

  // PREPARO I DATI PER I LOG
  const now = moment()
    .set({ minute: 0, second: 0, millisecond: 0 })
    .toISOString()
  const [from, setFrom] = useState(moment(now).toISOString())
  const [to, setTo] = useState(
    moment(now)
      .add({ hours: '1' })
      .toISOString()
  )

  const [openLogsModal, setOpenLogsModal] = useState(false)
  const [openExcelModal, setOpenExcelModal] = useState(false)
  const [logs, setLogs] = useState({
    uuid: '',
    name: '',
    data: {},
    columns: [],
    rows: []
  })
  const [isExcelTooBig, setIsExcelTooBig] = useState(false)

  // funzione che torna l'array con le colonne
  const generateLogColumns = (deviceProps, props) => {
    const arrayToSend = []
    if (deviceProps) {
      Object.keys(deviceProps).forEach(key => {
        const label = deviceProps[key]?.uom ? `${deviceProps[key]?.displayName || '-'} (${deviceProps[key].uom})` : `${deviceProps[key]?.displayName || '-'}`
        const propObject = {
          prop: key,
          label
        }
        arrayToSend.push(propObject)
      })
    }

    return arrayToSend
  }

  // funzione che ritorna l'array delle righe
  const generateLogRows = (data) => {
    const arrayToReturn = []
    // mi scorro i valori di tutte le prop e mi salvo i valori ISOString che non ho
    Object.keys(data).forEach((key) => {
      data[key].forEach((elem) => {
        if (arrayToReturn.indexOf(elem[0]) === -1) {
          arrayToReturn.push(elem[0])
        }
      })
    })
    // Ordino l'array in base al tempo (dal più vicino al from fino al to)
    arrayToReturn.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0))
    return arrayToReturn
  }

  // Funzione che preso in ingresso l'oggetto dei logs di un singolo device restituisce lo stesso oggetto ma trascurando la diversità di secondi tra un messaggio e l'altro
  const approximateLogsToMinutesPrecision = (logsObj) => {
    if (logsObj) {
      return Object.keys(logsObj).reduce((acc, propKey) => {
        const newProp = logsObj[propKey]
          .map(dateValueEl => dateValueEl?.map((el, index) => index === 0 ? moment(el).format('DD/MM/YYYY HH:mm') : el))

        acc[propKey] = newProp

        return acc
      }, {})
    }

    return {}
  }

  // funzione che apre la modal dei log
  const openLogsDialog = async (row) => {
    try {
      // setto i valori nei parametri della query
      logsParams.deviceId = row.uuid
      // mi calcolo le giuste propsLabels
      const currentDevice = energyMeters.find((device) => device.uuid === row.uuid)
      const newColumns = generateLogColumns(currentDevice.deviceType?.properties, currentDevice ? currentDevice.state : null)

      logsParams.name = newColumns.map((col) => col.prop)
      logsParams.timeFrom = from
      logsParams.timeTo = to
      const newData = await getDeviceLogs(logsParams)
      // mi preparo le righe
      const formattedData = approximateLogsToMinutesPrecision(newData[currentDevice?.uuid] || {})

      const newRows = generateLogRows(formattedData)

      setLogs({
        uuid: logsParams.deviceId,
        name: row.name,
        columns: newColumns,
        rows: newRows,
        data: formattedData
      })
      setOpenLogsModal(true)
    } catch (error) {
      enqueueSnackbar(`Errore nel reperire i dati del dispositivo ${row.name || ''}. Per favore riprovare.`, {
        variant: 'error'
      })
    }
  }

  // funzione che viene invocata alla chiusura della modal dei log
  const handleCloseLogsModal = () => {
    setOpenLogsModal(false)
  }

  // funzione che aggiorna i dati
  const onPeriodChange = async (newFrom, newTo) => {
    logsParams.timeFrom = moment(newFrom).toISOString()
    logsParams.timeTo = moment(newTo).toISOString()
    setFrom(logsParams.timeFrom)
    setTo(logsParams.timeTo)

    const newData = await getDeviceLogs(logsParams)

    const key = Object.keys(newData)
    const formattedData = approximateLogsToMinutesPrecision(newData[key])
    const newRows = generateLogRows(formattedData)

    setLogs({ ...logs, data: formattedData, rows: newRows })
  }

  // funzione che apre la modal per scaricare Excel
  const toogleDownloadExcel = () => {
    setOpenExcelModal(true)
  }

  // funzione che viene invocata alla chiusura della modal dei log
  const handleCloseExcelModal = () => {
    setOpenExcelModal(false)
  }

  // funzione che scarica l'excel
  const downloadExcel = async (from, to, selectedAggregation) => {
    setIsExcelTooBig(false)
    const excelParams = JSON.parse(JSON.stringify(logsParams))
    excelParams.timeFrom = moment(from).toISOString()
    excelParams.timeTo = moment(to).toISOString()
    excelParams.aggregationType = selectedAggregation || 'quarter'
    try {
      const excelResponse = await getExcelLogs(excelParams)
      // controllo se è ritornato il file o se manda email
      if (excelResponse.status === 202) {
        // mando l'excel per email
        setIsExcelTooBig(true)
      } else {
        // scarico il blob
        fileDownload(
          excelResponse.data,
          `LOGS ${logs.name} - dal ${moment(from).format('DD-MM-YY (HH)')} al ${moment(to).format(
            'DD-MM-YY (HH)'
          )} - dati tipo ${selectedAggregation || 'quarter'}.xlsx`
        )
      }
    } catch (err) {
      console.error('get excel err => ', err)
      if (String(err).indexOf('404') > -1) {
        enqueueSnackbar('Attenzione: non ci sono dati per il tipo di aggregazione richiesta.', {
          variant: 'warning'
        })
      } else {
        enqueueSnackbar('Errore nel richiedere i log in Excel, per favore aggiornare la pagina e riprovare.', {
          variant: 'error'
        })
      }
    }
  }

  const computedContainerHeight = `calc(100% - 48px - ${theme.spacing(2)}px - 80px)`

  return (
    <Box mt={1} pb={3} style={{ height: computedContainerHeight, overflowY: 'auto', overflowX: 'hidden' }}>
      {dataloggers[0] === '-'
        ? (
          <LoadingCard />
          )
        : dataloggers.length === 0
          ? (
            <Card>
              <CardContent style={{ paddingBottom: 16 }}>
                <Typography variant='body1' style={{ color: 'white', textAlign: 'center' }}>Non ci sono dispositivi per questo impianto.</Typography>
              </CardContent>
            </Card>
            )
          : (
            <Grid container spacing={2}>
              {energyMeters.length > 0 && (
                <Grid item xs={12}>
                  <DevicesTable
                    columns={energyMeterColumns}
                    rows={deviceRows}
                    title='Dati Energy Meter'
                    handleRowClick={openLogsDialog}
                  />
                </Grid>
              )}
              {energyMeters.length === 0 && (
                <Grid item xs={12}>
                  <Typography variant='h5' style={{ color: 'white' }}>
                    Non ci sono dispositivi sul/sui Datalogger di questo impianto.
                  </Typography>
                </Grid>
              )}
              <ModalDeviceLogs
                open={openLogsModal}
                onClose={handleCloseLogsModal}
                data={logs.data}
                columnsLabels={logs.columns}
                initialRows={logs.rows}
                name={logs.name}
                onPeriodChange={onPeriodChange}
                initialFrom={from}
                initialTo={to}
                toogleDownloadExcel={toogleDownloadExcel}
              />
              <ModalExcelDeviceLogs
                open={openExcelModal}
                onClose={handleCloseExcelModal}
                deviceId={logs.uuid}
                name={logs.name}
                initialFrom={from}
                initialTo={to}
                downloadExcel={downloadExcel}
                isExcelTooBig={isExcelTooBig}
              />
            </Grid>
            )}
    </Box>
  )
}

export default memo(Components)
