// Array contenente le zone dell'italia
export const zonesFilters = [
  {
    label: 'Nord',
    value: 'nord'
  },
  {
    label: 'Centro',
    value: 'centro'
  },
  {
    label: 'Sud',
    value: 'sud'
  }
]

// Array di oggetti che definisce le categorie di filtri disponibili
export const customFilterTypes = [
  {
    label: 'Potenza di Picco',
    value: 'peakPower'
  },
  {
    label: 'PR',
    value: 'pr'
  },
  {
    label: 'Stato',
    value: 'status'
  },
  {
    label: 'Tipo di contratto',
    value: 'contractType'
  }
]

// Array di oggetti che definisce i tipi di contratto disponibili
export const customFilterContractTypes = [
  {
    label: 'Noleggio Operativo',
    value: 'operatingRental'
  },
  {
    label: 'Energy Performance Contract',
    value: 'energyPerformanceContract'
  },
  {
    label: 'Engineering Procurement Construction',
    value: 'engineeringProcurementConstruction'
  },
  {
    label: 'DM FER',
    value: 'dmFer'
  }
]

// Array di oggetti che definisce i tipi di status disponibili
export const customFilterStatus = [
  {
    label: 'Ok',
    value: 'ok'
  },
  {
    label: 'In manutenzione',
    value: 'warning'
  },
  {
    label: 'In allarme',
    value: 'error'
  }
]

// Array di oggetti che definisce i tipi di operatore disponibili
export const customFilterOperators = [
  {
    label: 'Maggiore di',
    value: '>'
  },
  {
    label: 'Minore di',
    value: '<'
  },
  {
    label: 'Uguale a',
    value: '='
  },
  {
    label: 'Maggiore o uguale a',
    value: '>='
  },
  {
    label: 'Minore o uguale a',
    value: '<='
  }
]

// Array di oggetto che definisce tutti i tipi di impianto disponibili
export const filterTypes = [
  {
    label: 'Tutti',
    value: 'all'
  },
  {
    label: 'pv',
    value: 'pv'
  },
  {
    label: 'led',
    value: 'led'
  },
  {
    label: 'chp',
    value: 'chp'
  },
  {
    label: 'cchp',
    value: 'cchp'
  },
  {
    label: 'ca',
    value: 'ca'
  },
  {
    label: 'trs',
    value: 'trs'
  }
]
