import { useEffect, useState, memo } from 'react'
// import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx'
// import moment from 'moment'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import LoadingCard from 'src/components/LoadingCard'
import {
  Box,
  // Button,
  // Tooltip,
  Typography,
  Card,
  makeStyles
  // withStyles,
} from '@material-ui/core'
import Planimetry from 'src/components/Planimetry'
import { europeNum, getDeviceLogs } from 'src/utils/general'
import { basicColors } from 'src/theme'
import { alog } from 'src/utils/apioLog'
import moment from 'moment'
import ModalDeviceLogs from 'src/components/ModalDeviceLogs'
import api from 'src/utils/api'
import { normalizeAnomaly } from 'src/views/plant/PlantView/industryConsumption/utils'
import { getAnomaliesDevices, handleTabsChange } from 'src/slices/industryConsumption/plantView'
import { useDispatch } from 'src/store'
import AnomalySidenav from '../../shared/AnomalySidenav'
import useAuth from 'src/hooks/useAuth'

// import {
//  // Edit as EditIcon,
//  CheckCircle as OkIcon,
// } from 'react-feather'

// stile CSS generale
const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    borderTopLeftRadius: 1,
    borderTopRightRadius: 1
  },
  canvaContainer: {
    minHeight: '100% !important',
    height: '100%'
  },
  warningBox: {
    backgroundColor: basicColors.darkYellow,
    padding: 16,
    margin: 0,
    color: basicColors.darkText,
    textAlign: 'center'
  }
}))

// mi preparo i dati per i log
const logsParams = {
  deviceId: '',
  name: [],
  timeTo: null,
  timeFrom: null
}

// componente principale
const PlanimetryView = ({ className, ...rest }) => {
  const classes = useStyles()
  // const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const { planimetry, infoBoxes, energyMeters, uuid, anomaliesCategoryStatus } = useSelector((state) => state.industryConsumptionPlantView)
  alog('planimetry =>', planimetry, 'planimetry')

  const { user } = useAuth()

  const permissions = user.appPermissions || []

  // Variabile per gestire la visualizzazione delle anomalie
  const canViewAnomalies = !(permissions.includes('assetsmanager.plants.access') && !permissions.includes('assetsmanager.plants.manage'))

  // Stato locale per intercettare la modifica di infoboxes
  // infoBoxes al refresh della pagina è un oggetto vuoto
  const [fieldData, setFieldData] = useState({})

  // Parametri per la modal di dettaglio dei log excel per singolo device
  const now = moment()
    .set({ minute: 0, second: 0, millisecond: 0 })
    .toISOString()
  const [logs, setLogs] = useState({
    uuid: '',
    name: '',
    data: {},
    columns: [],
    rows: []
  })
  const [openLogsModal, setOpenLogsModal] = useState(false)
  const [openAnomalyDrawer, setOpenAnomalyDrawer] = useState(false)
  const [selectedAnomaly, setSelectedAnomaly] = useState({})

  const [from, setFrom] = useState(moment(now).toISOString())
  const [to, setTo] = useState(
    moment(now)
      .add({ hours: '1' })
      .toISOString()
  )

  // Ogni volta che infoBoxes si modifica, setto il nuovo valore per fieldData che cambiando permette al
  // componente planimetry di triggerare le receive dei singoli oggetto
  useEffect(() => {
    setFieldData(infoBoxes)
  }, [infoBoxes])

  // all'avvio aggiorno i dati
  // useEffect(() => {
  //  dispatch(updatePlants())
  //  // return () => {}
  // }, [dispatch])

  // funzione che torna l'array con le colonne
  const generateLogColumns = (deviceProps, props) => {
    const arrayToSend = []
    if (deviceProps) {
      Object.keys(deviceProps).forEach((key) => {
        const label = deviceProps[key]?.uom && deviceProps[key]?.uom !== '-' ? `${deviceProps[key]?.displayName || '-'} (${deviceProps[key].uom})` : `${deviceProps[key]?.displayName || '-'}`

        const propObject = {
          prop: key,
          label
        }

        arrayToSend.push(propObject)
      })
    }
    return arrayToSend
  }

  // funzione che ritorna l'array delle righe
  const generateLogRows = (data) => {
    const arrayToReturn = []
    // mi scorro i valori di tutte le prop e mi salvo i valori ISOString che non ho
    Object.keys(data).forEach((key) => {
      data[key].forEach((elem) => {
        if (arrayToReturn.indexOf(elem[0]) === -1) {
          arrayToReturn.push(elem[0])
        }
      })
    })
    // Ordino l'array in base al tempo (dal più vicino al from fino al to)
    arrayToReturn.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0))
    return arrayToReturn
  }

  // Funzione che preso in ingresso l'oggetto dei logs di un singolo device restituisce lo stesso oggetto ma trascurando la diversità di secondi tra un messaggio e l'altro
  const approximateLogsToMinutesPrecision = (logsObj) => {
    if (logsObj) {
      return Object.keys(logsObj).reduce((acc, propKey) => {
        const newProp = logsObj[propKey]
          .map(dateValueEl => dateValueEl?.map((el, index) => index === 0 ? moment(el).format('DD/MM/YYYY HH:mm') : el))

        acc[propKey] = newProp

        return acc
      }, {})
    }

    return {}
  }

  // funzione che apre la modal dei log
  const openLogsDialog = async (row) => {
    try {
      // setto i valori nei parametri della query
      logsParams.deviceId = row.uuid
      // mi calcolo le giuste propsLabels
      const currentDevice = energyMeters.find(device => device.uuid === row.uuid)
      const newColumns = generateLogColumns(currentDevice.deviceType?.properties, currentDevice?.state || null)

      logsParams.name = newColumns.map((col) => col.prop)
      logsParams.timeFrom = from
      logsParams.timeTo = to
      const newData = await getDeviceLogs(logsParams)

      // mi preparo le righe
      const formattedData = approximateLogsToMinutesPrecision(newData[currentDevice?.uuid] || {})
      const newRows = generateLogRows(formattedData)

      setLogs({
        uuid: logsParams.deviceId,
        name: row.name,
        columns: newColumns,
        rows: newRows,
        data: formattedData
      })

      setOpenLogsModal(true)
    } catch (e) {
      return e
    }
  }

  // funzione che aggiorna i dati
  const onPeriodChange = async (newFrom, newTo) => {
    logsParams.timeFrom = moment(newFrom).toISOString()
    logsParams.timeTo = moment(newTo).toISOString()
    setFrom(logsParams.timeFrom)
    setTo(logsParams.timeTo)

    const newData = await getDeviceLogs(logsParams)

    const key = Object.keys(newData)
    const formattedData = approximateLogsToMinutesPrecision(newData[key] || {})
    const newRows = generateLogRows(formattedData)

    setLogs({ ...logs, data: formattedData, rows: newRows })
  }

  const openDeviceDetail = async (deviceId) => {
    alog('config: ', planimetry.config, 'planimetry')
    const currentDevice = planimetry.config.find(el => el.id === deviceId)
    if (currentDevice) {
      const deviceId = currentDevice.uuid
      const deviceType = currentDevice.canvasLibraryType
      const deviceName = energyMeters.find(device => device.uuid === deviceId)?.name || '-'
      if (deviceId && deviceType && deviceName) {
        await openLogsDialog({ uuid: deviceId, type: deviceType, name: deviceName })
      }
    }
  }

  // funzione che prende le anomalie del singolo device
  const getDeviceAnomalies = async (deviceId) => {
    try {
      const anomalies = await api.getResource('anomaliesList', {
        params: {
          limit: 3,
          sortby: 'startedAt',
          sortorder: 'DESC',
          plantId: uuid,
          relatedResources: `/devices/${deviceId}`,
          filter: JSON.stringify({ endedAt: { $exists: false } })
        }
      })
      return anomalies
    } catch (e) {
      return null
    }
  }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Box style={{ height: '100%' }}>
        {
          openAnomalyDrawer
            ? (
              <AnomalySidenav
                open={openAnomalyDrawer}
                anomaly={selectedAnomaly}
                getAnomaliesDevices={getAnomaliesDevices}
                onClose={() => setOpenAnomalyDrawer(false)}
              />
              )
            : null
        }
        {openLogsModal
          ? (
            <ModalDeviceLogs
              open={openLogsModal}
              onClose={() => {
                setOpenLogsModal(false)
                setFrom(moment(now).toISOString())
                setTo(moment(now).add({ hours: '1' }).toISOString())
              }}
              data={logs.data}
              columnsLabels={logs.columns}
              initialRows={logs.rows}
              name={logs.name}
              onPeriodChange={onPeriodChange}
              initialFrom={from}
              initialTo={to}
              toogleDownloadExcel={null}
            />
            )
          : null}
        {planimetry.draw && planimetry.draw.length === 0
          ? (
            <Typography
              component='p'
              variant='body1'
              className={classes.warningBox}
            >La planimetria è vuota, modificare l&apos;impianto per crearla.
            </Typography>
            )
          : null}
        {planimetry.draw
          ? (
            <Planimetry
              formatter={europeNum}
              className={classes.canvaContainer}
              unselectable
              fieldData={fieldData}
              goToAnomalies={() => canViewAnomalies && dispatch(handleTabsChange('anomalies'))}
              selectAnomaly={(singleAnomaly) => {
                if (canViewAnomalies) {
                  setSelectedAnomaly(normalizeAnomaly(singleAnomaly, anomaliesCategoryStatus))
                  setOpenAnomalyDrawer(true)
                }
              }}
              getDeviceAnomalies={getDeviceAnomalies}
              openDeviceDetail={openDeviceDetail}
              importedCanvas={planimetry}
            />
            )
          : (
            <LoadingCard />
            )}
      </Box>
    </Card>
  )
}

PlanimetryView.propTypes = {
  className: PropTypes.string
  // customers: PropTypes.array.isRequired,
  // setRefreshUsers: PropTypes.func
}

// Results.defaultProps = {
//   customers: []
// }

export default memo(PlanimetryView)
