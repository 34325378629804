import { useState, memo, useRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  Grid,
  Chip,
  makeStyles,
  CircularProgress,
  IconButton,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem
} from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import format from 'date-fns/format'
import isSameDay from 'date-fns/isSameDay'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'
import isWithinInterval from 'date-fns/isWithinInterval'
import { parseISO } from 'date-fns'
import itLocale from 'date-fns/locale/it'
import { ChevronRight, ChevronLeft } from 'react-feather'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  chip: {
    minWidth: '100%',
    maxWidth: '100%'
  },
  dayWrapper: {
    position: 'relative'
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: '0 2px',
    color: 'inherit'
  },
  customDayHighlight: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '2px',
    right: '2px',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '50%'
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled
  },
  highlightNonCurrentMonthDay: {
    color: '#676767'
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  firstHighlight: {
    extend: 'highlight',
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%'
  },
  endHighlight: {
    extend: 'highlight',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%'
  },
  label: {
    width: '100%',
    textAlign: 'center'
  }
}))

const allPeriods = [
  {
    label: 'Live',
    value: 'live'
  },
  {
    label: 'Settimana',
    value: 'week'
  },
  {
    label: 'Mese',
    value: 'month'
  },
  {
    label: 'Anno',
    value: 'year'
  }
]

function PeriodSelection ({
  className,
  calendar = true,
  yearShift = null,
  minLimit = null,
  shortcuts = true,
  yearsArray = undefined,
  customPeriod,
  setCustomPeriod,
  selectedDate,
  setSelectedDate,
  isLoading,
  ...rest
}) {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)
  const anchorRef = useRef(null)
  const [yearArrayToShow, setYearArrayToShow] = useState(0)
  // Funzione che fa il render della selezione settimanale sul calendario
  const renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {
    const dateClone = parseISO(JSON.parse(JSON.stringify(date)))
    const selectedDateClone = parseISO(JSON.parse(JSON.stringify(selectedDate)))

    const start = startOfWeek(selectedDateClone, { weekStartsOn: 1 })
    const end = endOfWeek(selectedDateClone, { weekStartsOn: 1 })
    const dayIsBetween = isWithinInterval(dateClone, { start, end })
    const isFirstDay = isSameDay(dateClone, start)
    const isLastDay = isSameDay(dateClone, end)

    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay
    })

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween
    })

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {format(dateClone, 'd')} </span>
        </IconButton>
      </div>
    )
  }

  // Opzioni del calendario in base al periodo
  const datepickerOptions = () => {
    if (customPeriod === 'month') {
      return ({
        views: ['month']
      })
    } else if (customPeriod === 'week') {
      return ({
        renderDay: renderWrappedWeekDay
      })
    }
  }

  // Funzione che prende in ingresso una data in formato moment e ritorna una stringa contenente una data formattata a seconda del periodo da visualizzare
  const formatDateOptions = (date) => {
    const currentDate = date.toISOString ? parseISO(date.toISOString()) : parseISO(new Date(date).toISOString())
    if (customPeriod === 'live') {
      return format(currentDate, 'dd-MM-yyyy')
    } else if (customPeriod === 'week') {
      const start = startOfWeek(currentDate, { weekStartsOn: 1 })
      const end = endOfWeek(currentDate, { weekStartsOn: 1 })
      return `${format(start, 'd MMM', { locale: itLocale })} - ${format(end, 'd MMM', { locale: itLocale })}`
    } else if (customPeriod === 'month') {
      return format(currentDate, 'MMMM', { locale: itLocale })
    } else if (customPeriod === 'year') {
      if (yearsArray) return yearsArray[yearArrayToShow].label
      return format(currentDate, 'yyyy', { locale: itLocale })
    }

    return format(currentDate, 'dd-MM-yyyy')
  }

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setIsOpen(false)
    }
  }

  const handleMenuClick = (event, start, index) => {
    event.preventDefault()
    setIsOpen(false)
    setYearArrayToShow(index)
    setSelectedDate(moment(start))
  }

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {isOpen && customPeriod !== 'year' && calendar
        ? (
          <DatePicker
            open={isOpen}
            disableFuture
            {...datepickerOptions()}
            onClose={() => setIsOpen(false)}
            format='d MMM yyyy'
            value={selectedDate}
            TextFieldComponent={() => null}
            onChange={setSelectedDate}
          />
          )
        : null}
      {isOpen && customPeriod === 'year' && yearsArray
        ? (
          <Popper
            open={isOpen}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={{
              zIndex: 10000
            }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={(event) => setIsOpen(false)}>
                    <MenuList
                      autoFocusItem={isOpen}
                      id='year-list'
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem disabled>Anni contrattuali</MenuItem>
                      {yearsArray.map((year, index) => {
                        return (
                          <MenuItem
                            key={year.label + index}
                            onClick={(event) => handleMenuClick(event, year.start, index)}
                            // disabled={index === yearArrayToShow}
                          >
                            {`${year.label} (${moment(year.start).format('DD-MM-YYYY')} - ${moment(year.end).format('DD-MM-YYYY')})`}
                          </MenuItem>
                        )
                      })}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          )
        : null}
      <Grid container spacing={2}>
        {allPeriods.map((period, index) => {
          return (
            <Grid key={index} item xs={3}>
              <Chip
                ref={anchorRef}
                classes={{ label: classes.label }}
                className={classes.chip}
                disabled={customPeriod === period.value && isLoading}
                label={customPeriod === period.value && isLoading
                  ? <CircularProgress size={15} color='secondary' />
                  : selectedDate && customPeriod === period.value
                    ? formatDateOptions(selectedDate)
                    : period.label}
                color={customPeriod === period.value ? 'primary' : 'secondary'}
                {...{
                  onDelete: shortcuts &&
                    customPeriod === period.value &&
                    customPeriod !== 'year' &&
                    !isLoading &&
                    moment(selectedDate).isBefore(moment(), 'day')
                    ? () => {
                        if (selectedDate === null) {
                          if (customPeriod === 'live') {
                          // deve andare al precedente intervallo selezionato
                            setSelectedDate(moment().add(1, 'd'))
                          } else if (customPeriod === 'week') {
                          // aggiungo 7 giorni e prendo l'inizio della settimana
                            const newWeekStart = moment().add(7, 'd').startOf('week')
                            setSelectedDate(newWeekStart)
                          } else if (customPeriod === 'month') {
                          // prendo la fine del mese, ci aggiungo un giorno per entrare nel nuovo mese e prendo l'inizio del mese ottenuto
                            const newMonthStart = moment().endOf('month').add(1, 'd').startOf('month')
                            setSelectedDate(newMonthStart)
                          } else if (customPeriod === 'year') {
                            if (yearShift) {
                              const { baselineStartDate } = yearShift() || {}
                              if (baselineStartDate) {
                                const newMonthStart = moment(baselineStartDate).add(12, 'M')
                                setSelectedDate(newMonthStart)
                              }
                            } else if (yearsArray) {
                              const newIndex = yearArrayToShow + 1
                              if (yearsArray[newIndex]) {
                                setYearArrayToShow(newIndex)
                                setSelectedDate(yearsArray[newIndex].start)
                              }
                            }
                          }
                        } else {
                          const prevDate = selectedDate
                          if (customPeriod === 'live') {
                          // deve andare al precedente intervallo selezionato
                            setSelectedDate(moment(prevDate).add(1, 'd'))
                          } else if (customPeriod === 'week') {
                          // aggiungo 7 giorni e prendo l'inizio della settimana
                            const newWeekStart = moment(prevDate).add(7, 'd').startOf('week')
                            setSelectedDate(newWeekStart)
                          } else if (customPeriod === 'month') {
                          // prendo la fine del mese della data selezionata, ci aggiungo un giorno per entrare nel nuovo mese e prendo l'inizio del mese ottenuto
                            const newMonthStart = moment(prevDate).endOf('month').add(1, 'd').startOf('month')
                            setSelectedDate(newMonthStart)
                          } else if (customPeriod === 'year') {
                            if (yearShift) {
                              const { baselineStartDate } = yearShift(prevDate) || {}
                              if (baselineStartDate) {
                                const newMonthStart = moment(baselineStartDate).add(12, 'M')
                                setSelectedDate(newMonthStart)
                              }
                            } else if (yearsArray) {
                              const newIndex = yearArrayToShow + 1
                              if (yearsArray[newIndex]) {
                                setYearArrayToShow(newIndex)
                                setSelectedDate(yearsArray[newIndex].start)
                              }
                            }
                          }
                        }
                      }
                    : null,
                  deleteIcon: <ChevronRight />,
                  icon: shortcuts &&
                    customPeriod === period.value &&
                    customPeriod !== 'year' &&
                    !isLoading &&
                    (minLimit && !moment(selectedDate).isSameOrBefore(moment(minLimit), 'day'))
                    ? (
                      <ChevronLeft
                        size={22}
                        onClick={(e) => {
                          // serve stoppare la propagazione dell'evento per impedire
                          // anche l'apertura del calendario invece che la chiamata alla funzione di cambio periodo
                          e.stopPropagation()
                          if (selectedDate === null) {
                            if (customPeriod === 'live') {
                              // deve andare al precedente intervallo selezionato
                              setSelectedDate(moment().subtract(1, 'd'))
                            } else if (customPeriod === 'week') {
                              // sottraggo 7 giorni e prendo l'inizio della settimana
                              const newWeekStart = moment().subtract(7, 'd').startOf('week')
                              setSelectedDate(newWeekStart)
                            } else if (customPeriod === 'month') {
                              // prendo la l'inizio del mese, ci sottraggo un giorno per entrare nel nuovo mese e prendo l'inizio del mese ottenuto
                              const newMonthStart = moment().startOf('month').subtract(1, 'd').startOf('month')
                              setSelectedDate(newMonthStart)
                            } else if (customPeriod === 'year') {
                              if (yearShift) {
                                const { baselineStartDate } = yearShift() || {}
                                if (baselineStartDate) {
                                  const newMonthStart = moment(baselineStartDate).subtract(12, 'M')
                                  setSelectedDate(newMonthStart)
                                }
                              } else if (yearsArray) {
                                const newIndex = yearArrayToShow - 1
                                if (yearsArray[newIndex]) {
                                  setYearArrayToShow(newIndex)
                                  setSelectedDate(yearsArray[newIndex].start)
                                }
                              }
                            }
                          } else {
                            const prevDate = selectedDate
                            if (customPeriod === 'live') {
                              // deve andare al precedente intervallo selezionato
                              setSelectedDate(moment(prevDate).subtract(1, 'd'))
                            } else if (customPeriod === 'week') {
                              // sottraggo 7 giorni e prendo l'inizio della settimana
                              const newWeekStart = moment(prevDate).subtract(7, 'd').startOf('week')
                              setSelectedDate(newWeekStart)
                            } else if (customPeriod === 'month') {
                              // prendo la fine del mese della data selezionata, ci sottraggo un giorno per entrare nel nuovo mese e prendo l'inizio del mese ottenuto
                              const newMonthStart = moment(prevDate).startOf('month').subtract(1, 'd').startOf('month')
                              setSelectedDate(newMonthStart)
                            } else if (customPeriod === 'year') {
                              if (yearShift) {
                                const { baselineStartDate } = yearShift(prevDate) || {}

                                if (baselineStartDate) {
                                  const newMonthStart = moment(baselineStartDate).subtract(12, 'M')
                                  setSelectedDate(newMonthStart)
                                }
                              } else if (yearsArray) {
                                const newIndex = yearArrayToShow - 1
                                if (yearsArray[newIndex]) {
                                  setYearArrayToShow(newIndex)
                                  setSelectedDate(yearsArray[newIndex].start)
                                }
                              }
                            }
                          }
                        }}
                      />)
                    : null
                }
                }
                onClick={() => {
                  if (calendar && customPeriod === period.value) {
                    setIsOpen(true)
                  } else {
                    setSelectedDate(null)
                    setCustomPeriod(period.value)
                  }
                }}
              />
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}
PeriodSelection.propTypes = {
  className: PropTypes.string,
  customPeriod: PropTypes.string,
  setCustomPeriod: PropTypes.func
}
PeriodSelection.defaultProps = {
  customPeriod: 'live'
}

export default memo(PeriodSelection)
