import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import LoadingCard from '../LoadingCard'
import Chart from 'react-apexcharts'
import { europeNum } from 'src/utils/general'
import { alog } from 'src/utils/apioLog'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  }
}))

function StringAnalysisChart ({ data = {}, height = null, width = null, className }) {
  const classes = useStyles()

  const { underThreshold } = data

  const dataSet = {
    cc: {
      data: [],
      labels: [],
      yaxis: {
        max: 1.1
      },
      bar: {
        color: {
          min: '#ffb2bc',
          max: '#7cd44e'
        },
        width: '40px'
      }
    },
    energy: {
      data: [],
      color: '#18e7c8',
      yaxis: {
        max: 1.5
      }
    }
  }

  // Funzione che imposta le options con i dataset per il grafico di analisi di stringa
  const normalizeDataset = (data, dataSet) => {
    alog('dataSet: ', dataSet, 'stringAnalysisChart')
    const normalizedData = {}
    Object.keys(dataSet).forEach(key => {
      alog('key: ', key, 'stringAnalysisChart')
      if (data[key]) {
        if (key === 'cc') {
          normalizedData[key] = {
            ...dataSet[key],
            data: data[key].data,
            labels: data[key].labels,
            middle: data[key].middle,
            min: data[key].min,
            max: data[key].max || 1
          }
        } else {
          normalizedData[key] = {
            ...dataSet[key],
            data: data[key].data,
            middle: data[key].middle,
            max: data[key].max || 1
          }
        }
      }
    })

    return normalizedData
  }

  const normalizedDataset = normalizeDataset(data, dataSet)

  const options = {
    chart: {
      height: height || '100%',
      width: width || '100%',
      type: 'line',
      background: '#024660',
      stacked: false,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    series: [
      {
        name: 'Corrente',
        type: 'column',
        data: [null, ...normalizedDataset.cc.data]

      },
      {
        name: 'Energia Modulo',
        type: 'line',
        data: [null, ...normalizedDataset.energy.data]
      }
    ],
    annotations: {
      position: 'back',
      yaxis: [
        {
          y: normalizedDataset.cc.middle,
          y2: 0,
          yAxisIndex: 0,
          width: '100%',
          border: '1px 0 0 0',
          borderColor: '#66ff00',
          fillColor: '#024660',
          strokeDashArray: 0,
          opacity: 1,
          label: {
            text: 'Corrente Media',
            position: 'left',
            borderWidth: 0,
            textAnchor: 'start',
            offsetX: 5,
            style: {
              background: 'rgba(0,0,0,0)',
              color: '#ecffde'
            }
          }
        },
        {
          y: normalizedDataset.energy.middle,
          y2: 0,
          yAxisIndex: 1,
          opacity: 1,
          width: '100%',
          borderColor: '#ff2f23',
          fillColor: '#024660',
          strokeDashArray: 0,
          label: {
            text: 'Energia Media',
            borderWidth: 0,
            textAnchor: 'start',
            position: 'left',
            offsetX: 5,
            style: {
              background: 'rgba(0,0,0,0)',
              color: '#ff2f23'
            }
          }
        }
      ]
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1],
      formatter: (value, { dataPointIndex }) => dataPointIndex === 0 ? '' : europeNum(value)
    },
    colors: [function ({ value, w }) {
      // Prendo l'array di dati della corrente
      const currentSeries = w.config && w.config.series && w.config.series.length > 0 && w.config.series[0].data
      if (currentSeries) {
        // Rimuovo il primo elemento che viene aggiunto manualmente per far leggere le soglie sul grafico
        const dataSeries = currentSeries.filter((el, index) => index !== 0)
        // Trovo l'indice dell'array basandomi sul valore attuale
        const currentIndex = dataSeries.findIndex(el => Number(el) === Number(value))
        if (currentIndex > -1) {
          // uso il colore calcolato dal backend in base all'indice trovato
          return underThreshold[currentIndex] ? normalizedDataset.cc.bar.color.min : normalizedDataset.cc.bar.color.max
        }
      }
      return normalizedDataset.energy.color
    }, function () {
      return normalizedDataset.energy.color
    }],
    stroke: {
      width: [4, 4]
    },
    plotOptions: {
      bar: {
        columnWidth: normalizedDataset.cc.bar.width
      }
    },
    xaxis: {
      categories: ['', ...normalizedDataset.cc.labels],
      labels: {
        style: {
          colors: '#fff'
        }
      },
      axisBorder: {
        show: false
      }
    },
    yaxis: [
      {
        seriesName: 'Current',
        max: (normalizedDataset?.cc?.max || 1) * (normalizedDataset?.cc?.yaxis?.max || 1),
        axisTicks: {
          show: false
        },
        axisBorder: {
          show: true,
          color: normalizedDataset.cc.bar.color.max
        },
        title: {
          text: 'Correnti di Stringa',
          style: {
            color: '#fff'
          }
        },
        labels: {
          formatter: (value) => { return Math.floor(Number(value)) + ' A' },
          style: {
            colors: '#fff'
          }
        }
      },
      {
        opposite: true,
        seriesName: 'Energy',
        // max:  ,
        max: (normalizedDataset?.energy?.max || 1) * (normalizedDataset?.energy?.yaxis?.max || 1),
        axisTicks: {
          show: false
        },
        axisBorder: {
          show: true,
          color: normalizedDataset.energy.color
        },
        title: {
          text: 'Energia Specifica Modulo',
          style: {
            color: '#fff'
          }
        },
        labels: {
          formatter: (value) => { return europeNum(value, 2) + ' kWh/mod' },
          style: {
            colors: '#fff'
          }
        }
      }
    ],
    tooltip: {
      marker: {
        fillColors: [normalizedDataset.cc.bar.color.max, normalizedDataset.energy.color]
      },
      y: {
        formatter: (value, { seriesIndex }) => {
          return seriesIndex === 0 ? `${europeNum(value)} A` : `${europeNum(value)} kWh/modulo`
        }
      }
    },
    grid: {
      show: false
    },
    legend: {
      horizontalAlign: 'left',
      offsetY: -10,
      offsetX: 40,
      markers: {
        fillColors: [normalizedDataset.cc.bar.color.max, normalizedDataset.energy.color]
      }
    },
    theme: {
      mode: 'dark'
    }
  }

  // console.log(data)

  return (
    <div className={clsx(classes.root, className)}>
      {Object.keys(data).length > 0
        // ? data.cc && data.cc.max && data.cc.max > 0 && data.energy && data.energy.max && data.energy.max > 0
        ? (
          <Chart
            options={options}
            series={options.series || undefined}
            type={(options.chart && options.chart.type) || 'line'}
            height={(options.chart && options.chart.height) || 0}
            width={(options.chart && options.chart.width) || 0}
          />)
      // : null
        : (
          <LoadingCard />)}
    </div>
  )
}
export default StringAnalysisChart
