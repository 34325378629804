import { useCallback, useEffect, useState, memo } from 'react'
import clsx from 'clsx'
import { Box, Button, Checkbox, Fab, FormControlLabel, Grid, makeStyles, Popover, Radio, RadioGroup, TextField, Tooltip, Typography } from '@material-ui/core'
import ShareIcon from '@material-ui/icons/Share'
import { configuration } from 'src/config'
import { useSnackbar } from 'notistack'
import api from 'src/utils/api'

const useStyles = makeStyles(() => ({
  root: {}
}))

const shareUrl = configuration.dashboardUrl

function ShareButton ({ userId = null, period = 'live', path = '', params = [], className, ...rest }) {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  // Elemento di ancoraggio del popover
  const [anchorEl, setAnchorEl] = useState(null)
  // Variabile che contiene l'url del link di condivisione
  const [url, setUrl] = useState(shareUrl)
  // Variabile che determina se mostrare o meno l'url
  const [showUrl, setShowUrl] = useState(false)
  // Variabile che contiene il numero di minuti per cui ogni slide deve essere mostrata
  const [loopDuration, setLoopDuration] = useState('')
  // Variabile che attiva o disattiva la modalità slideshow
  // const [slideshowMode, setSlideshowMode] = useState(false)
  // Variabile che determina la modalità selezionata
  const [selectedMode, setSelectedMode] = useState('current')
  // Oggetto che contiene gli stati di tutte le checkbox dei periodi selezionabili
  const [periodsToShow, setPeriodsToShow] = useState({
    showLive: period === 'live',
    showWeek: period === 'week',
    showMonth: period === 'month',
    showYear: period === 'year'
  })

  const periodsText = {
    live: 'Mostra "Live"',
    week: 'Mostra "Settimana"',
    month: 'Mostra "Mese"',
    year: 'Mostra "Anno"'
  }

  useEffect(() => {
    if (selectedMode !== undefined) {
      setPeriodsToShow({
        showLive: period === 'live',
        showWeek: period === 'week',
        showMonth: period === 'month',
        showYear: period === 'year'
      })
    }
  }, [period, selectedMode])

  // Funzione che setta l'elemento di ancoraggio del popover
  const handleClick = e => {
    setAnchorEl(e.currentTarget)
  }

  // Funzione che rimuove l'elemento di ancoraggio del popover
  const handleClose = () => {
    setAnchorEl(null)
  }

  // Funzione che gestisce la selezione/deselezione delle checkbox del periodo
  const handlePeriodCheck = (e) => {
    e.persist()
    setPeriodsToShow(prevPeriodToShow => ({
      ...prevPeriodToShow,
      [e.target.name]: e.target.checked
    }))
  }

  // Funzione che costruisce il link di condivisione in base ai parametri selezionati
  const buildShareLink = useCallback(() => {
    // Url di base
    const baseUrl = shareUrl
    const sharePath = ['shared']
    // in base alla pagina devo aggiungere la parte di url che mi dice quale componente devo renderizzre
    sharePath.push(path)
    // Se almeno un parametro è settato a true creo la stringa di parametry queryString e li aggancio all'url
    const havePeriods = Object.keys(periodsToShow).some(key => periodsToShow[key] === true)
    // Url se non ci sono parametri settati
    let newUrl = `${baseUrl}/${sharePath.join('/')}`
    if (havePeriods) {
      // Prendo solo i parametri settati a true
      const queryStringParams = Object.keys(periodsToShow)
        .filter(key => periodsToShow[key] === true)
        .map(key => `${key}=${periodsToShow[key]}`)

      newUrl = `${newUrl}?${queryStringParams.join('&')}`

      // Se è stato settato loopDuration
      if (loopDuration && loopDuration !== undefined && loopDuration !== '') {
        newUrl = `${newUrl}&loopDuration=${loopDuration}`
      }
    }

    // Se sono definiti dei parametri
    if (params.length > 0) {
      const paramsString = params.map(el => `${el.name}=${el.value}`).join('&')

      // Se non è stata scelta la modalità slideshow creo la queryString altrimenti concateno i parametri a quella già esistente
      newUrl = newUrl.includes('?') ? `${newUrl}&${paramsString}` : `${newUrl}?${paramsString}`
    }

    setUrl(newUrl)
  }, [periodsToShow, loopDuration, params, path])

  // Funzione che copia il link nella clipboard
  const generateLink = async () => {
    // Effettuo la chiamata POST che genera il token univoco da passare per gestire chiamate non autenticate
    try {
      const body = { url }
      // l'uuid dell'utente che genera il link, per mostrare i dati corretti disponibili per quell'utente
      if (userId) {
        body.userId = userId
      }
      const linkToken = await api.postResource('links', { body })
      const token = linkToken.uuid
      const shortUrl = `${shareUrl}/shared/${token}`
      setUrl(shortUrl)
      setShowUrl(true)
      enqueueSnackbar('Link generato correttamente!', { variant: 'success' })
    } catch (e) {
      enqueueSnackbar('Errore durante la generazione del link', { variant: 'error' })
    }
  }

  // UseEffect che permette di ricostruire il link ad ogni cambiamento di parametro
  useEffect(() => {
    buildShareLink()
  }, [buildShareLink])

  const openShareMenu = Boolean(anchorEl)
  const activePeriodsCount = Object.values(periodsToShow).filter(el => el === true).length

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Tooltip title='Condividi'>
        <Fab onClick={handleClick} size='small' color='primary'>
          <ShareIcon />
        </Fab>
      </Tooltip>
      <Popover
        open={openShareMenu}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box width='360px' p={2}>
          <Typography variant='h6' color='primary'>Condivisione schermata</Typography>
          <Box width='100%' mt={3}>
            {showUrl
              ? <TextField
                  fullWidth
                  size='small'
                  type='text'
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  variant='outlined'
                  label='Link Pubblico'
                />
              : null}
          </Box>
          <Box mt={2}>
            <RadioGroup value={selectedMode} onChange={(e) => setSelectedMode(e.target.value)}>
              <FormControlLabel value='current' control={<Radio color='primary' />} label={periodsText[period] || 'Mostra dati attuali'} />
              <FormControlLabel value='slideshow' control={<Radio color='primary' />} label='Modalità Slideshow' />
            </RadioGroup>
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={slideshowMode}
                  onChange={() => setSlideshowMode(prevSlideshowMode => !prevSlideshowMode)}
                  name='slideshowMode'
                  color='primary'
                />
              }
              label='Modalità Slideshow'
            /> */}
          </Box>
          <Box width='100%' mt={1} pl={2}>
            <Box width='100%'>
              <TextField
                disabled={selectedMode !== 'slideshow' || !(activePeriodsCount > 1)}
                fullWidth
                size='small'
                type='number'
                value={loopDuration}
                onChange={(e) => setLoopDuration(e.target.value)}
                variant='outlined'
                label='Durata loop (minuti)'
              />
            </Box>
            <Box width='100%'>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={selectedMode !== 'slideshow' || (periodsToShow.showLive === true && !(activePeriodsCount > 1))}
                    checked={periodsToShow.showLive}
                    onChange={handlePeriodCheck}
                    name='showLive'
                    color='primary'
                  />
                }
                label='Mostra "Live"'
              />
            </Box>
            <Box width='100%'>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={selectedMode !== 'slideshow' || (periodsToShow.showWeek === true && !(activePeriodsCount > 1))}
                    checked={periodsToShow.showWeek}
                    onChange={handlePeriodCheck}
                    name='showWeek'
                    color='primary'
                  />
                }
                label='Mostra "Settimana"'
              />
            </Box>
            <Box width='100%'>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={selectedMode !== 'slideshow' || (periodsToShow.showMonth === true && !(activePeriodsCount > 1))}
                    checked={periodsToShow.showMonth}
                    onChange={handlePeriodCheck}
                    name='showMonth'
                    color='primary'
                  />
                }
                label='Mostra "Mese"'
              />
            </Box>
            <Box width='100%'>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={selectedMode !== 'slideshow' || (periodsToShow.showYear === true && !(activePeriodsCount > 1))}
                    checked={periodsToShow.showYear}
                    onChange={handlePeriodCheck}
                    name='showYear'
                    color='primary'
                  />
                }
                label='Mostra "Anno"'
              />
            </Box>
          </Box>
          <Box mt={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box width='100%' display='flex' alignItems='center' justifyContent='flex-start'>
                  <Button
                    disabled={!showUrl}
                    onClick={() => navigator.clipboard.writeText(url)
                      .then(() => {
                        enqueueSnackbar('Link copiato correttamente!', { variant: 'success' })
                      })}
                    size='small'
                    color='primary'
                  >
                    Copia Link
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box width='100%' display='flex' alignItems='center' justifyContent='flex-end'>
                  <Button onClick={generateLink} size='small' color='primary' variant='contained'>Genera Link</Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Popover>
    </div>
  )
}
export default memo(ShareButton)
