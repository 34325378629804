import { useEffect, useState, memo } from 'react'
// import PropTypes from 'prop-types'
import moment from 'moment'
import { Box, Grid, Typography, useTheme } from '@material-ui/core'
import { useSelector } from 'react-redux'
// import useIsMountedRef from 'src/hooks/useIsMountedRef'
import DevicesTable from './DevicesTable'
import { europeNum, getDeviceLogs, getExcelLogs } from 'src/utils/general'
import ModalDeviceLogs from 'src/components/ModalDeviceLogs'
import ModalExcelDeviceLogs from 'src/components/ModalExcelDeviceLogs'
import fileDownload from 'js-file-download'
import { useSnackbar } from 'notistack'
import { useParams } from 'react-router'
import { useDispatch } from 'src/store'
import { updatedPlantInfo } from 'src/slices/pv/plantView'
import LoadingCard from 'src/components/LoadingCard'

// mi preparo i dati per i log
const logsParams = {
  deviceId: '',
  name: [],
  timeTo: null,
  timeFrom: null
}

// COMPONENTE PRINCIPALE
const Components = () => {
  const { plantId } = useParams()
  const theme = useTheme()
  const { hasError } = useSelector((state) => state.pvPlantView)
  const dispatch = useDispatch()

  useEffect(() => {
    if (plantId && plantId !== 'demo') {
      dispatch(updatedPlantInfo({ plantId }))
    }
    const overviewInterval = setInterval(() => {
      console.log('dentro interval')
      dispatch(updatedPlantInfo({ plantId }))
    }, 60000)
    return () => {
      clearInterval(overviewInterval)
      // dispatch(resetPlantInfo())
    }
  }, [dispatch, plantId, hasError])

  const { inverters, sunMeters, energyMeters, dataloggers } = useSelector((state) => state.pvPlantView)
  const { enqueueSnackbar } = useSnackbar()
  // mi preparo i dati per le tabelle

  // INVERTER
  const inverterColumns = [
    {
      value: 'name',
      label: 'Nome',
      minWidth: 120
    },
    {
      value: 'vac',
      label: 'Vac',
      minWidth: 70
    },
    {
      value: 'iac',
      label: 'Iac',
      minWidth: 70
    },
    {
      value: 'activepowertot',
      label: 'Potenza (kW)',
      minWidth: 120
    },
    {
      value: 'totalenergy',
      label: 'Energia (kWh)',
      minWidth: 130
    },
    {
      value: 'datalogger',
      label: 'Datalogger',
      minWidth: 120
    },
    {
      value: 'status',
      label: 'Stato',
      minWidth: 100
    },
    {
      value: 'actions',
      label: 'Azioni'
    }
  ]

  const sortedInverters = Object.assign([], inverters)
  // mi sorto gli inverter per inverter id
  sortedInverters.sort((a, b) => {
    if (!Object.prototype.hasOwnProperty.call(a.metadata, 'inverterNum')) {
      // viene prima b
      return 1
    }

    if (!Object.prototype.hasOwnProperty.call(b.metadata, 'inverterNum')) {
      // viene prima a
      return -1
    }

    return Number(a.metadata.inverterNum) - Number(b.metadata.inverterNum)
  })
  const inverterRows = sortedInverters.map((inverter) => {
    // console.log('rows: ', inverter)
    // const datasheetUrl = inverter.metadata && inverter.metadata.documents && inverter.metadata.documents.length > 0 ? inverter.metadata.documents[inverter.metadata.documents.length - 1].url : ''
    const inverterDocuments = inverter.metadata && inverter.metadata.documents ? inverter.metadata.documents : []
    const deviceTypeDocuments = inverter.deviceType && inverter.deviceType.metadata && inverter.deviceType.metadata.documents ? inverter.deviceType.metadata.documents : []
    let documents = deviceTypeDocuments.concat(inverterDocuments)

    if (documents.length === 0) {
      documents = null
    }

    const currentRow = {
      uuid: '',
      type: 'inverter',
      name: inverter.name,
      vac: 'N.F.',
      iac: 'N.F.',
      power: 'N.F.',
      energy: 'N.F.',
      // pr: 'N.F.',
      icc1: 'N.F',
      vcc1: 'N.F.',
      status: {
        general: null,
        communication: null
      },
      datalogger: 'N.F.',
      actions: {
        documents
        // datasheet: datasheetUrl
      }
    }
    // mi salvo l'uuid
    currentRow.uuid = inverter.uuid
    // cerco il datalogger
    const dataloggerIndex = dataloggers.findIndex((datalogger) => datalogger.uuid === inverter.nodeId)
    if (dataloggerIndex > -1) {
      currentRow.datalogger = dataloggers[dataloggerIndex].name
      // currentRow.status.communication =
      //   dataloggers[dataloggerIndex].connectivityStatus === 'connected' ? 'online' : 'offline'
    }
    // controllo se ho dati
    if (inverter.state && Object.keys(inverter.state).length > 0) {
      // controllo i dati che ho
      // comunicazione e stato
      if (Object.prototype.hasOwnProperty.call(inverter, 'lastCommunicationAt') && inverter.lastCommunicationAt !== 'never') {
        // controllo la differenza del periodo
        const a = moment()
        const b = moment(inverter.lastCommunicationAt)
        if (a.diff(b, 'minutes') >= 30) {
          currentRow.status.communication = 'offline'
        } else {
          currentRow.status.communication = 'online'
        }
      } else {
        currentRow.status.communication = 'offline'
      }
      if (Object.prototype.hasOwnProperty.call(inverter.metadata, 'status')) {
        currentRow.status.general = inverter.metadata.status
      } else {
        currentRow.status.general = 'ok'
      }
      // altri dati
      if (
        Object.prototype.hasOwnProperty.call(inverter.state, 'vac1') &&
        Object.prototype.hasOwnProperty.call(inverter.state, 'vac2') &&
        Object.prototype.hasOwnProperty.call(inverter.state, 'vac3')
      ) {
        currentRow.vac = (inverter.state.vac1 + inverter.state.vac2 + inverter.state.vac3) / 3
        currentRow.vac = europeNum(currentRow.vac, 2)
        // if (!propsLabels.inverter.vac1) {
        // propsLabels.inverter.vac1 = 'Vac1'
        // }
        // if (!propsLabels.inverter.vac2) {
        // propsLabels.inverter.vac2 = 'Vac2'
        // }
        // if (!propsLabels.inverter.vac3) {
        // propsLabels.inverter.vac3 = 'Vac3'
        // }
      } else if (Object.prototype.hasOwnProperty.call(inverter.state, 'vac1')) {
        currentRow.vac = europeNum(inverter.state.vac1, 2)
        // if (propsLabels.inverter.vac1) {
        // propsLabels.inverter.vac1 = 'Vac1'
        // }
      }
      if (
        Object.prototype.hasOwnProperty.call(inverter.state, 'iac1') &&
        Object.prototype.hasOwnProperty.call(inverter.state, 'iac2') &&
        Object.prototype.hasOwnProperty.call(inverter.state, 'iac3')
      ) {
        currentRow.iac = (inverter.state.iac1 + inverter.state.iac2 + inverter.state.iac3) / 3
        currentRow.iac = europeNum(currentRow.iac, 2)
        // if (!propsLabels.inverter.iac1) {
        // propsLabels.inverter.iac1 = 'Iac1'
        // }
        // if (!propsLabels.inverter.iac2) {
        // propsLabels.inverter.iac2 = 'Iac2'
        // }
        // if (!propsLabels.inverter.iac3) {
        // propsLabels.inverter.iac3 = 'Iac3'
        // }
      } else if (Object.prototype.hasOwnProperty.call(inverter.state, 'iac1')) {
        currentRow.iac = europeNum(inverter.state.iac1, 2)
        // if (!propsLabels.inverter.iac1) {
        // propsLabels.inverter.iac1 = 'Iac1'
        // }
      }
      Object.keys(inverter.state).forEach((key) => {
        // skippo le variabili che ho già analizzato
        if (key === 'activepowertot' || key === 'totalenergy') {
          currentRow[key] = europeNum(inverter.state[key])
        }
        // if (
        // key !== 'status' &&
        // key.indexOf('avg') === -1 &&
        // key.indexOf('delta') === -1 &&
        // key.indexOf('sum') === -1 &&
        // key !== 'iac1' &&
        // key !== 'iac2' &&
        // key !== 'iac3' &&
        // key !== 'vac1' &&
        // key !== 'vac2' &&
        // key !== 'vac3'
        // ) {
        // controllo gli icc e vcc
        // if (key.indexOf('icc') > -1) {
        // currentRow[key] = europeNum(inverter.state[key])
        // // controllo se c'è già la colonna
        // const iccColumnIndex = inverterColumns.findIndex((column) => {
        // return column.value === key
        // })
        // if (iccColumnIndex === -1) {
        // const iccToAdd = {
        // value: key,
        // label: capitalizeFirstLetter(key),
        // }
        // inverterColumns.splice(iccColumnIndex, 0, iccToAdd)
        // }
        // } else if (key.indexOf('vcc') > -1) {
        // currentRow[key] = europeNum(inverter.state[key])
        // // controllo se c'è già la colonna
        // const vccColumnIndex = inverterColumns.findIndex((column) => {
        // return column.value === key
        // })
        // if (vccColumnIndex === -1) {
        // const vccToAdd = {
        // value: key,
        // label: capitalizeFirstLetter(key),
        // }
        // inverterColumns.splice(vccColumnIndex, 0, vccToAdd)
        // }
        // } else {
        // currentRow[key] = europeNum(inverter.state[key])
        // }
        // if (key === 'activepowertot' || key === 'totalenergy') {
        // currentRow[key] = europeNum(inverter.state[key])
        // }
        // else {
        // if (!propsLabels.inverter[key]) {
        // propsLabels.inverter[key] = key
        // }
        // }
        // }
      })
    }

    return currentRow
  })
  // console.log('inverterRows => ', inverterRows)
  // Solarimetri
  const sunMeterColumns = [
    {
      value: 'name',
      label: 'Nome',
      minWidth: 120
    },
    {
      value: 'irradiation',
      label: 'Irraggiamento (W/mq)'
      // codice ashii &sup2;
    },
    {
      value: 'temperature',
      label: 'Temperatura moduli (°C)'
      // unit: 'kWh',
    },
    {
      value: 'datalogger',
      label: 'Datalogger',
      minWidth: 120
    },
    {
      value: 'status',
      label: 'Stato',
      minWidth: 100
    },
    {
      value: 'actions',
      label: 'Azioni'
    }
  ]
  const sunMeterRows = sunMeters.map((sunMeter) => {
    // const datasheetUrl = sunMeter.metadata && sunMeter.metadata.documents && sunMeter.metadata.documents.length > 0 ? sunMeter.metadata.documents[sunMeter.metadata.documents.length - 1].url : ''
    const sunMeterDocuments = sunMeter.metadata && sunMeter.metadata.documents ? sunMeter.metadata.documents : []
    const deviceTypeDocuments = sunMeter.deviceType && sunMeter.deviceType.metadata && sunMeter.deviceType.metadata.documents ? sunMeter.deviceType.metadata.documents : []
    let documents = deviceTypeDocuments.concat(sunMeterDocuments)

    if (documents.length === 0) {
      documents = null
    }
    const currentRow = {
      uuid: '',
      type: 'sunMeter',
      name: sunMeter.name,
      irradiation: 'N.F.',
      temperature: 'N.F.',
      datalogger: 'N.F.',
      status: {
        general: null,
        communication: null
      },
      actions: {
        documents
        // datasheet: datasheetUrl
      }
    }
    // mi salvo l'uuid
    currentRow.uuid = sunMeter.uuid
    // cerco il datalogger
    const dataloggerIndex = dataloggers.findIndex((datalogger) => datalogger.uuid === sunMeter.nodeId)
    if (dataloggerIndex > -1) {
      currentRow.datalogger = dataloggers[dataloggerIndex].name
      // currentRow.status.communication =
      //   dataloggers[dataloggerIndex].connectivityStatus === 'connected' ? 'online' : 'offline'
    }
    // controllo se ho dati
    if (sunMeter.state && Object.keys(sunMeter.state).length > 0) {
      // controllo i dati che ho
      // comunicazione e stato
      if (Object.prototype.hasOwnProperty.call(sunMeter, 'lastCommunicationAt') && sunMeter.lastCommunicationAt !== 'never') {
        // controllo la differenza del periodo
        const a = moment()
        const b = moment(sunMeter.lastCommunicationAt)
        if (a.diff(b, 'minutes') >= 30) {
          currentRow.status.communication = 'offline'
        } else {
          currentRow.status.communication = 'online'
        }
      } else {
        currentRow.status.communication = 'offline'
      }
      if (Object.prototype.hasOwnProperty.call(sunMeter.metadata, 'status')) {
        currentRow.status.general = sunMeter.metadata.status
      } else {
        currentRow.status.general = 'ok'
      }
      // altri dati
      Object.keys(sunMeter.state).forEach((key) => {
        currentRow[key] = europeNum(sunMeter.state[key], 2)
      })
      // if (Object.prototype.hasOwnProperty.call(sunMeter.state, 'irradiation')) {
      // // currentRow.power = (inverter.state.potenzaattiva / 1000).toFixed(0)
      // currentRow.irradiation = sunMeter.state.irradiation
      // currentRow.irradiation = europeNum(currentRow.irradiation, 2)
      // }
      // if (Object.prototype.hasOwnProperty.call(sunMeter.state, 'temperature')) {
      // currentRow.temperature = sunMeter.state.temperature
      // currentRow.temperature = europeNum(currentRow.temperature, 2)
      // }
    }

    return currentRow
  })
  // Solarimetri
  const energyMeterColumns = [
    {
      value: 'name',
      label: 'Nome',
      minWidth: 120
    },
    {
      value: 'expenergy',
      label: 'Energia immessa (kWh)'
    },
    {
      value: 'impenergy',
      label: 'Energia consumata (kWh)'
    },
    {
      value: 'activepowertot',
      label: 'Potenza tot (kW)'
    },
    {
      value: 'activepowerl1',
      label: 'Potenza L1 (kW)'
    },
    {
      value: 'activepowerl2',
      label: 'Potenza L2 (kW)'
    },
    {
      value: 'activepowerl3',
      label: 'Potenza L3 (kW)'
    },
    {
      value: 'currentl1',
      label: 'Corrente L1 (A)'
    },
    {
      value: 'currentl2',
      label: 'Corrente L2 (A)'
    },
    {
      value: 'currentl3',
      label: 'Corrente L3 (A)'
    },
    {
      value: 'voltagel1',
      label: 'Tensione L1 (V)'
    },
    {
      value: 'voltagel2',
      label: 'Tensione L2 (V)'
    },
    {
      value: 'voltagel3',
      label: 'Tensione L3 (V)'
      // unit: 'kWh',
    },
    {
      value: 'datalogger',
      label: 'Datalogger',
      minWidth: 120
    },
    {
      value: 'status',
      label: 'Stato',
      minWidth: 100
    },
    {
      value: 'actions',
      label: 'Azioni'
    }
  ]
  const energyMeterRows = energyMeters.map((energyMeter) => {
    // const datasheetUrl = energyMeter.metadata && energyMeter.metadata.documents && energyMeter.metadata.documents.length > 0 ? energyMeter.metadata.documents[energyMeter.metadata.documents.length - 1].url : ''
    const energyMeterDocuments = energyMeter.metadata && energyMeter.metadata.documents ? energyMeter.metadata.documents : []
    const deviceTypeDocuments = energyMeter.deviceType && energyMeter.deviceType.metadata && energyMeter.deviceType.metadata.documents ? energyMeter.deviceType.metadata.documents : []
    let documents = deviceTypeDocuments.concat(energyMeterDocuments)

    if (documents.length === 0) {
      documents = null
    }
    const currentRow = {
      uuid: '',
      type: 'energyMeter',
      name: energyMeter.name,
      activepowerl1: 'N.F.',
      activepowerl2: 'N.F.',
      activepowerl3: 'N.F.',
      activepowertot: 'N.F.',
      expenergy: 'N.F.',
      impenergy: 'N.F.',
      currentl1: 'N.F.',
      currentl2: 'N.F.',
      currentl3: 'N.F.',
      voltagel1: 'N.F.',
      voltagel2: 'N.F.',
      voltagel3: 'N.F.',
      datalogger: 'N.F.',
      status: {
        general: null,
        communication: null
      },
      actions: {
        documents
        // datasheet: datasheetUrl
      }
    }
    // mi salvo l'uuid
    currentRow.uuid = energyMeter.uuid
    // cerco il datalogger
    const dataloggerIndex = dataloggers.findIndex((datalogger) => datalogger.uuid === energyMeter.nodeId)
    if (dataloggerIndex > -1) {
      currentRow.datalogger = dataloggers[dataloggerIndex].name
      // currentRow.status.communication =
      //   dataloggers[dataloggerIndex].connectivityStatus === 'connected' ? 'online' : 'offline'
    }
    // controllo se ho dati
    if (energyMeter.state && Object.keys(energyMeter.state).length > 0) {
      // controllo i dati che ho
      // comunicazione e stato
      if (Object.prototype.hasOwnProperty.call(energyMeter, 'lastCommunicationAt') && energyMeter.lastCommunicationAt !== 'never') {
        // controllo la differenza del periodo
        const a = moment()
        const b = moment(energyMeter.lastCommunicationAt)
        if (a.diff(b, 'minutes') >= 30) {
          currentRow.status.communication = 'offline'
        } else {
          currentRow.status.communication = 'online'
        }
      } else {
        currentRow.status.communication = 'offline'
      }
      if (Object.prototype.hasOwnProperty.call(energyMeter.metadata, 'status')) {
        currentRow.status.general = energyMeter.metadata.status
      } else {
        currentRow.status.general = 'warning'
      }
      // currentRow.status.general = 'ok'
      // altri dati
      Object.keys(energyMeter.state).forEach((key) => {
        if (
          key !== 'cosphil1' &&
          key !== 'cosphil2' &&
          key !== 'cosphil3' &&
          key !== 'status' &&
          key.indexOf('avg') === -1 &&
          key.indexOf('delta') === -1 &&
          key.indexOf('sum') === -1
        ) {
          if (key === 'expenergy' || key === 'impenergy') {
            // const finalValue = Number(energyMeter.state[key]) / 1000
            const finalValue = Number(energyMeter.state[key])
            currentRow[key] = europeNum(finalValue, 2)
          } else {
            currentRow[key] = europeNum(energyMeter.state[key], 2)
          }
        }
      })
    }

    return currentRow
  })

  // PREPARO I DATI PER I LOG
  const now = moment()
    .set({ minute: 0, second: 0, millisecond: 0 })
    .toISOString()
  const [from, setFrom] = useState(moment(now).toISOString())
  const [to, setTo] = useState(
    moment(now)
      .add({ hours: '1' })
      .toISOString()
  )
  // console.log('energyMeterRows => ', energyMeterRows)

  const [openLogsModal, setOpenLogsModal] = useState(false)
  const [openExcelModal, setOpenExcelModal] = useState(false)
  const [logs, setLogs] = useState({
    uuid: '',
    name: '',
    data: {},
    columns: [],
    rows: []
  })
  const [isExcelTooBig, setIsExcelTooBig] = useState(false)

  // funzione che torna l'array con le colonne
  const generateLogColumns = (deviceProps, props) => {
    const arrayToSend = []
    if (deviceProps) {
      Object.keys(deviceProps).forEach((key) => {
        const label = deviceProps[key]?.uom && deviceProps[key]?.uom !== '-' ? `${deviceProps[key]?.displayName || '-'} (${deviceProps[key].uom})` : `${deviceProps[key]?.displayName || '-'}`

        const propObject = {
          prop: key,
          label
        }

        arrayToSend.push(propObject)
      })
    }
    return arrayToSend
  }

  // funzione che ritorna l'array delle righe
  const generateLogRows = (data) => {
    const arrayToReturn = []
    // mi scorro i valori di tutte le prop e mi salvo i valori ISOString che non ho
    Object.keys(data).forEach((key) => {
      data[key].forEach((elem) => {
        if (arrayToReturn.indexOf(elem[0]) === -1) {
          arrayToReturn.push(elem[0])
        }
      })
    })
    // Ordino l'array in base al tempo (dal più vicino al from fino al to)
    arrayToReturn.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0))
    return arrayToReturn
  }

  // Funzione che preso in ingresso l'oggetto dei logs di un singolo device restituisce lo stesso oggetto ma trascurando la diversità di secondi tra un messaggio e l'altro
  const approximateLogsToMinutesPrecision = (logsObj) => {
    if (logsObj) {
      return Object.keys(logsObj).reduce((acc, propKey) => {
        const newProp = logsObj[propKey]
          .map(dateValueEl => dateValueEl?.map((el, index) => index === 0 ? moment(el).format('DD/MM/YYYY HH:mm') : el))

        acc[propKey] = newProp

        return acc
      }, {})
    }

    return {}
  }

  // funzione che apre la modal dei log
  const openLogsDialog = async (row) => {
    // setto i valori nei parametri della query
    logsParams.deviceId = row.uuid
    // mi calcolo le giuste propsLabels
    const currentDevice = [...inverters, ...energyMeters, ...sunMeters].find(device => device.uuid === row.uuid)
    const newColumns = generateLogColumns(currentDevice.deviceType?.properties, currentDevice?.state || null)

    logsParams.name = newColumns.map((col) => col.prop)
    logsParams.timeFrom = from
    logsParams.timeTo = to
    const newData = await getDeviceLogs(logsParams)

    // mi preparo le righe
    const formattedData = approximateLogsToMinutesPrecision(newData[currentDevice?.uuid] || {})

    const newRows = generateLogRows(formattedData)

    setLogs({
      uuid: logsParams.deviceId,
      name: row.name,
      columns: newColumns,
      rows: newRows,
      data: formattedData
    })

    setOpenLogsModal(true)
  }

  // funzione che viene invocata alla chiusura della modal dei log
  const handleCloseLogsModal = () => {
    setOpenLogsModal(false)
  }

  // funzione che aggiorna i dati
  const onPeriodChange = async (newFrom, newTo) => {
    logsParams.timeFrom = moment(newFrom).toISOString()
    logsParams.timeTo = moment(newTo).toISOString()
    setFrom(logsParams.timeFrom)
    setTo(logsParams.timeTo)

    const newData = await getDeviceLogs(logsParams)

    const key = Object.keys(newData)
    const formattedData = approximateLogsToMinutesPrecision(newData[key] || {})
    const newRows = generateLogRows(formattedData)

    setLogs({ ...logs, data: formattedData, rows: newRows })
  }

  // funzione che apre la modal per scaricare Excel
  const toogleDownloadExcel = () => {
    setOpenExcelModal(true)
  }

  // funzione che viene invocata alla chiusura della modal dei log
  const handleCloseExcelModal = () => {
    setOpenExcelModal(false)
  }

  // funzione che scarica l'excel
  const downloadExcel = async (from, to, selectedAggregation) => {
    setIsExcelTooBig(false)
    // console.log('downloadExcel from => ', from)
    // console.log('downloadExcel to => ', to)
    const excelParams = JSON.parse(JSON.stringify(logsParams))
    excelParams.timeFrom = moment(from).toISOString()
    excelParams.timeTo = moment(to).toISOString()
    excelParams.aggregationType = selectedAggregation || 'quarter'
    // console.log('excelParams => ', excelParams)
    try {
      const excelResponse = await getExcelLogs(excelParams)
      // console.log('excelResponse => ', excelResponse)
      // controllo se è ritornato il file o se manda email
      if (excelResponse.status === 202) {
        // mando l'excel per email
        // console.log('MANDO LA MAILLLLL')
        setIsExcelTooBig(true)
      } else {
        // scarico il blob
        fileDownload(
          excelResponse.data,
          `LOGS ${logs.name} - dal ${moment(from).format('DD-MM-YY (HH)')} al ${moment(to).format(
            'DD-MM-YY (HH)'
          )} - dati tipo ${selectedAggregation || 'quarter'}.xlsx`
        )
      }
    } catch (err) {
      console.error('get excel err => ', err)
      if (String(err).indexOf('404') > -1) {
        enqueueSnackbar('Attenzione: non ci sono dati per il tipo di aggregazione richiesta.', {
          variant: 'warning'
        })
      } else {
        enqueueSnackbar('Errore nel richiedere i log in Excel, per favore aggiornare la pagina e riprovare.', {
          variant: 'error'
        })
      }
    }
  }

  const computedContainerHeight = `calc(100% - 48px - ${theme.spacing(6)}px - 80px)`

  return (
    <Box mt={1} pb={3} style={{ height: computedContainerHeight, overflowX: 'hidden' }}>
      {dataloggers[0] === '-'
        ? (
          <LoadingCard />)
        : dataloggers.length === 0
          ? (
            <Typography variant='body1' style={{ color: 'white', textAlign: 'center' }}>Non ci sono dispositivi per questo impianto.</Typography>)
          : (
            <Grid container spacing={2}>
              {inverters.length > 0 && (
                <Grid item xs={12}>
                  <DevicesTable
                    columns={inverterColumns}
                    rows={inverterRows}
                    title='Dati Inverter'
                    handleRowClick={openLogsDialog}
                  />
                </Grid>
              )}
              {sunMeters.length > 0 && (
                <Grid item xs={12}>
                  <DevicesTable
                    columns={sunMeterColumns}
                    rows={sunMeterRows}
                    title='Dati Solarimetri'
                    handleRowClick={openLogsDialog}
                  />
                </Grid>
              )}
              {energyMeters.length > 0 && (
                <Grid item xs={12}>
                  <DevicesTable
                    columns={energyMeterColumns}
                    rows={energyMeterRows}
                    title='Dati Energy Meter'
                    handleRowClick={openLogsDialog}
                  />
                </Grid>
              )}
              {energyMeters.length === 0 && inverters.length === 0 && sunMeters.length === 0 && (
                <Grid item xs={12}>
                  <Typography variant='h5' style={{ color: 'white' }}>
                    Non ci sono dispositivi sul/sui Datalogger di questo impianto.
                  </Typography>
                </Grid>
              )}
              <ModalDeviceLogs
                open={openLogsModal}
                onClose={handleCloseLogsModal}
                data={logs.data}
                columnsLabels={logs.columns}
                initialRows={logs.rows}
                name={logs.name}
                onPeriodChange={onPeriodChange}
                initialFrom={from}
                initialTo={to}
                toogleDownloadExcel={toogleDownloadExcel}
              />
              <ModalExcelDeviceLogs
                open={openExcelModal}
                onClose={handleCloseExcelModal}
                deviceId={logs.uuid}
                name={logs.name}
                initialFrom={from}
                initialTo={to}
                downloadExcel={downloadExcel}
                isExcelTooBig={isExcelTooBig}
              />
            </Grid>)}
    </Box>
  )
}

export default memo(Components)
