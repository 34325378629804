import { useState, useEffect, useCallback } from 'react'
// import clsx from 'clsx'
import PropTypes from 'prop-types'
import { basicColors } from 'src/theme'
import moment from 'moment'
// import { copyTextToClipboard, openInNewTab } from 'src/utils/general'
import { copyTextToClipboard } from 'src/utils/general'
import { returnDuration } from '../pv/utils'
import ConfirmationDialogRaw from 'src/components/ConfirmationDialogRaw'
import {
  Link,
  Box,
  Drawer,
  AppBar,
  Toolbar,
  Paper,
  CircularProgress,
  Tooltip,
  SvgIcon,
  IconButton,
  Fab,
  Typography,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
  TableHead,
  useMediaQuery,
  TextField
  // withStyles,
} from '@material-ui/core'
// import { useSnackbar } from 'notistack'

import {
  X as CloseIcon,
  Slash as ForceCloseIcon,
  Edit3 as EditIcon,
  // ExternalLink as OpenLinkIcon,
  Copy as CopyIcon,
  Check as OkIcon
} from 'react-feather'
import { alog } from 'src/utils/apioLog'
import useAuth from 'src/hooks/useAuth'

// stile CSS generale
const useStyles = makeStyles((theme) => ({
  sidenavContainer: {
    width: '45vw'
    // maxWidth: '65vw',
  },
  title: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  }
}))

// componente principale
const AnomalySidenav = ({ className, open, anomaly, getAnomaliesDevices, onForceClose, onEdit, onClose }) => {
  const classes = useStyles()
  // const { enqueueSnackbar } = useSnackbar()
  // console.log('sidenav anomaly =>', anomaly)
  const isExtraSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const isMedium = useMediaQuery((theme) => theme.breakpoints.down('md'))
  // const now = moment()
  const [resources, setResources] = useState({
    hasError: false,
    devices: []
  })
  const [gettingResources, setGettingResources] = useState(true)
  // variabile che gestisce apertura alert conferma chiusura anomalia
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false)
  // variabile che specifica se editare le informazioni
  const [isEditing, setIsEditing] = useState(false)
  // variabile con note e link
  const [notes, setNotes] = useState(anomaly.notes)
  const [link, setLink] = useState(anomaly.externalUrl)

  const { user } = useAuth()

  const permissions = user.appPermissions || []

  useEffect(() => {
    setNotes(anomaly.notes)
    setLink(anomaly.externalUrl)
    // return () => {}
  }, [anomaly])

  // funzione che cambia lo stato di modifica
  const toogleEditAnomaly = () => {
    setIsEditing(!isEditing)
  }
  // funzione che conferma / salva le modifiche
  const confirmEdits = (newAnomaly) => {
    alog('dentro confirmEdits', null, 'anomalia')
    try {
      onEdit(newAnomaly)
      setIsEditing(false)
    } catch (err) {
      console.error('confirmEdits err => ', err)
    }
  }

  // funzione che gestisce l'apertura conferma chiusura anomalia
  const handleOpenConfirmDelete = () => {
    setOpenConfirmDelete(true)
  }
  // funzione che gestisce chiusura conferma chiusura anomalia
  const handleCloseConfirmDelete = async (confirmDelete) => {
    // console.log('dentro close confirm delete, confirmDelete => ', confirmDelete)
    if (confirmDelete) {
      // console.log('ho confermato')
      onForceClose(anomaly)
    } else {
      // console.log('non ho confermato')
    }
    setOpenConfirmDelete(false)
  }

  // funzione che prende i device
  const getResources = useCallback(async () => {
    // prendo le risorse associate
    // console.log('sidenav anomaly =>', anomaly)
    if (getAnomaliesDevices && anomaly.uuid) {
      const newResources = await getAnomaliesDevices(anomaly.uuid)
      // console.log('newResources => ', newResources)
      setResources(newResources)
      setGettingResources(false)
    }
  }, [anomaly])

  return (
    <Drawer anchor='right' open={open} onClose={onClose} ref={getResources}>
      <Box
        display='block'
        className={classes.sidenavContainer}
        style={{ width: isExtraSmall ? '100vw' : isSmall ? '78vw' : isMedium ? '55vw' : '45vw' }}
      >
        <AppBar position='sticky'>
          <Toolbar
            style={{
              borderLeft: `6px solid ${anomaly.subCategory && anomaly.subCategory.color ? anomaly.subCategory.color : basicColors.primary}`,
              backgroundColor: basicColors.secondary,
              paddingLeft: 16,
              paddingRight: 16
            }}
          >
            <Typography variant='h4' className={classes.title} display='block' noWrap>
              Anomalia: {anomaly.name}
            </Typography>
            <div className={classes.grow} />
            <IconButton aria-label='Chiudi anomalia' onClick={onClose} color='inherit'>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div style={{ padding: 16 }}>
          <Grid container spacing={4} style={{ marginBottom: 16 }}>
            <Grid item xs={12}>
              <Typography variant='h4' display='block' gutterBottom>
                Dettagli
              </Typography>
              <TableContainer component={Paper}>
                <Table aria-labelledby='anomalyStatus' aria-label='Stato generico anomalia'>
                  <TableBody>
                    <TableRow>
                      <TableCell variant='head' style={{ minWidth: 150 }}>
                        Stato
                      </TableCell>
                      <TableCell>
                        {!anomaly.endedAt || anomaly.endedAt === ''
                          ? (
                            <Grid container alignItems='center' justifyContent='center'>
                              <Grid item xs><b style={{ color: basicColors.lightYellow }}>Aperta</b></Grid>
                              {permissions.includes('assetsmanager.plants.manage')
                                ? (
                                  <Grid item>
                                    <Tooltip title='Chiudi Anomalia' aria-label='Chiudi Anomalia'>
                                      <Fab size='small' onClick={() => { handleOpenConfirmDelete() }} style={{ backgroundColor: basicColors.darkRed }}>
                                        <SvgIcon fontSize='small'>
                                          <ForceCloseIcon color={basicColors.whiteText} />
                                        </SvgIcon>
                                      </Fab>
                                    </Tooltip>
                                  </Grid>
                                  )
                                : null}
                            </Grid>
                            )
                          : (<b style={{ color: basicColors.lightGreen }}>Chiusa</b>)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant='head'>Risorse coinvolte</TableCell>
                      <TableCell>
                        {/* {anomaly.resourceType || '-'} */}
                        {gettingResources && !resources.hasError
                          ? (<CircularProgress className={classes.cicle} variant='indeterminate' size={20} thickness={6} />)
                          : resources.hasError
                            ? 'C\'è stato un errore nel reperire le risorse coinvolte.'
                            : resources.devices.length === 0
                              ? 'Non ci sono dispositivi associati a questa anomalia'
                              : resources.devices.map(device => device.name).join(', ')}
                      </TableCell>
                    </TableRow>
                    {anomaly.severity && (!anomaly.endedAt || anomaly.endedAt === '')
                      ? (
                        <TableRow>
                          <TableCell variant='head'>Severity attuale</TableCell>
                          <TableCell>{anomaly.severity[anomaly.severity.length - 1].level}</TableCell>
                        </TableRow>
                        )
                      : null}
                    <TableRow>
                      <TableCell variant='head'>Inizio</TableCell>
                      <TableCell>{moment(anomaly.startedAt).format('DD/MM/yyyy HH:mm') || '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant='head'>Fine</TableCell>
                      <TableCell>
                        {anomaly.endedAt && anomaly.endedAt !== ''
                          ? moment(anomaly.endedAt).format('DD/MM/yyyy HH:mm')
                          : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant='head'>Durata</TableCell>
                      <TableCell>{returnDuration(anomaly.duration)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant='head'>Nome</TableCell>
                      <TableCell>{anomaly.name || '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant='head'>Descrizione</TableCell>
                      <TableCell>{anomaly.description || '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant='head'>Categoria</TableCell>
                      <TableCell>
                        {anomaly.category && `${anomaly.category} > `}{anomaly.subCategory && anomaly.subCategory.label && `${anomaly.subCategory.label}`}
                        {!anomaly.category && (!anomaly.subCategory || !anomaly.subCategory.label) && '-'}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems='center' justifyContent='center'>
                <Grid item xs>
                  <Typography variant='h5' display='block' gutterBottom>
                    Informazioni aggiuntive
                  </Typography>
                </Grid>
                {permissions.includes('assetsmanager.plants.manage')
                  ? (
                    <Grid item>
                      {!isEditing && (
                        <Tooltip title='Modifica Anomalia' aria-label='Modifica informazioni'>
                          <IconButton onClick={() => { toogleEditAnomaly() }}>
                            <SvgIcon size='small'>
                              <EditIcon color={basicColors.darkYellow} />
                            </SvgIcon>
                          </IconButton>
                        </Tooltip>
                      )}
                      {isEditing && (
                        <Tooltip title='Chiudi Modifica' aria-label='Chiudi Modifica'>
                          <IconButton onClick={() => { toogleEditAnomaly() }}>
                            <SvgIcon size='small'>
                              <CloseIcon color={basicColors.darkYellow} />
                            </SvgIcon>
                          </IconButton>
                        </Tooltip>
                      )}
                      {isEditing && (
                        <Tooltip title='Conferma modifiche' aria-label='Conferma modifiche'>
                          <IconButton
                            onClick={() => {
                              const newAnomaly = JSON.parse(JSON.stringify(anomaly))
                              newAnomaly.notes = notes
                              newAnomaly.externalUrl = link
                              if (!newAnomaly.externalUrl.startsWith('http')) {
                                newAnomaly.externalUrl = 'https://' + newAnomaly.externalUrl
                              }
                              confirmEdits(newAnomaly)
                            }}
                          >
                            <SvgIcon size='small'>
                              <OkIcon color={basicColors.darkGreen} />
                            </SvgIcon>
                          </IconButton>
                        </Tooltip>
                      )}
                    </Grid>
                    )
                  : null}
              </Grid>
              <TableContainer component={Paper}>
                <Table aria-labelledby='anomalyStatus' aria-label='Stato generico anomalia'>
                  <TableBody>
                    <TableRow>
                      <TableCell variant='head'>Link esterno</TableCell>
                      <TableCell>
                        {!isEditing && (
                          <Grid container alignItems='center' justifyContent='space-between'>
                            <Grid item style={{ maxWidth: '280px' }}>
                              {anomaly.externalUrl
                                ? (
                                  <Typography component='div' noWrap>
                                    <Link href={anomaly.externalUrl} noWrap target='_blank' rel='noopener noreferrer' variant='h5'>
                                      {anomaly.externalUrl}
                                    </Link>
                                  </Typography>
                                  )
                                : <Typography variant='body2'>Nessun link inserito...</Typography>}
                            </Grid>
                            <Grid item>
                              <Tooltip title='Copia Link' aria-label='Copia Link'>
                                <IconButton onClick={() => { copyTextToClipboard(anomaly.externalUrl) }}>
                                  <SvgIcon>
                                    <CopyIcon color={basicColors.whiteText} />
                                  </SvgIcon>
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            {/* <Grid item>
                              <Tooltip title='Apri Link' aria-label='Apri Link'>
                                <IconButton onClick={() => { openInNewTab(anomaly.externalUrl) }}>
                                  <SvgIcon>
                                    <OpenLinkIcon color={basicColors.primary} />
                                  </SvgIcon>
                                </IconButton>
                              </Tooltip>
                            </Grid> */}
                          </Grid>
                        )}
                        {isEditing && (
                          <TextField
                            id='link'
                            fullWidth
                            name='link'
                            value={link || ''}
                            placeholder='Link esterno'
                            variant='outlined'
                            // style={{ marginTop: 20 }}
                            onChange={(event) => {
                              // alog('new plantStatusNotes =>', event.target.value, 'report')
                              const newLink = event.target.value
                              setLink(newLink)
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant='head'>Note</TableCell>
                      <TableCell>
                        <TextField
                          id='notes'
                          fullWidth
                          // label='Note'
                          disabled={!isEditing}
                          name='notes'
                          multiline
                          value={notes || ''}
                          placeholder='Non ci sono note'
                          minRows={4}
                          maxRows={6}
                          variant='outlined'
                          // style={{ marginTop: 20 }}
                          onChange={(event) => {
                            // alog('new plantStatusNotes =>', event.target.value, 'report')
                            const newNote = event.target.value
                            setNotes(newNote)
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h4' display='block' gutterBottom>
                Severity
              </Typography>
              <TableContainer component={Paper}>
                <Table aria-labelledby='anomalyStatus' aria-label='Stato generico anomalia'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Livello</TableCell>
                      <TableCell>Inizio</TableCell>
                      <TableCell>Fine</TableCell>
                      <TableCell>Durata</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {anomaly.severity && anomaly.severity.length > 0
                      ? (anomaly.severity.map((sev, index) => {
                          return (
                            <TableRow key={index}>
                            <TableCell>{sev.level || '-'}</TableCell>
                            <TableCell>
                              {sev.startedAt && sev.startedAt !== ''
                                ? moment(sev.startedAt).format('DD/MM/yyyy HH:mm')
                                : '-'}
                            </TableCell>
                            <TableCell>
                              {sev.endedAt && sev.endedAt !== '' ? moment(sev.endedAt).format('DD/MM/yyyy HH:mm') : '-'}
                            </TableCell>
                            <TableCell>
                              {sev.duration
                                ? returnDuration(sev.duration)
                                : '-'}
                            </TableCell>
                          </TableRow>
                          )
                        }))
                      : (
                        <TableRow>
                          <TableCell colSpan={4}>Non ci sono severity.</TableCell>
                        </TableRow>
                        )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <ConfirmationDialogRaw
            open={openConfirmDelete}
            onClose={handleCloseConfirmDelete}
            title='Conferma eliminazione Datalogger'
            description={'Sei sicuro di voler chiudere l\'anomalia?'}
          />
        </div>
      </Box>
    </Drawer>
  )
}

AnomalySidenav.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool.isRequired,
  anomaly: PropTypes.object,
  getAnomaliesDevices: PropTypes.func,
  onClose: PropTypes.func,
  onForceClose: PropTypes.func,
  onEdit: PropTypes.func
}

AnomalySidenav.defaultProps = {
  open: false,
  anomaly: {}
}

export default AnomalySidenav
