import { useEffect, useState, memo } from 'react'
import clsx from 'clsx'
import { Fab, makeStyles, Tooltip } from '@material-ui/core'
import { Download as DownloadIcon } from 'react-feather'
import api from 'src/utils/api'
import fileDownload from 'js-file-download'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { getDatesFromPeriod } from '../pv/utils'
import { alog } from 'src/utils/apioLog'

const useStyles = makeStyles(() => ({
  root: {}
}))

// Componente che permette di scaricare un excel della view di produzione/consumi
/*
  * In ingresso prende 3 proprietà
  * 1. plantId -> uuid dell'impianto di cui vogliamo scaricare i dati (required)
  * 2. exportType -> tipo di view (e di conseguenza tipo di chiamata alle API) della quale vogliamo scaricare i dati (required)
  * 3. description -> testo mostrato nel tooltip all'hover del mouse
  * 4. plantName -> Nome dell'impianto che stiamo visualizzando, serve per avere il nome corretto del file. Di default sarà 'Impianto'
  * 5. from -> data di partenza della visualizzazione (required)
  * 6. to -> data di fine della visualizzazione (required)
  * NB: In caso una tra plantId o exportType non vengono passate o sono null, il bottone sarà disabilitato
*/

function DownloadExcelButton ({ calculateYear = null, selectedDate = null, period = null, plantName = 'Impianto', plantId = null, exportType = null, description = '', className }) {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const [from, setFrom] = useState(null)
  const [to, setTo] = useState(null)

  const viewType = {
    production: 'Produzione',
    consumption: 'Consumi'
  }

  useEffect(() => {
    alog('selectedDate', selectedDate, 'downloadButton')
    alog('period', period, 'downloadButton')
    if (period) {
      const periodDate = (selectedDate && selectedDate.toDate()) || new Date()
      if (calculateYear && period === 'year') {
        const { baselineStartDate, baselineEndDate } = calculateYear()
        setFrom(baselineStartDate)
        setTo(baselineEndDate)
      } else {
        const { minDate, maxDate } = getDatesFromPeriod(period, periodDate)
        setFrom(minDate)
        setTo(maxDate)
      }
    }
  }, [period, selectedDate])

  // Funzione che compone il nome del file da scaricare
  const composeFileName = () => {
    return from && to
      ? `${plantName} - ${viewType[exportType] || ''} dal ${moment(from).format('DD-MM-YY HH-mm')} al ${moment(to).format('DD-MM-YY HH-mm')}.xlsx`
      : `${plantName} - ${viewType[exportType] || ''}.xlsx`
  }

  // Funzione che esegue la chiamata per avere il blob del file excel da poter scaricare
  const downloadExcel = async () => {
    const timeFrom = moment(from).toISOString()
    const timeTo = moment(to).toISOString()
    if (plantId && exportType) {
      try {
        const blob = await api.getResource('plantDetails', { path: `/${plantId}/${exportType}/export?timeFrom=${timeFrom}&timeTo=${timeTo}`, responseType: 'blob' })
        alog('blob: ', blob, 'downloadButton')
        if (blob) {
          fileDownload(
            blob,
            composeFileName()
          )
        }
      } catch (e) {
        enqueueSnackbar('Errore nel richiedere i dati Excel, per favore aggiornare la pagina e riprovare.', {
          variant: 'error'
        })
      }
    }
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Tooltip title={description}>
        <span>
          <Fab disabled={!plantId || !exportType} onClick={() => downloadExcel()} size='small' color='primary'>
            <DownloadIcon />
          </Fab>
        </span>
      </Tooltip>
    </div>
  )
}
export default memo(DownloadExcelButton)
