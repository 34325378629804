// oggetto con le configurazioni generali della piattaforma
export const configuration = {
  apiUrl: process.env.REACT_APP_API_BASE_URL || 'https://api.test.venera.apio.network',
  projectId: process.env.REACT_APP_PROJECTID || 'venera',
  dashboardUrl: process.env.REACT_APP_DASHBOARD_BASE_URL || 'https://app.test.venera.apio.network',
  dataloggerAddress: process.env.REACT_APP_DATALOGGER_ADDRESS || 'broker.test.venera.apio.network',
  dataloggerUsername: process.env.REACT_APP_DATALOGGER_USERNAME || '/projects/venera/nodes/',
  dataloggerUsernameZpass: process.env.REACT_APP_DATALOGGER_USERNAME_ZPASS || '_projects_venera_nodes_',
  dataloggerTopic: process.env.REACT_APP_DATALOGGER_TOPIC || 'apio/core/projects/venera/telemetry/uplink',
  logTag: process.env.REACT_APP_APIO_LOG || null,
  // webSocket: process.env.REACT_APP_WEB_SOCKET || 'wss://ws.venera.cloud',
  webSocket: process.env.REACT_APP_WEB_SOCKET || 'wss://ws.test.venera.apio.network',
  sentry: {
    dsn: 'https://0f1cc5a77c3e447bb59a1d4a69138c0f@sentry.apio.cloud/46',
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT
  }
  // environment: process.env.REACT_APP_ENV,
  // smartlook: {
  //   apiKey: '921799a88e1f5fb5267f7a1bd3a3c63b5c80d642'
  // }
}

// variabile con la struttura dei permessi
export const assetsManagerPermissions = [
  {
    value: 'assetsmanager.nodes.manage',
    name: 'Gestione Datalogger',
    description: 'Creazione e modifica dei Datalogger e dei Dispositivi che gli sono assegnati.',
    defaultRoute: '/app/management/devices',
    acceptedRoutes: [
      '/app/management/devices'
      // '/app/settings/account'
    ]
  },
  {
    value: 'assetsmanager.plants.manage',
    name: 'Gestione Impianti',
    description: 'Creazione e modifica degi dati anagrafici degli Impianti che gli sono assegnati.',
    defaultRoute: '/app/management/plants',
    acceptedRoutes: [
      '/app/management/plants',
      '/app/overview/anomalies'
      // '/app/settings/account'
    ]
  },
  {
    value: 'assetsmanager.plants.access',
    name: 'Visualizzazione Impianti',
    description: 'Visualizzazione dei dati associati agli Impianti che gli sono assegnati.',
    defaultRoute: '/app/overview/maps',
    acceptedRoutes: [
      '/app/overview/maps',
      '/app/overview/analytics',
      // '/app/overview/anomalies',
      '/app/view/plant'
      // '/app/settings/account'
    ]
  },
  {
    value: 'assetsmanager.reports.board',
    name: 'Gestione Report di Board',
    description: 'Visualizzazione, creazione e modifica dei Report di Board.',
    defaultRoute: '/app/overview/reports',
    acceptedRoutes: [
      '/app/overview/reports'
      // '/app/settings/account'
    ]
  },
  {
    value: 'assetsmanager.reports.plants',
    name: 'Gestione Report degli Impianti',
    description: 'Visualizzazione, creazione e modifica dei Report degli Impianti.',
    defaultRoute: '/app/view/plant',
    acceptedRoutes: [
      '/app/view/plant'
      // '/app/settings/account'
    ]
  },
  {
    value: 'assetsmanager.users.manage',
    name: 'Gestione Utenti',
    description: 'Creazione e modifica degli Utenti e del Ruolo che hanno assegnato.',
    defaultRoute: '/app/management/users',
    acceptedRoutes: [
      '/app/management/users'
      // '/app/settings/account'
    ]
  },
  {
    value: 'assetsmanager.roles.manage',
    name: 'Gestione Ruoli',
    description: 'Creazione e modifica dei Ruoli dell\'Organizzazione.',
    defaultRoute: '/app/management/roles',
    acceptedRoutes: [
      '/app/management/roles',
      '/app/management/groups'
      // '/app/settings/account'
    ]
  },
  {
    value: 'assetsmanager.alarms.components',
    name: 'Notifica Allarmi dei Componenti',
    description: 'Ricezione delle notifiche relative agli allarmi dei componenti assegnati.',
    defaultRoute: '/app/settings/account',
    acceptedRoutes: []
  },
  {
    value: 'assetsmanager.alarms.performances',
    name: 'Notifica Allarmi della Performance',
    description: 'Ricezione delle notifiche relative agli allarmi di performance degli impianti assegnati.',
    defaultRoute: '/app/settings/account',
    acceptedRoutes: []
  },
  {
    value: 'assetsmanager.alarms.communication',
    name: 'Notifica Allarmi di Comunicazione',
    description: 'Ricezione delle notifiche relative agli allarmi di comunicazione degli impianti assegnati.',
    defaultRoute: '/app/settings/account',
    acceptedRoutes: []
  }
]

// variabile con i permessi backend in base ad i permessi frontend
// default full backend permission
export const allBackendPermissions = {
  'assetsmanager.nodes.manage': [
    'apio.core.devices.read',
    'apio.core.devices.write',
    'apio.core.devicetypes.read',
    // 'apio.core.devicetypes.write',
    'apio.core.nodetypes.read',
    // 'apio.core.nodetypes.write',
    'apio.core.nodes.read',
    'apio.core.nodes.write',
    'apio.telemetry.data.read',
    'apio.authentication.apikeys.read',
    'apio.authentication.apikeys.write',
    'apio.telemetry.data.write',
    'apio.authentication.aclrules.read',
    'apio.authentication.roles.read',
    'apio.authentication.groups.read',
    'apio.authentication.groups.write'
  ],
  'assetsmanager.plants.manage': [
    'apio.core.plants.read',
    'apio.core.plants.write',
    'apio.core.nodes.read',
    'apio.core.nodes.write',
    'apio.core.devices.read',
    'apio.core.devices.write',
    'apio.core.devicetypes.read',
    // 'apio.core.devicetypes.write',
    'apio.core.nodetypes.read',
    // 'apio.core.nodetypes.write',
    'apio.authentication.aclrules.read',
    'apio.authentication.roles.read',
    'apio.authentication.groups.read',
    'apio.authentication.groups.write'
  ],
  'assetsmanager.plants.access': [
    'apio.core.plants.read',
    'apio.core.plants.write',
    'apio.core.nodes.read',
    'apio.core.nodes.write',
    'apio.core.devices.read',
    'apio.core.devices.write',
    'apio.core.devicetypes.read',
    // 'apio.core.devicetypes.write',
    'apio.core.nodetypes.read',
    // 'apio.core.nodetypes.write',
    'apio.telemetry.data.read'
  ],
  'assetsmanager.reports.board': [
    'apio.core.plants.read'
  ],
  'assetsmanager.reports.plants': [
    'assetsmanager.reports.plants'
  ],
  'assetsmanager.users.manage': [
    'apio.authentication.aclrules.read',
    'apio.authentication.aclrules.write',
    'apio.authentication.roles.read',
    'apio.authentication.groupmemberships.read',
    'apio.authentication.groupmemberships.write',
    'apio.authentication.groups.read',
    'apio.authentication.groups.write'
  ],
  'assetsmanager.roles.manage': [
    'apio.authentication.roles.read',
    'apio.authentication.roles.write'
  ]
  // 'assetsmanager.alarms.components': [],
  // 'assetsmanager.alarms.performances': [],
  // 'assetsmanager.alarms.communication': []
}

// funzione che torna i backend permissions
export const returnBackendPermissions = (appPermissions) => {
  // mi sistemo i permessi backend
  const thisBackPerms = ['apio.core.projects.read']
  appPermissions.forEach(perm => {
    if (allBackendPermissions[perm]) {
      thisBackPerms.push(...allBackendPermissions[perm])
    }
  })
  // alog('thisBackPerms => ', JSON.parse(JSON.stringify(thisBackPerms)), 'backRole')
  // elimina i duplicati e ritorno
  return [...new Set(thisBackPerms)]
}

// export const auth0Config = {
//   client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
//   domain: process.env.REACT_APP_AUTH0_DOMAIN
// }

// export const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
// }
