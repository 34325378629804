export const it = {
  attachment: {
    clickToDownload: 'Scarica',
    noAttachment: 'Non ci sono allegati'
  },
  bookmark: {
    noBookmark: 'Non ci sono bookmarks'
  },
  core: {
    askingPassword: {
      requirePasswordToOpen: 'Per aprire questo documento serve una Password',
      submit: 'Conferma'
    },
    wrongPassword: {
      submit: 'Conferma',
      tryAgain: 'La password non è corretta. Per favore riprova'
    }
  },
  defaultLayout: {
    attachment: 'Allegato',
    bookmark: 'Bookmark',
    thumbnail: 'Thumbnail'
  },
  download: {
    download: 'Download'
  },
  drop: {
    dragDropFile: 'Trascina qui un documento PDF'
  },
  fullScreen: {
    enterFullScreen: 'Schermo Intero'
  },
  localeSwitcher: {
    switchLocale: 'Switch locale'
  },
  open: {
    openFile: 'Apri file'
  },
  pageNavigation: {
    goToFirstPage: 'Prima pagina',
    goToLastPage: 'Ultima pagina',
    goToNextPage: 'Pagina Successiva',
    goToPreviousPage: 'Pagina Precedente'
  },
  print: {
    cancel: 'Annulla',
    preparingDocument: 'Preparo il documento ...',
    print: 'Stampa'
  },
  properties: {
    author: 'Autore',
    close: 'Chiudi',
    creationDate: 'Data Creazione',
    creator: 'Creatore',
    fileName: 'Nome File',
    fileSize: 'Dimensioni File',
    keywords: 'Keywords',
    modificationDate: 'Data Modifica',
    pageCount: 'Conteggio delle pagine',
    pdfProducer: 'Produttore PDF',
    pdfVersion: 'Versione PDF',
    showProperties: 'Mostra Proprietà',
    subject: 'Oggetto',
    title: 'Titolo'
  },
  rotate: {
    rotateBackward: 'Ruota in senso antiorario',
    rotateForward: 'Ruota in senso orario'
  },
  scrollMode: {
    horizontalScrolling: 'Scorrimento orizzontale',
    verticalScrolling: 'Scorrimento verticale',
    wrappedScrolling: 'Scorrimento'
  },
  search: {
    close: 'Chiudi',
    enterToSearch: 'Premi Invio per cercare',
    matchCase: 'Parola ricercata',
    nextMatch: 'Istanza Successiva',
    previousMatch: 'Istanza Precedente',
    search: 'Cerca',
    wholeWords: 'Parole intere'
  },
  selectionMode: {
    handTool: 'Mano Libera',
    textSelectionTool: 'Seleziona Testo'
  },
  toolbar: {
    moreActions: 'Altre azioni'
  },
  zoom: {
    actualSize: 'Dimensioni attuali',
    pageFit: 'Contieni Pagina',
    pageWidth: 'Larghezza Pagina',
    zoomIn: 'Zoom in',
    zoomOut: 'Zoom out'
  }
}
