import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import Chart from 'react-apexcharts'
import LoadingCard from 'src/components/LoadingCard'
import { europeNum } from 'src/utils/general'
// import { alog } from 'src/utils/apioLog'
// import moment from 'moment'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    position: 'relative'
  }
}))

function ReportMonthlyEnergyGraph ({ showTitle = true, isDark = false, data = {}, width = null, height = null, className, ...rest }) {
  const classes = useStyles()

  const dataNormalize = function (dataSet, options) {
    const series = {
      dailyEnergy: {
        type: 'column',
        color: isDark ? '#63b521' : '#8bb9dd',
        name: 'Prod. Giornaliera',
        data: []
      },
      dailyCumulatedEnergy: {
        data: [],
        type: 'line',
        color: isDark ? '#54F286' : '#06526F',
        name: 'Prod. Reale Cumulata'
      },
      dailyCumulatedBaseline: {
        data: [],
        type: 'line',
        color: isDark ? '#8bb9dd' : '#B0BEC5',
        name: 'Prod. da Baseline'
      }
    }
    // mi preparo le label dell'asse X
    const labels = dataSet.dailyEnergy.length > 0 ? dataSet.dailyEnergy.map((el) => el.label) : []
    options.labels = labels
    // mi trovo min e max per l'asse X
    // let minX = moment(labels[0])
    // let maxX = moment(labels[labels.length - 1])
    // minX = moment(minX).subtract(1, 'days')
    // maxX = moment(maxX).add(1, 'days')
    // options.xaxis.min = minX.toISOString()
    // options.xaxis.max = maxX.toISOString()
    // mi setto i colori
    options.colors.push(series.dailyEnergy.color, series.dailyCumulatedEnergy.color, series.dailyCumulatedBaseline.color)
    // mi preparo i dati della serie
    series.dailyEnergy.data = dataSet.dailyEnergy.length > 0 ? dataSet.dailyEnergy.map((el) => el.value) : []
    series.dailyCumulatedEnergy.data = dataSet.dailyCumulatedEnergy.length > 0 ? dataSet.dailyCumulatedEnergy.map((el) => el.value) : []
    series.dailyCumulatedBaseline.data = dataSet.dailyCumulatedBaseline.length > 0 ? dataSet.dailyCumulatedBaseline.map((el) => el.value) : []
    // pusho i dati della serie
    options.series.push(series.dailyEnergy, series.dailyCumulatedEnergy, series.dailyCumulatedBaseline)
    // mi calcolo i massimi dei vari assi
    const bar1Max = Math.max(...series.dailyEnergy.data)
    const line1Max = Math.max(...series.dailyCumulatedEnergy.data)
    const line2Max = Math.max(...series.dailyCumulatedBaseline.data)
    const finalLineMax = line1Max >= line2Max ? line1Max * 1.05 : line2Max * 1.05
    // pusho il primo asse
    options.yaxis.push({
      show: true,
      seriesName: series.dailyEnergy.name,
      max: bar1Max * 1.05,
      min: 0,
      title: {
        text: 'kWh',
        rotate: -90,
        offsetX: 0,
        offsetY: 0,
        style: {
          color: isDark ? '#ffffff' : '#263238',
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 500
        }
      },
      labels: {
        minWidth: '100%',
        style: {
          colors: isDark ? ['#ffffff'] : ['#263238']
        },
        formatter: (value) => { return Math.floor(Number(value)) }
      }
    })
    // pusho il secondo e terzo asse
    options.yaxis.push({
      seriesName: series.dailyCumulatedEnergy.name,
      opposite: true,
      min: 0,
      max: finalLineMax,
      title: {
        text: 'kWh',
        rotate: -90,
        offsetX: 0,
        offsetY: 0,
        style: {
          color: isDark ? '#ffffff' : '#263238',
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 500
        }
      },
      labels: {
        style: {
          colors: isDark ? ['#ffffff'] : ['#263238']
        },
        formatter: (value) => {
          return Math.floor(Number(value))
        }
      }
    })
    options.yaxis.push({
      seriesName: series.dailyCumulatedBaseline.name,
      opposite: true,
      show: false,
      min: 0,
      max: finalLineMax,
      title: {
        text: 'kWh',
        rotate: -90,
        offsetX: 0,
        offsetY: 0,
        style: {
          color: isDark ? '#ffffff' : '#263238',
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 500
        }
      },
      labels: {
        style: {
          colors: isDark ? ['#ffffff'] : ['#263238']
        },
        formatter: (value) => {
          return Math.floor(Number(value))
        }
      }
    })

    // console.log('options: ', options)
    return options
  }

  const options = {
    chart: {
      width: width || '100%',
      // id: 'energyGraph',
      // group: 'energy',
      height: height || '342px',
      foreColor: isDark ? '#ffffff' : '#263238',
      type: 'line',
      animations: {
        enabled: false
      },
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    title: {
      text: showTitle ? 'Producibilità giornaliera Mese' : '',
      align: 'center',
      offsetY: 14,
      style: {
        fontSize: '14px',
        fontWeight: 500
        // color: '#263238'
      }
    },
    // stroke: {
    //   curve: 'smooth'
    // },
    labels: [],
    colors: [],
    series: [],
    tooltip: {
      shared: true,
      theme: isDark ? 'dark' : 'light',
      x: {
        show: false
      },
      y: {
        show: false,
        formatter: function (value) {
          return value ? `${europeNum(value)} kWh` : '-'
        }
      }
    },
    yaxis: [],
    xaxis: {
      type: 'datetime',
      labels: {
        hideOverlappingLabels: true,
        datetimeUTC: false,
        style: {
          colors: isDark ? ['#ffffff'] : ['#263238']
        }
      }
    },
    legend: {
      offsetX: 0,
      offsetY: 6,
      labels: {
        colors: isDark ? ['#ffffff'] : ['#263238'],
        useSeriesColors: false
      }
    }
  }

  // mi sistemo i dati
  const normalizedOptions = Object.keys(data).length > 0 ? dataNormalize(data, options) : options
  // alog('monthlyEnergyGraph normalizedOptions =>', normalizedOptions, 'report')

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {options.series.length > 0
        ? (
          <Chart
            options={normalizedOptions}
            series={normalizedOptions.series}
            type={(normalizedOptions.chart && normalizedOptions.chart.type) || 'line'}
            height={normalizedOptions.chart.height}
            width={normalizedOptions.chart.width}
          />
          )
        : (
          <LoadingCard />
          )}
    </div>
  )
}

export default ReportMonthlyEnergyGraph
