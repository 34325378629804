import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import LoadingCard from '../LoadingCard'
import Chart from 'react-apexcharts'
import { europeNum, getContrastColor } from 'src/utils/general'
import moment from 'moment'
import { alog } from 'src/utils/apioLog'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  }
}))

function LinearRegressionChart ({ isInverter = false, isPlant = false, onDataPointClick, data = {}, width = null, height = null, className, ...rest }) {
  const classes = useStyles()

  // console.log(data)

  const normalizeOptions = (data, options) => {
    if (options.series) {
      alog('data', data, 'linearRegression')
      options.series = data.map((el, elIndex) => Object.keys(el).filter(dataKey => (dataKey === 'line' || dataKey === 'points')).map(key => ({
        name: `${key}${elIndex + 1}`,
        type: key === 'line' ? 'line' : 'scatter',
        r2: el.r2,
        orientationName: el.orientationName,
        // setto a 0 i valori null
        data: el[key].map(singleEl => singleEl.date ? ({ x: !singleEl.x ? 0 : singleEl.x, y: !singleEl.y ? 0 : singleEl.y, date: !singleEl.date ? 0 : singleEl.date }) : ({ x: !singleEl.x ? 0 : singleEl.x, y: !singleEl.y ? 0 : singleEl.y }))
      }))).flat()

      const colors = ['#31eeef', '#31eeef', '#eaf360', '#eaf360', '#3ef075', '#3ef075', '#ffb2bc', '#ffb2bc', '#EA8F06', '#EA8F06', '#3F784C', '#3F784C', '#FE6D73', '#FE6D73', '#8FCB9B', '#8FCB9B', '#8B95C9', '#8B95C9', '#AA4586', '#AA4586']

      const graphMaxArray = []

      data.forEach((dataEl) => {
        // console.log(dataEl)
        // Metto tutti i valori di max in un array del quale troverò il massimo per usarlo come valore massimo dell'asse y del grafico
        graphMaxArray.push(dataEl.max)
        /* // Costruisco un solo asse y
        if (options.yaxis.length === 0) {
          options.yaxis.push({
            max: Number(dataEl.max) * 1.1,
            labels: {
              formatter: (value) => { return Math.floor(Number(value)) + ' kWh' }
            }
          })
        } */
      })
      options.series.forEach((el, index) => {
        // console.log(el)
        if (el.type === 'line') {
          const arrayYPoints = el.data.map(dataEl => dataEl.y)
          const arrayXPoints = el.data.map(dataEl => dataEl.x)
          const lineYMax = Math.max(...arrayYPoints)
          const lineXMax = Math.max(...arrayXPoints)
          options.annotations.points.push({
            y: lineYMax,
            x: lineXMax,
            border: 'none',
            borderColor: 'rgba(0,0,0,0)',
            fillColor: '#024660',
            marker: {
              size: 0
            },
            label: {
              text: `R${String.fromCodePoint(0x00B2)}: ${europeNum(el.r2, 2)}`,
              position: 'right',
              textAnchor: 'end',
              style: {
                fontSize: 13,
                background: [...colors, ...colors, ...colors].length > index ? [...colors, ...colors, ...colors][index] : 'rgba(0,0,0,0.5)',
                color: [...colors, ...colors, ...colors].length > index ? getContrastColor([...colors, ...colors, ...colors][index], true) : '#ffffff'
              }
            }
          })
        }
      })

      options.yaxis.push({
        max: Math.max(...graphMaxArray) * 1.1 || 1.1,
        // max: Math.max(...graphMaxArray) * 4 || 1.1,
        labels: {
          formatter: (value) => { return Math.floor(Number(value)) + ' kWh' }
        }
      })
      options.markers.size = options.series.map(el => el.type === 'scatter' ? 6 : 0)

      // alog('options', options, 'linearRegression')
    }

    return options
  }

  const options = {
    series: [],
    annotations: {
      position: 'back',
      points: []
    },
    chart: {
      type: 'line',
      width: width || '100%',
      background: '#024660',
      height: height || '95%',
      events: {
        beforeResetZoom: async function () {
          await onDataPointClick(null)
        },
        dataPointSelection: async (event, chartContext, config) => {
          // Prendo l'array che contiene tutte le serie graficate
          const completeSeries = config.w && config.w.config && config.w.config.series ? config.w.config.series : []
          const seriesIndex = config.seriesIndex
          const dataPointIndex = config.dataPointIndex
          // prendo la data del punto selezionato
          if (completeSeries.length > (seriesIndex)) {
            const currentSerie = completeSeries[seriesIndex]
            if (currentSerie && currentSerie.data && currentSerie.data.length > (dataPointIndex)) {
              const currentDate = currentSerie.data[dataPointIndex].date || null
              if (currentDate) {
                // se esiste aggiorno il periodo dell'analisi di stringa
                await onDataPointClick(currentDate)
              }
            }
          }
        }
      },
      toolbar: {
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false
        }
      }
    },
    fill: {
      type: 'solid'
    },
    colors: ['#31eeef', '#31eeef', '#eaf360', '#eaf360', '#3ef075', '#3ef075', '#ffb2bc', '#ffb2bc', '#EA8F06', '#EA8F06', '#3F784C', '#3F784C', '#FE6D73', '#FE6D73', '#8FCB9B', '#8FCB9B', '#8B95C9', '#8B95C9', '#AA4586', '#AA4586'],
    markers: {
      size: []
    },
    tooltip: {
      shared: false,
      intersect: true,
      x: {
        show: false
      },
      y: {
        title: {
          formatter: () => null
        },
        formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
          const completeSeries = w.config && w.config.series ? w.config.series : []
          if (completeSeries.length > (seriesIndex)) {
            const currentSerie = completeSeries[seriesIndex]
            if (currentSerie && currentSerie.data && currentSerie.data.length > (dataPointIndex)) {
              alog('series', series, 'formatter')
              alog('completeSeries', completeSeries, 'formatter')
              const currentIrradiation = currentSerie.data[dataPointIndex].x || 'noirradiation'
              const currentDate = currentSerie.data[dataPointIndex].date || null
              if (currentDate && currentIrradiation !== 'noirradiation') {
                return `<div>
                          <div><b>${currentSerie.orientationName}</b></div>
                          <br/>
                          <div>Giorno: ${moment(currentDate).format('DD/MM/YYYY')}</div>
                          <div>Energia: ${europeNum(value)} kWh</div>
                          <div>Irraggiamento: ${europeNum(currentIrradiation)} kWh/m${String.fromCodePoint(0x00B2)}</div>
                        </div> `
              }
            }

            return value
          }

          return value
        }
      }
    },
    xaxis: {
      type: 'numeric',
      labels: {
        formatter: (value) => { return `${europeNum(value)} kWh/m${String.fromCodePoint(0x00B2)} ` }
      },
      tooltip: {
        enabled: false
      }
    },
    yaxis: [],
    legend: {
      show: false,
      offsetY: 5,
      labels: {
        useSeriesColors: true
      },
      formatter: function (seriesName, opts) {
        // console.log(opts)
        const series = (opts.w && opts.w.config && opts.w.config.series) || []
        const lines = series.filter(el => el.type === 'line')
        const currentIndex = lines.findIndex(el => el.name === seriesName)
        let title = null
        if (currentIndex > -1) {
          title = isPlant ? 'Impianto' : isInverter ? `Or ${currentIndex + 1}` : `Inv ${currentIndex + 1}`
        }
        return title
      },
      markers: {
        width: 0,
        height: 0
      }
    },
    grid: {
      show: false,
      borderColor: '#90A4AE'
    },
    theme: {
      mode: 'dark',
      palette: 'palette1'
    }
  }

  const normalizedOptions = data.length > 0 ? normalizeOptions(data, options) : options

  alog('normalizedOptions: ', normalizedOptions, 'LinearRegressionChart')
  // alog('data: ', data, 'LinearRegressionChart')

  return (
    <div
      className={clsx(classes.root, className)} {...rest}
    >
      {
        Object.keys(data).length > 0
          ? (
            <Chart
              options={normalizedOptions}
              series={normalizedOptions.series || undefined}
              type={(normalizedOptions.chart && normalizedOptions.chart.type) || 'line'}
              height={(normalizedOptions.chart && normalizedOptions.chart.height) || 0}
              width={(normalizedOptions.chart && normalizedOptions.chart.width) || 0}
            />
            )
          : (
            <LoadingCard />
            )
      }
    </div>
  )
}
export default LinearRegressionChart
