import _ from 'lodash'
import { colors, responsiveFontSizes } from '@material-ui/core'
import { createTheme as createMuiTheme } from '@material-ui/core/styles'
import { itIT } from '@material-ui/core/locale'
import { THEMES } from 'src/constants'
import { softShadows, strongShadows } from './shadows'
import typography from './typography'

// variabili dei colori
export const basicColors = {
  darkGreen: '#219653',
  lightGreen: '#C8F6CC',
  lightOrange: colors.deepOrange[200],
  darkYellow: '#e4ac02',
  lightYellow: '#FFF3E0',
  // darkRed: '#AC1416',
  darkRed: colors.red[800],
  lightRed: '#FFE0E0',
  lightBlue: '#d3e3ff',
  lightBlue2: '#E8F1F4',
  blueGrayDark: '#263238',
  blueGrayMedium: '#546E7A',
  blueGrayLight: '#B0BEC5',
  background: '#053244',
  nav: '#024660',
  secondary: '#06526F',
  primary: '#009FDA',
  darkText: colors.blueGrey[900],
  whiteText: '#efefef'
}

const baseOptions = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    }
  }
}

// Doc for customizations
// https://material-ui.com/customization/default-theme/?expand-path=$.palette
// https://material-ui.com/customization/palette/
const themesOptions = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      }
    },
    palette: {
      type: 'light',
      action: {
        active: '#1D6F9B'
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white
      },
      primary: {
        main: '#06526F',
        alternative: '#1D6F9B'
      },
      secondary: {
        main: '#009DE0',
        alternative: '#139FD0'
      },
      alert: {
        error: basicColors.darkRed,
        warning: '#E4C548'
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600]
      }
    },
    shadows: softShadows,
    redButton: {
      backgroundColor: colors.red[700],
      color: 'white',
      '&:hover': {
        backgroundColor: basicColors.darkRed
      }
    },
    greenButton: {
      backgroundColor: colors.green[600],
      color: 'white',
      '&:hover': {
        backgroundColor: colors.green[800]
      }
    }
  },
  {
    name: THEMES.ONE_DARK,
    overrides: {
      MuiCardHeader: {
        title: {
          color: basicColors.primary
        }
      },
      MuiTabs: {
        scrollButtonsDesktop: {
          color: basicColors.primary
        }
      },
      MuiAvatar: {
        colorDefault: {
          backgroundColor: 'rgba(245, 245, 245, 0.8)'
        }
      },
      MuiTableCell: {
        root: {
          borderBottom: '1px solid ' + basicColors.background
        }
      },
      MuiPaper: {
        outlined: {
          border: '1px solid rgb(0 0 0 / 22%)'
        }
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: '#fefefe',
          color: 'rgba(0, 0, 0, 0.87)',
          boxShadow: strongShadows[3],
          fontSize: 13
        }
      },
      MuiFormHelperText: {
        root: {
          fontWeight: 500
        }
      }
    },
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(250, 250, 250, 0.54)',
        hover: 'rgba(250, 250, 250, 0.04)',
        selected: 'rgba(240, 240, 240, 0.08)',
        disabled: 'rgba(250, 250, 250, 0.26)',
        disabledBackground: 'rgba(250, 250, 250, 0.12)',
        focus: 'rgba(250, 250, 250, 0.12)'
      },
      background: {
        default: basicColors.nav, // nav
        dark: basicColors.background, // background
        paper: basicColors.nav // cards + more
      },
      text: {
        primary: '#efefef',
        secondary: '#ced1dc',
        icon: '#efefef'
      },
      primary: {
        main: basicColors.primary
      },
      secondary: {
        main: basicColors.secondary
      },
      error: {
        main: colors.red[600]
      },
      warning: {
        main: basicColors.darkYellow
      },
      success: {
        // light: '#8a85ff',
        main: basicColors.darkGreen
      },
      // info: {
      //  main: '#8a85ff',
      // },
      alert: {
        error: basicColors.darkRed,
        warning: basicColors.darkYellow
      }
    },
    shadows: strongShadows
  },
  {
    name: THEMES.UNICORN,
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#2a2d3d',
        dark: '#222431',
        paper: '#2a2d3d'
      },
      primary: {
        main: '#a67dff'
      },
      secondary: {
        main: '#a67dff'
      },
      text: {
        primary: '#f6f5f8',
        secondary: '#9699a4'
      }
    },
    shadows: strongShadows
  }
]

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme)

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`))
    ;[themeOptions] = themesOptions
  }

  let theme = createMuiTheme(_.merge({}, baseOptions, themeOptions, { direction: config.direction }), itIT)

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme)
  }

  return theme
}
