import { useMemo, useRef, useState, memo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { renderToString } from 'react-dom/server'
import clsx from 'clsx'
import { MapContainer as LeafletMap, TileLayer, Marker, Popup, useMap } from 'react-leaflet'
import { jssPreset, makeStyles, StylesProvider, ThemeProvider, useMediaQuery } from '@material-ui/core'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import L from 'leaflet'
import useSettings from 'src/hooks/useSettings'
import { createTheme } from 'src/theme'
import { create } from 'jss'
import rtl from 'jss-rtl'
import ClusterPopover from 'src/views/overview/MapsView/ClusterPopover'
import { alog } from 'src/utils/apioLog'

const useStyles = makeStyles(() => ({
  root: {
    height: 280
  },
  popupLink: {
    textDecoration: 'none'
  }
}))

const urlTiles = {
  satellite: 'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
  openstreetmap: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
}

const statusMarkers = {
  ok: L.icon({
    iconUrl: '/static/images/green_marker.png',
    iconSize: [23, 25],
    iconAnchor: [12, 24]
  }),
  warning: L.icon({
    iconUrl: '/static/images/yellow_marker.png',
    iconSize: [23, 25],
    iconAnchor: [12, 24]
  }),
  maintenance: L.icon({
    iconUrl: '/static/images/yellow_marker.png',
    iconSize: [23, 25],
    iconAnchor: [12, 24]
  }),
  error: L.icon({
    iconUrl: '/static/images/red_marker.png',
    iconSize: [23, 25],
    iconAnchor: [12, 24]
  })
}

const createClusterCustomIcon = function (cluster) {
  // Prendo tutti i markers aggregati nel singolo cluster
  const clusterMarkers = cluster.getAllChildMarkers()
  // Array contenente il singolo status per ogni marker di quel cluster
  const clusterStatusArray = clusterMarkers.map(el => el.options && el.options.status ? el.options.status : null).filter(el => el)
  // seleziono l'icona del marker del cluster in base allo status
  const clusterIcon = clusterStatusArray.includes('error')
    ? statusMarkers.error
    : clusterStatusArray.includes('warning') || clusterStatusArray.includes('maintenance')
      ? statusMarkers.warning
      : statusMarkers.ok

  return clusterIcon
}

const MapGrid = memo(({ zoom, mapRef }) => {
  const map = useMap()

  mapRef.current = map

  const zoomLevel = useMediaQuery(theme => theme.breakpoints.up('xl')) ? 6 : zoom
  const currentZoomLevel = map.getZoom()
  if (currentZoomLevel <= 6) {
    map.setZoom(zoomLevel)
  }
  return null
})

function Map ({ draggable = false, mapEventHandlers = null, className, bounds = null, markers = null, lat = 41.896187, lng = 12.492046, minZoom = null, zoom = 17, ...rest }) {
  const classes = useStyles()
  const mapRef = useRef(null)

  const jss = create({ plugins: [...jssPreset().plugins, rtl()] })
  const { settings } = useSettings()

  const [showPopup, setShowPopup] = useState(false)

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  })

  // console.log('lat,lng: ', lat, lng)
  // eslint-disable-next-line
  const [tileSet, setTileSet] = useState(urlTiles.openstreetmap)
  const markerRef = useRef(null)

  const eventHandlers = useMemo(
    () => ({
      dragend () {
        const marker = markerRef.current
        if (marker != null) {
          alog('newPoint: ', marker.getLatLng(), 'maps')
          const newLatLng = marker.getLatLng()
          mapEventHandlers({ lat: newLatLng.lat, lng: newLatLng.lng })
        }
      }
    }),
    // eslint-disable-next-line
    []
  )

  // funzione degli eventHandlers di un marker
  const clickEventHandlers = useMemo(
    () => ({
      // evento di click su uno specifico marker
      click (e) {
        // accedo alle proprietà aggiuntive passate al marker, in questo caso una funzione
        const action = e.target.options.onClick || null
        if (action) {
          action()
        }
      },
      mouseover (e) {
        const action = e.target.options.onMouseOver || null
        if (action) {
          action(e)
        }
      },
      mouseout (e) {
        const action = e.target.options.onMouseOut || null
        if (action) {
          action(e)
        }
      }
    }),
    []
  )

  // Funzione che presi in ingresso i quattro vertici ritorna il centro del rettangolo che descrivono
  // Si ha bisogno del centro per poter usare lo zoom sulla mappa
  const calculateBoundsCenter = (currentBounds) => {
    // punto con X e Y minori
    const firstPoint = currentBounds[0]
    // punto con X e Y maggiori
    const secondPoint = currentBounds[2]

    const centerX = (secondPoint[0] - firstPoint[0]) / 2 + firstPoint[0]
    const centerY = (secondPoint[1] - firstPoint[1]) / 2 + firstPoint[1]

    return [centerX, centerY]
  }

  // Funzione che presi in ingresso un array di markers, restituisce un array di oggetti nel seguente formato
  /*
    {
      status: 'ok' | 'error' | 'warning',
      plantName: <String>
    }
  */
  const convertMarkersToClusterTooltipData = (markersArray = []) => {
    return markersArray.map(el => {
      const status = el.options && el.options.status ? el.options.status : null
      const plantName = el.options && el.options.plantName ? el.options.plantName : null
      const plantCoordinates = [el.options.coordinates[1], el.options.coordinates[0]]
      // const coordinates = [plantCoordinates[1], plantCoordinates[1], plantCoordinates[0], plantCoordinates[0]]
      // const newBounds = calculateBounds(coordinates)
      if (status && plantName) {
        return {
          status,
          plantName,
          coordinates: plantCoordinates
        }
      }

      return null
    })
      .filter(el => el)
  }

  // Proprietà di base da passare al componente LeafletMap
  const leafletMapProps = {
    doubleClickZoom: false,
    scrollWheelZoom: false,
    touchZoom: true,
    boxZoom: false,
    zoom: 5
  }

  /*
    Proprietà assegnate solo se dichiarate dal componente padre
  */
  if (minZoom) {
    leafletMapProps.minZoom = minZoom
  }

  /*  if (center) {
    leafletMapProps.viewCenter = center
  } else  */if (markers && bounds) {
    leafletMapProps.bounds = bounds
    // leafletMapProps.maxBounds = bounds
    leafletMapProps.center = calculateBoundsCenter(bounds)
  } else {
    leafletMapProps.center = [lat, lng]
  }

  let clusterTooltipData = []

  return (
    <LeafletMap
      {...rest}
      className={clsx(classes.root, className)}
      {...leafletMapProps}
    >
      <MapGrid {...leafletMapProps} mapRef={mapRef} />
      <TileLayer attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url={tileSet} />
      <MarkerClusterGroup
        key={uuidv4()}
        onMouseOver={(e) => {
          // Array di dati da passare al tooltip di del cluster
          clusterTooltipData = convertMarkersToClusterTooltipData(e.sourceTarget.getAllChildMarkers())
          // e.propagatedFrom.bindPopup(createClusterPopover(clusterTooltipData, clusterTooltipData.length), {
          e.propagatedFrom.unbindPopup()
          e.propagatedFrom.bindPopup(renderToString(
            <ThemeProvider theme={theme}>
              <StylesProvider jss={jss}>
                <ClusterPopover data={clusterTooltipData} />
              </StylesProvider>
            </ThemeProvider>
          ), {
            maxHeight: 420,
            maxWidth: 360
          }).openPopup()

          clusterTooltipData.forEach((el, index) => {
            const plantRow = document.getElementById(`plant-${index}`)
            plantRow.addEventListener('click', function () {
              if (mapRef.current) {
                alog('mapRef.current ', mapRef.current, 'maps')
                mapRef.current.closePopup()
                const latLngToCenterPoint = mapRef.current.latLngToContainerPoint({ lat: el.coordinates[0], lng: el.coordinates[1] })
                // Vertici in pixel
                const ltPoint = { x: latLngToCenterPoint.x - 20, y: latLngToCenterPoint.y - 20 }
                const rtPoint = { x: latLngToCenterPoint.x + 20, y: latLngToCenterPoint.y - 20 }
                const lbPoint = { x: latLngToCenterPoint.x + 20, y: latLngToCenterPoint.y + 20 }
                const rbPoint = { x: latLngToCenterPoint.x - 20, y: latLngToCenterPoint.y + 20 }
                // Vertici in LAtLng
                const lt = mapRef.current.containerPointToLatLng(ltPoint)
                const rt = mapRef.current.containerPointToLatLng(rtPoint)
                const lb = mapRef.current.containerPointToLatLng(lbPoint)
                const rb = mapRef.current.containerPointToLatLng(rbPoint)
                const newBounds = [lt, rt, lb, rb]
                mapRef.current.fitBounds(newBounds)
              }
            })
          })
        }}
        maxClusterRadius={7}
        // maxClusterRadius={30}
        showCoverageOnHover={false}
        iconCreateFunction={createClusterCustomIcon}
      >
        {markers
          ? markers.map((singleMarker, index) => singleMarker.coordinates && singleMarker.coordinates.length > 0
              ? (
                <Marker
                  key={`singleMarker${index}-${singleMarker.plantName}`}
                  position={[singleMarker.coordinates[1], singleMarker.coordinates[0]]}
                  {...singleMarker.icon ? ({ icon: singleMarker.icon }) : null}
                // parametri aggiuntivi come onClick passati al marker sono disponibili negli eventHandlers
                // accedendo all'oggetto event.target.options
                  status={singleMarker.status || ''}
                  plantName={singleMarker.plantName || ''}
                  coordinates={singleMarker.coordinates || ''}
                  onClick={function handleClick () {
                    singleMarker.onClick()
                  }}
                  onMouseOver={function handleMouseOver (e) {
                    setShowPopup(true)
                    singleMarker.onMouseOver()
                    e.target.openPopup()
                  }}
                  onMouseOut={function handleMouseOut () {
                    singleMarker.onMouseOut()
                  }}
                  eventHandlers={clickEventHandlers}
                >
                  {singleMarker.popup && singleMarker.popup !== undefined && showPopup
                    ? (
                      <Popup onClose={() => setShowPopup(false)}>
                        {singleMarker.popup}
                      </Popup>
                      )
                    : null}
                </Marker>
                )
              : null)
          : (
            <Marker
              draggable={draggable}
              eventHandlers={eventHandlers}
              ref={markerRef}
              position={[lat, lng]}
            >
              <Popup>
                <a
                  className={classes.popupLink}
                  href={`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`}
                  target='_blank'
                  rel='noopener noreferrer nofollow'
                >
                  Apri la mappa
                </a>
              </Popup>
            </Marker>
            )}
      </MarkerClusterGroup>
    </LeafletMap>
  )
}
export default Map
