import { useState } from 'react'
import clsx from 'clsx'
import CloseIcon from '@material-ui/icons/Close'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, makeStyles, TextField, Typography, useMediaQuery } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {},
  closeButton: {
    color: theme.palette.grey[500]
  },
  stepDescription: {
    color: theme.palette.text.secondary
  }
}))

function FilterNameDialog ({ name: editName, filtersLength = 0, open, onClose, onConfirm, className }) {
  const classes = useStyles()
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const [name, setName] = useState(editName || `Configurazione ${filtersLength + 1}`)

  return (
    <Dialog
      fullWidth
      fullScreen={isSmall}
      maxWidth='sm'
      open={open}
      onClose={onClose}
      className={clsx(classes.root, className)}
    >
      <DialogTitle>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography component='div' variant='h3'>
            Inserisci il nome del filtro
          </Typography>
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box my={2}>
          <Typography variant='body2' className={classes.stepDescription}>
            Scegli un nome da dare al filtro
          </Typography>
        </Box>
        <TextField
          size='small'
          fullWidth
          label='Nome Filtro'
          value={name}
          variant='outlined'
          onChange={(e) => setName(e.target.value)}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose} size='small'>Chiudi</Button>
        <Button onClick={() => onConfirm(name)} disabled={!name} variant='contained' color='primary' size='small'>Salva</Button>
      </DialogActions>
    </Dialog>
  )
}
export default FilterNameDialog
