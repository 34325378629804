import moment from 'moment'
import { europeNum } from 'src/utils/general'
import {
  AlertOctagon as AlertOctagonIcon,
  Watch as WatchIcon,
  Clipboard as ClipboardIcon,
  Archive as ArchiveIcon
} from 'react-feather'

// Funzione che presi in ingresso il typo di periodo e la data di inizio ritornano data di inizio e data di fine
export const getDatesFromPeriod = (period, now, formatString = null) => {
  let maxDate, minDate
  if (period === 'live') {
    // min e max saranno la mezzanotte di oggi e le 23:59 di oggi
    minDate = moment(now).set({ hours: 0, minute: 0, second: 0, millisecond: 1 })
    maxDate = moment(now).set({ hours: 23, minute: 59, second: 59, millisecond: 900 })
  } else if (period === 'week') {
    // il min sarà il lunedì di questa settimana, il max la domenica
    //  su momento 0 è domenica, 6 è sabato, 7 è la prossima domenica
    minDate = moment(now).startOf('week')
    maxDate = moment(now).endOf('week')
  } else if (period === 'month') {
    // il min sarà l'1 del mese e il max sarà il 30/31 del mese
    // (secondo la doc moment gestisce da solo che se setti 31 ed ha 30 giorni imposta 30 e non 31)
    minDate = moment(now).startOf('month')
    maxDate = moment(now).endOf('month')
  } else if (period === 'year') {
    // sarà l'anno corrente
    minDate = moment(now).set({ hours: 0, minute: 0, second: 0, millisecond: 1 })
    minDate = moment(minDate)
      .month(0)
      .date(1)
    maxDate = moment(now).set({
      hours: 23,
      minute: 59,
      second: 59,
      millisecond: 900
    })
    maxDate = moment(maxDate)
      .month(11)
      .date(31)
  }

  return formatString
    ? { minDate: moment(minDate).format(formatString), maxDate: moment(maxDate).format(formatString) }
    : { minDate: moment(minDate).toISOString(), maxDate: moment(maxDate).toISOString() }
}

// Funzione che prende in ingresso i dati ricevuti dall'api e li formatta per le card
export const decodeCardsFromApi = (dataObj) => {
  // Oggetto che contiene la configurazione delle cards
  const labelIconConfig = {
    totalAnomalies: {
      icon: <AlertOctagonIcon />,
      label: 'Anomalie Totali'
    },
    totalInactiveHours: {
      icon: <WatchIcon />,
      label: 'Tot. ore disservizio'
    },
    openAnomalies: {
      icon: <ClipboardIcon />,
      label: 'Anomalie Aperte'
    },
    closedAnomalies: {
      icon: <ArchiveIcon />,
      label: 'Anomalie Chiuse'
    }
  }

  return Object.keys(dataObj).map((key) => ({
    icon: (labelIconConfig[key] !== null && labelIconConfig[key].icon) || null,
    values: labelIconConfig[key] !== null && labelIconConfig[key].label && Array.isArray(labelIconConfig[key].label)
      ? labelIconConfig[key].label.map((label, index) => ({
          label,
          value: dataObj[key][index] !== '-'
            ? dataObj[key][index] >= 100000
                ? `${europeNum(dataObj[key][index] / 1000)} Mwh`
                : `${europeNum(dataObj[key][index])} kWh`
            : '-'
        }))
      : [
          {
            label: (labelIconConfig[key] && labelIconConfig[key].label) || '',
            value: key === 'totalInactiveHours'
              ? europeNum(moment.duration(dataObj[key]).asHours().toFixed(0))
              : europeNum(Number(dataObj[key]).toFixed(0))
          }
        ]
  }))
}

// Funzione che prende in ingresso i dati ricevuti dalle api e li formatta per la barra delle categorie
export const decodeCategoryStatusFromApi = (newAnomaliesCategoryStatus, anomaliesCategoryStatus) => {
  const categoryStatusCopy = JSON.parse(
    JSON.stringify(anomaliesCategoryStatus)
  )
  categoryStatusCopy.forEach((category) => {
    if (
      newAnomaliesCategoryStatus[category.name] &&
      Object.prototype.hasOwnProperty.call(newAnomaliesCategoryStatus[category.name], 'count')
      // newAnomaliesCategoryStatus[category.name].count
    ) {
      category.value = newAnomaliesCategoryStatus[category.name].count || 0
      category.subCategory.forEach((subCategory) => {
        if (
          newAnomaliesCategoryStatus[category.name].subcategories[subCategory.name] &&
          Object.prototype.hasOwnProperty.call(newAnomaliesCategoryStatus[category.name].subcategories[subCategory.name], 'count')
          // newAnomaliesCategoryStatus[category.name].subcategories[subCategory.name].count
        ) {
          subCategory.value = newAnomaliesCategoryStatus[category.name].subcategories[subCategory.name].count || 0
        } else {
          subCategory.value = 0
        }
      })
    } else {
      category.value = 0
    }
  })

  return categoryStatusCopy
}

// funzione che prende in ingresso i dati ricevuti dall'api e li formatta per il grafico del bilancio per taglia
export const decodeSizeBalanceGraphFromApi = (dataObj) => {
  const labelsObj = {
    // alarms: ['Allarmi'],
    // communication: ['Comunicazione'],
    // environmentalConditions: ['Condizioni', 'Ambiente'],
    datalogger: ['Datalogger'],
    sunmeter: ['Centralina'],
    energy: ['Energia'],
    // components: ['Componenti'],
    inverter: ['Inverter'],
    // modules: ['Moduli'],
    // strings: ['Stringhe'],
    performance: ['Performance']
    // platform: ['Piattaforma'],
    // activePower: ['Potenza', 'Attiva'],
    // report: ['Report'],
    // sensors: ['Sensori']
  }

  const labels = Object.keys(dataObj.group1.data).map(key => labelsObj[key] ? labelsObj[key] : null).filter(el => el !== null)
  // Funzione che preso in ingresso l'oggetto dei dati restituisce il valore massimo dei dati da assegnare come massima y al grafico
  const getYmax = (data) => {
    const dataArray = Object.keys(data).map(key => {
      return data[key] ? Object.values(data[key].data) : []
    })

    const allData = dataArray.flat()

    return Math.max(...allData)
  }

  const performanceGraph = {
    labels,
    axis: {
      color: '#fff',
      yMax: getYmax(dataObj) || 1
    }
  }

  Object.keys(dataObj).forEach(key => {
    const data = dataObj[key] ? Object.values(dataObj[key].data) : []
    const name = dataObj[key] ? dataObj[key].name : ''

    performanceGraph[key] = {
      data,
      name
    }
  })

  // console.log('sizeBalance: ', performanceGraph)

  return performanceGraph
}
