import { useState } from 'react'
import clsx from 'clsx'
import { Box, Grid, makeStyles, TextField, Typography } from '@material-ui/core'
import { HexColorPicker } from 'react-colorful'

const useStyles = makeStyles(() => ({
  root: {}
}))

function PlainContent ({ className, ...rest }) {
  const { element, setElement } = rest
  // Nome del piano
  const currentText = element && element.config.length > 0
    ? element.config[0].data
        ? element.config[0].data.draw && element.config[0].data.draw.currentText
            ? element.config[0].data.draw.currentText
            : ''
        : ''
    : ''
  // Colore del bordo del piano
  const color = element && element.config.length > 0
    ? element.config[0].data
        ? element.config[0].data.draw && element.config[0].data.draw.borderColor
            ? element.config[0].data.draw.borderColor
            : '#0000FF'
        : '#0000FF'
    : '#0000FF'

  const [textContent, setTextContent] = useState(currentText)
  const [currentColor, setCurrentColor] = useState(color)

  const classes = useStyles()

  return (
    <div className={clsx(classes.root, className)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Typography variant='h6'>Nome del Piano</Typography>
          <Box my={2} width='100%'>
            <TextField
              fullWidth
              size='small'
              variant='outlined'
              label='Nome Piano'
              name='plainName'
              value={textContent}
              onChange={(e) => {
                e.persist()
                setElement(prevElement => {
                  if (prevElement.config.length > 0) {
                    // se l'elemento non ha l'oggetto data lo imposto a oggetto vuoto
                    if (!prevElement.config[0].data) {
                      prevElement.config[0].data = {}
                    }
                    // se l'elemento non ha l'oggetto draw lo imposto a oggetto vuoto
                    if (!prevElement.config[0].data.draw) {
                      prevElement.config[0].data.draw = {}
                    }
                    prevElement.config[0].data.draw = {
                      ...prevElement.config[0].data.draw,
                      currentText: e.target.value
                    }
                  } else {
                    prevElement.config.push({
                      devices: [],
                      data: {
                        draw: {
                          currentText: e.target.value
                        }
                      }
                    })
                  }
                  return { ...prevElement }
                })
                setTextContent(e.target.value)
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant='h6'>Colore del piano</Typography>
          <Box my={2} width='100%'>
            <HexColorPicker
              style={{ height: '80px' }}
              color={currentColor}
              onChange={setCurrentColor}
              onMouseUp={(e) => {
                e.persist()
                setElement((prevElement) => {
                  if (prevElement.config.length > 0) {
                    // se l'elemento non ha l'oggetto data lo imposto a oggetto vuoto
                    if (!prevElement.config[0].data) {
                      prevElement.config[0].data = {}
                    }
                    // se l'elemento non ha l'oggetto draw lo imposto a oggetto vuoto
                    if (!prevElement.config[0].data.draw) {
                      prevElement.config[0].data.draw = {}
                    }
                    prevElement.config[0].data.draw = {
                      ...prevElement.config[0].data.draw,
                      borderColor: currentColor
                    }
                  } else {
                    prevElement.config.push({
                      devices: [],
                      data: {
                        draw: {
                          borderColor: currentColor
                        }
                      }
                    })
                  }
                  return { ...prevElement }
                })
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}
export default PlainContent
