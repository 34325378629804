import { combineReducers } from '@reduxjs/toolkit'
// PLantTypes reducers
import { reducer as pvPlantReducer } from 'src/slices/pv/plant'
import { reducer as ledPlantReducer } from 'src/slices/led/plant'
import { reducer as industryConsumptionPlantReducer } from 'src/slices/industryConsumption/plant'
import { reducer as chpPlantReducer } from 'src/slices/chp/plant'
import { reducer as cchpPlantReducer } from 'src/slices/cchp/plant'
import { reducer as pvPlantViewReducer } from 'src/slices/pv/plantView'
import { reducer as ledPlantViewReducer } from 'src/slices/led/plantView'
import { reducer as industryConsumptionPlantViewReducer } from 'src/slices/industryConsumption/plantView'
import { reducer as chpPlantViewReducer } from 'src/slices/chp/plantView'
import { reducer as cchpPlantViewReducer } from 'src/slices/cchp/plantView'

import { reducer as mapsReducer } from 'src/slices/maps'
import { reducer as plantsReducer } from 'src/slices/plants'

const rootReducer = combineReducers({
  plants: plantsReducer,
  pvPlant: pvPlantReducer,
  ledPlant: ledPlantReducer,
  industryConsumptionPlant: industryConsumptionPlantReducer,
  chpPlant: chpPlantReducer,
  cchpPlant: cchpPlantReducer,
  pvPlantView: pvPlantViewReducer,
  ledPlantView: ledPlantViewReducer,
  industryConsumptionPlantView: industryConsumptionPlantViewReducer,
  chpPlantView: chpPlantViewReducer,
  cchpPlantView: cchpPlantViewReducer,
  maps: mapsReducer
})

export default rootReducer
