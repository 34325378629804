import { useState } from 'react'
import clsx from 'clsx'
// import numeral from 'numeral';
// import moment from 'moment'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
// import { getDeviceLogs } from 'src/utils/general'
// import { useDispatch, useSelector } from 'react-redux'
// import { useSelector } from 'react-redux'
// import { updatePlants } from 'src/slices/plantView'
import {
  // colors,
  Box,
  // Button,
  Tooltip,
  Card,
  CardHeader,
  // Checkbox,
  IconButton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  // Typography,
  makeStyles
  // withStyles,
} from '@material-ui/core'
// import { useSnackbar } from 'notistack'
import { StatusTableRow } from 'src/components/StatusTableRow'
import { basicColors } from 'src/theme'

import {
  // Edit as EditIcon,
  CheckCircle as OkIcon,
  AlertTriangle as WarningIcon,
  Slash as ErrorIcon,
  // Sun as SunIcon,
  // Cloud as CloudIcon,
  // CloudRain as RainIcon,
  // TrendingUp as UpIcon,
  // TrendingDown as DownIcon,
  DownloadCloud as DownloadIcon,
  Wifi as ConnectedIcon,
  WifiOff as DisconnectedIcon,
  HelpCircle as NullIcon
} from 'react-feather'
import DownloadDatasheetsDialog from '../../shared/DownloadDatasheetsDialog'

// stile CSS generale
const useStyles = makeStyles(() => ({
  root: {
    // height: '100%',
    width: '100%'
  }
}))

const OkStatus = () => (
  <span style={{ marginRight: 10 }}>
    <Tooltip title='Stato: OK' aria-label='Stato: OK'>
      <SvgIcon fontSize='small'>
        <OkIcon color={basicColors.darkGreen} fontSize='small' />
      </SvgIcon>
    </Tooltip>
  </span>
)

const WarningStatus = () => (
  <span style={{ marginRight: 10 }}>
    <Tooltip title='Stato: Attenzione' aria-label='Stato: Attenzione'>
      <SvgIcon fontSize='small'>
        <WarningIcon color={basicColors.darkYellow} fontSize='small' />
      </SvgIcon>
    </Tooltip>
  </span>
)

const ErrorStatus = () => (
  <span style={{ marginRight: 10 }}>
    <Tooltip title='Stato: Errore' aria-label='Stato: Errore'>
      <SvgIcon fontSize='small'>
        <ErrorIcon color={basicColors.darkRed} fontSize='small' />
      </SvgIcon>
    </Tooltip>
  </span>
)

const OnlineStatus = () => (
  <span style={{ marginRight: 10 }}>
    <Tooltip title='Connesso' aria-label='Connesso'>
      <SvgIcon fontSize='small'>
        <ConnectedIcon fontSize='small' />
      </SvgIcon>
    </Tooltip>
  </span>
)

const OfflineStatus = () => (
  <span style={{ marginRight: 10 }}>
    <Tooltip title='Disconnesso' aria-label='Disconnesso'>
      <SvgIcon fontSize='small'>
        <DisconnectedIcon fontSize='small' />
      </SvgIcon>
    </Tooltip>
  </span>
)

const NullStatus = () => (
  <span style={{ marginRight: 10 }}>
    <Tooltip title='Info non trovata' aria-label='Info non trovata'>
      <SvgIcon fontSize='small'>
        <NullIcon fontSize='small' />
      </SvgIcon>
    </Tooltip>
  </span>
)

// componente principale
const DevicesTable = ({ className, columns, rows, title, handleRowClick, ...rest }) => {
  const classes = useStyles()
  // const { enqueueSnackbar } = useSnackbar()
  // const dispatch = useDispatch()
  // const { name, peakPower, startDate, contractDuration, endDate } = useSelector(
  // (state) => state.plantView
  // )
  // console.log('deviceProps => ', deviceProps)

  // all'avvio aggiorno i dati
  // useEffect(() => {
  // dispatch(updatePlants())
  // // return () => {}
  // }, [dispatch])
  const [showDownloadDialog, setShowDownloadDialog] = useState(false)
  const [currentDocuments, setCurrentDocuments] = useState(null)

  // funziona che torna il valore con eventuale unità di misura
  const returnValue = (value, unit) => {
    let string = ''
    if (unit && value && value !== 'N.F.') {
      string = `${value}${unit}`
    } else if (value) {
      string = `${value}`
    }
    return string
  }

  const returnStatus = (status) => {
    return (
      <div>
        {status.general === 'ok'
          ? <OkStatus />
          : status.general === 'anomaly_component'
            ? <ErrorStatus />
            : status.general === 'anomaly_communication' || status.general === 'anomaly_energy'
              ? <WarningStatus />
              : <NullStatus />}
        {status.communication && status.communication === 'online'
          ? (
            <OnlineStatus />
            )
          : status.communication && status.communication === 'offline'
            ? (
              <OfflineStatus />
              )
            : (
              <NullStatus />
              )}
      </div>
    )
  }

  // funziona che torna le icone delle azioni
  const returnActions = (actions) => {
    return (
      <span style={{ marginRight: 10 }}>
        <Tooltip title={actions.documents ? 'Download Datasheet' : 'Nessun Datasheet caricato'} aria-label='Download Datasheet'>
          <span>
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                setShowDownloadDialog(true)
                setCurrentDocuments(actions.documents || [])
              }}
              /* onClick={(e) => {
                e.stopPropagation()
                if (actions.datasheet && actions.datasheet !== '') {
                  const a = document.createElement('a')
                  document.body.appendChild(a)
                  a.style = 'display: none'
                  a.href = actions.datasheet
                  a.click()
                  window.URL.revokeObjectURL(actions.datasheet)
                }
              }} */
              fontSize='small'
              disabled={!(actions.documents && actions.documents !== '')}
            >
              <DownloadIcon
                fontSize='small'
                color={actions.documents && actions.documents !== '' ? basicColors.darkText : basicColors.blueGrayLight}
              />
            </IconButton>
          </span>
        </Tooltip>
      </span>
    )
  }

  return (
    <>
      {showDownloadDialog
        ? <DownloadDatasheetsDialog list={currentDocuments} open={showDownloadDialog} onClose={() => setShowDownloadDialog(false)} />
        : null}
      <Card className={clsx(classes.root, className)} {...rest}>
        {/* <Box pt={1} pl={2}>
        <Typography gutterBottom variant='h5' component='h2'>
          {title}
        </Typography>
      </Box> */}
        <CardHeader
          // avatar={
          // <Avatar aria-label='recipe' className={classes.avatar}>
          // R
          // </Avatar>
          // }
          // action={
          // <Tooltip title='Anagrafica impianto' aria-label='Anagrafica'>
          // <IconButton size='small'>
          // <UserIcon color={colors.blueGrey[900]} />
          // </IconButton>
          // </Tooltip>
          // }
          title={title}
          // subheader='September 14, 2016'
          style={{
            paddingTop: 16,
            paddingBottom: 6
          }}
        />
        <Box>
          <PerfectScrollbar>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => {
                    return (
                      <TableCell key={index} align={column.value !== 'actions' ? 'left' : 'right'} style={{ minWidth: column.minWidth || 'auto' }}>
                        {column.label}
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, rowIndex) => {
                  return (
                    <StatusTableRow key={rowIndex} status={row.status.general} onClick={() => handleRowClick(row)}>
                      {columns.map((column, columnIndex) => {
                        return (
                          <TableCell
                            key={columnIndex}
                            align={column.value !== 'actions' ? 'left' : 'right'}
                            style={{
                              color: basicColors.darkText
                            }}
                          >
                            {column.value !== 'status' && column.value !== 'actions'
                              ? returnValue(row[column.value], column.unit)
                              : column.value === 'status'
                                ? returnStatus(row[column.value])
                                : column.value === 'actions'
                                  ? returnActions(row[column.value])
                                  : ''}
                          </TableCell>
                        )
                      })}
                    </StatusTableRow>
                  )
                })}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </Box>
      </Card>
    </>
  )
}

DevicesTable.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.array,
  rows: PropTypes.array,
  title: PropTypes.string,
  // deviceProps: PropTypes.object,
  handleRowClick: PropTypes.func
  // setRefreshUsers: PropTypes.func
}

DevicesTable.defaultProps = {
  columns: [],
  rows: [],
  title: 'Tabella',
  handleRowClick: null
  // deviceType: '',
}

export default DevicesTable
