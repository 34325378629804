import clsx from 'clsx'
import { Box, Divider, makeStyles, Tooltip, Typography } from '@material-ui/core'
import { basicColors } from 'src/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '300px',
    maxWidth: '400px',
    margin: theme.spacing(-1, 0),
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black
  },
  divider: {
    margin: theme.spacing(0, -2),
    backgroundColor: basicColors.blueGrayLight
  },
  anomalyRow: {
    padding: theme.spacing(1, 0)
  },
  anomalyWrapper: {
    backgroundColor: basicColors.lightRed,
    color: basicColors.darkRed,
    cursor: 'pointer'
  }
}))

function ContentTooltip ({ tooltipInfos, tooltipAnomalies, selectAnomaly, goToAnomalies, className }) {
  // console.log(tooltipAnomalies)
  const classes = useStyles()
  return (tooltipAnomalies && tooltipAnomalies.length > 0) || (tooltipInfos && tooltipInfos.length > 0)
    ? (
      <Box className={clsx(classes.root, className)}>
        {tooltipAnomalies && tooltipAnomalies.slice(0, 2).map((el, anomaliesIndex) => (
          <Box onClick={() => selectAnomaly(el)} pr={2} pl={2} className={classes.anomalyWrapper} key={`anomalies-${anomaliesIndex}`} width='100%'>
            <Typography className={classes.anomalyRow} component='div' variant='h5'>
              {(el.configuration && el.configuration.name) || ''}
            </Typography>
            <Divider className={classes.divider} />
          </Box>
        ))}
        {tooltipAnomalies && tooltipAnomalies.length > 2
          ? (
            <Box onClick={goToAnomalies} pr={2} pl={2} className={classes.anomalyWrapper} width='100%'>
              <Tooltip title='Vai alla tab Anomalie'>
                <Typography component='div' variant='h5'>
                  Altre anomalie
                </Typography>
              </Tooltip>
              <Divider className={classes.divider} />
            </Box>
            )
          : null}
        {tooltipInfos && tooltipInfos.map((el, index) => (
          <Box pr={2} pl={2} key={`infos-${index}`} width='100%'>
            <Box className={classes.anomalyRow} width='100%' display='flex' alignItems='center' justifyContent='space-between'>
              <Typography component='div' variant='h5'>
                {el.label}
              </Typography>
              <Typography component='div' variant='h5' style={{ textAlign: 'right' }}>
                {el.value}
              </Typography>
            </Box>
            <Divider className={classes.divider} />
          </Box>
        ))}
      </Box>
      )
    : (
      <Box className={clsx(classes.root, className)}>
        <Box pr={2} pl={2} width='100%'>
          <Box className={classes.anomalyRow} width='100%' display='flex' alignItems='center' justifyContent='center'>
            <Typography component='div' variant='h5'>
              Informazioni non disponibili per questo dispositivo
            </Typography>
          </Box>
        </Box>
      </Box>)
}
export default ContentTooltip
