import clsx from 'clsx'
import { Box, Divider, makeStyles, Typography } from '@material-ui/core'
import { basicColors } from 'src/theme'
import {
  CheckCircle as OkIcon,
  AlertTriangle as WarningIcon,
  Slash as ErrorIcon
} from 'react-feather'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 360,
    maxHeight: 420,
    overflowY: 'auto',
    // padding: theme.spacing(1),
    margin: '-15px -21px',
    borderRadius: '2p 2px 0 0'
  },
  label: {
    fontWeight: 500,
    marginRight: theme.spacing(2)
  },
  title: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    fontWeight: 700,
    borderRadius: '8px 8px 0 0'
  },
  divider: {
    backgroundColor: basicColors.blueGrayLight,
    marginLeft: -7,
    marginRight: -7
  },
  ok: {
    backgroundColor: basicColors.lightGreen
  },
  error: {
    backgroundColor: basicColors.lightRed
  },
  warning: {
    backgroundColor: basicColors.lightYellow
  },
  maintenance: {
    backgroundColor: basicColors.lightYellow
  },
  clickable: {
    cursor: 'pointer'
  }
}))

function ClusterPopover ({ data = [], className, ...rest }) {
  const classes = useStyles()

  const errorData = data.filter(el => el.status === 'error')
  const warningData = data.filter(el => (el.status === 'warning' || el.status === 'maintenance'))
  const okData = data.filter(el => el.status === 'ok')

  const allData = errorData.concat(warningData, okData)

  const iconByStatus = {
    ok: <OkIcon color={basicColors.darkGreen} />,
    warning: <WarningIcon color={basicColors.darkYellow} />,
    maintenance: <WarningIcon color={basicColors.darkYellow} />,
    error: <ErrorIcon color={basicColors.darkRed} />
  }

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {allData.length > 0
        ? (
          <div>
            <Box className={classes.title} mt={-0.875} mx={-0.875} pt={2} pb={1} px={2} display='flex' alignItems='center'>
              <Typography noWrap component='div' variant='h5'>
                {`Impianti Aggregati: ${allData.length}`}
              </Typography>
            </Box>
            {
              allData.map((el, index) => (
                <div id={`plant-${index}`} className={clsx(classes.clickable, classes[el.status])} key={`popup-element${index}`}>
                  <Box px={1} py={0.5} width='100%' display='flex' alignItems='center' justifyContent='space-between'>
                    <Typography className={classes.label} component='div' variant='body2' color='secondary'>
                      {el.plantName}
                    </Typography>
                    <Typography component='div' variant='body2'>
                      {iconByStatus[el.status] || ''}
                    </Typography>
                  </Box>
                  {index !== (Object.keys(allData).length - 1)
                    ? <Divider className={classes.divider} />
                    : null}
                </div>
              ))
            }
          </div>
          )
        : null}
    </div>
  )
}
export default ClusterPopover
