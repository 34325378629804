import { useState, useEffect, memo } from 'react'
import clsx from 'clsx'
// import moment from 'moment'
import PropTypes from 'prop-types'
import Chart from 'react-apexcharts'
import { useSelector } from 'react-redux'
import { fixedNum, europeNum } from 'src/utils/general'
import { basicColors } from 'src/theme'
import LoadingCard from 'src/components/LoadingCard'
import { decodeIrradiationGraphFromApi } from '../utils'
import {
  // colors,
  Box,
  // Button,
  // Tooltip,
  Card,
  // CardContent,
  // IconButton,
  Table,
  TableBody,
  TableCell,
  // TableHead,
  TableRow,
  // Typography,
  makeStyles,
  useMediaQuery
  // withStyles,
} from '@material-ui/core'
// import { useSnackbar } from 'notistack'

// import {
//  // Edit as EditIcon,
//  WifiOff as DisconnectedIcon,
// } from 'react-feather'

// variaible con le dimenzioni del grafico
const graphSize = {
  height: 270,
  width: 380
}

// stile CSS generale
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    paddingTop: 8
  },
  boxContainer: {
    width: '100%',
    height: graphSize.height,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000
  },
  iconContainer: {
    marginLeft: 10
  }
}))

const graphColors = {
  lightBlue: '#449CEE',
  lightGreen: '#54F286'
}

// componente principale
const PrGraphView = ({ className, showTable, ...rest }) => {
  // console.log('showTable =>', showTable)
  const classes = useStyles()
  // const { enqueueSnackbar } = useSnackbar()
  // const dispatch = useDispatch()
  const {
    analytics,
    activePower,
    averageRadiation,
    modulesTemperature,
    environmentTemperature,
    peakPowerValue,
    peakIrradiationValue
  } = useSelector((state) => state.pvPlantView)
  // console.log('analytics => ', analytics)
  // funzione che torna il valore % rispetto al 100%
  const returnPercentage = (data, minIndex, maxIndex) => {
    // console.log('data => ', data)
    // console.log('minIndex => ', minIndex)
    // console.log('maxIndex => ', maxIndex)
    const val = (100 * data[minIndex]) / data[maxIndex]
    if (data[minIndex] && data[maxIndex]) {
      return fixedNum(val, 0)
    }
    return 0
  }

  const productionData = {
    kwh: [
      analytics.realProduction !== '-' ? fixedNum(analytics.realProduction, 0) : 0,
      analytics.baselineProduction !== '-' ? fixedNum(analytics.baselineProduction, 0) : 0
    ],
    pr: [
      analytics.realPr !== '-' ? fixedNum(analytics.realPr, 2) : 0,
      analytics.baselinePr !== '-' ? fixedNum(analytics.baselinePr, 2) : 0
    ],
    productability: 0,
    series: [0, 0]
  }
  productionData.productability =
    analytics.realProduction !== '-'
      ? (Number(productionData.kwh[0]) - Number(productionData.kwh[1])) / Number(productionData.kwh[1])
      : 0
  productionData.productability = fixedNum(productionData.productability, 2)
  // mi setto i valori da passare alla serie
  const maxValueIndex = Number(productionData.kwh[0]) > Number(productionData.kwh[1]) ? 0 : 1
  const minValueIndex = maxValueIndex === 0 ? 1 : 0
  // trovato il maggiore mi sistemo i dati per la serie
  productionData.series[0] = maxValueIndex === 0 ? '100' : returnPercentage(productionData.kwh, minValueIndex, maxValueIndex)
  productionData.series[1] = maxValueIndex === 1 ? '100' : returnPercentage(productionData.kwh, minValueIndex, maxValueIndex)
  // console.log('productionData => ', productionData)

  const [chartData, setChartData] = useState({
    series: productionData.series,
    chart: {
      height: graphSize.height,
      width: graphSize.width,
      type: 'radialBar'
      // id='radialPr'
    },
    options: {
      tooltip: {
        enabled: true,
        shared: true,
        followCursor: false,
        // intersect: true,
        fillSeriesColor: false,
        theme: 'dark',
        style: {
          fontSize: '12px',
          fontFamily: undefined
        },
        onDatasetHover: {
          highlightDataSeries: false
        },
        // custom: customTooltip,
        // custom: function({ series, seriesIndex }) {
        // 	// console.log('custom series => ', series)
        // 	// console.log('custom seriesIndex => ', seriesIndex)
        // 	let finalProd = 'N.F.'
        // 	let finalPr = 'N.F.'
        // 	if (seriesIndex === 0 && analytics.realProduction !== '-') {
        // 		finalProd = productionData.kwh[seriesIndex]
        // 		finalPr = productionData.pr[seriesIndex]
        // 	} else if (seriesIndex === 1 && analytics.baselineProduction !== '-') {
        // 		finalProd = productionData.kwh[seriesIndex]
        // 		finalPr = productionData.pr[seriesIndex]
        // 	}
        // 	return `<div class="arrow_box"><p>Prod: ${finalProd} kWh</p><p>PR: ${finalPr}</p></div>`
        // },
        x: {
          show: false
        },
        y: {
          // formatter: function(value, obj) {
          // 	// N.B. potrei avere 2 value uguali
          // 	console.log('value ', value)
          // 	console.log('obj ', obj)
          // 	var dataPointIndex = productionData.kwh.findIndex(
          // 		(val) => val === value
          // 	)
          // 	if (productionData.pr[dataPointIndex] !== 'null') {
          // 		return (
          // 			'<p>Prod: ' +
          // 			value +
          // 			'Kwh </p><p>PR: ' +
          // 			productionData.pr[dataPointIndex] +
          // 			'</p>'
          // 		)
          // 	} else {
          // 		return (
          // 			'<p>' +
          // 			value +
          // 			'Kwh </p><p>PR: ' +
          // 			(
          // 				productionData.kwh[dataPointIndex] /
          // 				productionData.standard.kwh
          // 			).toFixed(2) +
          // 			'</p>'
          // 		)
          // 	}
          // },
          // title: {
          // 	formatter: (seriesName) => '',
          // },
        },
        marker: {
          show: false
        },
        fixed: {
          enabled: false,
          position: 'bottomRight',
          offsetX: 0,
          offsetY: 0
        }
      },
      stroke: {
        lineCap: 'round'
      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 5,
            size: '60%',
            background: basicColors.nav,
            image: undefined
          },
          dataLabels: {
            name: {
              show: true,
              color: graphColors.lightBlue,
              offsetY: -10,
              formatter: function (val) {
                // console.log('dataLabels NAME formatter val => ', val)
                if (val !== 'Reale' && val.indexOf('Produzione') === -1) {
                  // sopra barra PVgis
                  return 'PR | Scostam.'
                } else if (val.indexOf('Produzione') === -1) {
                  // sopra barra Reale
                  return 'PR | Scostam.'
                } else {
                  // ogni altro caso
                  return 'Produz. | PR'
                }
              }
            },
            value: {
              show: true,
              color: '#fff',
              fontSize: '15px',
              offsetY: 0
              // formatter: formatterValueDataLabels,
              // formatter: function(val, opts) {
              // 	// console.log('dataLabels VALUE formatter opts => ', opts)
              // 	// console.log('dataLabels VALUE formatter val => ', val)
              // 	const finalPr = analytics.realPr !== '-' ? productionData.pr[0] : 'N.F.'
              // 	return `${finalPr} | ${productionData.productability}%`
              // },
            },
            total: {
              show: true,
              label: 'Produzione | PR',
              color: graphColors.lightBlue,
              fontSize: '14px',
              // fontFamily: undefined,
              fontWeight: 600
              // formatter: formatterTotalDataLabels,
              // formatter: function(w) {
              // 	const finalProd = analytics.realProduction !== '-' ? productionData.kwh[0] : 'N.F.'
              // 	const finalPr = analytics.realPr !== '-' ? productionData.pr[0] : 'N.F.'
              // 	return ` ${finalProd}kWh | ${finalPr}`
              // },
            }
          },
          track: {
            // show: false,
            // startAngle: 0,
            // endAngle: 270,
            // background: '#ffffff',
            // strokeWidth: '100%',
            // opacity: 1,
            // margin: 3,
            show: true,
            startAngle: 0,
            endAngle: 270,
            background: basicColors.background,
            strokeWidth: '100%',
            margin: 3,
            dropShadow: {
              enabled: false,
              top: 0,
              left: 0,
              blur: 3,
              opacity: 0.5
            }
          }
        }
      },
      colors: [graphColors.lightGreen, graphColors.lightBlue],
      labels: ['Reale', 'Baseline'],
      legend: {
        show: true,
        floating: true,
        position: 'left',
        // horizontalAlign: 'center',
        fontSize: '12px',
        fontWeight: 600,
        offsetX: 50,
        offsetY: -6,
        labels: {
          useSeriesColors: true
          // colors: ['#fff']
        },
        markers: {
          width: 0,
          height: 0,
          strokeWidth: 0,
          strokeColor: '#fff',
          fillColors: undefined,
          radius: 0,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0
        },
        itemMargin: {
          vertical: 0
        },
        formatter: function (seriesName, opts) {
          return seriesName
        }
      }
      // responsive: [
      //   {
      //     breakpoint: 480,
      //     options: {
      //       legend: {
      //         show: false,
      //       },
      //     },
      //   },
      // ],
    }
  })

  // mi preparo i dati per il grafico dell'irradiation
  const dataNormalize = function (dataSet, options) {
    // Aggiungo il primo asse y (energia prodotta)
    options.yaxis.push({
      show: false,
      seriesName: Object.keys(dataSet).length > 0 ? dataSet.energy.series[0].name : '',
      decimalsInFloat: 3,
      // max: Object.keys(dataSet).length > 0 ? dataSet.axis.yMax : 0,
      labels: {
        minWidth: '100%',
        show: false,
        style: {
          colors: Object.keys(dataSet).length > 0 ? dataSet.axis.color : ''
        },
        formatter: (value) => { return value ? Math.floor(Number(value)) + ' kWh' : '' }
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    })
    if (Object.keys(dataSet).length > 0) {
      for (let s = 0; s < dataSet.energy.series.length; s++) {
        options.colors.push(dataSet.energy.series[s].color)

        /* options.yaxis.push({
          seriesName: dataSet.energy.series[0].name,
          decimalsInFloat: 3,
          show: false,
          labels: {
            style: {
              colors: dataSet.axis.color
            },
            formatter: (value) => { return value ? Math.floor(Number(value)) + ' kWh' : '' }
          }
        }) */

        if (options.series === undefined) {
          options.series = []
        }
        const isAllEmpty = dataSet.energy.series[s].data.every(data => !data || data === 0)
        options.series.push({
          name: dataSet.energy.series[s].name,
          type: (dataSet.energy.series[s] && dataSet.energy.series[s].type) || 'area',
          data: isAllEmpty ? [] : dataSet.energy.series[s].data
        })
      }
    }

    options.yaxis.push({
      // seriesName: Object.keys(dataSet).length > 0 ? dataSet.radiation.series[0].name : '',
      decimalsInFloat: 3,
      opposite: true,
      min: 0,
      show: false,
      labels: {
        show: false,
        style: {
          colors: Object.keys(dataSet).length > 0 ? dataSet.axis.color : ''
        },
        formatter: (value) => {
          return value ? europeNum(value) + ` W/m${String.fromCodePoint(0x00B2)}` : ''
          // return value ? Math.floor(Number(value)) + ` kW/m${String.fromCodePoint(0x00B2)}` : ''
        }
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    })
    if (Object.keys(dataSet).length > 0) {
      for (let s = 0; s < dataSet.radiation.series.length; s++) {
        options.colors.push(dataSet.radiation.series[s].color)

        /* options.yaxis.push({
          seriesName: dataSet.radiation.series[0].name,
          decimalsInFloat: 3,
          opposite: true,
          show: false,
          labels: {
            style: {
              colors: dataSet.axis.color
            },
            formatter: (value) => {
              return value ? europeNum(value) + ` kW/m${String.fromCodePoint(0x00B2)}` : ''
            }
          }
        }) */
        if (options.series === undefined) {
          options.series = []
        }
        const normalizedData = dataSet.radiation.series[s].data
        const isAllEmpty = normalizedData.every(data => !data || data === 0)
        // normalizedData[0] = 1
        options.series.push({
          name: dataSet.radiation.series[s].name,
          type: (dataSet.radiation.series[s] && dataSet.radiation.series[s].type) || 'area',
          data: isAllEmpty ? [] : normalizedData
        })
      }
    }

    // options.xaxis.min = Object.keys(dataSet).length > 0 ? dataSet.radiation.series[0].x : null
    // options.xaxis.max = Object.keys(dataSet).length > 0 ? dataSet.radiation.series[dataSet.radiation.series.length - 1].x : null

    return options
  }
  // mi preparo la variabile per il grafico
  const radiationData = analytics.radiationData.irradiation[0] !== '-' ? decodeIrradiationGraphFromApi(analytics.radiationData, 'live') : {}

  const options = {
    chart: {
      width: '100%',
      height: graphSize.height - 20,
      foreColor: '#fff',
      // id: 'energyRadiation',
      // group: 'energy',
      type: 'area',
      sparkline: {
        enabled: false
      },
      animations: {
        enabled: false
      },
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    labels: Object.keys(radiationData).length > 0 ? radiationData.labels : [],
    colors: [],
    series: undefined,
    tooltip: {
      theme: 'dark',
      x: {
        show: false,
        format: 'dd/MM/yyyy HH:mm'
      },
      y: {
        show: false,
        formatter: function (value, { seriesIndex }) {
          if (seriesIndex === 1) {
            return value ? `${europeNum(value)} W/m${String.fromCodePoint(0x00B2)}` : '-'
          }

          return value ? `${europeNum(value)} kWh` : '-'
        }
      }
    },
    xaxis: {
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: [],
    grid: {
      show: false
    },
    legend: {
      show: false,
      labels: {
        colors: Object.keys(radiationData).length > 0 ? radiationData.axis.color : '',
        useSeriesColors: false
      }
    },
    noData: {
      text: 'Non ci sono dati da visualizzare.',
      align: 'center',
      verticalAlign: 'middle',
      // offsetX: 0,
      // offsetY: 0,
      style: {
        // color: undefined,
        fontSize: '13px'
        // fontFamily: undefined
      }
    }
  }

  const normalizedOptions = Object.keys(radiationData).length > 0 ? dataNormalize(radiationData, options) : options
  // console.log('normalizedOptions => ', normalizedOptions)

  // ogni update di analytics aggiorno i dati
  useEffect(() => {
    // console.log('PrGraphView dentro useEffect')
    // controllo se ho i dati
    if (analytics.realProduction !== '-') {
      // console.log('PrGraphView dentro if')
      const newProductionData = {
        kwh: [
          analytics.realProduction !== '-' ? fixedNum(analytics.realProduction, 0) : 0,
          analytics.baselineProduction !== '-' ? fixedNum(analytics.baselineProduction, 0) : 0
        ],
        pr: [
          analytics.realPr !== '-' ? fixedNum(analytics.realPr, 2) : 0,
          analytics.baselinePr !== '-' ? fixedNum(analytics.baselinePr, 2) : 0
        ],
        productability: 0,
        series: [0, 0]
      }
      newProductionData.productability =
        analytics.realProduction !== '-'
          ? (Number(newProductionData.kwh[0]) - Number(newProductionData.kwh[1])) / Number(newProductionData.kwh[1])
          : 0
      newProductionData.productability = fixedNum(newProductionData.productability, 2)
      // mi setto i valori da passare alla serie
      const maxValueIndex = Number(newProductionData.kwh[0]) > Number(newProductionData.kwh[1]) ? 0 : 1
      const minValueIndex = maxValueIndex === 0 ? 1 : 0
      // trovato il maggiore mi sistemo i dati per la serie
      newProductionData.series[0] =
        maxValueIndex === 0 ? '100' : returnPercentage(newProductionData.kwh, minValueIndex, maxValueIndex)
      newProductionData.series[1] =
        maxValueIndex === 1 ? '100' : returnPercentage(newProductionData.kwh, minValueIndex, maxValueIndex)
      // console.log('newProductionData => ', newProductionData)
      // setto la serie
      setChartData((prevState) => {
        return {
          ...prevState,
          series: newProductionData.series,
          options: {
            tooltip: {
              ...prevState.options.tooltip,
              // enabled: false,
              shared: !prevState.options.tooltip.shared,
              custom: function ({ series, seriesIndex }) {
                // console.log('custom series => ', series)
                // console.log('custom seriesIndex => ', seriesIndex)
                let finalProd = 'N.F.'
                let finalPr = 'N.F.'
                if (seriesIndex === 0 && analytics.realProduction !== '-') {
                  finalProd = newProductionData.kwh[seriesIndex]
                  finalPr = newProductionData.pr[seriesIndex]
                } else if (seriesIndex === 1 && analytics.baselineProduction !== '-') {
                  finalProd = newProductionData.kwh[seriesIndex]
                  finalPr = newProductionData.pr[seriesIndex]
                }
                return `<div class="arrow_box"><p>Prod: ${finalProd} kWh</p><p>PR: ${finalPr}</p></div>`
              }
            },
            plotOptions: {
              ...prevState.options.plotOptions,
              radialBar: {
                ...prevState.options.plotOptions.radialBar,
                dataLabels: {
                  ...prevState.options.plotOptions.radialBar.dataLabels,
                  value: {
                    ...prevState.options.plotOptions.radialBar.dataLabels.value,
                    formatter: function (val, opts) {
                      // console.log('dataLabels VALUE formatter opts => ', opts)
                      // console.log('dataLabels VALUE formatter val => ', val)
                      const finalPr = analytics.realPr !== '-' ? newProductionData.pr[0] : 'N.F.'
                      return `${finalPr} | ${newProductionData.productability}%`
                    }
                  },
                  total: {
                    ...prevState.options.plotOptions.radialBar.dataLabels.total,
                    formatter: function (w) {
                      const finalProd = analytics.realProduction !== '-' ? newProductionData.kwh[0] : 'N.F.'
                      const finalPr = analytics.realPr !== '-' ? newProductionData.pr[0] : 'N.F.'
                      return ` ${finalProd}kWh | ${finalPr}`
                    }
                  }
                }
              }
            }
          }
        }
      })
    }
    return () => { }
  }, [analytics])
  // console.log('PrGraphView options => ', JSON.parse(JSON.stringify(options)))
  const isOneLineTable = useMediaQuery((theme) => theme.breakpoints.down('lg'))
  // console.log('chartData => ', chartData)

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Box className={classes.boxContainer}>
        {analytics.realProduction !== '-'
          ? <Chart
              id='radialPr'
              options={chartData.options}
              series={chartData.series}
              type={chartData.chart.type}
              height={chartData.chart.height}
              width={chartData.chart.width}
            />
          : <LoadingCard />}
      </Box>
      <Box className={classes.boxContainer}>
        {analytics.radiationData.irradiation[0] !== '-'
          ? <Chart
              options={normalizedOptions || options}
              series={normalizedOptions.series || undefined}
              type={(normalizedOptions.chart && normalizedOptions.chart.type) || 'area'}
              height={(normalizedOptions.chart && normalizedOptions.chart.height) || 0}
              width={(normalizedOptions.chart && normalizedOptions.chart.width) || 0}
            />
          : <LoadingCard />}
      </Box>
      {showTable && isOneLineTable
        ? (
          <Box display='flex'>
            <Table size='small'>
              <TableBody>
                <TableRow>
                  <TableCell variant='head'>P. Attiva</TableCell>
                  <TableCell>{europeNum(activePower)} kW</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant='head'>P. Picco</TableCell>
                  <TableCell>{europeNum(peakPowerValue)} kW</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant='head'>Irr. Medio</TableCell>
                  <TableCell>{europeNum(averageRadiation)} W/m<sup>2</sup></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant='head'>Irr. Picco</TableCell>
                  <TableCell>{europeNum(peakIrradiationValue)} W/m<sup>2</sup></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant='head'>Temp. Moduli</TableCell>
                  <TableCell>{europeNum(modulesTemperature)} °C</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant='head'>Temp. Ambiente</TableCell>
                  <TableCell>{europeNum(environmentTemperature)} °C</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          )
        : (
          <Box display='flex'>
            <Table size='small'>
              <TableBody>
                <TableRow>
                  <TableCell variant='head'>P. Attiva</TableCell>
                  <TableCell>{europeNum(activePower)} kW</TableCell>
                  <TableCell variant='head'>P. Picco</TableCell>
                  <TableCell>{europeNum(peakPowerValue)} kW</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant='head'>Irr. Medio</TableCell>
                  <TableCell>{europeNum(averageRadiation)} W/m<sup>2</sup></TableCell>
                  <TableCell variant='head'>Irr. Picco</TableCell>
                  <TableCell>{europeNum(peakIrradiationValue)} W/m<sup>2</sup></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant='head'>Temp. Moduli</TableCell>
                  <TableCell>{europeNum(modulesTemperature)} °C</TableCell>
                  <TableCell variant='head'>Temp. Ambiente</TableCell>
                  <TableCell>{europeNum(environmentTemperature)} °C</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          )}
    </Card>
  )
}

PrGraphView.propTypes = {
  className: PropTypes.string,
  showTable: PropTypes.bool
  // customers: PropTypes.array.isRequired,
  // setRefreshUsers: PropTypes.func
}

PrGraphView.defaultProps = {
  showTable: false
}

export default memo(PrGraphView)
