import { useEffect, memo } from 'react'
import NProgress from 'nprogress'
import { Box, CircularProgress, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3)
  },
  cicle: {
    color: theme.palette.primary.main
  }
}))

const LoadingCard = ({ className }) => {
  const classes = useStyles()

  useEffect(() => {
    NProgress.start()

    return () => {
      NProgress.done()
    }
  }, [])

  return (
    <div className={clsx(className, classes.root)}>
      <Box width='100%' align='center'>
        <CircularProgress className={classes.cicle} variant='indeterminate' size={70} thickness={4} />
      </Box>
    </div>
  )
}

export default memo(LoadingCard)
