import mock from 'src/utils/___mock'

// Dati Mock Analisi di Stringa
const stringAnalysisData = {
  stringAnalysis: [{
    cc: {
      data: [0, 7123.7, 6996.8, 6984.3, 6872.9, 6572.0],
      labels: ['', '1.2', '1.5', '1.3', '2.5', '1.1'],
      min: 5900,
      middle: 6910
    },
    energy: {
      data: [null, 35.3, 34.7, 34.3, 33.5, 33.2],
      middle: 34.2
    }
  },
  {
    cc: {
      data: [0, 8362.7, 3964.8, 5034.3, 7129.9, 6572.0],
      labels: ['', '1.5', '1.8', '2.4', '2.8', '3.1'],
      min: 6400,
      middle: 7130
    },
    energy: {
      data: [null, 45.3, 23.7, 56.3, 55.5, 42.2],
      middle: 41.5
    }
  },
  {
    cc: {
      data: [0, 8362.7, 3964.8, 5034.3, 7129.9, 6572.0],
      labels: ['', '1.5', '1.8', '2.4', '2.8', '3.1'],
      min: 6400,
      middle: 7130
    },
    energy: {
      data: [null, 45.3, 23.7, 56.3, 55.5, 42.2],
      middle: 41.5
    }
  }]
}
// Dati Mock Andamento
const trendData = {
  trendGraph: [{
    expectedEnergy: [
      {
        label: '2021-02-23T23:00:00.000Z',
        value: 0.03714681440443214
      },
      {
        label: '2021-02-24T00:00:00.000Z',
        value: 0.049529085872576185
      },
      {
        label: '2021-02-24T01:00:00.000Z',
        value: 0.049529085872576185
      },
      {
        label: '2021-02-24T02:00:00.000Z',
        value: 0.049529085872576185
      },
      {
        label: '2021-02-24T03:00:00.000Z',
        value: 0.049529085872576185
      },
      {
        label: '2021-02-24T04:00:00.000Z',
        value: 0.049529085872576185
      },
      {
        label: '2021-02-24T05:00:00.000Z',
        value: 0.11753331048632558
      },
      {
        label: '2021-02-24T06:00:00.000Z',
        value: 3.026277242910162
      },
      {
        label: '2021-02-24T07:00:00.000Z',
        value: 4.127939768409621
      },
      {
        label: '2021-02-24T08:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T09:00:00.000Z',
        value: 7.092977840907741
      },
      {
        label: '2021-02-24T10:00:00.000Z',
        value: 30.683647060007996
      },
      {
        label: '2021-02-24T11:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T12:00:00.000Z',
        value: 30.32568635291079
      },
      {
        label: '2021-02-24T13:00:00.000Z',
        value: 25.3386674522183
      },
      {
        label: '2021-02-24T14:00:00.000Z',
        value: 17.649276941196312
      },
      {
        label: '2021-02-24T15:00:00.000Z',
        value: 7.603333766651318
      },
      {
        label: '2021-02-24T16:00:00.000Z',
        value: 1.341928644578627
      },
      {
        label: '2021-02-24T17:00:00.000Z',
        value: 0.014858725467210043
      },
      {
        label: '2021-02-24T18:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T19:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T20:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T21:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T22:00:00.000Z',
        value: null
      }
    ],
    producedEnergy: [
      {
        label: '2021-02-23T23:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T00:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T01:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T02:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T03:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T04:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T05:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T06:00:00.000Z',
        value: 2.25
      },
      {
        label: '2021-02-24T07:00:00.000Z',
        value: 7.069999999992433
      },
      {
        label: '2021-02-24T08:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T09:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T10:00:00.000Z',
        value: 38.580000000001746
      },
      {
        label: '2021-02-24T11:00:00.000Z',
        value: 8.779999999998836
      },
      {
        label: '2021-02-24T12:00:00.000Z',
        value: 79.80999999999767
      },
      {
        label: '2021-02-24T13:00:00.000Z',
        value: 40.26000000000931
      },
      {
        label: '2021-02-24T14:00:00.000Z',
        value: 28.389999999992142
      },
      {
        label: '2021-02-24T15:00:00.000Z',
        value: 15.190000000002328
      },
      {
        label: '2021-02-24T16:00:00.000Z',
        value: 3.609999999993306
      },
      {
        label: '2021-02-24T17:00:00.000Z',
        value: 0.12000000000261934
      },
      {
        label: '2021-02-24T18:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T19:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T20:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T21:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T22:00:00.000Z',
        value: null
      }
    ],
    exportedEnergy: [
      {
        label: '2021-02-23T23:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T00:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T01:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T02:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T03:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T04:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T05:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T06:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T07:00:00.000Z',
        value: 0.1
      },
      {
        label: '2021-02-24T08:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T09:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T10:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T11:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T12:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T13:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T14:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T15:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T16:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T17:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T18:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T19:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T20:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T21:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T22:00:00.000Z',
        value: null
      }
    ]
  },
  {
    expectedEnergy: [
      {
        label: '2021-02-23T23:00:00.000Z',
        value: 0.03714681440443214
      },
      {
        label: '2021-02-24T00:00:00.000Z',
        value: 0.049529085872576185
      },
      {
        label: '2021-02-24T01:00:00.000Z',
        value: 0.049529085872576185
      },
      {
        label: '2021-02-24T02:00:00.000Z',
        value: 0.049529085872576185
      },
      {
        label: '2021-02-24T03:00:00.000Z',
        value: 0.049529085872576185
      },
      {
        label: '2021-02-24T04:00:00.000Z',
        value: 0.049529085872576185
      },
      {
        label: '2021-02-24T05:00:00.000Z',
        value: 0.11753331048632558
      },
      {
        label: '2021-02-24T06:00:00.000Z',
        value: 3.026277242910162
      },
      {
        label: '2021-02-24T07:00:00.000Z',
        value: 4.127939768409621
      },
      {
        label: '2021-02-24T08:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T09:00:00.000Z',
        value: 7.092977840907741
      },
      {
        label: '2021-02-24T10:00:00.000Z',
        value: 30.683647060007996
      },
      {
        label: '2021-02-24T11:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T12:00:00.000Z',
        value: 30.32568635291079
      },
      {
        label: '2021-02-24T13:00:00.000Z',
        value: 25.3386674522183
      },
      {
        label: '2021-02-24T14:00:00.000Z',
        value: 17.649276941196312
      },
      {
        label: '2021-02-24T15:00:00.000Z',
        value: 7.603333766651318
      },
      {
        label: '2021-02-24T16:00:00.000Z',
        value: 1.341928644578627
      },
      {
        label: '2021-02-24T17:00:00.000Z',
        value: 0.014858725467210043
      },
      {
        label: '2021-02-24T18:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T19:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T20:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T21:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T22:00:00.000Z',
        value: null
      }
    ],
    producedEnergy: [
      {
        label: '2021-02-23T23:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T00:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T01:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T02:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T03:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T04:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T05:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T06:00:00.000Z',
        value: 2.25
      },
      {
        label: '2021-02-24T07:00:00.000Z',
        value: 7.069999999992433
      },
      {
        label: '2021-02-24T08:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T09:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T10:00:00.000Z',
        value: 38.580000000001746
      },
      {
        label: '2021-02-24T11:00:00.000Z',
        value: 8.779999999998836
      },
      {
        label: '2021-02-24T12:00:00.000Z',
        value: 79.80999999999767
      },
      {
        label: '2021-02-24T13:00:00.000Z',
        value: 40.26000000000931
      },
      {
        label: '2021-02-24T14:00:00.000Z',
        value: 28.389999999992142
      },
      {
        label: '2021-02-24T15:00:00.000Z',
        value: 15.190000000002328
      },
      {
        label: '2021-02-24T16:00:00.000Z',
        value: 3.609999999993306
      },
      {
        label: '2021-02-24T17:00:00.000Z',
        value: 0.12000000000261934
      },
      {
        label: '2021-02-24T18:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T19:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T20:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T21:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T22:00:00.000Z',
        value: null
      }
    ],
    exportedEnergy: [
      {
        label: '2021-02-23T23:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T00:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T01:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T02:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T03:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T04:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T05:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T06:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T07:00:00.000Z',
        value: 0.1
      },
      {
        label: '2021-02-24T08:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T09:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T10:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T11:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T12:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T13:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T14:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T15:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T16:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T17:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T18:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T19:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T20:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T21:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T22:00:00.000Z',
        value: null
      }
    ]
  }]
}
// Dati Mock Scostamento
const deviationData = {
  linearRegression: {
    line: [
      { x: 4.08, y: 223.586 },
      { x: 5.42, y: 289.514 },
      { x: 6.06, y: 321.02 },
      { x: 6.21, y: 328.382 },
      { x: 6.47, y: 341.174 },
      { x: 6.49, y: 342.158 },
      { x: 6.77, y: 355.934 }
    ],
    points: [
      { x: 6.77, y: 361.23 },
      { x: 4.08, y: 222.75 },
      { x: 6.47, y: 342.19 },
      { x: 6.49, y: 339.98 },
      { x: 6.21, y: 320.85 },
      { x: 6.21, y: 327.36 },
      { x: 6.06, y: 319.1 },
      { x: 5.42, y: 295.18 }
    ]
  },
  prDeviation: {
    baselinePr: 1.193026632415803,
    realPr: 0.9727761207753779,
    baselineProduction: 127.60691934951171,
    realProduction: 224.0599999999904
  },
  stringAnalysis: [{
    cc: {
      data: [0, 7123.7, 6996.8, 6984.3, 6872.9, 6572.0],
      labels: ['', '1.2', '1.5', '1.3', '2.5', '1.1'],
      min: 5900,
      middle: 6910
    },
    energy: {
      data: [null, 35.3, 34.7, 34.3, 33.5, 33.2],
      middle: 34.2
    }
  },
  {
    cc: {
      data: [0, 8362.7, 3964.8, 5034.3, 7129.9, 6572.0],
      labels: ['', '1.5', '1.8', '2.4', '2.8', '3.1'],
      min: 6400,
      middle: 7130
    },
    energy: {
      data: [null, 45.3, 23.7, 56.3, 55.5, 42.2],
      middle: 41.5
    }
  }]
}
// Dati Mock Strings PR
export const stringsPrData = {
  status: true,
  data: [
    {
      orientation: {
        name: 'Orientamento 1',
        uuid: '121bdc5d-8b33-43f7-8ea2-22fa6cc0239e'
      },
      strings: [
        {
          name: '1.1.1',
          values: [
            [
              '2023-04-01T22:00:00.000Z',
              0.849
            ],
            [
              '2023-04-02T22:00:00.000Z',
              0.842
            ],
            [
              '2023-04-03T22:00:00.000Z',
              0.797
            ],
            [
              '2023-04-04T22:00:00.000Z',
              0.837
            ],
            [
              '2023-04-05T22:00:00.000Z',
              0.847
            ],
            [
              '2023-04-06T22:00:00.000Z',
              0.837
            ],
            [
              '2023-04-07T22:00:00.000Z',
              0.832
            ],
            [
              '2023-04-08T22:00:00.000Z',
              0.846
            ],
            [
              '2023-04-09T22:00:00.000Z',
              0.851
            ],
            [
              '2023-04-10T22:00:00.000Z',
              0.866
            ],
            [
              '2023-04-11T22:00:00.000Z',
              0.847
            ],
            [
              '2023-04-12T22:00:00.000Z',
              0.823
            ],
            [
              '2023-04-13T22:00:00.000Z',
              0.861
            ],
            [
              '2023-04-14T22:00:00.000Z',
              0.865
            ],
            [
              '2023-04-15T22:00:00.000Z',
              0.85
            ],
            [
              '2023-04-16T22:00:00.000Z',
              0.886
            ],
            [
              '2023-04-17T22:00:00.000Z',
              0.877
            ],
            [
              '2023-04-18T22:00:00.000Z',
              0.821
            ],
            [
              '2023-04-19T22:00:00.000Z',
              0.846
            ],
            [
              '2023-04-20T22:00:00.000Z',
              0.834
            ],
            [
              '2023-04-21T22:00:00.000Z',
              0.835
            ],
            [
              '2023-04-22T22:00:00.000Z',
              0.839
            ],
            [
              '2023-04-23T22:00:00.000Z',
              0.852
            ],
            [
              '2023-04-24T22:00:00.000Z',
              0.85
            ],
            [
              '2023-04-25T22:00:00.000Z',
              0.824
            ],
            [
              '2023-04-26T22:00:00.000Z',
              0.849
            ],
            [
              '2023-04-27T22:00:00.000Z',
              0.834
            ],
            [
              '2023-04-28T22:00:00.000Z',
              0.837
            ],
            [
              '2023-04-29T22:00:00.000Z',
              0.85
            ],
            [
              '2023-04-30T22:00:00.000Z',
              0.858
            ]
          ]
        },
        {
          name: '1.1.2',
          values: [
            [
              '2023-04-01T22:00:00.000Z',
              0.902
            ],
            [
              '2023-04-02T22:00:00.000Z',
              0.889
            ],
            [
              '2023-04-03T22:00:00.000Z',
              0.983
            ],
            [
              '2023-04-04T22:00:00.000Z',
              1.006
            ],
            [
              '2023-04-05T22:00:00.000Z',
              0.895
            ],
            [
              '2023-04-06T22:00:00.000Z',
              0.878
            ],
            [
              '2023-04-07T22:00:00.000Z',
              0.879
            ],
            [
              '2023-04-08T22:00:00.000Z',
              0.896
            ],
            [
              '2023-04-09T22:00:00.000Z',
              0.903
            ],
            [
              '2023-04-10T22:00:00.000Z',
              0.909
            ],
            [
              '2023-04-11T22:00:00.000Z',
              0.911
            ],
            [
              '2023-04-12T22:00:00.000Z',
              0.909
            ],
            [
              '2023-04-13T22:00:00.000Z',
              0.917
            ],
            [
              '2023-04-14T22:00:00.000Z',
              0.922
            ],
            [
              '2023-04-15T22:00:00.000Z',
              0.958
            ],
            [
              '2023-04-16T22:00:00.000Z',
              0.943
            ],
            [
              '2023-04-17T22:00:00.000Z',
              0.949
            ],
            [
              '2023-04-18T22:00:00.000Z',
              0.858
            ],
            [
              '2023-04-19T22:00:00.000Z',
              0.885
            ],
            [
              '2023-04-20T22:00:00.000Z',
              0.875
            ],
            [
              '2023-04-21T22:00:00.000Z',
              0.868
            ],
            [
              '2023-04-22T22:00:00.000Z',
              0.877
            ],
            [
              '2023-04-23T22:00:00.000Z',
              0.898
            ],
            [
              '2023-04-24T22:00:00.000Z',
              0.909
            ],
            [
              '2023-04-25T22:00:00.000Z',
              0.86
            ],
            [
              '2023-04-26T22:00:00.000Z',
              0.88
            ],
            [
              '2023-04-27T22:00:00.000Z',
              0.866
            ],
            [
              '2023-04-28T22:00:00.000Z',
              0.918
            ],
            [
              '2023-04-29T22:00:00.000Z',
              0.882
            ],
            [
              '2023-04-30T22:00:00.000Z',
              0.914
            ]
          ]
        },
        {
          name: '1.2.3',
          values: [
            [
              '2023-04-01T22:00:00.000Z',
              0.876
            ],
            [
              '2023-04-02T22:00:00.000Z',
              0.866
            ],
            [
              '2023-04-03T22:00:00.000Z',
              0.838
            ],
            [
              '2023-04-04T22:00:00.000Z',
              0.878
            ],
            [
              '2023-04-05T22:00:00.000Z',
              0.866
            ],
            [
              '2023-04-06T22:00:00.000Z',
              0.858
            ],
            [
              '2023-04-07T22:00:00.000Z',
              0.858
            ],
            [
              '2023-04-08T22:00:00.000Z',
              0.866
            ],
            [
              '2023-04-09T22:00:00.000Z',
              0.868
            ],
            [
              '2023-04-10T22:00:00.000Z',
              0.879
            ],
            [
              '2023-04-11T22:00:00.000Z',
              0.867
            ],
            [
              '2023-04-12T22:00:00.000Z',
              0.848
            ],
            [
              '2023-04-13T22:00:00.000Z',
              0.88
            ],
            [
              '2023-04-14T22:00:00.000Z',
              0.886
            ],
            [
              '2023-04-15T22:00:00.000Z',
              0.87
            ],
            [
              '2023-04-16T22:00:00.000Z',
              0.898
            ],
            [
              '2023-04-17T22:00:00.000Z',
              0.893
            ],
            [
              '2023-04-18T22:00:00.000Z',
              0.838
            ],
            [
              '2023-04-19T22:00:00.000Z',
              0.865
            ],
            [
              '2023-04-20T22:00:00.000Z',
              0.852
            ],
            [
              '2023-04-21T22:00:00.000Z',
              0.85
            ],
            [
              '2023-04-22T22:00:00.000Z',
              0.854
            ],
            [
              '2023-04-23T22:00:00.000Z',
              0.864
            ],
            [
              '2023-04-24T22:00:00.000Z',
              0.867
            ],
            [
              '2023-04-25T22:00:00.000Z',
              0.844
            ],
            [
              '2023-04-26T22:00:00.000Z',
              0.864
            ],
            [
              '2023-04-27T22:00:00.000Z',
              0.85
            ],
            [
              '2023-04-28T22:00:00.000Z',
              0.857
            ],
            [
              '2023-04-29T22:00:00.000Z',
              0.871
            ],
            [
              '2023-04-30T22:00:00.000Z',
              0.872
            ]
          ]
        },
        {
          name: '1.2.4',
          values: [
            [
              '2023-04-01T22:00:00.000Z',
              0.908
            ],
            [
              '2023-04-02T22:00:00.000Z',
              0.898
            ],
            [
              '2023-04-03T22:00:00.000Z',
              0.961
            ],
            [
              '2023-04-04T22:00:00.000Z',
              0.987
            ],
            [
              '2023-04-05T22:00:00.000Z',
              0.903
            ],
            [
              '2023-04-06T22:00:00.000Z',
              0.885
            ],
            [
              '2023-04-07T22:00:00.000Z',
              0.886
            ],
            [
              '2023-04-08T22:00:00.000Z',
              0.902
            ],
            [
              '2023-04-09T22:00:00.000Z',
              0.907
            ],
            [
              '2023-04-10T22:00:00.000Z',
              0.911
            ],
            [
              '2023-04-11T22:00:00.000Z',
              0.909
            ],
            [
              '2023-04-12T22:00:00.000Z',
              0.912
            ],
            [
              '2023-04-13T22:00:00.000Z',
              0.917
            ],
            [
              '2023-04-14T22:00:00.000Z',
              0.921
            ],
            [
              '2023-04-15T22:00:00.000Z',
              0.955
            ],
            [
              '2023-04-16T22:00:00.000Z',
              0.943
            ],
            [
              '2023-04-17T22:00:00.000Z',
              0.945
            ],
            [
              '2023-04-18T22:00:00.000Z',
              0.869
            ],
            [
              '2023-04-19T22:00:00.000Z',
              0.889
            ],
            [
              '2023-04-20T22:00:00.000Z',
              0.881
            ],
            [
              '2023-04-21T22:00:00.000Z',
              0.873
            ],
            [
              '2023-04-22T22:00:00.000Z',
              0.882
            ],
            [
              '2023-04-23T22:00:00.000Z',
              0.902
            ],
            [
              '2023-04-24T22:00:00.000Z',
              0.907
            ],
            [
              '2023-04-25T22:00:00.000Z',
              0.868
            ],
            [
              '2023-04-26T22:00:00.000Z',
              0.885
            ],
            [
              '2023-04-27T22:00:00.000Z',
              0.87
            ],
            [
              '2023-04-28T22:00:00.000Z',
              0.919
            ],
            [
              '2023-04-29T22:00:00.000Z',
              0.888
            ],
            [
              '2023-04-30T22:00:00.000Z',
              0.916
            ]
          ]
        },
        {
          name: '1.3.5',
          values: [
            [
              '2023-04-01T22:00:00.000Z',
              0.888
            ],
            [
              '2023-04-02T22:00:00.000Z',
              0.877
            ],
            [
              '2023-04-03T22:00:00.000Z',
              0.872
            ],
            [
              '2023-04-04T22:00:00.000Z',
              0.901
            ],
            [
              '2023-04-05T22:00:00.000Z',
              0.881
            ],
            [
              '2023-04-06T22:00:00.000Z',
              0.873
            ],
            [
              '2023-04-07T22:00:00.000Z',
              0.867
            ],
            [
              '2023-04-08T22:00:00.000Z',
              0.875
            ],
            [
              '2023-04-09T22:00:00.000Z',
              0.882
            ],
            [
              '2023-04-10T22:00:00.000Z',
              0.887
            ],
            [
              '2023-04-11T22:00:00.000Z',
              0.876
            ],
            [
              '2023-04-12T22:00:00.000Z',
              0.862
            ],
            [
              '2023-04-13T22:00:00.000Z',
              0.888
            ],
            [
              '2023-04-14T22:00:00.000Z',
              0.897
            ],
            [
              '2023-04-15T22:00:00.000Z',
              0.89
            ],
            [
              '2023-04-16T22:00:00.000Z',
              0.911
            ],
            [
              '2023-04-17T22:00:00.000Z',
              0.911
            ],
            [
              '2023-04-18T22:00:00.000Z',
              0.848
            ],
            [
              '2023-04-19T22:00:00.000Z',
              0.874
            ],
            [
              '2023-04-20T22:00:00.000Z',
              0.861
            ],
            [
              '2023-04-21T22:00:00.000Z',
              0.858
            ],
            [
              '2023-04-22T22:00:00.000Z',
              0.861
            ],
            [
              '2023-04-23T22:00:00.000Z',
              0.877
            ],
            [
              '2023-04-24T22:00:00.000Z',
              0.876
            ],
            [
              '2023-04-25T22:00:00.000Z',
              0.855
            ],
            [
              '2023-04-26T22:00:00.000Z',
              0.871
            ],
            [
              '2023-04-27T22:00:00.000Z',
              0.857
            ],
            [
              '2023-04-28T22:00:00.000Z',
              0.872
            ],
            [
              '2023-04-29T22:00:00.000Z',
              0.877
            ],
            [
              '2023-04-30T22:00:00.000Z',
              0.885
            ]
          ]
        },
        {
          name: '1.3.6',
          values: [
            [
              '2023-04-01T22:00:00.000Z',
              0.899
            ],
            [
              '2023-04-02T22:00:00.000Z',
              0.888
            ],
            [
              '2023-04-03T22:00:00.000Z',
              0.952
            ],
            [
              '2023-04-04T22:00:00.000Z',
              0.977
            ],
            [
              '2023-04-05T22:00:00.000Z',
              0.894
            ],
            [
              '2023-04-06T22:00:00.000Z',
              0.877
            ],
            [
              '2023-04-07T22:00:00.000Z',
              0.881
            ],
            [
              '2023-04-08T22:00:00.000Z',
              0.894
            ],
            [
              '2023-04-09T22:00:00.000Z',
              0.902
            ],
            [
              '2023-04-10T22:00:00.000Z',
              0.899
            ],
            [
              '2023-04-11T22:00:00.000Z',
              0.899
            ],
            [
              '2023-04-12T22:00:00.000Z',
              0.901
            ],
            [
              '2023-04-13T22:00:00.000Z',
              0.908
            ],
            [
              '2023-04-14T22:00:00.000Z',
              0.917
            ],
            [
              '2023-04-15T22:00:00.000Z',
              0.95
            ],
            [
              '2023-04-16T22:00:00.000Z',
              0.94
            ],
            [
              '2023-04-17T22:00:00.000Z',
              0.938
            ],
            [
              '2023-04-18T22:00:00.000Z',
              0.861
            ],
            [
              '2023-04-19T22:00:00.000Z',
              0.881
            ],
            [
              '2023-04-20T22:00:00.000Z',
              0.869
            ],
            [
              '2023-04-21T22:00:00.000Z',
              0.865
            ],
            [
              '2023-04-22T22:00:00.000Z',
              0.871
            ],
            [
              '2023-04-23T22:00:00.000Z',
              0.888
            ],
            [
              '2023-04-24T22:00:00.000Z',
              0.896
            ],
            [
              '2023-04-25T22:00:00.000Z',
              0.859
            ],
            [
              '2023-04-26T22:00:00.000Z',
              0.875
            ],
            [
              '2023-04-27T22:00:00.000Z',
              0.859
            ],
            [
              '2023-04-28T22:00:00.000Z',
              0.909
            ],
            [
              '2023-04-29T22:00:00.000Z',
              0.881
            ],
            [
              '2023-04-30T22:00:00.000Z',
              0.909
            ]
          ]
        },
        {
          name: '1.4.7',
          values: [
            [
              '2023-04-01T22:00:00.000Z',
              0.874
            ],
            [
              '2023-04-02T22:00:00.000Z',
              0.865
            ],
            [
              '2023-04-03T22:00:00.000Z',
              0.828
            ],
            [
              '2023-04-04T22:00:00.000Z',
              0.861
            ],
            [
              '2023-04-05T22:00:00.000Z',
              0.868
            ],
            [
              '2023-04-06T22:00:00.000Z',
              0.862
            ],
            [
              '2023-04-07T22:00:00.000Z',
              0.855
            ],
            [
              '2023-04-08T22:00:00.000Z',
              0.863
            ],
            [
              '2023-04-09T22:00:00.000Z',
              0.872
            ],
            [
              '2023-04-10T22:00:00.000Z',
              0.871
            ],
            [
              '2023-04-11T22:00:00.000Z',
              0.866
            ],
            [
              '2023-04-12T22:00:00.000Z',
              0.848
            ],
            [
              '2023-04-13T22:00:00.000Z',
              0.874
            ],
            [
              '2023-04-14T22:00:00.000Z',
              0.883
            ],
            [
              '2023-04-15T22:00:00.000Z',
              0.872
            ],
            [
              '2023-04-16T22:00:00.000Z',
              0.901
            ],
            [
              '2023-04-17T22:00:00.000Z',
              0.897
            ],
            [
              '2023-04-18T22:00:00.000Z',
              0.839
            ],
            [
              '2023-04-19T22:00:00.000Z',
              0.866
            ],
            [
              '2023-04-20T22:00:00.000Z',
              0.849
            ],
            [
              '2023-04-21T22:00:00.000Z',
              0.847
            ],
            [
              '2023-04-22T22:00:00.000Z',
              0.851
            ],
            [
              '2023-04-23T22:00:00.000Z',
              0.86
            ],
            [
              '2023-04-24T22:00:00.000Z',
              0.857
            ],
            [
              '2023-04-25T22:00:00.000Z',
              0.842
            ],
            [
              '2023-04-26T22:00:00.000Z',
              0.861
            ],
            [
              '2023-04-27T22:00:00.000Z',
              0.847
            ],
            [
              '2023-04-28T22:00:00.000Z',
              0.86
            ],
            [
              '2023-04-29T22:00:00.000Z',
              0.869
            ],
            [
              '2023-04-30T22:00:00.000Z',
              0.873
            ]
          ]
        },
        {
          name: '1.4.8',
          values: [
            [
              '2023-04-01T22:00:00.000Z',
              0.92
            ],
            [
              '2023-04-02T22:00:00.000Z',
              0.905
            ],
            [
              '2023-04-03T22:00:00.000Z',
              0.981
            ],
            [
              '2023-04-04T22:00:00.000Z',
              0.998
            ],
            [
              '2023-04-05T22:00:00.000Z',
              0.911
            ],
            [
              '2023-04-06T22:00:00.000Z',
              0.897
            ],
            [
              '2023-04-07T22:00:00.000Z',
              0.898
            ],
            [
              '2023-04-08T22:00:00.000Z',
              0.909
            ],
            [
              '2023-04-09T22:00:00.000Z',
              0.912
            ],
            [
              '2023-04-10T22:00:00.000Z',
              0.912
            ],
            [
              '2023-04-11T22:00:00.000Z',
              0.907
            ],
            [
              '2023-04-12T22:00:00.000Z',
              0.921
            ],
            [
              '2023-04-13T22:00:00.000Z',
              0.919
            ],
            [
              '2023-04-14T22:00:00.000Z',
              0.933
            ],
            [
              '2023-04-15T22:00:00.000Z',
              0.96
            ],
            [
              '2023-04-16T22:00:00.000Z',
              0.949
            ],
            [
              '2023-04-17T22:00:00.000Z',
              0.953
            ],
            [
              '2023-04-18T22:00:00.000Z',
              0.877
            ],
            [
              '2023-04-19T22:00:00.000Z',
              0.901
            ],
            [
              '2023-04-20T22:00:00.000Z',
              0.888
            ],
            [
              '2023-04-21T22:00:00.000Z',
              0.887
            ],
            [
              '2023-04-22T22:00:00.000Z',
              0.889
            ],
            [
              '2023-04-23T22:00:00.000Z',
              0.899
            ],
            [
              '2023-04-24T22:00:00.000Z',
              0.909
            ],
            [
              '2023-04-25T22:00:00.000Z',
              0.875
            ],
            [
              '2023-04-26T22:00:00.000Z',
              0.899
            ],
            [
              '2023-04-27T22:00:00.000Z',
              0.883
            ],
            [
              '2023-04-28T22:00:00.000Z',
              0.927
            ],
            [
              '2023-04-29T22:00:00.000Z',
              0.902
            ],
            [
              '2023-04-30T22:00:00.000Z',
              0.924
            ]
          ]
        },
        {
          name: '1.5.9',
          values: [
            [
              '2023-04-01T22:00:00.000Z',
              0.844
            ],
            [
              '2023-04-02T22:00:00.000Z',
              0.847
            ],
            [
              '2023-04-03T22:00:00.000Z',
              0.791
            ],
            [
              '2023-04-04T22:00:00.000Z',
              0.832
            ],
            [
              '2023-04-05T22:00:00.000Z',
              0.844
            ],
            [
              '2023-04-06T22:00:00.000Z',
              0.83
            ],
            [
              '2023-04-07T22:00:00.000Z',
              0.824
            ],
            [
              '2023-04-08T22:00:00.000Z',
              0.837
            ],
            [
              '2023-04-09T22:00:00.000Z',
              0.835
            ],
            [
              '2023-04-10T22:00:00.000Z',
              0.853
            ],
            [
              '2023-04-11T22:00:00.000Z',
              0.843
            ],
            [
              '2023-04-12T22:00:00.000Z',
              0.824
            ],
            [
              '2023-04-13T22:00:00.000Z',
              0.851
            ],
            [
              '2023-04-14T22:00:00.000Z',
              0.852
            ],
            [
              '2023-04-15T22:00:00.000Z',
              0.836
            ],
            [
              '2023-04-16T22:00:00.000Z',
              0.872
            ],
            [
              '2023-04-17T22:00:00.000Z',
              0.866
            ],
            [
              '2023-04-18T22:00:00.000Z',
              0.821
            ],
            [
              '2023-04-19T22:00:00.000Z',
              0.835
            ],
            [
              '2023-04-20T22:00:00.000Z',
              0.829
            ],
            [
              '2023-04-21T22:00:00.000Z',
              0.824
            ],
            [
              '2023-04-22T22:00:00.000Z',
              0.83
            ],
            [
              '2023-04-23T22:00:00.000Z',
              0.836
            ],
            [
              '2023-04-24T22:00:00.000Z',
              0.84
            ],
            [
              '2023-04-25T22:00:00.000Z',
              0.812
            ],
            [
              '2023-04-26T22:00:00.000Z',
              0.838
            ],
            [
              '2023-04-27T22:00:00.000Z',
              0.82
            ],
            [
              '2023-04-28T22:00:00.000Z',
              0.822
            ],
            [
              '2023-04-29T22:00:00.000Z',
              0.839
            ],
            [
              '2023-04-30T22:00:00.000Z',
              0.842
            ]
          ]
        },
        {
          name: '1.5.10',
          values: [
            [
              '2023-04-01T22:00:00.000Z',
              0.893
            ],
            [
              '2023-04-02T22:00:00.000Z',
              0.883
            ],
            [
              '2023-04-03T22:00:00.000Z',
              0.963
            ],
            [
              '2023-04-04T22:00:00.000Z',
              0.977
            ],
            [
              '2023-04-05T22:00:00.000Z',
              0.882
            ],
            [
              '2023-04-06T22:00:00.000Z',
              0.874
            ],
            [
              '2023-04-07T22:00:00.000Z',
              0.866
            ],
            [
              '2023-04-08T22:00:00.000Z',
              0.885
            ],
            [
              '2023-04-09T22:00:00.000Z',
              0.891
            ],
            [
              '2023-04-10T22:00:00.000Z',
              0.886
            ],
            [
              '2023-04-11T22:00:00.000Z',
              0.892
            ],
            [
              '2023-04-12T22:00:00.000Z',
              0.895
            ],
            [
              '2023-04-13T22:00:00.000Z',
              0.904
            ],
            [
              '2023-04-14T22:00:00.000Z',
              0.918
            ],
            [
              '2023-04-15T22:00:00.000Z',
              0.939
            ],
            [
              '2023-04-16T22:00:00.000Z',
              0.929
            ],
            [
              '2023-04-17T22:00:00.000Z',
              0.939
            ],
            [
              '2023-04-18T22:00:00.000Z',
              0.852
            ],
            [
              '2023-04-19T22:00:00.000Z',
              0.878
            ],
            [
              '2023-04-20T22:00:00.000Z',
              0.865
            ],
            [
              '2023-04-21T22:00:00.000Z',
              0.861
            ],
            [
              '2023-04-22T22:00:00.000Z',
              0.866
            ],
            [
              '2023-04-23T22:00:00.000Z',
              0.879
            ],
            [
              '2023-04-24T22:00:00.000Z',
              0.892
            ],
            [
              '2023-04-25T22:00:00.000Z',
              0.851
            ],
            [
              '2023-04-26T22:00:00.000Z',
              0.873
            ],
            [
              '2023-04-27T22:00:00.000Z',
              0.859
            ],
            [
              '2023-04-28T22:00:00.000Z',
              0.911
            ],
            [
              '2023-04-29T22:00:00.000Z',
              0.871
            ],
            [
              '2023-04-30T22:00:00.000Z',
              0.906
            ]
          ]
        },
        {
          name: '1.6.11',
          values: [
            [
              '2023-04-01T22:00:00.000Z',
              0.856
            ],
            [
              '2023-04-02T22:00:00.000Z',
              0.859
            ],
            [
              '2023-04-03T22:00:00.000Z',
              0.822
            ],
            [
              '2023-04-04T22:00:00.000Z',
              0.854
            ],
            [
              '2023-04-05T22:00:00.000Z',
              0.859
            ],
            [
              '2023-04-06T22:00:00.000Z',
              0.842
            ],
            [
              '2023-04-07T22:00:00.000Z',
              0.841
            ],
            [
              '2023-04-08T22:00:00.000Z',
              0.854
            ],
            [
              '2023-04-09T22:00:00.000Z',
              0.854
            ],
            [
              '2023-04-10T22:00:00.000Z',
              0.859
            ],
            [
              '2023-04-11T22:00:00.000Z',
              0.853
            ],
            [
              '2023-04-12T22:00:00.000Z',
              0.838
            ],
            [
              '2023-04-13T22:00:00.000Z',
              0.864
            ],
            [
              '2023-04-14T22:00:00.000Z',
              0.867
            ],
            [
              '2023-04-15T22:00:00.000Z',
              0.85
            ],
            [
              '2023-04-16T22:00:00.000Z',
              0.885
            ],
            [
              '2023-04-17T22:00:00.000Z',
              0.886
            ],
            [
              '2023-04-18T22:00:00.000Z',
              0.836
            ],
            [
              '2023-04-19T22:00:00.000Z',
              0.847
            ],
            [
              '2023-04-20T22:00:00.000Z',
              0.837
            ],
            [
              '2023-04-21T22:00:00.000Z',
              0.839
            ],
            [
              '2023-04-22T22:00:00.000Z',
              0.838
            ],
            [
              '2023-04-23T22:00:00.000Z',
              0.848
            ],
            [
              '2023-04-24T22:00:00.000Z',
              0.85
            ],
            [
              '2023-04-25T22:00:00.000Z',
              0.831
            ],
            [
              '2023-04-26T22:00:00.000Z',
              0.847
            ],
            [
              '2023-04-27T22:00:00.000Z',
              0.836
            ],
            [
              '2023-04-28T22:00:00.000Z',
              0.84
            ],
            [
              '2023-04-29T22:00:00.000Z',
              0.856
            ],
            [
              '2023-04-30T22:00:00.000Z',
              0.856
            ]
          ]
        },
        {
          name: '1.6.12',
          values: [
            [
              '2023-04-01T22:00:00.000Z',
              0.897
            ],
            [
              '2023-04-02T22:00:00.000Z',
              0.891
            ],
            [
              '2023-04-03T22:00:00.000Z',
              0.956
            ],
            [
              '2023-04-04T22:00:00.000Z',
              0.969
            ],
            [
              '2023-04-05T22:00:00.000Z',
              0.884
            ],
            [
              '2023-04-06T22:00:00.000Z',
              0.876
            ],
            [
              '2023-04-07T22:00:00.000Z',
              0.87
            ],
            [
              '2023-04-08T22:00:00.000Z',
              0.887
            ],
            [
              '2023-04-09T22:00:00.000Z',
              0.896
            ],
            [
              '2023-04-10T22:00:00.000Z',
              0.887
            ],
            [
              '2023-04-11T22:00:00.000Z',
              0.893
            ],
            [
              '2023-04-12T22:00:00.000Z',
              0.902
            ],
            [
              '2023-04-13T22:00:00.000Z',
              0.904
            ],
            [
              '2023-04-14T22:00:00.000Z',
              0.916
            ],
            [
              '2023-04-15T22:00:00.000Z',
              0.946
            ],
            [
              '2023-04-16T22:00:00.000Z',
              0.934
            ],
            [
              '2023-04-17T22:00:00.000Z',
              0.937
            ],
            [
              '2023-04-18T22:00:00.000Z',
              0.862
            ],
            [
              '2023-04-19T22:00:00.000Z',
              0.886
            ],
            [
              '2023-04-20T22:00:00.000Z',
              0.875
            ],
            [
              '2023-04-21T22:00:00.000Z',
              0.864
            ],
            [
              '2023-04-22T22:00:00.000Z',
              0.872
            ],
            [
              '2023-04-23T22:00:00.000Z',
              0.883
            ],
            [
              '2023-04-24T22:00:00.000Z',
              0.89
            ],
            [
              '2023-04-25T22:00:00.000Z',
              0.859
            ],
            [
              '2023-04-26T22:00:00.000Z',
              0.878
            ],
            [
              '2023-04-27T22:00:00.000Z',
              0.863
            ],
            [
              '2023-04-28T22:00:00.000Z',
              0.913
            ],
            [
              '2023-04-29T22:00:00.000Z',
              0.878
            ],
            [
              '2023-04-30T22:00:00.000Z',
              0.908
            ]
          ]
        },
        {
          name: '2.1.1',
          values: [
            [
              '2023-04-01T22:00:00.000Z',
              0.844
            ],
            [
              '2023-04-02T22:00:00.000Z',
              0.847
            ],
            [
              '2023-04-03T22:00:00.000Z',
              0.781
            ],
            [
              '2023-04-04T22:00:00.000Z',
              0.825
            ],
            [
              '2023-04-05T22:00:00.000Z',
              0.847
            ],
            [
              '2023-04-06T22:00:00.000Z',
              0.832
            ],
            [
              '2023-04-07T22:00:00.000Z',
              0.822
            ],
            [
              '2023-04-08T22:00:00.000Z',
              0.844
            ],
            [
              '2023-04-09T22:00:00.000Z',
              0.847
            ],
            [
              '2023-04-10T22:00:00.000Z',
              0.85
            ],
            [
              '2023-04-11T22:00:00.000Z',
              0.841
            ],
            [
              '2023-04-12T22:00:00.000Z',
              0.828
            ],
            [
              '2023-04-13T22:00:00.000Z',
              0.846
            ],
            [
              '2023-04-14T22:00:00.000Z',
              0.848
            ],
            [
              '2023-04-15T22:00:00.000Z',
              0.813
            ],
            [
              '2023-04-16T22:00:00.000Z',
              0.87
            ],
            [
              '2023-04-17T22:00:00.000Z',
              0.863
            ],
            [
              '2023-04-18T22:00:00.000Z',
              0.835
            ],
            [
              '2023-04-19T22:00:00.000Z',
              0.839
            ],
            [
              '2023-04-20T22:00:00.000Z',
              0.835
            ],
            [
              '2023-04-21T22:00:00.000Z',
              0.831
            ],
            [
              '2023-04-22T22:00:00.000Z',
              0.834
            ],
            [
              '2023-04-23T22:00:00.000Z',
              0.83
            ],
            [
              '2023-04-24T22:00:00.000Z',
              0.832
            ],
            [
              '2023-04-25T22:00:00.000Z',
              0.83
            ],
            [
              '2023-04-26T22:00:00.000Z',
              0.843
            ],
            [
              '2023-04-27T22:00:00.000Z',
              0.828
            ],
            [
              '2023-04-28T22:00:00.000Z',
              0.828
            ],
            [
              '2023-04-29T22:00:00.000Z',
              0.848
            ],
            [
              '2023-04-30T22:00:00.000Z',
              0.841
            ]
          ]
        },
        {
          name: '2.1.2',
          values: [
            [
              '2023-04-01T22:00:00.000Z',
              0.883
            ],
            [
              '2023-04-02T22:00:00.000Z',
              0.884
            ],
            [
              '2023-04-03T22:00:00.000Z',
              0.932
            ],
            [
              '2023-04-04T22:00:00.000Z',
              0.928
            ],
            [
              '2023-04-05T22:00:00.000Z',
              0.864
            ],
            [
              '2023-04-06T22:00:00.000Z',
              0.864
            ],
            [
              '2023-04-07T22:00:00.000Z',
              0.854
            ],
            [
              '2023-04-08T22:00:00.000Z',
              0.884
            ],
            [
              '2023-04-09T22:00:00.000Z',
              0.889
            ],
            [
              '2023-04-10T22:00:00.000Z',
              0.884
            ],
            [
              '2023-04-11T22:00:00.000Z',
              0.891
            ],
            [
              '2023-04-12T22:00:00.000Z',
              0.905
            ],
            [
              '2023-04-13T22:00:00.000Z',
              0.898
            ],
            [
              '2023-04-14T22:00:00.000Z',
              0.908
            ],
            [
              '2023-04-15T22:00:00.000Z',
              0.927
            ],
            [
              '2023-04-16T22:00:00.000Z',
              0.919
            ],
            [
              '2023-04-17T22:00:00.000Z',
              0.924
            ],
            [
              '2023-04-18T22:00:00.000Z',
              0.858
            ],
            [
              '2023-04-19T22:00:00.000Z',
              0.882
            ],
            [
              '2023-04-20T22:00:00.000Z',
              0.879
            ],
            [
              '2023-04-21T22:00:00.000Z',
              0.859
            ],
            [
              '2023-04-22T22:00:00.000Z',
              0.871
            ],
            [
              '2023-04-23T22:00:00.000Z',
              0.878
            ],
            [
              '2023-04-24T22:00:00.000Z',
              0.879
            ],
            [
              '2023-04-25T22:00:00.000Z',
              0.862
            ],
            [
              '2023-04-26T22:00:00.000Z',
              0.874
            ],
            [
              '2023-04-27T22:00:00.000Z',
              0.859
            ],
            [
              '2023-04-28T22:00:00.000Z',
              0.895
            ],
            [
              '2023-04-29T22:00:00.000Z',
              0.871
            ],
            [
              '2023-04-30T22:00:00.000Z',
              0.907
            ]
          ]
        },
        {
          name: '2.2.3',
          values: [
            [
              '2023-04-01T22:00:00.000Z',
              0.837
            ],
            [
              '2023-04-02T22:00:00.000Z',
              0.843
            ],
            [
              '2023-04-03T22:00:00.000Z',
              0.76
            ],
            [
              '2023-04-04T22:00:00.000Z',
              0.799
            ],
            [
              '2023-04-05T22:00:00.000Z',
              0.831
            ],
            [
              '2023-04-06T22:00:00.000Z',
              0.821
            ],
            [
              '2023-04-07T22:00:00.000Z',
              0.81
            ],
            [
              '2023-04-08T22:00:00.000Z',
              0.834
            ],
            [
              '2023-04-09T22:00:00.000Z',
              0.837
            ],
            [
              '2023-04-10T22:00:00.000Z',
              0.835
            ],
            [
              '2023-04-11T22:00:00.000Z',
              0.826
            ],
            [
              '2023-04-12T22:00:00.000Z',
              0.819
            ],
            [
              '2023-04-13T22:00:00.000Z',
              0.835
            ],
            [
              '2023-04-14T22:00:00.000Z',
              0.839
            ],
            [
              '2023-04-15T22:00:00.000Z',
              0.812
            ],
            [
              '2023-04-16T22:00:00.000Z',
              0.856
            ],
            [
              '2023-04-17T22:00:00.000Z',
              0.849
            ],
            [
              '2023-04-18T22:00:00.000Z',
              0.822
            ],
            [
              '2023-04-19T22:00:00.000Z',
              0.831
            ],
            [
              '2023-04-20T22:00:00.000Z',
              0.824
            ],
            [
              '2023-04-21T22:00:00.000Z',
              0.825
            ],
            [
              '2023-04-22T22:00:00.000Z',
              0.824
            ],
            [
              '2023-04-23T22:00:00.000Z',
              0.82
            ],
            [
              '2023-04-24T22:00:00.000Z',
              0.824
            ],
            [
              '2023-04-25T22:00:00.000Z',
              0.82
            ],
            [
              '2023-04-26T22:00:00.000Z',
              0.834
            ],
            [
              '2023-04-27T22:00:00.000Z',
              0.821
            ],
            [
              '2023-04-28T22:00:00.000Z',
              0.818
            ],
            [
              '2023-04-29T22:00:00.000Z',
              0.841
            ],
            [
              '2023-04-30T22:00:00.000Z',
              0.835
            ]
          ]
        },
        {
          name: '2.3.5',
          values: [
            [
              '2023-04-01T22:00:00.000Z',
              0.829
            ],
            [
              '2023-04-02T22:00:00.000Z',
              0.843
            ],
            [
              '2023-04-03T22:00:00.000Z',
              0.881
            ],
            [
              '2023-04-04T22:00:00.000Z',
              0.903
            ],
            [
              '2023-04-05T22:00:00.000Z',
              0.838
            ],
            [
              '2023-04-06T22:00:00.000Z',
              0.834
            ],
            [
              '2023-04-07T22:00:00.000Z',
              0.825
            ],
            [
              '2023-04-08T22:00:00.000Z',
              0.858
            ],
            [
              '2023-04-09T22:00:00.000Z',
              0.867
            ],
            [
              '2023-04-10T22:00:00.000Z',
              0.863
            ],
            [
              '2023-04-11T22:00:00.000Z',
              0.861
            ],
            [
              '2023-04-12T22:00:00.000Z',
              0.864
            ],
            [
              '2023-04-13T22:00:00.000Z',
              0.873
            ],
            [
              '2023-04-14T22:00:00.000Z',
              0.886
            ],
            [
              '2023-04-15T22:00:00.000Z',
              0.894
            ],
            [
              '2023-04-16T22:00:00.000Z',
              0.906
            ],
            [
              '2023-04-17T22:00:00.000Z',
              0.907
            ],
            [
              '2023-04-18T22:00:00.000Z',
              0.819
            ],
            [
              '2023-04-19T22:00:00.000Z',
              0.825
            ],
            [
              '2023-04-20T22:00:00.000Z',
              0.838
            ],
            [
              '2023-04-21T22:00:00.000Z',
              0.818
            ],
            [
              '2023-04-22T22:00:00.000Z',
              0.838
            ],
            [
              '2023-04-23T22:00:00.000Z',
              0.864
            ],
            [
              '2023-04-24T22:00:00.000Z',
              0.836
            ],
            [
              '2023-04-25T22:00:00.000Z',
              0.821
            ],
            [
              '2023-04-26T22:00:00.000Z',
              0.827
            ],
            [
              '2023-04-27T22:00:00.000Z',
              0.819
            ],
            [
              '2023-04-28T22:00:00.000Z',
              0.87
            ],
            [
              '2023-04-29T22:00:00.000Z',
              0.838
            ],
            [
              '2023-04-30T22:00:00.000Z',
              0.88
            ]
          ]
        }
      ]
    }
  ]
}

// Per ora nelle chiamate mock mancano i parametri di data di inizio, data di fine e della configurazione dell'oggetto che serve al backend per prendere i dati corretti
// Chiamate Mock analisi di stringa
mock.onGet('/api/plant/plantId/analytics?type=stringAnalysis').reply(200, { data: stringAnalysisData })
// Chiamate Mock andamento
mock.onGet('/api/plant/plantId/analytics?type=trend').reply(200, { data: trendData })
// Chiamate scostamento
mock.onGet('/api/plant/plantId/analytics?type=deviation').reply(200, { data: deviationData })
// Chiamate scostamento
mock.onGet('/api/plant/plantId/analytics?type=stringsPr').reply(200, { data: stringsPrData })
