import moment from 'moment'

export const getGraphType = (type) => {
  const graphs = {
    line: 'Linea',
    column: 'Barra'
  }
  return graphs[type] || graphs.line
}

// funzione che ritorna i tipi di schermate correntemente selezionabili
export function getDashboardTypes () {
  return [{
    value: 'stringAnalysis',
    label: 'Analisi di stringa'
  }, {
    value: 'deviation',
    label: 'Discostamento'
  }, {
    value: 'trend',
    label: 'Andamento'
  }]
}

// oggetto che contiene la descrizione dello step della selezione degli elementi in base al tipo di visualizzazione selezionata
export const elementsSection = {
  stringAnalysis: {
    description: 'Scegliere gli elementi da visualizzare'
  },
  deviation: {
    description: 'Scegliere gli elementi da visualizzare'
  },
  trend: {
    description: 'Scegliere le variabili da visualizzare su ogni grafico'
  }
}

// Funzione che prese in ingresso una data di inizio e una di fine, ritorna una stringa contenente le date in formato D MMMM YYYY
export const formatPeriod = (startDate, endDate) => {
  let periodString = ''
  if (startDate.format('YYYY') === endDate.format('YYYY')) {
    if (startDate.format('MM') === endDate.format('MM')) {
      if (startDate.format('DD') === endDate.format('DD')) {
        periodString = `${startDate.format('DD/MM/YYYY HH:mm')} - ${endDate.format('DD/MM/YYYY HH:mm')}`
      } else {
        periodString = `${startDate.format('DD/MM')} - ${endDate.format('DD/MM')}`
      }
    } else {
      periodString = `${startDate.format('DD/MM')} - ${endDate.format('DD/MM')}`
    }
  } else {
    periodString = `${startDate.format('D MMMM YYYY')} - ${endDate.format('D MMMM YYYY')}`
  }

  return periodString
}

// Valori di default da utilizzare per la prima chiamata
export const baseValues = {
  baseDate: `${moment().subtract(1, 'days').format('DD/MM')} - ${moment().format('D/MM')}`,
  baseDashboardType: 'deviation',
  getBaseFilter: () => {
    return {
      deviation: [{
        elements: [{ uuid: 'plant', name: 'Impianto' }]
      }],
      stringAnalysis: [{
        elements: [{ uuid: 'plant', name: 'Impianto' }]
      }]
    }
  }
}
