import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import Chart from 'react-apexcharts'
import LoadingCard from '../LoadingCard'
import { europeNum } from 'src/utils/general'
import { alog } from 'src/utils/apioLog'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  }
}))

function TrendOverTimeGraph ({ data = {}, width = null, height = null, className, ...rest }) {
  const classes = useStyles()

  // console.log(data.labels)

  const dataNormalize = function (dataSet, options) {
    // Array di oggetti contenenti nome e unità di misura degli assi
    const axisByUom = dataSet.series.map(serie => ({
      uom: serie.uom === 'W/m^2' ? `kWh/m${String.fromCodePoint(0x00B2)}` : serie.uom,
      name: serie.name
    }))

    // Array di assi per singola unità di misura
    const uniqueAxisByUom = [...new Set(axisByUom.map(el => el.uom))].map(uom => axisByUom.find(el => el.uom === uom))

    const usedUoms = []
    // Per ogni asse, devo dare il nome della serie preso dall'asse scelto per quell'unità di misura
    const axis = dataSet.series.map((serie, serieIndex) => {
      // unità di misura da utilizzare sull'asse
      const uom = serie.uom === 'W/m^2' ? `kWh/m${String.fromCodePoint(0x00B2)}` : serie.uom
      // variabile che determina se mostrare o meno un'asse
      let showAxis = true
      if (usedUoms.includes(uom)) {
        showAxis = false
      } else {
        usedUoms.push(uom)
      }
      // Asse da utilizzare per il nome dell'asse
      const currentAxis = uniqueAxisByUom.find(el => el.uom === uom)
      // Indice degli assi unici per unità di misura
      const uniqueAxisIndex = uniqueAxisByUom.findIndex(el => el.uom === uom)

      return ({
        uuid: `${serie.name}-${serieIndex}`,
        uom,
        opposite: uniqueAxisIndex > -1 ? uniqueAxisIndex % 2 !== 0 : serieIndex % 2 !== 0,
        show: showAxis,
        type: serie.type,
        seriesName: currentAxis.name,
        labels: {
          minWidth: '100%',
          formatter: value => `${europeNum(value)} ${uom}`
        }
      })
    })

    alog('axis', axis, 'decodetrendgraph')

    // Se ho un solo elemento e deve essere rappresentato a barre, trasformo il grafico in tipo 'bar' anzichè lasciarlo di tipo 'line' per sfruttare il combo chart
    if (axis.length === 1 && axis[0] && axis[0].type === 'column') {
      options.chart.type = 'bar'
    }

    options.yaxis = axis

    if (Object.keys(dataSet).length > 0) {
      for (let s = 0; s < dataSet.series.length; s++) {
        options.series.push({
          name: dataSet.series[s].name,
          uom: dataSet.series[s].uom === 'W/m^2' ? `kWh/m${String.fromCodePoint(0x00B2)}` : dataSet.series[s].uom,
          type: (dataSet.series[s] && dataSet.series[s].type) || 'column',
          data: dataSet.series[s].data
        })
      }
    }

    // console.log('options: ', options)
    return options
  }

  const options = {
    chart: {
      width: width || '100%',
      height: height || '300px',
      foreColor: '#fff',
      type: 'line',
      animations: {
        enabled: true
      },
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      },
      stroke: {
        curve: 'smooth'
      }
    },
    dataLabels: {
      enabled: false
    },
    labels: Object.keys(data).length > 0 ? data.labels : [],
    series: [],
    tooltip: {
      shared: true,
      intersect: false,
      theme: 'dark',
      x: {
        show: false
      },
      y: {
        show: false,
        formatter: function (value, { seriesIndex, w }) {
          const series = (w.config && w.config.series) || null
          if (series) {
            const currentUom = series.length > seriesIndex ? series[seriesIndex].uom : null
            if (currentUom) {
              // Fix temporaneo, vanno cambiate le unità di misura a livello di backend
              return value !== null && value !== undefined ? `${europeNum(value)} ${currentUom === 'W/m^2' ? `kW/m${String.fromCodePoint(0x00B2)}` : currentUom}` : '-'
            }
          }

          return value !== null && value !== undefined ? europeNum(value) : '-'
        }
      }
    },
    yaxis: [],
    legend: {
      showForSingleSeries: true,
      labels: {
        useSeriesColors: false
      }
    }
  }

  const normalizedOptions = Object.keys(data).length > 0 ? dataNormalize(data, options) : options
  alog('normalizedOptions =>', normalizedOptions, 'trendovertimegraph')

  return (
    <div
      className={clsx(classes.root, className)} {...rest}
    >
      {
        Object.keys(data).length > 0
          ? (
            <Chart
              options={normalizedOptions}
              series={normalizedOptions.series}
              type={(normalizedOptions.chart && normalizedOptions.chart.type) || 'line'}
              height={normalizedOptions.chart.height}
              width={normalizedOptions.chart.width}
            />
            )
          : (
            <LoadingCard />
            )
      }
    </div>
  )
}
export default TrendOverTimeGraph
