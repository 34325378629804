import { Suspense } from 'react'
import Overview from './Overview'
import Components from './Components'
import Anomalies from './Anomalies'
import LoadingScreen from 'src/components/LoadingScreen'
import Production from './Production'
import Analytics from './Analytics'
import Report from './Report'
import moment from 'moment'
import { chunkArray, europeNum } from 'src/utils/general'
import { CornerUpRight as CornerUpRightIcon, TrendingUp as TrendingUpIcon, Activity as ActivityIcon, Sun as SunIcon } from 'react-feather'
import { alog } from 'src/utils/apioLog'
import SunCalc from 'suncalc'
// import { useDispatch } from 'react-redux'

export const tabs = [
  {
    value: 'overview',
    label: 'Panoramica',
    isSmall: true
  },
  {
    value: 'components',
    label: 'Componenti',
    isSmall: true
  },
  {
    value: 'production',
    label: 'Produzione',
    isSmall: true
  },
  {
    value: 'anomalies',
    label: 'Anomalie',
    isSmall: true
  },
  {
    value: 'analytics',
    label: 'Analytics',
    isSmall: false
  },
  {
    value: 'report',
    label: 'Report',
    isSmall: true
  }
]

export const selectActiveTab = (currentTab, permissions) => {
  switch (currentTab) {
    case 'overview': {
      return (
        <Suspense fallback={<LoadingScreen />}>
          <Overview />
        </Suspense>
      )
    }
    case 'components': {
      return (
        <Suspense fallback={<LoadingScreen />}>
          <Components />
        </Suspense>
      )
    }
    case 'production': {
      return (
        <Suspense fallback={<LoadingScreen />}>
          <Production />
        </Suspense>
      )
    }
    case 'anomalies': {
      /* return permissions.includes('assetsmanager.plants.access') &&
        !permissions.includes('assetsmanager.plants.manage') */
      return !permissions.includes('assetsmanager.plants.access')
        ? null
        : (
          <Suspense fallback={<LoadingScreen />}>
            <Anomalies />
          </Suspense>)
    }
    case 'analytics': {
      return (
        <Suspense fallback={<LoadingScreen />}>
          <Analytics />
        </Suspense>
      )
    }
    case 'report': {
      return permissions.includes('assetsmanager.reports.plants')
        ? (
          <Suspense fallback={<LoadingScreen />}>
            <Report />
          </Suspense>)
        : null
    }
    default:
      return null
  }
}

// Funzione che presi in ingresso il typo di periodo e la data di inizio ritornano data di inizio e data di fine
export const getDatesFromPeriod = (period, now, formatString = null, isSolarHours = false) => {
  let maxDate, minDate
  if (period === 'live') {
    // min e max saranno la mezzanotte di oggi e le 23:59 di oggi
    if (isSolarHours) {
      minDate = moment(now).set({ hours: 5, minute: 0, second: 0, millisecond: 1 })
      maxDate = moment(now).set({ hours: 20, minute: 59, second: 59, millisecond: 900 })
    } else {
      minDate = moment(now).set({ hours: 0, minute: 0, second: 0, millisecond: 1 })
      maxDate = moment(now).set({ hours: 23, minute: 59, second: 59, millisecond: 900 })
    }
    // maxDate = moment(now).add(1, 'day')
  } else if (period === 'week') {
    // il min sarà il lunedì di questa settimana, il max la domenica
    //  su momento 0 è domenica, 6 è sabato, 7 è la prossima domenica
    /* minDate = moment(now).set({ hours: 0, minute: 0, second: 0, millisecond: 1 })
    minDate = moment(minDate).day(1)
    maxDate = moment(now).set({ hours: 23, minute: 59, second: 59, millisecond: 900 })
    maxDate = moment(maxDate).day(7) */
    minDate = moment(now).startOf('week')
    maxDate = moment(now).endOf('week')
  } else if (period === 'month') {
    // il min sarà l'1 del mese e il max sarà il 30/31 del mese
    // (secondo la doc moment gestisce da solo che se setti 31 ed ha 30 giorni imposta 30 e non 31)
    // https://momentjs.com/docs/#/get-set/date/
    // https://momentjs.com/docs/#/get-set/month/
    /*  minDate = moment(now).set({ hours: 0, minute: 0, second: 0, millisecond: 1 })
    minDate = moment(minDate).date(1)
    maxDate = moment(now).set({
      hours: 23,
      minute: 59,
      second: 59,
      millisecond: 900
    })
    maxDate = moment(maxDate).date(31) */
    minDate = moment(now).startOf('month')
    maxDate = moment(now).endOf('month')
  } else if (period === 'year') {
    // sarà l'anno corrente
    minDate = moment(now).set({ hours: 0, minute: 0, second: 0, millisecond: 1 })
    minDate = moment(minDate)
      .month(0)
      .date(1)
    maxDate = moment(now).set({
      hours: 23,
      minute: 59,
      second: 59,
      millisecond: 900
    })
    maxDate = moment(maxDate)
      .month(11)
      .date(31)
  }

  return formatString
    ? { minDate: moment(minDate).format(formatString), maxDate: moment(maxDate).format(formatString) }
    : { minDate: moment(minDate).toISOString(), maxDate: moment(maxDate).toISOString() }
}

// Funzione che prende in ingresso i dati ricevuti dall'api e li formatta per le card
export const decodeCardsFromApi = (dataObj) => {
  // Oggetto che contiene la configurazione delle cards
  const labelIconConfig = {
    totalExpectedEnergy: {
      icon: <CornerUpRightIcon />,
      label: 'E. Attesa'
    },
    totalExportedEnergy: {
      icon: <ActivityIcon />,
      label: 'E. Immessa'
    },
    totalProducedEnergy: {
      icon: <TrendingUpIcon />,
      label: 'E. Prodotta'
    },
    totalRadiation: {
      icon: <SunIcon />,
      label: 'Irraggiamento'
    }
  }

  return Object.keys(dataObj).map((key) => ({
    icon: (labelIconConfig[key] && labelIconConfig[key].icon) || null,
    values: [
      {
        label: (labelIconConfig[key] && labelIconConfig[key].label) || '',
        value:
          key === 'totalRadiation'
            ? dataObj[key] !== '-'
                ? dataObj[key] * 1000 >= 10000
                    ? `${europeNum(dataObj[key])} kWh/m${String.fromCodePoint(0x00B2)}`
                    : `${europeNum(dataObj[key] * 1000)} Wh/m${String.fromCodePoint(0x00B2)}`
                : '-'
            : dataObj[key] !== '-'
              ? dataObj[key] >= 1000000
                  ? `${europeNum(dataObj[key] / 1000)} Mwh`
                  : `${europeNum(dataObj[key])} kWh`
              : '-'
      }
    ]
  }))
}

// funzione che prende in ingresso i dati ricevuti dall'api e li formatta per il grafico di scostamento della produzione/pr
export const decodePrDeviationGraphFromApi = (dataObj) => {
  const { realPr, realProduction, baselinePr, baselineProduction } = dataObj
  const prValue = realPr && baselinePr ? Math.floor((Number(realPr) / Number(baselinePr)) * 100) : 0
  const productionValue =
    realProduction && baselineProduction ? Math.floor((Number(realProduction) / Number(baselineProduction)) * 100) : 0

  return {
    kwh: {
      value: productionValue,
      realValue: realProduction,
      name: 'Produz. reale/attesa'
    },
    pr: {
      value: prValue,
      realValue: realPr,
      name: 'PR reale/atteso'
    }
  }
}

// funzione che prende in ingresso i dati ricevuti dall'api e li formatta per il grafico totalizzatore
export const decodeTotalPrGraphFromApi = (dataObj) => {
  const { realPr, realProduction, baselinePr, baselineProduction } = dataObj
  const prValue = realPr && baselinePr ? Math.floor((Number(realPr) / Number(baselinePr)) * 100) : 0
  const productionValue =
    realProduction && baselineProduction ? Math.floor((Number(realProduction) / Number(baselineProduction)) * 100) : 0

  return {
    kwh: {
      value: productionValue,
      realValue: realProduction,
      name: 'Produz. reale/attesa'
    },
    pr: {
      value: prValue,
      realValue: realPr,
      name: 'PR reale/atteso'
    }
  }
}

// funzione che prende in ingresso i dati ricevuti dall'api e li formatta per il grafico dell'energia
export const decodeEnergyGraphFromApi = (dataObj, customPeriod) => {
  const periodXFormat = {
    raw_live: 'HH:mm',
    raw_quarter: 'HH:mm',
    raw_week: 'DD/MM/YY',
    raw_month: 'DD/MM/YY',
    raw_year: 'MM/YY',
    live: 'HH:mm',
    quarter: 'HH:mm',
    week: 'DD MMM',
    month: 'DD MMM',
    year: 'MMM YY'
  }

  const graphConfig = {
    expectedEnergy: {
      type: 'column',
      color: '#8bb9dd',
      name: 'E. Attesa'
    },
    producedEnergy: {
      type: 'column',
      color: '#63b521',
      name: 'E. Prodotta'
    },
    exportedEnergy: {
      type: 'column',
      color: '#fce903',
      name: 'E. Immessa'
    }
  }
  // Prendo le labels (le date) dall'array dell'energia attesa che comprende tutte quelle del periodo selezionato
  const labels = dataObj.expectedEnergy.map((el) => moment(el.label).format(periodXFormat[customPeriod]))
  const rawLabels = dataObj.expectedEnergy.map(el => moment(el.label).format(periodXFormat[`raw_${customPeriod}`]))
  const maxLength = dataObj.producedEnergy.length
  // lunghezza massima dell'array delle energie
  // console.log('maxLength: ', maxLength)
  const series = Object.keys(dataObj).map((key) => {
    let data = (dataObj[key] && dataObj[key].map((el) => el.value)) || []
    // Se sto manipolando l'energia attesa, annullo tutti i valori che vanno oltre l'ultimo valore utile (ora/giorno/mese) a seconda della view
    // Faccio questa operazione assumendo che il valore massimo dei dati che voglio conservare, siano in numero uguale a quelli dell'energia prodotta
    if (key === 'expectedEnergy') {
      const normalizedData = data
        // prendo tutti i valori che arrivano all'ora attuale
        .map((el, index) => (index < maxLength ? el : null))
        // e rimuovo tutti i valori null che si trovano dopo l'ora attuale
        .filter((el, index) => (index < maxLength || el !== null))
      // console.log('normalizedData: ', normalizedData)
      // console.log('normalizedData - data: ', data)
      data = normalizedData
    }
    while (data.length < labels.length) {
      data.push(null)
    }
    return {
      data,
      ...graphConfig[key]
    }
  })
  // oggetto formattato da ritornare per visualizzare il grafico
  const energyGraph = {
    labels,
    rawLabels,
    axis: {
      color: '#fff'
      // yMax: 800
    },
    series
  }

  return energyGraph
}

// funzione che prende in ingresso i dati ricevuti dall'api e li formatta per il grafico dell'irraggiamento
export const decodeIrradiationGraphFromApi = (dataObj, customPeriod) => {
  const periodXFormat = {
    live: 'HH:mm',
    week: 'DD/MM/YY',
    month: 'DD/MM/YY',
    year: 'MM/YY'
  }

  const graphConfig = {
    producedEnergy: {
      // type: 'area',
      color: '#54F286',
      name: 'Energia Prodotta'
    },
    power: {
      color: '#54F286',
      name: 'Potenza'
    },
    irradiation: {
      // type: 'area',
      // color: '#8bb9dd',
      color: '#fce903',
      name: 'Irraggiamento'
    }
  }

  // Variabile che determina quale chiave utilizzare per il calcolo delle labels
  const labelProp = dataObj.power ? 'power' : 'expectedEnergy'

  // Prendo le labels (le date) dall'array dell'energia attesa che comprende tutte quelle del periodo selezionato
  const labels = dataObj[labelProp].map((el) => moment(el.label).format(periodXFormat[customPeriod]))
  /* const irradiationData = dataObj.expectedEnergy.map(el => ({
    x: el.label,
    y: (dataObj.irradiation.find(irrEl => irrEl.label === el.label)
      ? dataObj.irradiation.find(irrEl => irrEl.label === el.label).value
      : 0)
  }))
  const energyData = dataObj.expectedEnergy.map(el => ({
    x: el.label,
    y: (dataObj.producedEnergy.find(prodEl => prodEl.label === el.label)
      ? dataObj.producedEnergy.find(prodEl => prodEl.label === el.label).value
      : 0)
  })) */

  const irradiationData = dataObj.irradiation.map((el) => el.value !== null ? Number(el.value) : null)
  while (irradiationData.length < labels.length) {
    irradiationData.push(null)
  }

  const radiationGraph = {
    labels,
    axis: {
      color: '#fff'
      // yMax: 800
    },
    radiation: {
      series: [
        {
          data: irradiationData,
          ...graphConfig.irradiation
        }
      ]
    }
  }

  if (dataObj.power) {
    const powerData = dataObj.power.map((el) => el.value)
    while (powerData.length < labels.length) {
      powerData.push(null)
    }

    radiationGraph.power = {
      series: [
        {
          data: powerData,
          ...graphConfig.power
        }
      ]
    }
  }

  if (dataObj.producedEnergy) {
    const energyData = dataObj.producedEnergy.map((el) => el.value)
    while (energyData.length < labels.length) {
      energyData.push(null)
    }

    radiationGraph.energy = {
      series: [
        {
          data: energyData,
          ...graphConfig.producedEnergy
        }
      ]
    }
  }

  return radiationGraph
}

// funzione che torna la durata formattata
export const returnDuration = (duration) => {
  const toReturn = Number(duration)
  if (duration === '' || isNaN(toReturn)) {
    return duration
  } else {
    return moment.duration(toReturn).humanize()
  }
}

// funzione che ritorna i dati per la lista delle anomalie sistemati per la view della lista
export const normalizeAnomaly = (anomaly, categories) => {
  // mi preparo l'oggetto finale
  const finalObj = {
    ...anomaly,
    name: 'N.F.',
    description: 'N.F.',
    category: '',
    subCategory: {
      label: '',
      color: ''
    },
    selected: true
  }
  // cerco config e categorie
  const thisConfig = anomaly.configuration
  if (thisConfig) {
    finalObj.name = thisConfig.name
    finalObj.description = thisConfig.description
    // cerco la categoria principale
    const thisCategory = categories.find(
      (category) => category.name === thisConfig.category
    )
    if (thisCategory) {
      finalObj.category = thisCategory.label
      // cerco la subcategory
      const thisSubCategory = thisCategory.subCategory.find(
        (sub) => sub.name === thisConfig.subCategory
      )
      if (thisSubCategory) {
        finalObj.subCategory.label = thisSubCategory.label
        finalObj.subCategory.color = thisSubCategory.color
      }
    }
  }
  // mi sistemo la durata nel caso sia ancora aperta
  if (!finalObj.duration && (!finalObj.endedAt || finalObj.endedAt === '')) {
    // DA RIMUOVERE -> ref usato per i dati demo
    const dateRef = moment()
    finalObj.duration = moment
      .duration(moment(dateRef).diff(moment(finalObj.startedAt)))
      .asMilliseconds()
    // finalObj.duration = moment.duration(finalObj.duration).asHours()
  }

  return finalObj
}

// funzione che prende in ingresso i dati ricevuti dall'api e li formatta per il grafico dell'andamento
// L'oggetto dataObj avrà una chiave per ogni proprietà richiesta
export const decodeTrendGraph = (dataObj, currentFilter) => {
  const periodXFormat = {
    raw: 'HH:mm',
    quarter: 'HH:mm',
    hourly: 'HH:mm',
    daily: 'DD/MM/YY'
  }

  // prendo il primo dispositivo disponibile
  const firstDevice = Object.keys(dataObj).length > 0 ? Object.keys(dataObj)[0] : null
  // prendo la prima proprietà del dispositivo selezionato
  const firstKey = firstDevice && Object.keys(dataObj[firstDevice]).length > 0 ? Object.keys(dataObj[firstDevice])[0] : ''
  // Prendo il livello di aggregazione
  const { aggregationType } = currentFilter
  const { elements } = currentFilter
  alog('elements', elements, 'decodetrendgraph')
  alog('firstDevice', firstDevice, 'decodetrendgraph')
  alog('firstKey', firstKey, 'decodetrendgraph')
  alog('dataObj', dataObj, 'decodetrendgraph')
  // Prendo le labels (le date) dall'array del primo elemento e le formatta secondo l'oggetto periodXFormat
  const labels = dataObj[firstDevice][firstKey] ? dataObj[firstDevice][firstKey].map((el) => moment(el.label).format(periodXFormat[aggregationType])) : []
  const series = elements.map(property => {
    const data = (dataObj[property.deviceId] && dataObj[property.deviceId][property.name] && dataObj[property.deviceId][property.name].map(el => el.value)) || []

    // faccio questo per avere un numero di elementi sempre omogeneo per tutte le grandezze
    while (data.length < labels.length) {
      data.push(null)
    }

    return {
      data,
      type: property.graphType || 'column',
      uom: property.uom || 'kWh',
      name: property.displayName || 'Value'
    }
  })

  alog('series', series, 'decodetrendgraph')
  // oggetto formattato da ritornare per visualizzare il grafico
  const trendGraph = {
    labels,
    axis: {
      color: '#fff'
      // yMax: 800
    },
    series
  }

  return trendGraph
}

// Funzione che prende in ingresso i dati ricevuti dalle API per i grafici di analisi di stringa e restituisce i dati nel formato giusto per essere graficati
export const decodeStringAnalysisFromApi = (dataObj) => {
  alog('dataObj', dataObj, 'decodeStringAnalysisFromApi')
  const stringAnalysisData = Object.keys(dataObj)
    .map(key => ({ ...dataObj[key] }))
    .map(element => Object.keys(element)
      .map(elKey => ({ ...element[elKey] }))).flat()

  alog('stringAnalysisData ', stringAnalysisData, 'decodeStringAnalysisFromApi')

  if (stringAnalysisData.some(el => el.orientations !== undefined)) {
    alog('el.orientations ', null, 'decodeStringAnalysisFromApi')
    const inverterStringAnalysis = stringAnalysisData
      .map(el => Object.keys(el.orientations)
        .map(orientation => ({ ...el.orientations[orientation] }))).flat()

    const chunkStringAnalysis = chunkArray(inverterStringAnalysis, 2)
    return chunkStringAnalysis
  }
  const chunkStringAnalysis = chunkArray(stringAnalysisData, 2)

  alog('chunkStringAnalysis', chunkStringAnalysis, 'decodeStringAnalysisFromApi')
  return chunkStringAnalysis
}

// Funzione che prende in ingresso i dati ricevuti dalle API per il grafico di scostamento e restituisce i dati nel formato giusto per essere graficati
export const decodeLinearRegressionGraphFromApi = (dataObj) => {
  alog('dataObj', dataObj, 'decodeLinearRegressionGraphFromApi')
  // Array di oggetti presenti in un grafico
  const elements = Object.keys(dataObj).map(key => dataObj[key])

  alog('elements', elements, 'decodeLinearRegressionGraphFromApi')
  let data = []
  // Se sto trattando i dati di un'analisi di impianto
  if (elements.some(el => el.plant !== undefined)) {
    data = elements.map(element => ({ ...element.plant })).map(inv => ({
      max: inv.max,
      r2: inv.r2,
      orientationName: 'Impianto',
      line: inv.line.map(el => ({ x: el.irradiation, y: el.energy })),
      points: inv.scatter.map(el => ({ x: el.irradiation, y: el.energy, date: el.label }))
    }))
    // Se sto trattando i dati di un' analisi dei singoli inverter
    /* } else if (elements.some(el => el.inverter !== undefined)) {
     alog('elements - inverter', elements, 'decodeLinearRegressionGraphFromApi')
     // Array di oggetti degli elementi da graficare
     data = elements
       .map(element => Object.keys(element)
         .map(elementKey => element[elementKey].orientations)).flat()
       .map(orientation => Object.keys(orientation)
         .map(orientationKey => orientation[orientationKey])).flat()
       .map(ori => ({
         max: ori.max,
         r2: ori.r2,
         line: ori.line.map(el => ({ x: el.irradiation, y: el.energy })),
         points: ori.scatter.map(el => ({ x: el.irradiation, y: el.energy, date: el.label }))
       })) */
  } else {
    const inverterNames = elements
      .map(element => Object.keys(element)
        .map(elementKey => element[elementKey].inverter
          ? element[elementKey].inverter.name
          : (element[elementKey].orientation && element[elementKey].orientation.name)))
      .flat()
    // Array di oggetti degli elementi da graficare
    data = elements
      .map(element => Object.keys(element)
        .map(elementKey => element[elementKey].inverters ? element[elementKey].inverters : element[elementKey].orientations)).flat()
      .map(inverter => Object.keys(inverter)
        .map(inverterKey => inverter[inverterKey])).flat()
      .map((inv, index) => ({
        max: inv.max,
        r2: inv.r2,
        orientationName: (inv.orientation && `${inverterNames[index] || ''} (${inv.orientation.name})`) || '',
        line: inv.line.map(el => ({ x: el.irradiation, y: el.energy })),
        points: inv.scatter.map(el => ({ x: el.irradiation, y: el.energy, date: el.label }))
      }))
  }
  alog('dataa', data, 'decodeLinearRegressionGraphFromApi')
  /* const data = elements
    .map(element => Object.keys(element)
      .map(key => (
        {
          line: element[key].line.map(el => ({ x: el.irradiation, y: el.energy })),
          points: element[key].scatter.map(el => ({ x: el.irradiation, y: el.energy, date: el.label }))
        }
      ))
    ).flat() */
  return data
}

// Funzione che prende in ingresso una data di inizio e una data di fine e ritorna il tipo di aggregazione da utilizzare
export const getAggregationTypeFromPeriod = (startDate, endDate) => {
  const daysDiff = moment(endDate).diff((moment(startDate)), 'days')
  const hoursDiff = moment(endDate).diff(moment(startDate), 'hours')

  // Se il periodo è maggiore di un giorno richiedo i dati in forma giornaliera
  if (daysDiff > 1) {
    return 'daily'
  }
  // Se il periodo è maggiore di 12 ore richiedo i dati in forma oraria
  if (hoursDiff > 12) {
    return 'hourly'
  }

  // Se il periodo è mggiore di 1 ora richiedo i dati in forma quartoraria
  if (hoursDiff > 1) {
    return 'quarter'
  }

  // Se sono sotto 1 ora richiedo i dati puntuali
  return 'raw'
}

// Funzione che prende in ingresso un set di filtri per il grafico di regressione e li restituisce arrichiti della chiamata all'analisi di stringa
export const addStringAnalysisToDeviation = (filter, type) => {
  let filterCopy = { ...filter }

  const currentElements = (filter[type] && filter[type].length > 0 && filter[type][0].elements) || []
  if (currentElements.length > 0) {
    const stringAnalysisFilter = currentElements.map(el => ({
      show: true,
      elements: [{ ...el }]
    }))
    filterCopy = {
      ...filter,
      stringAnalysis: stringAnalysisFilter
    }
  }

  alog('filterCopy: ', filterCopy, 'utils')
  return filterCopy
}

// Utility che restituisce un filtro normalizzato
const normalizeFilter = (filterArray) => {
  return filterArray.map(singleFilter => ({
    ...singleFilter,
    elements: singleFilter.elements.map(el => ({
      uuid: el.uuid,
      name: el.name,
      deviceType: {
        category: el.deviceType?.category || null,
        properties: el.deviceType?.properties || {}
      }
    }))
  }))
}

// Funzione che rimuove le informazioni dei devices passati ad un filtro della sezione analytics
export const normalizeElementsFilter = (filter, type) => {
  // Copio il filtro per restituirlo
  const filterCopy = { ...filter }
  // Prendo l'oggetto del filtro del tipo selezionato
  const filterType = filter[type] || null
  // se esiste
  if (filterType) {
    // Se il tipo è regressione, devo normalizzare anche l'analisi di stringa
    if (type === 'deviation' && filter.stringAnalysis) {
      const normalizedStringAnalysis = normalizeFilter(filter.stringAnalysis)
      filterCopy.stringAnalysis = normalizedStringAnalysis
    }
    // altrimenti normalizzo solo il filtro del tipo passato
    const normalizedFilterType = normalizeFilter(filterType)
    filterCopy[type] = normalizedFilterType
  }

  return filterCopy
}

function getPlantSunTimes (plant, _now) {
  const now = new Date(_now)
  let sunrise = new Date(now)
  sunrise.setHours(5, 0, 0, 0)
  let sunset = new Date(now)
  sunset.setHours(22, 0, 0, 0)
  if (plant.location && plant.location.coordinates) {
    const suntimes = SunCalc.getTimes(new Date(now), plant.location.coordinates[1], plant.location.coordinates[0])
    sunrise = new Date(suntimes.sunrise)
    sunset = new Date(suntimes.sunset)
  }

  let sunriseMinutesOffset = 90
  let sunsetMinutesOffset = 90
  if (plant.metadata && plant.metadata.sunriseMinutesOffset && !isNaN(plant.metadata.sunriseMinutesOffset)) {
    sunriseMinutesOffset = Number(plant.metadata.sunriseMinutesOffset)
  }
  if (plant.metadata && plant.metadata.sunsetMinutesOffset && !isNaN(plant.metadata.sunsetMinutesOffset)) {
    sunsetMinutesOffset = Number(plant.metadata.sunsetMinutesOffset)
  }
  sunrise.setMinutes(sunrise.getMinutes() + sunriseMinutesOffset)
  sunset.setMinutes(sunset.getMinutes() - sunsetMinutesOffset)
  return { sunset, sunrise }
}

function isNightAtThePlant (plant, _now) {
  const now = new Date(_now)
  const { sunset, sunrise } = getPlantSunTimes(plant, now)
  // alog(`Dopo aver applicato gli offset, per l'impianto abbiamo sunrise=${sunrise.toISOString()} e sunset=${sunset.toISOString()}`, null, 'plant')
  return (now.getTime() < sunrise.getTime() || now.getTime() > sunset.getTime())
}
// funzione che ritorna la durata effettiva dell'anomalia (tiene conto solo delle ore di esercizio)
export const getAnomalyDuration = (_from, _to, plant) => {
  // alog('plant', plant, 'anomalie')
  const from = new Date(_from)
  const to = new Date(_to)

  const cursor = new Date(from)
  let totalMinutes = 0
  while (cursor.getTime() < to.getTime()) {
    if (!isNightAtThePlant(plant, cursor)) {
      totalMinutes += 15
    }
    cursor.setMinutes(cursor.getMinutes() + 15)
  }

  // Diamo l'ouput in ms
  return totalMinutes * 60 * 1000
}
