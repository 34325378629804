import { useState } from 'react'
import clsx from 'clsx'
import CloseIcon from '@material-ui/icons/Close'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import { KeyboardDateTimePicker } from '@material-ui/pickers'

const useStyles = makeStyles(theme => ({
  root: {},
  closeButton: {
    color: theme.palette.grey[500]
  }
}))

function PeriodModal ({ open, onClose, start, end, confirm, className }) {
  const [startDate, setStartDate] = useState(start)
  const [endDate, setEndDate] = useState(end)

  const classes = useStyles()
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
  return (
    <Dialog
      fullWidth
      fullScreen={isSmall}
      maxWidth='sm'
      className={clsx(classes.root, className)}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography component='div' variant='h3'>
            Modifica Periodo
          </Typography>
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box width='100%'>
          <Typography variant='body2'>Scegliere il periodo da visualizzare</Typography>
          <Box mt={2} display='flex' alignItems='center'>
            <Box mr={1} width='100%'>
              <KeyboardDateTimePicker
                fullWidth
                className={classes.datePicker}
                label='Data inizio'
                size='small'
                KeyboardButtonProps={{ size: 'small' }}
                format='DD/MM/YYYY HH:mm'
                name='currentPeriod'
                disableFuture
                views={['date', 'hours']}
                ampm={false}
                inputVariant='outlined'
                value={startDate}
                onChange={(date) => {
                  setStartDate(date)
                }}
              />
            </Box>
            <KeyboardDateTimePicker
              fullWidth
              className={classes.datePicker}
              label='Data fine'
              size='small'
              KeyboardButtonProps={{ size: 'small' }}
              format='DD/MM/YYYY HH:mm'
              name='currentPeriod'
              disableFuture
              views={['date', 'hours']}
              ampm={false}
              inputVariant='outlined'
              value={endDate}
              onChange={(date) => {
                setEndDate(date)
              }}
            />
          </Box>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant='contained' color='primary' onClick={() => confirm(startDate, endDate)}>
          Conferma
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default PeriodModal
