import { useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import { Box, Button, Card, CardContent, Chip, Grid, makeStyles, Tooltip, Typography } from '@material-ui/core'
import { Filter as FilterIcon, ChevronRight, ChevronLeft } from 'react-feather'
import { decodeTrendGraph, getAggregationTypeFromPeriod } from '../../led/utils'
import SidebarFilters from './SidebarFilters'
import { useSelector } from 'src/store'
import TrendOverTimeGraph from 'src/components/charts/TrendOverTimeGraph'
import { alog } from 'src/utils/apioLog'
import { baseValues, formatPeriod, getDashboardTypes } from './SidebarFilters/FiltersContent/utils'
import useAuth from 'src/hooks/useAuth'
import api from 'src/utils/api'
import { useSnackbar } from 'notistack'
import LoadingCard from 'src/components/LoadingCard'
import moment from 'moment'
import { useClientRect } from 'src/hooks/useClientRect'
import PeriodModal from './PeriodModal'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: `calc(100% - 48px - ${theme.spacing(2)}px - 80px)`
  },
  loading: {
    width: '100%'
  },
  section: {
    padding: theme.spacing(1, 2),
    width: '100%',
    height: '100%'
  },
  sectionTitle: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main
  },
  topMargin: {
    marginTop: (theme.spacing(2) - 4)
  },
  smallViewport: {
    overflowY: 'auto',
    height: '100%'
  },
  filterTitle: {
    color: theme.palette.common.white
  },
  chip: {
    backgroundColor: theme.palette.secondary.main,
    margin: theme.spacing(1, 2, 1, 0)
  }
}))

function Analytics ({ className, ...rest }) {
  const classes = useStyles()
  const { baseDate, baseDashboardType, getBaseFilter } = baseValues
  const { user, updateAnalyticsFilters: ctxUpdateUserAnalyticsFilters } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const { energyMeters, uuid: plantId } = useSelector(state => state.ledPlantView)
  const [filterOpen, setFilterOpen] = useState(false)
  const [dashboardType, setDashboardType] = useState(baseDashboardType)
  const [currentPeriod, setCurrentPeriod] = useState(baseDate)
  const [startDate, setStartDate] = useState(moment().subtract(1, 'days'))
  const [endDate, setEndDate] = useState(moment())
  const [currentFilter, setCurrentFilter] = useState(getBaseFilter())
  const [isLoading, setIsLoading] = useState(false)
  const [openPeriodModal, setOpenPeriodModal] = useState(false)

  // const isSmall = useMediaQuery(theme => theme.breakpoints.down('lg'))
  // Ref per calcolare l'altezza dei grafici
  const [rect, ref] = useClientRect()

  // Stato per i grafici
  const [trend, setTrend] = useState([])

  const getData = useCallback(async (type, timeFrom, timeTo, filter) => {
    const config = {}
    // Preparo l'oggetto config ad ospitare il numero corretto di grafici
    filter[type].forEach((el, index) => {
      // console.log(el)
      if (el.elements && el.elements.length > 0) {
        if (type === 'trend') {
          const properties = el.elements
          const elements = [...new Set(properties.map(prop => prop.deviceId))]
            .map(id => ({
              properties: properties.filter(el => el.deviceId === id).filter(el => el.selected === true).map(el => el.name),
              resourceType: properties.find(el => el.deviceId === id) ? properties.find(el => el.deviceId === id).deviceType : 'device',
              resourceId: id
            }))

          alog('elements', elements, 'getData')
          config[`graph${index + 1}`] = {
            aggregationType: el.aggregationType || getAggregationTypeFromPeriod(timeFrom, timeTo),
            elements
          }
        }
      }
    })

    alog('config', config, 'getDataconfig')
    // Oggetto che contiene i parametri per effettuare la chiamata API
    const paramsObj = {
      type,
      timeFrom,
      timeTo,
      plantId,
      config
    }
    const params = JSON.stringify(paramsObj)
    // console.log(type, timeFrom, timeTo, filter)
    if (plantId) {
      try {
        // const response = await axios.get(`/api/plant/plantId/analytics?type=${type}`)
        const response = await api.getResource('plantDetails', { path: `/${plantId}/analytics?q=${params}` })
        alog('getData', response, 'response')
        // const dataObj = response.data
        return response
      } catch (e) {
        alog('getData', e, 'error')
        return null
      }
    }
  }, [plantId])

  useEffect(() => {
    async function initialiseView () {
      if (dashboardType === 'trend') {
        setIsLoading(true)
        const data = await getData(dashboardType, startDate, endDate, currentFilter)
        if (data) {
          const newTrend = Object.keys(data).map((key, index) => decodeTrendGraph({ ...data[key] }, currentFilter.trend[index]))
          setTrend(newTrend)
        }
        setIsLoading(false)
      }
    }

    if (dashboardType && startDate && endDate && currentFilter) {
      initialiseView()
    }
  }, [dashboardType, startDate, endDate, currentFilter, getData])

  // Funzione che elimina un filtro utente
  const deleteFilter = async (filterId) => {
    try {
      const userFilters = user.metadata.analyticsFilters || []
      const newUserFilters = userFilters.filter(el => el.uuid !== filterId)
      const response = await api.deleteResource('settings', { path: `/${filterId}`, fullResponse: true })
      if (response?.status) {
        ctxUpdateUserAnalyticsFilters(newUserFilters)
      }
      enqueueSnackbar('Filtro eliminato con successo!', { variant: 'success' })
    } catch (e) {
      enqueueSnackbar('Errore durante l\'eliminazione del filtro', { variant: 'error' })
    }
  }

  // Funzione che modifica e salva un filtro utente
  const editFilters = async (filter, name, type, startDate, endDate) => {
    const newFilter = {
      ...filter
    }
    newFilter.startDate = moment(startDate).toISOString()
    newFilter.endDate = moment(endDate).toISOString()
    if (name) {
      newFilter.name = name
    }

    alog('editFilters', filter, 'EDIT')
    alog('newEditFilters', newFilter, 'EDIT')
    const newSetting = {
      data: newFilter
    }
    try {
      if (filter?.uuid) {
        const { data: newFilter } = await api.putResource('settings', { path: `/${filter.uuid}`, body: newSetting })
        if (newFilter) {
          const newUserFilters = user?.metadata?.analyticsFilters || []
          const newAnalyticsFilters = newUserFilters.map(el => el.uuid === newFilter.uuid ? newFilter : el)
          ctxUpdateUserAnalyticsFilters(newAnalyticsFilters)
        }
        enqueueSnackbar('Filtro modificato con successo!', { variant: 'success' })
      }
    } catch (e) {
      enqueueSnackbar('Errore durante la modifica del filtro', { variant: 'error' })
    }
  }

  // Funzione che crea e salva un filtro utente
  const saveFilters = async (filter, name, type, startDate, endDate) => {
    const filterCopy = { ...filter }
    const newFilter = {
      ...filterCopy,
      name,
      type,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      plantId
    }
    // Creo l'oggetto che rapprensenta la setting
    const newSetting = {
      name: `analyticsFilters.${type}`,
      data: newFilter
    }

    try {
      const { uuid: settingId, data: newFilter } = await api.postResource('settings', { body: newSetting }) || {}
      if (newFilter) {
        const newUserFilters = user?.metadata?.analyticsFilters || []
        const newAnalyticsFilters = [...newUserFilters, { ...newFilter, uuid: settingId }]
        ctxUpdateUserAnalyticsFilters(newAnalyticsFilters)
      }
      enqueueSnackbar('Filtro creato con successo!', { variant: 'success' })
    } catch (e) {
      enqueueSnackbar('Errore durante la creazione del filtro', { variant: 'error' })
    }
  }

  // Funzione che prende in ingresso il filtro corrente e crea la stringa contenente gli elementi presi in considerazione dal filtro
  const getElements = (filter) => {
    const currentFilterView = filter[dashboardType]
    if (currentFilterView) {
      // Ricreo un array contenente tutti gli elementi di ogni grafico dei filtri
      const currentElements = currentFilterView.map(currFilter => currFilter.elements).flat()
      return dashboardType === 'trend' ? currentElements.map(el => el.displayName || '').join(', ') : ''
    }
  }

  const graphHeight = rect ? rect.height - 220 : '100%'

  alog('graphHeight: ', graphHeight, 'graphHeight')
  // funzione che in base al tipo di dashboard da visualizzare restituisce i grafici corretti
  const getCurrentDashboard = (viewType) => {
    switch (viewType) {
      case 'trend': {
        return (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card className={classes.section}>
                  <Grid container spacing={2}>
                    {!isLoading && trend.length > 0
                      ? trend.map((el, elIndex) => currentFilter[dashboardType][elIndex].show === true
                          ? (
                            <Grid item xs={12} key={`trend-${elIndex}`}>
                              <Typography className={classes.sectionTitle} variant='h6'>{`Andamento nel tempo ${elIndex + 1}`}</Typography>
                              <Box width='100%' height='100%' display='flex' alignItems='center' justifyContent='center'>
                                <TrendOverTimeGraph height={graphHeight < 300 ? 300 : trend.length > 1 ? graphHeight * 0.5 : graphHeight} data={el} />
                              </Box>
                            </Grid>
                            )
                          : (
                            <Grid item xs={12}>
                              <Typography className={classes.sectionTitle} variant='h6'>{`Andamento nel tempo ${elIndex + 1}`}</Typography>
                              <Typography variant='body1'>Grafico non attivo</Typography>
                            </Grid>
                            ))
                      : !isLoading && !(getElements(currentFilter) !== '')
                          ? (
                            <Box width='100%' height='100%' display='flex' alignItems='center' justifyContent='center'>
                              <Card>
                                <CardContent>
                                  <Typography variant='h5'>
                                    Nessuna grandezza da visualizzare, selezionale nel menu di modifica
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Box>
                            )
                          : (
                            <Box width='100%' height='100%' display='flex' alignItems='center' justifyContent='center'>
                              <LoadingCard className={classes.loading} size={40} />
                            </Box>
                            )}
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Box>
        )
      }
      default: {
        return null
      }
    }
  }

  // funzione che in base al parametro ricevuto, porta data iniziale e data finale di un periodo indietro o avanti
  const nextOrPrevPeriod = (type, start, end) => {
    const newStartDate = type === 'prev' ? moment(start).subtract(1, 'days') : moment(start).add(1, 'days')
    const newEndDate = type === 'prev' ? moment(end).subtract(1, 'days') : moment(end).add(1, 'days')
    const newPeriod = `${newStartDate.format('DD/MM')} - ${newEndDate.format('DD/MM')}`
    setStartDate(newStartDate)
    setEndDate(newEndDate)
    setCurrentPeriod(newPeriod)
  }

  return (
    // <div className={isSmall ? clsx(classes.root, className, classes.smallViewport) : clsx(classes.root, className)} {...rest}>
    <div className={clsx(classes.root, className)} {...rest}>
      {
        openPeriodModal
          ? (
            <PeriodModal
              confirm={(start, end) => {
                // console.log(start, end)
                const newPeriod = `${moment(start).format('DD/MM')} - ${moment(end).format('DD/MM')}`
                setStartDate(start)
                setEndDate(end)
                setCurrentPeriod(newPeriod)
                setOpenPeriodModal(false)
              }}
              start={startDate}
              end={endDate}
              open={openPeriodModal}
              onClose={() => setOpenPeriodModal(false)}
            />)
          : null
      }
      <Box mt={2}>
        <Box width='100%' display='flex' alignItems='center'>
          <Typography className={classes.filterTitle} variant='h6'>Filtri attivi</Typography>
        </Box>
        <Grid width='100%' container spacing={2}>
          <Grid item xs={10} md={10} xl={8}>
            <Box width='100%' display='flex' alignItems='center'>
              {
                (getDashboardTypes().find(el => el.value === dashboardType)
                  ? (
                    <Chip
                      size='small'
                      className={classes.chip}
                      label={`Tipo: ${getDashboardTypes().find(el => el.value === dashboardType).label}`}
                    />)
                  : null)

              }
              {currentPeriod
                ? (
                  <Chip
                    size='small'
                    className={classes.chip}
                    label={currentPeriod}
                    color='secondary'
                    onClick={() => setOpenPeriodModal(true)}
                    deleteIcon={moment().diff(endDate, 'days') > 0 ? <ChevronRight style={{ cursor: 'pointer' }} size={18} color='#e0e0e0' /> : null}
                    {...{
                      onDelete: moment().diff(endDate, 'days') > 0 ? () => nextOrPrevPeriod('next', startDate, endDate) : null
                    }}
                    icon={
                      <ChevronLeft
                        size={18}
                        onClick={(e) => {
                          // serve stoppare la propagazione dell'evento per impedire
                          // anche l'apertura del calendario invece che la chiamata alla funzione di cambio periodo
                          e.stopPropagation()
                          nextOrPrevPeriod('prev', startDate, endDate)
                        }}
                      />
                    }
                  />)
                : null}
              {currentFilter && Object.keys(currentFilter).length > 0 && getElements(currentFilter) !== ''
                ? (
                  <Tooltip title={getElements(currentFilter)}>
                    <Chip style={{ maxWidth: 300 }} size='small' className={classes.chip} label={getElements(currentFilter)} />
                  </Tooltip>)
                : null}
            </Box>
          </Grid>
          <Grid item xs={2} xl={4}>
            <Box width='100%' display='flex' alignItems='center' justifyContent='flex-end'>
              <Button onClick={() => setFilterOpen(true)} size='small' variant='contained' color='secondary' startIcon={<FilterIcon size={14} />}>Modifica</Button>
            </Box>
            <SidebarFilters
              applyFilters={(filter, viewType, startPeriod, endPeriod) => {
                setCurrentFilter(filter)
                setDashboardType(viewType)
                setStartDate(startPeriod)
                setEndDate(endPeriod)
                setCurrentPeriod(formatPeriod(moment(startPeriod), moment(endPeriod)))
              }}
              plantId={plantId}
              editFilters={editFilters}
              saveFilters={saveFilters}
              deleteFilter={deleteFilter}
              energyMeters={energyMeters}
              open={filterOpen}
              onClose={() => setFilterOpen(false)}
            />
          </Grid>
        </Grid>
      </Box>
      <Box ref={ref} width='100%' height='100%'>
        {getCurrentDashboard(dashboardType)}
      </Box>
    </div>
  )
}
export default Analytics
