import { useRef, useState, memo } from 'react'
import { Link as RouterLink, useHistory } from 'react-router-dom'

import { useSnackbar } from 'notistack'
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles
} from '@material-ui/core'
import useAuth from 'src/hooks/useAuth'

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  }
}))

const placeholder = '/static/images/avatars/user-placeholder2.svg'

const Account = () => {
  const classes = useStyles()
  const history = useHistory()
  const ref = useRef(null)
  const { user, logout } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const [isOpen, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleLogout = async () => {
    try {
      handleClose()
      await logout()
      console.clear()
      history.push('/login')
    } catch (err) {
      console.error('Errore nel gestire il logout', err)
      enqueueSnackbar('Errore nel fare logout. Per favore controlla la connessione.', {
        variant: 'error'
      })
    }
  }

  return (
    <>
      <Box
        display='flex'
        alignItems='center'
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar
          alt='User'
          className={classes.avatar}
          src={user.metadata && user.metadata.imageURL ? user.metadata.imageURL : placeholder}
        />
        <Hidden smDown>
          <Typography
            variant='h6'
            color='inherit'
          >
            {user.fullName}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        {/* <MenuItem
          component={RouterLink}
          to="/app/social/profile"
        >
          Profile
        </MenuItem> */}
        <MenuItem
          component={RouterLink}
          to='/app/settings/account'
        >
          Account
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          Logout
        </MenuItem>
      </Menu>
    </>
  )
}

export default memo(Account)
