import { memo } from 'react'
import clsx from 'clsx'
import moment from 'moment'
import PropTypes from 'prop-types'
import Chart from 'react-apexcharts'
// import ApexCharts from 'apexcharts'
// import PerfectScrollbar from 'react-perfect-scrollbar'
// import { fixedNum, europeNum } from 'src/utils/general'
import { basicColors } from 'src/theme'
import LoadingCard from 'src/components/LoadingCard'
import {
  Box,
  // Button,
  Tooltip,
  Card,
  CardHeader,
  // CardContent,
  // IconButton,
  SvgIcon,
  makeStyles
  // Typography,
  // withStyles
} from '@material-ui/core'
// import { useSnackbar } from 'notistack'

import {
  Info as InfoIcon
} from 'react-feather'

// variaible con le dimenzioni del grafico
const graphSize = {
  height: 180,
  width: 300
}

// stile CSS generale
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    maxHeight: '100%',
    width: '100%'
    // maxHeight: 300,
  },
  boxContainer: {
    width: '100%',
    height: '82%',
    // maxHeight: 'calc(100% - 51px)',
    paddingLeft: 12,
    paddingRight: 12,
    paddingBottom: 16,
    // display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'center',
    // justifyContent: 'center',
    zIndex: 1000
  }
}))

// componente principale
const AvgHoursGraphView = ({ noCard = false, width = null, height = null, className, avgHoursGraph, anomaliesCategoryStatus, ...rest }) => {
  // console.log('showTable =>', showTable)
  const classes = useStyles()
  // const { enqueueSnackbar } = useSnackbar()
  // mi preparo il dataset
  const dataSet = {
    series: [],
    labels: [],
    colors: []
  }
  // costruisco l'array dei dati che mi tornano dalla query
  const queryData = avgHoursGraph[0] ? JSON.parse(JSON.stringify(avgHoursGraph)) : []
  // mi ordino i dati della serie
  const sortedQueryData = queryData.sort(function (a, b) {
    return b.value - a.value
  })
  // mi creo la variabili delle label e dei colori
  sortedQueryData.forEach((data) => {
    // cerco il riferimento alla categoria e sotto categoria
    const thisCategory = anomaliesCategoryStatus.find((category) => {
      return category.name === data.category
    })
    if (thisCategory) {
      const thisSubCategory = thisCategory.subCategory.find((subCategory) => {
        return subCategory.name === data.subCategory
      })
      // controllo se ho trovato la categoria
      if (thisSubCategory) {
        // pusho i dati
        const thisHours = moment.duration(data.value).asHours()
        dataSet.series.push(Number(thisHours.toFixed(0)))
        dataSet.labels.push(thisSubCategory.label)
        dataSet.colors.push(thisSubCategory.color)
      }
    }
  })

  const chartData = {
    series: dataSet.series,
    chart: {
      height: graphSize.height,
      width: graphSize.width,
      type: 'polarArea'
      // id='radialPr'
    },
    options: {
      chart: {
        id: 'polarAvgHours',
        toolbar: {
          show: false
        }
        // redrawOnParentResize: false
        // width: '100%',
        // height: 'auto'
      },
      labels: dataSet.labels,
      colors: dataSet.colors,
      stroke: {
        show: false,
        curve: 'smooth',
        lineCap: 'round',
        colors: ['rgba(2, 70, 96, 0.1)'],
        width: 10
      },
      fill: {
        opacity: 0.9
      },
      plotOptions: {
        polarArea: {
          // rings: {
          //   strokeWidth: 1,
          //   strokeColor: '#e8e8e8',
          // },
          spokes: {
            strokeWidth: 0
            // connectorColors: '#e8e8e8',
          }
        }
      },
      legend: {
        show: false,
        fontSize: '11px',
        fontFamily: 'Helvetica, Arial',
        position: 'right',
        // offsetY: -10,
        labels: {
          colors: basicColors.whiteText
        },
        formatter: function (seriesName, opts) {
          // console.log('seriesName => ', seriesName)
          // console.log('opts => ', opts)
          // return [dataSet.series[opts.seriesIndex].name, " - ", opts.w.globals.series[opts.seriesIndex]]
          return [seriesName, ' - ', opts.w.globals.series[opts.seriesIndex]]
        }
      },
      tooltip: {
        theme: 'dark',
        y: {
          formatter: (value) => value + ' ore'
        }
      },
      yaxis: {
        tickAmount: 5,
        labels: {
          style: {
            colors: basicColors.blueGrayLight,
            fontSize: '11px'
          },
          offsetX: 0,
          offsetY: -8,
          formatter: (value) => {
            // console.log('yAxis formatter value => ', value)
            return Number(value).toFixed(value % 1 !== 0 ? 1 : 0)
          }
        }
      }
    }
  }

  return noCard
    ? avgHoursGraph[0] !== '-'
        ? (
          <Chart
            id={chartData.options.chart.id}
            options={chartData.options}
            series={chartData.series}
            type={chartData.chart.type}
            width={width || '100%'}
            height={height || '100%'}
          />
          )
        : <LoadingCard />
    : (
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader
          title='Durata media nel periodo'
          // subheader='September 14, 2016'
          style={{ paddingBottom: 6 }}
          action={
            <Tooltip
              title='Nel grafico viene contata solo la durata media delle anomalie nel periodo selezionato'
              aria-label='Durata media nel periodo'
              interactive
            >
              <SvgIcon fontSize='small'>
                <InfoIcon color={basicColors.primary} />
              </SvgIcon>
            </Tooltip>
          }
        />
        <Box className={classes.boxContainer} display='flex' alignItems='center' justifyContent='center'>
          {avgHoursGraph[0] !== '-'
            ? (
              <Chart
                id={chartData.options.chart.id}
                options={chartData.options}
                series={chartData.series}
                type={chartData.chart.type}
                width='100%'
                height='100%'
              />
              )
            : <LoadingCard />}
        </Box>
      </Card>
      )
}

AvgHoursGraphView.propTypes = {
  className: PropTypes.string,
  avgHoursGraph: PropTypes.array,
  anomaliesCategoryStatus: PropTypes.array
  // customers: PropTypes.array.isRequired,
  // setRefreshUsers: PropTypes.func
}

AvgHoursGraphView.defaultProps = {
  avgHoursGraph: [],
  anomaliesCategoryStatus: []
}

export default memo(AvgHoursGraphView)
