import { useState } from 'react'
import clsx from 'clsx'
import { Box, Checkbox, Grid, ListItemIcon, ListItemText, makeStyles, MenuItem, TextField, Typography } from '@material-ui/core'
import { HexColorPicker } from 'react-colorful'
import { libraryDevices } from '../../libraryDevices'

const useStyles = makeStyles(() => ({
  root: {}
}))

function MeterContent ({ className, ...rest }) {
  const classes = useStyles()
  const { selectedObject, devices, element, setElement } = rest

  const configDevices = element && element !== undefined
    ? element.config.length > 0 ? element.config[0].devices : []
    : []

  const selected = configDevices && configDevices.length > 0 ? configDevices[0].deviceId : ''
  const number = element && element.config.length > 0
    ? element.config[0].data
        ? element.config[0].data.draw && element.config[0].data.draw.number
            ? element.config[0].data.draw.number
            : 0
        : 0
    : 0

  const color = element && element.config.length > 0
    ? element.config[0].data
        ? element.config[0].data.draw && element.config[0].data.draw.backgroundColor
            ? element.config[0].data.draw.backgroundColor
            : 'white'
        : 'white'
    : 'white'

  const [selectedDevice, setSelectedDevice] = useState(selected)
  const [currentNumber, setCurrentNumber] = useState(number)
  const [currentColor, setCurrentColor] = useState(color)

  const meters = devices ? devices.filter(device => device.deviceType.category === 'EnergyMeter') : []

  return (
    <div className={clsx(classes.root, className)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Typography variant='h6'>Meter</Typography>
          <Box my={2} width='100%'>
            <TextField
              size='small'
              color='primary'
              variant='outlined'
              select
              fullWidth
              SelectProps={{
                renderValue: (renderSelected) => <ListItemText style={{ marginTop: 0, marginBottom: 0 }}>{meters.find(or => or.uuid === renderSelected) ? meters.find(or => or.uuid === renderSelected).name : ''}</ListItemText>
              }}
              label='Seleziona Meter'
              value={selectedDevice}
            >
              {meters.map(el => (
                <MenuItem
                  dense
                  onClick={() => {
                    let isDeselection = false
                    setElement(prevElement => {
                      // oggetto completo del device selezionato
                      const selectedDevice = devices.find(dev => dev.uuid === el.uuid)

                      // se l'oggetto esiste, devo aggiornare l'array dei devices nell'elemento
                      if (selectedDevice && selectedDevice !== undefined) {
                        // creo l'oggetto con le proprietà da mostrare nelle infobox
                        const properties = {}
                        const libraryDevice = libraryDevices.find(libEl => libEl.models.includes(selectedDevice.deviceType.model))
                        if (libraryDevice) {
                          const deviceKeys = Object.keys(libraryDevice)
                          deviceKeys.filter(key => key !== 'models').forEach(key => {
                            properties[key] = {
                              label: libraryDevice[key],
                              value: 0
                            }
                          })
                        }
                        // strutturo l'oggetto device
                        const elementDevice = {
                          type: selectedObject.type,
                          model: selectedDevice.deviceType.model,
                          deviceId: selectedDevice.uuid,
                          properties,
                          additionalData: {}
                        }

                        if (prevElement.config.length > 0) {
                          const sameDevice = prevElement.config[0].devices.find(configEl => configEl.deviceId === el.uuid)
                          // se è presente lo rimuovo
                          if (sameDevice && sameDevice !== undefined) {
                            const newDevices = prevElement.config[0].devices.filter(dev => dev.deviceId !== el.uuid)
                            prevElement.config[0].devices = newDevices
                            isDeselection = true
                          } else {
                            // aggiorno l'array di devices di un singolo elemento
                            prevElement.config[0].devices = [{ ...elementDevice }]
                          }
                        } else {
                          prevElement.config.push({
                            devices: [{ ...elementDevice }],
                            data: {}
                          })
                        }
                      }

                      return ({
                        config: [{
                          ...prevElement.config[0]
                        }]
                      })
                    })
                    if (isDeselection) {
                      setSelectedDevice('')
                    } else {
                      setSelectedDevice(el.uuid)
                    }
                  }} key={el.uuid} value={el.uuid}
                >
                  <ListItemIcon>
                    <Checkbox
                      color='primary'
                      edge='start'
                      checked={selected === el.uuid}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText>{el.name}</ListItemText>
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box width='100%'>
            <TextField
              fullWidth
              size='small'
              variant='outlined'
              label='Numero'
              type='number'
              placeholder='0'
              value={currentNumber || ''}
              onChange={(e) => {
                e.persist()
                setElement(prevElement => {
                  if (prevElement.config.length > 0) {
                    // se l'elemento non ha l'oggetto data lo imposto a oggetto vuoto
                    if (!prevElement.config[0].data) {
                      prevElement.config[0].data = {}
                    }
                    // se l'elemento non ha l'oggetto draw lo imposto a oggetto vuoto
                    if (!prevElement.config[0].data.draw) {
                      prevElement.config[0].data.draw = {}
                    }
                    prevElement.config[0].data.draw = {
                      ...prevElement.config[0].data.draw,
                      number: e.target.value
                    }
                  } else {
                    prevElement.config.push({
                      devices: [],
                      data: {
                        draw: {
                          number: e.target.value
                        }
                      }
                    })
                  }
                  return { ...prevElement }
                })
                setCurrentNumber(e.target.value)
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant='h6'>Colore di sfondo</Typography>
          <Box my={2} width='100%'>
            <HexColorPicker
              style={{ height: '100px' }} color={currentColor} onChange={setCurrentColor} onMouseUp={(e) => {
                e.persist()
                setElement((prevElement) => {
                  // console.log(prevElement)
                  if (prevElement.config.length > 0) {
                    if (!prevElement.config[0].data.draw) {
                      prevElement.config[0].data.draw = {}
                    }
                    prevElement.config[0].data.draw = {
                      ...prevElement.config[0].data.draw,
                      backgroundColor: currentColor
                    }
                  } else {
                    prevElement.config.push({
                      devices: [],
                      data: {
                        draw: {
                          backgroundColor: currentColor
                        }
                      }
                    })
                  }
                  return { ...prevElement }
                })
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}
export default MeterContent
