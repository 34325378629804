import { memo } from 'react'
import { StepConnector } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

const StepperConnector = withStyles((theme) => ({
  vertical: {
    marginLeft: 19,
    padding: 0
  },
  line: {
    borderColor: theme.palette.divider
  }
}))(StepConnector)

export default memo(StepperConnector)
