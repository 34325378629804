import { createContext, useEffect, useReducer } from 'react'
import SplashScreen from 'src/components/SplashScreen'
import { assetsManagerPermissions, configuration } from 'src/config.js'
import api from 'src/utils/api'
import { alog } from 'src/utils/apioLog'

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
}

const newBasicRole = [
  'assetsmanager.nodes.manage',
  'assetsmanager.plants.manage',
  'assetsmanager.plants.access',
  'assetsmanager.reports.board',
  'assetsmanager.reports.plants',
  'assetsmanager.users.manage',
  'assetsmanager.roles.manage',
  'assetsmanager.alarms.components',
  'assetsmanager.alarms.performances',
  'assetsmanager.alarms.communication'
]

const setSession = (accessToken) => {
  if (accessToken) {
    window.localStorage.setItem('accessToken', accessToken)
  } else {
    window.localStorage.removeItem('accessToken')
  }
}

// Funzione che in base al filtro passato come parametro genera l'oggetto corretto per essere interpretato dalla UI
const generateFilterObj = (filter = null, type = '') => {
  // Caso in cui sto generando i filtri per la mappa
  if (filter?.name.startsWith(`${type}.maps`)) {
    const dataFilter = Array.isArray(filter?.data) ? filter?.data : []
    const mapsFilters = dataFilter?.map(el => ({
      ...el,
      settingId: filter.uuid
    }))

    return mapsFilters || []
  }

  const filterObj = {
    ...filter.data,
    uuid: filter.uuid
  }

  return filterObj
}

// Funzione che prende in ingresso un array di filtri e li divide in array separati per tipo di filtro
const formatFilters = (filters) => {
  const formattedFilters = filters.reduce((acc, filter) => {
    // Tipi di filtro gestiti
    const filterTypes = ['analyticsFilters', 'userFilters']

    const type = filter.name?.split('.')?.[0] || null

    if (type) {
      const normalizedType = filterTypes.find(el => el.startsWith(type)) || null

      // Genero gli oggetti che rappresentano i filtri
      const generatedFilters = generateFilterObj(filter, normalizedType)
      // Se ottengo un array di oggetti, estrapolo i singoli oggetti e li setto come filtri singoli
      if (Array.isArray(generatedFilters)) {
        generatedFilters.forEach(generatedFilter => {
          const newFiltersArray = acc[normalizedType] ? [...acc[normalizedType], generatedFilter] : [generatedFilter]
          acc[normalizedType] = newFiltersArray
        })
        // Altrimenti setto direttamente gli oggetti
      } else {
        const newFiltersArray = acc[normalizedType] ? [...acc[normalizedType], generatedFilters] : [generatedFilters]
        acc[normalizedType] = newFiltersArray
      }
    }

    return acc
  }, {})

  return formattedFilters
}

// Funzione che ritorna un oggetto con tutti i filtri per un utente
const getUserSettings = async () => {
  const settings = await api.getResource('settings')
  const formattedSettings = formatFilters(settings)
  return formattedSettings
}

// funzione che prende in ingresso l'account e lo ritorna con l'aggiunta dei filtri utente
const composeUserWithSettings = async (user) => {
  alog('account self => ', user, 'auth')

  // RIMUOVERE QUESTE RIGHE, vale solo per test
  if (user.metadata?.userFilters) {
    delete user.metadata.userFilters
  }

  if (user.metadata?.analyticsFilters) {
    delete user.metadata.analyticsFilters
  }
  // FINE RIGHE DA ELIMINARE

  const userSettings = await getUserSettings()

  if (!user.metadata) {
    user.metadata = {}
  }

  alog('account self no filters => ', user, 'auth')

  Object.keys(userSettings).forEach(key => {
    if (!user.metadata[key]) {
      user.metadata[key] = userSettings[key]
    }
  })

  return user
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      }
    }
    case 'LOGIN': {
      const { user } = action.payload

      return {
        ...state,
        isAuthenticated: true,
        user
      }
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      }
    }
    case 'REGISTER': {
      const { user } = action.payload

      return {
        ...state,
        isAuthenticated: true,
        user
      }
    }
    case 'UPDATE_USER': {
      const { newEdits } = action.payload

      return {
        ...state,
        user: {
          ...state.user,
          ...newEdits
        }
      }
    }
    case 'UPDATE_USER_IMAGE': {
      const { newUrl } = action.payload

      return {
        ...state,
        user: {
          ...state.user,
          metadata: {
            ...state.user.metadata,
            imageURL: newUrl
          }
        }
      }
    }
    case 'UPDATE_USER_ZONES': {
      const { zones } = action.payload
      return {
        ...state,
        user: {
          ...state.user,
          metadata: {
            ...state.user.metadata,
            zones
          }
        }
      }
    }
    case 'UPDATE_USER_ANALYTICS_FILTERS': {
      const { filters } = action.payload
      return {
        ...state,
        user: {
          ...state.user,
          metadata: {
            ...state.user.metadata,
            analyticsFilters: filters
          }
        }
      }
    }
    case 'UPDATE_USER_FILTERS': {
      const { filters } = action.payload
      return {
        ...state,
        user: {
          ...state.user,
          metadata: {
            ...state.user.metadata,
            userFilters: filters
          }
        }
      }
    }
    case 'UPDATE_REPORT_EMAIL': {
      const { emails } = action.payload
      return {
        ...state,
        user: {
          ...state.user,
          metadata: {
            ...state.user.metadata,
            reportEmails: emails
          }
        }
      }
    }
    default: {
      return { ...state }
    }
  }
}

const AuthContext = createContext({
  ...initialAuthState,
  allUserPermissions: assetsManagerPermissions,
  newBasicRole: newBasicRole,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => { }
  // register: () => Promise.resolve()
})

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState)

  const login = async (email, password) => {
    // const response = await axios.post(configuration.apiUrl + '/accounts/authenticate', { email, password })
    const response = await api.postResource('accounts', { path: '/authenticate', body: { email, password }, fullResponse: true })
    alog('login reponse => ', response.data.data, 'authcontext')
    const { token, account, roles, groups } = response.data.data
    // aggiungo dati all'account

    account.role = roles.find(role => role.projectId === configuration.projectId) || {}
    // account.frontPermissions = roles[0].metadata && roles[0].metadata.userPermissions ? JSON.parse(roles[0].metadata.userPermissions) : {}
    account.appPermissions = account?.role?.metadata?.applicationPermissions ? account.role.metadata.applicationPermissions : []
    account.groups = groups || []
    if (!account.metadata.reportEmails) {
      account.metadata.reportEmails = [account.email]
    }
    if (!account.mustConfirmEmailAddress) {
      account.mustConfirmEmailAddress = false
    }

    setSession(token)

    const accountWithSettings = await composeUserWithSettings(account)
    // localStorage.setItem('userUuid', account.uuid)
    dispatch({
      type: 'LOGIN',
      payload: {
        user: accountWithSettings
      }
    })
  }

  const updateZones = (zones) => {
    dispatch({
      type: 'UPDATE_USER_ZONES',
      payload: {
        zones
      }
    })
  }

  const updateFilters = (customFilters) => {
    dispatch({
      type: 'UPDATE_USER_FILTERS',
      payload: {
        filters: customFilters
      }
    })
  }

  const updateAnalyticsFilters = (customFilters) => {
    dispatch({
      type: 'UPDATE_USER_ANALYTICS_FILTERS',
      payload: {
        filters: customFilters
      }
    })
  }

  const updateReportEmails = (emails) => {
    dispatch({
      type: 'UPDATE_REPORT_EMAIL',
      payload: {
        emails: emails
      }
    })
  }

  const logout = async () => {
    try {
      await api.postResource('logout')
      setSession(null)
      dispatch({ type: 'LOGOUT' })
    } catch (err) {
      console.error('Error while loggin out, err => ', err)
    }
  }

  /*   const register = async (email, name, password) => {
    const response = await axios.post('/api/account/register', {
      email,
      name,
      password
    })
    const { accessToken, user } = response.data

    window.localStorage.setItem('accessToken', accessToken)

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    })
  } */

  // funzione che aggiorna l'immagine dello user salvato
  const updateLocalUserImage = (newUrl) => {
    alog('update image, newUrl =>', newUrl, 'user')
    dispatch({
      type: 'UPDATE_USER_IMAGE',
      payload: {
        newUrl: newUrl || null
      }
    })
  }

  // funzione che aggiorna il nome dello user salvato
  const updateLocalUser = (newEdits) => {
    alog('update nome, newName =>', newEdits, 'user')
    dispatch({
      type: 'UPDATE_USER',
      payload: {
        newEdits: newEdits || null
      }
    })
  }

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken')
        // const userUuid = window.localStorage.getItem('userUuid')

        // if (accessToken && isValidToken(accessToken)) {
        if (accessToken) {
          setSession(accessToken)

          const userParams = {
            include: 'roles,groups,aclrules,groupmemberships'
          }
          const response = await api.getResource('accounts', { path: '/self', params: userParams, fullResponse: true })
          // alog('account self => ', JSON.parse(JSON.stringify(response.data.data)), 'auth')
          const account = response.data.data

          account.role = account.includes.roles.find(role => role.projectId === configuration.projectId) || {}
          account.acl = account.includes.aclrules || []
          account.groups = account.includes.groups || []
          account.groupmemberships = account.includes.groupmemberships || []
          // account.frontPermissions = account.role.metadata && account.role.metadata.userPermissions ? JSON.parse(account.role.metadata.userPermissions) : {}
          account.appPermissions = account?.role?.metadata?.applicationPermissions ? account.role.metadata.applicationPermissions : []
          if (!account.metadata.reportEmails) {
            account.metadata.reportEmails = [account.email]
          }
          if (!account.mustConfirmEmailAddress) {
            account.mustConfirmEmailAddress = false
          }
          alog('account self => ', account, 'auth')

          const accountWithSettings = await composeUserWithSettings(account)

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user: accountWithSettings
            }
          })
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          })
        }
      } catch (err) {
        console.error(err)
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        })
      }
    }

    initialise()
  }, [])

  if (!state.isInitialised) {
    return <SplashScreen />
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        allUserPermissions: assetsManagerPermissions,
        newBasicRole: newBasicRole,
        method: 'JWT',
        login,
        logout,
        // register,
        updateFilters,
        updateAnalyticsFilters,
        updateReportEmails,
        updateZones,
        updateLocalUserImage,
        updateLocalUser
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
