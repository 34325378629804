/* eslint-disable no-use-before-define */
import { useEffect, memo } from 'react'
import { useLocation, Link as RouterLink } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
// import { assetsManagerPermissions } from 'src/config.js'
import { canIAccessThisUrl } from 'src/hooks/usePermissions'
import {
  // Avatar,
  Box,
  // Chip,
  Divider,
  Drawer,
  Hidden,
  // Link,
  List,
  ListSubheader,
  // Typography,
  makeStyles
} from '@material-ui/core'
// import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import { User as UserIcon, Cpu as CpuIcon, Users as UsersIcon, Layout as OverviewIcon } from 'react-feather'
import Logo from 'src/components/Logo'
import useAuth from 'src/hooks/useAuth'
import NavItem from './NavItem'
import Account from '../TopBar/Account'
// import { alog } from 'src/utils/apioLog'

export const routesToBreadcrums = {
  overview: 'overview',
  maps: 'mappa',
  analytics: 'analytics',
  anomalies: 'anomalie',
  reports: 'reports',
  settings: 'impostazioni',
  account: 'profilo',
  management: 'gestione',
  users: 'utenti',
  roles: 'ruoli',
  devices: 'datalogger',
  plants: 'impianti',
  report: 'report'
}

const sections = [
  {
    subheader: 'Home',
    items: [
      {
        title: 'Panoramica',
        icon: OverviewIcon,
        // href: '/app/overview/maps',
        items: [
          {
            title: 'Mappa',
            href: '/app/overview/maps'
          },
          {
            title: 'Analytics',
            href: '/app/overview/analytics'
          },
          {
            title: 'Anomalie',
            href: '/app/overview/anomalies'
          },
          {
            title: 'Reports',
            href: '/app/overview/reports'
          }
        ]
      }
    ]
  },
  {
    subheader: 'Gestione',
    items: [
      {
        title: 'Personale',
        icon: UsersIcon,
        // href: '/app/management/users',
        items: [
          {
            title: 'Utenti',
            href: '/app/management/users'
          },
          {
            title: 'Ruoli',
            href: '/app/management/roles'
          },
          {
            title: 'Gruppi',
            href: '/app/management/groups'
          }
        ]
      },
      {
        title: 'Componenti',
        icon: CpuIcon,
        // href: '/app/management/devices',
        items: [
          {
            title: 'Datalogger',
            href: '/app/management/devices'
          },
          {
            title: 'Impianti',
            href: '/app/management/plants'
          }
        ]
      }
    ]
  }
  /* {
    subheader: 'Impostazioni',
    items: [
      {
        title: 'Account',
        href: '/app/settings/account',
        icon: UserIcon
      }
    ]
  } */
]
// export default sections

function renderNavItems ({ items, pathname, depth = 0, appPermissions, groups }) {
  // alog('renderNavItems, appPermissions =>', appPermissions, 'nav')
  return (
    <List disablePadding>
      {items.reduce((acc, item) => reduceChildRoutes({ acc, item, pathname, depth, appPermissions, groups }), [])}
    </List>
  )
}

function reduceChildRoutes ({ acc, pathname, item, depth, appPermissions, groups }) {
  let fullAnswer = {}
  // chiave per il map
  const key = item.title + depth
  // se ha dei sottofigli costruisco la sotto nav per loro
  if (item.items) {
    // controllo se può accedere ad almeno uno degli url dei figli
    const thoseAnswers = []
    item.items.forEach(subItem => thoseAnswers.push(canIAccessThisUrl(appPermissions, subItem.href, groups)))
    if (thoseAnswers.some(fullAnswer => fullAnswer.answer === true)) {
      // controllo se l'item è selezionato
      const open = true
      // const open = matchPath(pathname, {
      //   path: item.href,
      //   exact: false
      // })
      // alog('item => ', item, 'nav')
      // alog('open => ', open, 'nav')

      acc.push(
        <NavItem depth={depth} icon={item.icon} info={item.info} key={key} open={Boolean(open)} title={item.title}>
          {renderNavItems({
            depth: depth + 1,
            pathname,
            items: item.items,
            appPermissions,
            groups
          })}
        </NavItem>
      )
    }
  } else {
    // controllo se può accedere a questo url
    fullAnswer = canIAccessThisUrl(appPermissions, item.href, groups)
    if (fullAnswer.answer) {
      acc.push(<NavItem depth={depth} href={item.href} icon={item.icon} info={item.info} key={key} title={item.title} />)
    }
  }

  return acc
}

// funzione che mi ritorna l'array degli url di una sezione principale
// la funzione estrapola l'href della sezione principale e ricorsivamente di ogni suo figlio
const getHrefs = arr => {
  return arr.reduce((acc, elem) => {
    if (Object.prototype.hasOwnProperty.call(elem, 'href')) {
      acc.push(elem.href)
    }

    if (Object.prototype.hasOwnProperty.call(elem, 'items')) {
      const innerHrefs = getHrefs(elem.items)
      acc.push(...innerHrefs)
    }

    return acc
  }, [])
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 220,
    top: 0,
    height: '100%'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}))

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles()
  const location = useLocation()
  const { user } = useAuth()

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
  }, [location.pathname])

  const content = (
    <Box height='100%' display='flex' flexDirection='column'>
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box paddingX={3} paddingY={2} display='flex' justifyContent='start' alignItems='center'>
          <RouterLink to='/'>
            <Logo logocolor='white' style={{ width: 120 }} />
          </RouterLink>
        </Box>
        <Divider />
        <Box p={2}>
          {sections
            .filter((section) => {
              const thisSectionUrls = getHrefs(section.items || [])
              const thoseAnswers = []
              thisSectionUrls.forEach(url => thoseAnswers.push(canIAccessThisUrl(user && user.appPermissions ? user.appPermissions : [], url, user.groups)))
              return thoseAnswers.some(fullAnswer => fullAnswer.answer === true)
            }).map((section) => (
              <List
                key={section.subheader}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname,
                  appPermissions: user && user.appPermissions ? user.appPermissions : [],
                  groups: user.groups
                })}
              </List>
            ))}
        </Box>
        {/* <Divider /> */}
      </PerfectScrollbar>
    </Box>
  )

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor='left'
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant='temporary'
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor='left' classes={{ paper: classes.desktopDrawer }} open variant='persistent'>
          {content}
          <Box padding={3}>
            <Account />
          </Box>
        </Drawer>
      </Hidden>
    </>
  )
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
}

export default memo(NavBar)
