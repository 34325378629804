import { Box, makeStyles, Typography } from '@material-ui/core'
import { useSelector } from 'src/store'
import { useDispatch } from 'react-redux'
import { editOrientation, pushOrientation, removeOrientation, savePlanimetryAndOrientations } from 'src/slices/pv/plant'
import Planimetry from 'src/components/Planimetry'
import clsx from 'clsx'
import { getAllDevices } from 'src/utils/plantOperations'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%'
  },
  stepDescription: {
    color: theme.palette.text.secondary
  },
  canvaContainer: {
    minHeight: '100% !important',
    height: '100%'
  }
}))

function PlantPlanimetry ({ className, ...rest }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { planimetry, addedDataloggers, orientations, distribution, plantType } = useSelector(state => state.pvPlant)

  const devices = getAllDevices({ dataloggers: addedDataloggers })

  // const [orientationsCopy, setOrientationsCopy] = useState(JSON.parse(JSON.stringify(orientations)))

  const saveCanvas = (exportedCanvas) => {
    // Larghezza e alteza in mm
    // const moduleWidth = module.rows && module.rows.length > 0 ? module.rows[0].width : 0
    // const moduleHeight = module.rows && module.rows.length > 0 ? module.rows[0].height : 0
    // const moduleEfficency = module.rows && module.rows.length > 0 ? Number(module.rows[0].efficency) / 100 : 0
    // const moduleMaxPower = module.rows && module.rows.length > 0 ? Number(module.rows[0].maxPower) : 0
    // Superficie in metri quadri
    // const surface = (moduleWidth / 1000) * (moduleHeight / 1000) * (moduleEfficency / 100)
    // const newOrientations = calculateOrientationsSurfaces(moduleEfficency, moduleMaxPower, distribution, exportedCanvas.config, currentOrientations)
    // console.log('orientations - newOrientations: ', newOrientations)
    dispatch(savePlanimetryAndOrientations(exportedCanvas))
  }

  const saveOrientation = (newOrientation) => {
    // 2. dispatch del push dell'orientamento
    dispatch(pushOrientation(newOrientation))
  }

  const modifyOrientation = (editedOrientation) => {
    dispatch(editOrientation(editedOrientation))
  }

  const deleteOrientation = (orientationUuid) => {
    dispatch(removeOrientation(orientationUuid))
    // console.log('deleteOrientation - uuid: ', orientationUuid)
  }

  return (
    <>
      <Box className={clsx(classes.root, className)} {...rest} my={3}>
        <Typography className={classes.stepDescription} variant='body2'>
          Disegna la planimetria generale dell&apos; impianto
        </Typography>
      </Box>
      <Box height='60vh'>
        <Planimetry
          plantType={plantType}
          className={classes.canvaContainer}
          distribution={distribution}
          saveOrientation={saveOrientation}
          deleteOrientation={deleteOrientation}
          modifyOrientation={modifyOrientation}
          devices={devices}
          orientations={orientations}
          importedCanvas={planimetry}
          saveCanvas={saveCanvas}
        />
      </Box>
    </>
  )
}
export default PlantPlanimetry
