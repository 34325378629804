import { memo } from 'react'
import clsx from 'clsx'
import { makeStyles, useMediaQuery } from '@material-ui/core'
import Chart from 'react-apexcharts'
import { europeNum } from 'src/utils/general'
import LoadingCard from 'src/components/LoadingCard'
import { alog } from 'src/utils/apioLog'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  }
}))

function EnergyRadiationGraph ({ data = {}, width = null, height = null, className, ...rest }) {
  const classes = useStyles()
  const isExtraSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  const dataNormalize = function (dataSet, options) {
    // Aggiungo il primo asse y (energia prodotta)
    options.yaxis.push({
      show: !isExtraSmall,
      seriesName: Object.keys(dataSet).length > 0 ? dataSet.power.series[0].name : '',
      decimalsInFloat: 3,
      // max: Object.keys(dataSet).length > 0 ? dataSet.axis.yMax : 0,
      labels: {
        minWidth: '100%',
        style: {
          colors: Object.keys(dataSet).length > 0 ? dataSet.axis.color : ''
        },
        formatter: (value) => { return value ? Math.floor(Number(value)) + ' kW' : '' }
      }
    })
    if (Object.keys(dataSet).length > 0) {
      for (let s = 0; s < dataSet.power.series.length; s++) {
        options.colors.push(dataSet.power.series[s].color)

        /* options.yaxis.push({
          seriesName: dataSet.energy.series[0].name,
          decimalsInFloat: 3,
          show: false,
          labels: {
            style: {
              colors: dataSet.axis.color
            },
            formatter: (value) => { return value ? Math.floor(Number(value)) + ' kWh' : '' }
          }
        }) */

        if (options.series === undefined) {
          options.series = []
        }
        options.series.push({
          name: dataSet.power.series[s].name,
          type: (dataSet.power.series[s] && dataSet.power.series[s].type) || 'area',
          data: dataSet.power.series[s].data
        })
      }
    }

    options.yaxis.push({
      // seriesName: Object.keys(dataSet).length > 0 ? dataSet.radiation.series[0].name : '',
      decimalsInFloat: 3,
      opposite: true,
      min: 0,
      show: !isExtraSmall,
      labels: {
        style: {
          colors: Object.keys(dataSet).length > 0 ? dataSet.axis.color : ''
        },
        formatter: (value) => {
          return value ? europeNum(value) + ` W/m${String.fromCodePoint(0x00B2)}` : ''
          // return value ? Math.floor(Number(value)) + ` kW/m${String.fromCodePoint(0x00B2)}` : ''
        }
      }
    })
    if (Object.keys(dataSet).length > 0) {
      for (let s = 0; s < dataSet.radiation.series.length; s++) {
        options.colors.push(dataSet.radiation.series[s].color)

        /* options.yaxis.push({
          seriesName: dataSet.radiation.series[0].name,
          decimalsInFloat: 3,
          opposite: true,
          show: false,
          labels: {
            style: {
              colors: dataSet.axis.color
            },
            formatter: (value) => {
              return value ? europeNum(value) + ` kW/m${String.fromCodePoint(0x00B2)}` : ''
            }
          }
        }) */
        if (options.series === undefined) {
          options.series = []
        }
        const normalizedData = dataSet.radiation.series[s].data
        // normalizedData[0] = 1
        options.series.push({
          name: dataSet.radiation.series[s].name,
          type: (dataSet.radiation.series[s] && dataSet.radiation.series[s].type) || 'area',
          data: normalizedData
        })
      }
    }

    // options.xaxis.min = Object.keys(dataSet).length > 0 ? dataSet.radiation.series[0].x : null
    // options.xaxis.max = Object.keys(dataSet).length > 0 ? dataSet.radiation.series[dataSet.radiation.series.length - 1].x : null

    return options
  }

  const options = {
    chart: {
      width: width || '100%',
      height: height || '300px',
      foreColor: '#fff',
      // id: 'energyRadiation',
      // group: 'energy',
      type: 'area',
      animations: {
        enabled: false
      },
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    labels: Object.keys(data).length > 0 ? data.labels : [],
    colors: [],
    series: undefined,
    tooltip: {
      theme: 'dark',
      x: {
        show: false,
        format: 'dd/MM/yyyy HH:mm'
      },
      y: {
        show: false,
        formatter: function (value, { seriesIndex }) {
          if (seriesIndex === 1) {
            return value !== undefined && value !== null ? `${europeNum(value)} W/m${String.fromCodePoint(0x00B2)}` : '-'
          }

          return value !== undefined && value !== null ? `${europeNum(value)} kW` : '-'
        }
      }
    },
    yaxis: [],
    xaxis: {
      // type: 'category',
      // categories: [],
      tickAmount: 40,
      labels: {
        hideOverlappingLabels: true,
        show: !isExtraSmall
      }
    },
    legend: {
      labels: {
        colors: Object.keys(data).length > 0 ? data.axis.color : '',
        useSeriesColors: false
      }
    }
  }

  const normalizedOptions = Object.keys(data).length > 0 ? dataNormalize(data, options) : options

  // console.log('normalized: radiation: ', JSON.stringify(normalizedOptions))
  /* const fixedSeries = [{
    type: 'area',
    // data: [2, 34, 12, 3, 4, 5, 2, 3, 3, 2, 5, 1, 8, 6, 7, 8, 6, 9, 23, null, null, null, null, null],
    data: [0, 0, 0, 0, 0, 0, 0.23000000001047738, 4.430000000007567, 9.699999999989814, 12.510000000002037, 24.909999999996217, 32.01999999998952, 32.52999999999156, 4.369999999995343, null, null, null, null, null, null, null, null, null, null],
    color: '#fce903',
    name: 'Energia Prodotta'
  }, {
    type: 'area',
    data: [1, 0, 0, 0, 0, 0, 0, 0, 0.11, 0.20, 0.30, 0.40, 0.50, 0.20, null, null, null, null, null, null, null, null, null, null],
    // data: [2, 3, 4, 5, 3, 2.0004, 1, 2, 3, 8, 6, 7, 8, 5, 6, 9, 34, 12, 23, null, null, null, null, null],
    color: '#8bb9dd',
    name: 'Irraggiamento'
  }] */

  alog('normalizedOptions: ', normalizedOptions, 'radiationgraph')
  // console.log('series - fixed: ', fixedSeries)
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {Object.keys(data).length > 0
        ? (
          <Chart
            options={normalizedOptions || options}
            // series={fixedSeries}
            series={normalizedOptions.series || undefined}
            type={(normalizedOptions.chart && normalizedOptions.chart.type) || 'area'}
            height={(normalizedOptions.chart && normalizedOptions.chart.height) || 0}
            width={(normalizedOptions.chart && normalizedOptions.chart.width) || 0}
          />
          )
        : (
          <LoadingCard />
          )}
    </div>
  )
}
export default memo(EnergyRadiationGraph)
