import { useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import { Box, Button, Card, Chip, Grid, makeStyles, Tooltip, Typography } from '@material-ui/core'
import { Filter as FilterIcon, ChevronRight, ChevronLeft } from 'react-feather'
import StringPRChart from 'src/components/charts/StringPRChart'
import LinearRegressionChart from 'src/components/charts/LinearRegressionChart'
import StringAnalysisChart from 'src/components/charts/StringAnalysisChart'
import { decodeLinearRegressionGraphFromApi, decodeTrendGraph, getAggregationTypeFromPeriod, addStringAnalysisToDeviation, decodeStringAnalysisFromApi, normalizeElementsFilter } from '../utils'
import SidebarFilters from './SidebarFilters'
import { useSelector } from 'src/store'
import TrendOverTimeGraph from 'src/components/charts/TrendOverTimeGraph'
import { alog } from 'src/utils/apioLog'
import { baseValues, formatPeriod, getDashboardTypes } from './SidebarFilters/FiltersContent/utils'
import useAuth from 'src/hooks/useAuth'
import api from 'src/utils/api'
import { useSnackbar } from 'notistack'
import LoadingCard from 'src/components/LoadingCard'
import moment from 'moment'
import { getDatesFromPeriod } from 'src/views/overview/AnalyticsView/pv/utils'
import { useClientRect } from 'src/hooks/useClientRect'
import PeriodModal from './PeriodModal'
// import { stringsPrData as stringsMock } from 'src/__mocks__/plantAnalytics'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: `calc(100% - 48px - ${theme.spacing(6)}px - 80px)`
  },
  loading: {
    width: '100%'
  },
  section: {
    padding: theme.spacing(1, 2),
    width: '100%',
    height: '100%'
  },
  sectionTitle: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main
  },
  topMargin: {
    marginTop: (theme.spacing(2) - 4)
  },
  smallViewport: {
    overflowY: 'auto',
    height: '100%'
  },
  filterTitle: {
    color: theme.palette.common.white
  },
  chip: {
    backgroundColor: theme.palette.secondary.main,
    margin: theme.spacing(1, 2, 1, 0)
  }
}))

function Analytics ({ className, ...rest }) {
  const classes = useStyles()
  const { baseDate, baseDashboardType, getBaseFilter } = baseValues
  const { user, updateAnalyticsFilters: ctxUpdateUserAnalyticsFilters } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const { inverters, orientations, energyMeters, sunMeters, uuid: plantId } = useSelector(state => state.pvPlantView)
  const [filterOpen, setFilterOpen] = useState(false)
  const [dashboardType, setDashboardType] = useState(baseDashboardType)
  const [currentPeriod, setCurrentPeriod] = useState(baseDate)
  const [startDate, setStartDate] = useState(moment().subtract(1, 'days'))
  const [endDate, setEndDate] = useState(moment())
  const [currentFilter, setCurrentFilter] = useState(getBaseFilter())
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingStringAnalysisData, setIsLoadingStringAnalysisData] = useState(false)
  const [openPeriodModal, setOpenPeriodModal] = useState(false)

  // const isSmall = useMediaQuery(theme => theme.breakpoints.down('lg'))
  // Ref per calcolare l'altezza dei grafici
  const [rect, ref] = useClientRect()

  // Stato per i grafici
  const [regressionData, setRegressionData] = useState({ regression: [], stringAnalysis: [], stringAnalysisPeriod: formatPeriod(moment().subtract(1, 'days'), moment()) })
  // const [prTotalData, setPrTotalData] = useState({})
  const [stringAnalysisData, setStringAnalysisData] = useState([])
  const [trend, setTrend] = useState([])

  const [isLoadingStringsPrData, setIsLoadingStringsPrData] = useState(true)
  const [stringsPrStartDate, setStringsPrStartDate] = useState(moment().subtract(30 * 6, 'days'))
  const [stringsPrEndDate, setStringsPrEndDate] = useState(moment())
  const [stringsPrCurrentPeriod, setStringsPrCurrentPeriod] = useState(`${stringsPrStartDate.format('DD/MM')} - ${stringsPrEndDate.format('DD/MM')}`)
  const [openStringsPrPeriodModal, setOpenStringsPrPeriodModal] = useState(false)
  const [stringsPrData, setStringsPrData] = useState([])

  const getData = useCallback(async (type, timeFrom, timeTo, filter) => {
    const config = {}
    // Preparo l'oggetto config ad ospitare il numero corretto di grafici
    filter[type].forEach((el, index) => {
      // console.log(el)
      if (el.elements && el.elements.length > 0) {
        if (type === 'trend') {
          const properties = el.elements
          const elements = [...new Set(properties.map(prop => prop.deviceId))]
            .map(id => ({
              properties: properties.filter(el => el.deviceId === id).filter(el => el.selected === true).map(el => el.name),
              resourceType: properties.find(el => el.deviceId === id) ? properties.find(el => el.deviceId === id).deviceType : 'device',
              resourceId: id
            }))

          alog('elements', elements, 'getData')
          config[`graph${index + 1}`] = {
            aggregationType: el.aggregationType || getAggregationTypeFromPeriod(timeFrom, timeTo),
            elements
            /* elements: el.elements.map(element => ({
              properties: (element.properties && element.properties.filter(el => el.selected === true).map(el => el.name)) || [],
              resourceType: element.deviceType && element.deviceType.category ? 'device' : 'orientation',
              resourceId: element.uuid
            })) */
          }
        } else {
          config[`graph${index + 1}`] = {
            aggregationType: getAggregationTypeFromPeriod(timeFrom, timeTo),
            elements: el.elements.map(element => ({
              // Se ho scelto la configurazione dell'intero impianto
              resourceType: element.uuid === 'plant'
                ? 'plant'
                : element.deviceType && element.deviceType.category ? element.deviceType.category.toLowerCase() : 'orientation',
              // Se ho scelto la configurazione dell'intero impianto
              resourceId: element.uuid === 'plant'
                ? plantId
                : element.uuid
            }))
          }
        }
      }
    })

    alog('config', config, 'getDataconfig')
    // Oggetto che contiene i parametri per effettuare la chiamata API
    const paramsObj = {
      type,
      timeFrom,
      timeTo,
      plantId,
      config
    }
    const params = JSON.stringify(paramsObj)
    // console.log(type, timeFrom, timeTo, filter)
    if (plantId) {
      try {
        // const response = await axios.get(`/api/plant/plantId/analytics?type=${type}`)
        const response = await api.getResource('plantDetails', { path: `/${plantId}/analytics?q=${params}` })
        alog('getData', response, 'response')
        // const dataObj = response.data
        return response
      } catch (e) {
        alog('getData', e, 'error')
        return null
      }
    }
  }, [plantId])

  const getStringPrData = useCallback(async (timeFrom, timeTo) => {
    // console.log('----------- timeFrom', timeFrom)
    // console.log('----------- timeTo', timeTo)
    // Oggetto che contiene i parametri per effettuare la chiamata API
    const params = {
      timeFrom: timeFrom.toISOString(),
      timeTo: timeTo.toISOString()
      // orientationId
    }
    // console.log(type, timeFrom, timeTo, filter)
    if (plantId) {
      try {
        // const response = await axios.get(`/api/plant/plantId/analytics?type=${type}`)
        const response = await api.getResource('plantDetails', { path: `/${plantId}/analytics/stringpr`, params })
        alog('getStringPrData', response, 'response')
        // const dataObj = response.data
        return response?.data || response
      } catch (e) {
        alog('getStringPrData', e, 'error')
        return null
      }
    }
  }, [plantId])

  // Funzione che setta i dati dell'analisi di stringa in base al periodo selezionato nel grafico di regressione lineare
  const changeStringAnalysisPeriod = async (selectedISODate) => {
    setIsLoadingStringAnalysisData(true)
    if (!selectedISODate) {
      setIsLoading(true)
      try {
        const completeFilter = addStringAnalysisToDeviation(currentFilter, 'deviation')
        const deviationStringData = await getData('stringAnalysis', startDate, endDate, completeFilter)
        // setto i dati per i grafici di analisi di stringa
        if (deviationStringData) {
          const newStringAnalysis = decodeStringAnalysisFromApi(deviationStringData)
          /* const newStringAnalysis = Object.keys(deviationStringData)
            .map(key => ({ ...deviationStringData[key] }))
            .map(element => Object.keys(element)
              .map(elKey => ({ ...element[elKey] }))).flat() */
          alog('comparison', { stringAnalysisData, newStringAnalysis }, 'changeStringAnalysisPeriod')
          // setStringAnalysisData(newStringAnalysis)
          setRegressionData(prevRegressionData => ({
            ...prevRegressionData,
            stringAnalysis: newStringAnalysis,
            stringAnalysisPeriod: moment(startDate).format('DD/MM/YYYY')
          }))
          alog('setStringAnalysisData - 3', newStringAnalysis, 'setStringAnalysisData')
        }
      } catch (e) {
        return null
      }
      setIsLoading(false)
    } else {
      const { minDate, maxDate } = getDatesFromPeriod('live', moment(selectedISODate))
      try {
        const completeFilter = addStringAnalysisToDeviation(currentFilter, 'deviation')
        const deviationStringData = await getData('stringAnalysis', minDate, maxDate, completeFilter)
        // setto i dati per i grafici di analisi di stringa
        if (deviationStringData) {
          const newStringAnalysis = decodeStringAnalysisFromApi(deviationStringData)
          /* const newStringAnalysis = Object.keys(deviationStringData)
            .map(key => ({ ...deviationStringData[key] }))
            .map(element => Object.keys(element)
              .map(elKey => ({ ...element[elKey] }))).flat() */
          alog('comparison', { stringAnalysisData, newStringAnalysis }, 'changeStringAnalysisPeriod')
          // setStringAnalysisData(newStringAnalysis)
          setRegressionData(prevRegressionData => ({
            ...prevRegressionData,
            stringAnalysis: newStringAnalysis,
            stringAnalysisPeriod: moment(minDate).format('DD/MM/YYYY')
          }))
          alog('setStringAnalysisData - 4', newStringAnalysis, 'setStringAnalysisData')
        }
      } catch (e) {
        return null
      }
    }
    setIsLoadingStringAnalysisData(false)
  }

  useEffect(() => {
    const initialiseView = async () => {
      if (dashboardType === 'deviation') {
        const completeFilter = addStringAnalysisToDeviation(currentFilter, dashboardType)
        try {
          alog('currentFilter', currentFilter, 'getDataconfig')
          alog('completeFilter', completeFilter, 'getDataconfig')
          setIsLoading(true)
          setIsLoadingStringAnalysisData(true)
          const data = await getData(dashboardType, startDate, endDate, completeFilter)
          const deviationStringData = await getData('stringAnalysis', startDate, endDate, completeFilter)
          // const data = await getData(dashboardType, startDate, endDate, currentFilter)
          // Se sto visualizzando una view di scostamento, devo aggiungere la chiamata di stringAnalysis
          // const deviationStringData = await getData('stringAnalysis', startDate, endDate, completeFilter)
          const { baselinePr, realPr, baselineProduction, realProduction, ...rest } = data

          // Preparo i dati per il grafico di regressione
          const linearRegressionData = decodeLinearRegressionGraphFromApi(rest)
          // Preparo i dati per il grafico di analisi di stringa
          const newStringAnalysis = decodeStringAnalysisFromApi(deviationStringData)
          // setto i dati per il grafico di regressione e per i grafici di analisi di stringa
          if (linearRegressionData && newStringAnalysis.length > 0) {
            setRegressionData({
              regression: linearRegressionData,
              stringAnalysis: newStringAnalysis,
              stringAnalysisPeriod: formatPeriod(moment(startDate), moment(endDate))
            })
            // setRegressionData(linearRegressionData)
            // setStringAnalysisData(newStringAnalysis)
          }
          setIsLoading(false)
          setIsLoadingStringAnalysisData(false)
          // setto i dati per il grafico radial
          /* if (baselinePr && realPr && baselineProduction && realProduction) {
            setPrTotalData(decodeTotalPrGraphFromApi({
              baselinePr,
              realPr,
              baselineProduction,
              realProduction
            }))
          } */
        } catch (e) {
          return null
        }
      } else if (dashboardType === 'stringAnalysis') {
        setIsLoading(true)
        const data = await getData(dashboardType, startDate, endDate, currentFilter)
        if (data) {
          const newStringAnalysis = decodeStringAnalysisFromApi(data)
          setStringAnalysisData(newStringAnalysis)
        }
        setIsLoading(false)
      } else if (dashboardType === 'trend') {
        setIsLoading(true)
        const data = await getData(dashboardType, startDate, endDate, currentFilter)
        if (data) {
          const newTrend = Object.keys(data).map((key, index) => decodeTrendGraph({ ...data[key] }, currentFilter.trend[index]))
          setTrend(newTrend)
        }
        setIsLoading(false)
      }
    }

    if (dashboardType && startDate && endDate && currentFilter) {
      initialiseView()
    }
  }, [dashboardType, startDate, endDate, currentFilter, getData])

  useEffect(() => {
    const updatePrView = async () => {
      try {
        setIsLoadingStringsPrData(true)
        const newStringsPrData = await getStringPrData(stringsPrStartDate, stringsPrEndDate)
        console.log('----------- newStringsPrData', newStringsPrData)

        setStringsPrData(newStringsPrData)
        setIsLoadingStringsPrData(false)
      } catch (e) {
        return null
      }
    }

    if (dashboardType && dashboardType === 'deviation' && stringsPrStartDate && stringsPrEndDate) {
      updatePrView()
    }
  }, [dashboardType, stringsPrStartDate, stringsPrEndDate, getStringPrData])

  // Funzione che elimina un filtro utente
  const deleteFilter = async (filterId) => {
    try {
      const userFilters = user.metadata.analyticsFilters || []
      const newUserFilters = userFilters.filter(el => el.uuid !== filterId)
      const response = await api.deleteResource('settings', { path: `/${filterId}`, fullResponse: true })
      if (response?.status) {
        ctxUpdateUserAnalyticsFilters(newUserFilters)
      }
      enqueueSnackbar('Filtro eliminato con successo!', { variant: 'success' })
    } catch (e) {
      enqueueSnackbar('Errore durante l\'eliminazione del filtro', { variant: 'error' })
    }
  }

  // Funzione che modifica e salva un filtro utente
  const editFilters = async (filter, name, type, startDate, endDate) => {
    const newFilter = {
      ...filter
    }

    const filterWithNormalizedElements = type === 'trend' ? newFilter : normalizeElementsFilter(newFilter, type)

    filterWithNormalizedElements.startDate = moment(startDate).toISOString()
    filterWithNormalizedElements.endDate = moment(endDate).toISOString()
    if (name) {
      filterWithNormalizedElements.name = name
    }

    alog('editFilters', filter, 'EDIT')
    alog('newEditFilters', filterWithNormalizedElements, 'EDIT')

    const newSetting = {
      data: filterWithNormalizedElements
    }
    try {
      if (filter?.uuid) {
        const { data: newFilter } = await api.putResource('settings', { path: `/${filter.uuid}`, body: newSetting })
        if (newFilter) {
          const newUserFilters = user?.metadata?.analyticsFilters || []
          const newAnalyticsFilters = newUserFilters.map(el => el.uuid === newFilter.uuid ? newFilter : el)
          ctxUpdateUserAnalyticsFilters(newAnalyticsFilters)
        }
        enqueueSnackbar('Filtro modificato con successo!', { variant: 'success' })
      }
    } catch (e) {
      enqueueSnackbar('Errore durante la modifica del filtro', { variant: 'error' })
    }
  }

  // Funzione che crea e salva un filtro utente
  const saveFilters = async (filter, name, type, startDate, endDate) => {
    let filterCopy = { ...filter }
    // In caso sto salvando un filtro di tipo deviation, devo aggiungere anche il filtro stringAnalysis per via della doppia chiamata
    if (type === 'deviation') {
      filterCopy = addStringAnalysisToDeviation(filter, type)
    }

    // Rimuovo le informazioni non utilizzate dai devices all'interno dei filtri
    const filterWithNormalizedElements = type === 'trend' ? filterCopy : normalizeElementsFilter(filterCopy, type)

    // Creo l'oggetto che rappresenta il filtro
    const newFilter = {
      ...filterWithNormalizedElements,
      name,
      type,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      plantId
    }

    // Creo l'oggetto che rapprensenta la setting
    const newSetting = {
      name: `analyticsFilters.${type}`,
      data: newFilter
    }

    try {
      const { uuid: settingId, data: newFilter } = await api.postResource('settings', { body: newSetting }) || {}
      if (newFilter) {
        const newUserFilters = user?.metadata?.analyticsFilters || []
        const newAnalyticsFilters = [...newUserFilters, { ...newFilter, uuid: settingId }]
        ctxUpdateUserAnalyticsFilters(newAnalyticsFilters)
      }
      enqueueSnackbar('Filtro creato con successo!', { variant: 'success' })
    } catch (e) {
      enqueueSnackbar('Errore durante la creazione del filtro', { variant: 'error' })
    }

    alog('filtercopy', filterCopy, 'savefilter')
  }

  // Funzione che prende in ingresso il filtro corrente e crea la stringa contenente gli elementi presi in considerazione dal filtro
  const getElements = (filter) => {
    const currentFilterView = filter[dashboardType]
    if (currentFilterView) {
      // Ricreo un array contenente tutti gli elementi di ogni grafico dei filtri
      const currentElements = currentFilterView.map(currFilter => currFilter.elements).flat()
      const uniqueElements = [...new Set(currentElements.filter(el => (el && el !== undefined)).map(el => el.uuid))].map(uuid => currentElements.find(el => el.uuid === uuid))

      return dashboardType === 'trend' ? currentElements.map(el => el.displayName || '').join(', ') : uniqueElements.map(el => (el.name || '')).join(', ')
    }
  }

  const graphHeight = rect ? rect.height - 220 : '100%'

  alog('graphHeight: ', graphHeight, 'graphHeight')
  // funzione che in base al tipo di dashboard da visualizzare restituisce i grafici corretti
  const getCurrentDashboard = (viewType) => {
    switch (viewType) {
      case 'deviation': {
        return (
          <Box style={{ minHeight: '98%', paddingBottom: 40 }}>
            <Grid container spacing={2} style={{ height: '98%', overflowY: 'auto' }}>
              <Grid item xs={12} sm={6} style={{ minHeight: '98%', overflowY: 'auto' }}>
                <Card className={classes.section} style={{ height: '98%' }}>
                  <Typography className={classes.sectionTitle} variant='h6'>Regressioni lineari</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box mt={1} width='100%' height='100%' display='flex' alignItems='center' justifyContent='center'>
                        {!isLoading
                          ? (
                            <LinearRegressionChart
                              isPlant={getElements(currentFilter).toLowerCase().includes('impianto')}
                              isInverter={getElements(currentFilter).toLowerCase().includes('inverter')}
                              height={graphHeight < 300 ? 300 : graphHeight}
                              onDataPointClick={changeStringAnalysisPeriod}
                              data={regressionData.regression}
                            />)
                          : (
                            <Box width='100%' height='100%' display='flex' alignItems='center' justifyContent='center'>
                              <LoadingCard className={classes.loading} size={40} />
                            </Box>)}
                      </Box>
                    </Grid>
                    {/* <Grid item xs={12} md={4} xl={3}>
                      <Box width='100%' height='100%' display='flex' alignItems='center' justifyContent='center'>
                        <TotalPrGraph data={prTotalData} />
                      </Box>
                    </Grid> */}
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} style={{ height: '98%', overflowY: 'auto' }}>
                {(!isLoadingStringAnalysisData) && (regressionData.stringAnalysis && regressionData.stringAnalysis.length > 0)
                  ? regressionData.stringAnalysis.map((el, elIndex) => (
                    <Card key={`deviation-stringAnalysis-${elIndex}`} className={classes.section} style={{ height: '98%', paddingBottom: '48px', overflowY: 'hidden', marginBottom: '8px' }}>
                      <Grid container spacing={2}>
                        {el.map((singleGraph, singleGraphIndex) => (
                          <Grid key={`deviation-stringAnalysisGraph-${singleGraphIndex}`} item xs={12}>
                            <Typography variant='h6' className={classes.sectionTitle}>
                              {singleGraph && singleGraph.orientation && singleGraph.orientation.name
                                ? `Analisi di stringa: ${singleGraph.orientation.name} - ${regressionData.stringAnalysisPeriod}`
                                : ''}
                            </Typography>
                            <Box width='100%' height='100%' display='flex' alignItems='center' justifyContent='center'>
                              <StringAnalysisChart height={graphHeight < 280 ? 280 : el.length > 1 ? graphHeight * 0.5 : graphHeight} data={singleGraph} />
                            </Box>
                          </Grid>))}
                      </Grid>
                    </Card>))
                  : (
                    <Box width='100%' height='100%' display='flex' alignItems='center' justifyContent='center'>
                      <LoadingCard className={classes.loading} size={40} />
                    </Box>)}
              </Grid>
              <Grid item xs={12} style={{ height: '98%', overflowY: 'auto' }}>
                {stringsPrCurrentPeriod
                  ? (
                    <Box width='100%' display='flex' alignItems='center'>
                      <Typography className={classes.filterTitle} variant='h6' style={{ marginRight: 10 }}>Periodo analisi PR stringa</Typography>
                      <Chip
                        size='small'
                        className={classes.chip}
                        label={stringsPrCurrentPeriod}
                        color='secondary'
                        onClick={() => setOpenStringsPrPeriodModal(true)}
                        deleteIcon={moment().diff(stringsPrEndDate, 'days') > 0 ? <ChevronRight style={{ cursor: 'pointer' }} size={18} color='#e0e0e0' /> : null}
                        {...{
                          onDelete: moment().diff(stringsPrEndDate, 'days') > 0 ? () => nextOrPrevStringsPrPeriod('next', stringsPrStartDate, stringsPrEndDate) : null
                        }}
                        icon={
                          <ChevronLeft
                            size={18}
                            onClick={(e) => {
                              // serve stoppare la propagazione dell'evento per impedire
                              // anche l'apertura del calendario invece che la chiamata alla funzione di cambio periodo
                              e.stopPropagation()
                              nextOrPrevStringsPrPeriod('prev', stringsPrStartDate, stringsPrEndDate)
                            }}
                          />
                        }
                      />
                    </Box>)
                  : null}
                {isLoadingStringsPrData
                  ? (
                    <Box width='100%' height='100%' display='flex' alignItems='center' justifyContent='center'>
                      <LoadingCard className={classes.loading} size={40} />
                    </Box>
                    )
                  : stringsPrData && stringsPrData.length > 0
                    ? stringsPrData.map((orientation, orIndex) => (
                      <Card key={`deviation-stringPr-${orIndex}`} className={classes.section} style={{ height: '98%', paddingBottom: '48px', overflowY: 'hidden', marginBottom: '8px' }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography variant='h6' className={classes.sectionTitle}>
                              {orientation?.orientation?.name
                                ? `PR stringhe: ${orientation.orientation.name}`
                                : ''}
                            </Typography>
                            <Box width='100%' height='100%' display='flex' alignItems='center' justifyContent='center'>
                              <StringPRChart
                                height={graphHeight < 300 ? 300 : graphHeight}
                                data={orientation?.strings}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Card>))
                    : (
                      <Card className={classes.section} style={{ marginBottom: '8px', paddingTop: '20px', paddingBottom: '20px' }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography>
                              {stringsPrData && stringsPrData.length === 0
                                ? 'Non ci sono dati'
                                : 'Errore durante il caricamento dei dati'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Card>
                      )}
              </Grid>
            </Grid>
          </Box>
        )
      }
      case 'stringAnalysis': {
        return (
          <Box>
            <Grid container spacing={2}>
              {!isLoading && (stringAnalysisData && stringAnalysisData.length > 0)
                ? stringAnalysisData.map((el, elIndex) => (
                  <Grid key={`stringAnalysis-${elIndex}`} item xs={12}>
                    <Card className={classes.section}>
                      <Grid container spacing={2}>
                        {el.map((singleGraph, singleGraphIndex) => (
                          <Grid key={`stringAnalysisGraph-${singleGraphIndex}`} item xs={el.length > 1 ? 6 : 12}>
                            <Typography variant='h6' className={classes.sectionTitle}>
                              {singleGraph && singleGraph.orientation && singleGraph.orientation.name
                                ? `Analisi di stringa: ${singleGraph.orientation.name}`
                                : ''}
                            </Typography>
                            <Box width='100%' height='100%' display='flex' alignItems='center' justifyContent='center'>
                              <StringAnalysisChart
                                data={singleGraph}
                                height={
                                  // l'altezza del grafico non deve essere minore di 280
                                  graphHeight < 280
                                    ? 280
                                    // se ci sono 1 o 2 grafici in totale, l'altezza deve essere al massimo della pagina
                                    : stringAnalysisData.length < 2
                                      ? graphHeight
                                      : graphHeight * 0.5
                                }
                              />
                            </Box>
                          </Grid>
                        )
                        )}
                      </Grid>
                    </Card>
                  </Grid>))
                : (
                  <Box width='100%' height='100%' display='flex' alignItems='center' justifyContent='center'>
                    <LoadingCard className={classes.loading} size={40} />
                  </Box>)}
            </Grid>
          </Box>
        )
      }
      case 'trend': {
        return (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card className={classes.section}>
                  <Grid container spacing={2}>
                    {!isLoading && trend.length > 0
                      ? trend.map((el, elIndex) => currentFilter[dashboardType][elIndex].show === true
                          ? (
                          <Grid item xs={12} key={`trend-${elIndex}`}>
                            <Typography className={classes.sectionTitle} variant='h6'>{`Andamento nel tempo ${elIndex + 1}`}</Typography>
                            <Box width='100%' height='100%' display='flex' alignItems='center' justifyContent='center'>
                              <TrendOverTimeGraph height={graphHeight < 300 ? 300 : trend.length > 1 ? graphHeight * 0.5 : graphHeight} data={el} />
                            </Box>
                          </Grid>)
                          : (
                          <Grid item xs={12}>
                            <Typography className={classes.sectionTitle} variant='h6'>{`Andamento nel tempo ${elIndex + 1}`}</Typography>
                            <Typography variant='body1'>Grafico non attivo</Typography>
                          </Grid>))
                      : (
                        <Box width='100%' height='100%' display='flex' alignItems='center' justifyContent='center'>
                          <LoadingCard className={classes.loading} size={40} />
                        </Box>)}
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Box>
        )
      }
      default: {
        return null
      }
    }
  }

  // funzione che in base al parametro ricevuto, porta data iniziale e data finale di un periodo indietro o avanti
  const nextOrPrevPeriod = (type, start, end) => {
    const newStartDate = type === 'prev' ? moment(start).subtract(1, 'days') : moment(start).add(1, 'days')
    const newEndDate = type === 'prev' ? moment(end).subtract(1, 'days') : moment(end).add(1, 'days')
    const newPeriod = `${newStartDate.format('DD/MM')} - ${newEndDate.format('DD/MM')}`
    setStartDate(newStartDate)
    setEndDate(newEndDate)
    setCurrentPeriod(newPeriod)
  }

  // funzione che in base al parametro ricevuto, porta data iniziale e data finale di un periodo indietro o avanti
  // uguale per string PR
  const nextOrPrevStringsPrPeriod = (type, start, end) => {
    const days = moment(end).diff(moment(start), 'days')
    const newStartDate = type === 'prev' ? moment(start).subtract(days, 'days') : moment(start).add(days, 'days')
    const newEndDate = type === 'prev' ? moment(end).subtract(days, 'days') : moment(end).add(days, 'days')
    const newPeriod = `${newStartDate.format('DD/MM')} - ${newEndDate.format('DD/MM')}`
    setStringsPrStartDate(newStartDate)
    setStringsPrEndDate(newEndDate)
    setStringsPrCurrentPeriod(newPeriod)
  }

  // console.log('regressionData: ', regressionData)
  // console.log('stringAnalysisData: ', stringAnalysisData)

  return (
    // <div className={isSmall ? clsx(classes.root, className, classes.smallViewport) : clsx(classes.root, className)} {...rest}>
    <div className={clsx(classes.root, className)} {...rest}>
      {openPeriodModal
        ? (
          <PeriodModal
            confirm={(start, end) => {
              // console.log(start, end)
              const newPeriod = `${moment(start).format('DD/MM')} - ${moment(end).format('DD/MM')}`
              setStartDate(start)
              setEndDate(end)
              setCurrentPeriod(newPeriod)
              setOpenPeriodModal(false)
            }}
            start={startDate}
            end={endDate}
            open={openPeriodModal}
            onClose={() => setOpenPeriodModal(false)}
          />)
        : null}
      {openStringsPrPeriodModal
        ? (
          <PeriodModal
            confirm={(start, end) => {
              // console.log(start, end)
              const newPeriod = `${moment(start).format('DD/MM')} - ${moment(end).format('DD/MM')}`
              setStringsPrStartDate(start)
              setStringsPrEndDate(end)
              setStringsPrCurrentPeriod(newPeriod)
              setOpenStringsPrPeriodModal(false)
            }}
            start={stringsPrStartDate}
            end={stringsPrEndDate}
            open={openStringsPrPeriodModal}
            onClose={() => setOpenStringsPrPeriodModal(false)}
          />)
        : null}
      <Box mt={2}>
        <Box width='100%' display='flex' alignItems='center'>
          <Typography className={classes.filterTitle} variant='h6'>Filtri attivi</Typography>
        </Box>
        <Grid width='100%' container spacing={2}>
          <Grid item xs={10} md={10} xl={8}>
            <Box width='100%' display='flex' alignItems='center'>
              {
                (getDashboardTypes().find(el => el.value === dashboardType)
                  ? (
                    <Chip
                      size='small'
                      className={classes.chip}
                      label={`Tipo: ${getDashboardTypes().find(el => el.value === dashboardType).label}`}
                    />)
                  : null)

              }
              {currentPeriod
                ? (
                  <Chip
                    size='small'
                    className={classes.chip}
                    label={currentPeriod}
                    color='secondary'
                    onClick={() => setOpenPeriodModal(true)}
                    deleteIcon={moment().diff(endDate, 'days') > 0 ? <ChevronRight style={{ cursor: 'pointer' }} size={18} color='#e0e0e0' /> : null}
                    {...{
                      onDelete: moment().diff(endDate, 'days') > 0 ? () => nextOrPrevPeriod('next', startDate, endDate) : null
                    }}
                    icon={
                      <ChevronLeft
                        size={18}
                        onClick={(e) => {
                          // serve stoppare la propagazione dell'evento per impedire
                          // anche l'apertura del calendario invece che la chiamata alla funzione di cambio periodo
                          e.stopPropagation()
                          nextOrPrevPeriod('prev', startDate, endDate)
                        }}
                      />
                    }
                  />)
                : null}
              {currentFilter && Object.keys(currentFilter).length > 0
                ? (
                  <Tooltip title={getElements(currentFilter)}>
                    <Chip style={{ maxWidth: 300 }} size='small' className={classes.chip} label={getElements(currentFilter)} />
                  </Tooltip>)
                : null}
            </Box>
          </Grid>
          <Grid item xs={2} xl={4}>
            <Box width='100%' display='flex' alignItems='center' justifyContent='flex-end'>
              <Button onClick={() => setFilterOpen(true)} size='small' variant='contained' color='secondary' startIcon={<FilterIcon size={14} />}>Modifica</Button>
            </Box>
            <SidebarFilters
              applyFilters={(filter, viewType, startPeriod, endPeriod) => {
                setCurrentFilter(filter)
                setDashboardType(viewType)
                setStartDate(startPeriod)
                setEndDate(endPeriod)
                setCurrentPeriod(formatPeriod(moment(startPeriod), moment(endPeriod)))
              }}
              plantId={plantId}
              editFilters={editFilters}
              saveFilters={saveFilters}
              deleteFilter={deleteFilter}
              orientations={orientations}
              inverters={inverters}
              sunMeters={sunMeters}
              energyMeters={energyMeters}
              open={filterOpen}
              onClose={() => setFilterOpen(false)}
            />
          </Grid>
        </Grid>
      </Box>
      <Box ref={ref} width='100%' height='100%' style={{ overflowY: 'auto' }}>
        {getCurrentDashboard(dashboardType)}
      </Box>
    </div>
  )
}
export default Analytics
