import { useState } from 'react'
import clsx from 'clsx'
import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, IconButton, makeStyles, TextField, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Cancel'

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 99999999
  }
}))

function OrientationDialog ({ orientationToModify, setOrientationToModify, handleModifyOrientation, handleNewOrientation, open, onClose, className }) {
  const classes = useStyles()

  const [orientationName, setOrientationName] = useState('')

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      className={clsx(classes.root, className)}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography component='div' variant='h4'>
            Imposta le caratteristiche dell&apos;orientamento
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <div>
          <TextField
            variant='outlined'
            name='orientationName'
            fullWidth
            label='Nome Orientamento'
            value={orientationToModify ? orientationToModify.name : orientationName}
            onChange={e => {
              e.persist()
              orientationToModify
                ? setOrientationToModify(prevOr => ({
                    ...prevOr,
                    name: e.target.value
                  }))
                : setOrientationName(e.target.value)
            }}
          />
          <Box my={1} width='100%'>
            <Button
              disabled={orientationToModify
                ? !(orientationToModify.name && orientationToModify.name !== '')
                : !(orientationName && orientationName !== '')}
              onClick={orientationToModify
                ? handleModifyOrientation
                : () => {
                    handleNewOrientation(orientationName)
                    setOrientationName('')
                  }}
              fullWidth
              variant='contained'
              color='primary'
            >
              {orientationToModify ? 'Modifica Orientamento' : 'Aggiungi Orientamento'}
            </Button>
          </Box>
        </div>
      </DialogContent>
    </Dialog>
  )
}
export default OrientationDialog
