import api from 'src/utils/api'

// Funzione che fa l'upload del logo di un impianto
export const loadLogo = async (logo, plantId) => {
  if (plantId && plantId !== undefined) {
    const body = new FormData()
    body.append('file', logo)
    return api.putResource('plantDetails', { body, path: `/${plantId}/logo` })
  }
}

// funzione che fa l'upload un datasheet di un device
// riceve in ingresso un array di files da caricare e l'id del device selezionato
export const loadDatasheet = async (files, deviceId) => {
  if (deviceId && deviceId !== undefined) {
    return Promise.all(
      files.map((file) => {
        const body = new FormData()
        body.append('file', file)
        return api.postResource('uploadDatasheet', { body, path: `/${deviceId}/documents` })
      })
    )
  } else {
    return false
  }
}

// funzione che fa l'upload dei documenti associati ad un tipo di device o all'impianto
export const loadDocuments = async (files, deviceType, deviceId) => {
  if (deviceType && deviceType !== undefined && deviceId && deviceId !== undefined) {
    return Promise.all(
      files.map(file => {
        const body = new FormData()
        body.append('file', file)
        return api.postResource('uploads', { body, path: `/${deviceType}/${deviceId}/documents` })
      })
    )
  } else {
    return false
  }
}

// Funzione che permette l'upload di un datasheet per un determinato modulo (per ora solo in impianti LED)
export const loadModuleDatasheet = async (files, plantId, moduleId) => {
  if (plantId && plantId !== undefined && moduleId && moduleId !== undefined) {
    return Promise.all(
      files.map(file => {
        const body = new FormData()
        body.append('file', file)
        return api.postResource('uploads', { body, path: `/plants/${plantId}/modules/${moduleId}/datasheet` })
      })
    )
  } else {
    return false
  }
}

// funzione che elimina un logo
export const deleteLogo = async (plantId) => {
  return api.deleteResource('plantDetails', { path: `/${plantId}/logo` })
}

// funzione che fa la delete dei documenti associati ad un tipo di device o all'impianto
export const deleteDocuments = async (fileIndex, deviceType, deviceId) => {
  if (fileIndex !== null && fileIndex !== undefined && deviceType && deviceType !== undefined && deviceId && deviceId !== undefined) {
    return api.deleteResource('uploads', { path: `/${deviceType}/${deviceId}/documents/${fileIndex}` })
  } else {
    return false
  }
}

export const deleteDatasheet = async (fileIndex, deviceId) => {
  if (fileIndex !== null && fileIndex !== undefined && deviceId && deviceId !== undefined) {
    return api.deleteResource('uploadDatasheet', { path: `/${deviceId}/documents/${fileIndex}` })
  } else {
    return false
  }
}

// Funzione che fa la delete del datasheet associato ad un modulo
export const removeModuleDatasheet = async (plantId, moduleId) => {
  return api.deleteResource('uploads', { path: `/plants/${plantId}/modules/${moduleId}/datasheet` })
}
