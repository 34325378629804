import createHeaders from './createHeaders'
import axios from 'axios'
import resources from './apiResourcesConfig'

/* Util che si occupa di creare una interfaccia semplificata per effettuare chiamate http */

// Funzione che gestisce l'errore di mancata autenticazione
const handleAuthError = (error) => {
  const authErrorCode = 'UNKNOWN_AUTHENTICATION_TYPE'
  const { response } = error
  const errorData = response?.data?.error || null
  if (errorData?.code === authErrorCode) {
    // window.location.href = '/login'
    setTimeout(() => {
      window.location.reload()
    }, 1000)
  }
  // console.log('error.toJSON() => ', error.toJSON())
  // console.log('Keys error => ', Object.keys(error))
  return error.toJSON()
}

// funzione che si occupa di settare i parametri iniziali di ogni chiamata
const resourcesBaseOperations = (resource, { responseType = null, params = null, auth = null, disableAuth = null, body = null, path = null, customHeaders = [] }) => {
  // creo gli headers per la chiamata
  const headers = createHeaders(params, auth, disableAuth, customHeaders, responseType)
  // url di base
  const baseUrl = resources[resource]
  // url completo
  const completeUrl = !path ? baseUrl : `${baseUrl}${path}`
  return body ? { url: completeUrl, headers, body } : { url: completeUrl, headers }
}

/*
  Tutte le funzioni di seguito ritornano i dati che restituisce la chiamata http.
  tutte le funzioni hanno le seguenti proprietà:
  - resource: Risorsa che vogliamo utilizzare (corrisponde all'url di base)
  - options: Oggetto di configurazione della chiamata

  Proprietà di options:
  - params: parametri da passare negli headers della chiamata HTTP
  - auth: parametro che sovrascrive il valore dell'header 'authorization'
  - body: body delle chiamate HTTP
  - path: continuazione del'url dopo quello di base
*/

axios.interceptors.response.use(
  (response) => response,
  (error) => handleAuthError(error)
)

// funzione che si occupa di fare chiamate GET
const getResource = async (resource, { ...options }) => {
  const { url, headers } = resourcesBaseOperations(resource, options)
  const resourceResponse = await axios.get(url, headers)
  // console.log('resourceResponse', resourceResponse)
  const { data: response, ...rest } = resourceResponse
  return options.fullResponse ? { ...rest, data: response } : response?.data ? response?.data : response
}

// funzione che si occupa di fare chiamate POST
const postResource = async (resource, { ...options }) => {
  const { url, headers, body } = resourcesBaseOperations(resource, options)
  const resourceResponse = await axios.post(url, body, headers)
  const { data: response, ...rest } = resourceResponse
  return options.fullResponse ? { ...rest, data: response } : response?.data ? response?.data : response
}

// funzione che si occupa di fare chiamate PUT
const putResource = async (resource, { ...options }) => {
  const { url, headers, body } = resourcesBaseOperations(resource, options)
  const resourceResponse = await axios.put(url, body, headers)
  const { data: response, ...rest } = resourceResponse
  return options.fullResponse ? { ...rest, data: response } : response?.data ? response?.data : response
}

// funzione che si occupa di fare chiamate DELETE
const deleteResource = async (resource, { ...options }) => {
  const { url, headers } = resourcesBaseOperations(resource, options)
  const resourceResponse = await axios.delete(url, headers)
  const { data: response, ...rest } = resourceResponse
  return options.fullResponse ? { ...rest, data: response } : response?.data ? response?.data : response
}

export default {
  getResource,
  postResource,
  deleteResource,
  putResource
}
