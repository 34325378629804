// import React from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { create } from 'jss'
import rtl from 'jss-rtl'
import MomentUtils from '@date-io/moment'
import { SnackbarProvider } from 'notistack'
import { jssPreset, StylesProvider, ThemeProvider, Slide } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import GlobalStyles from 'src/components/GlobalStyles'
import ScrollReset from 'src/components/ScrollReset'
// import CookiesNotification from 'src/components/CookiesNotification'
// import GoogleAnalytics from 'src/components/GoogleAnalytics'
// import SettingsNotification from 'src/components/SettingsNotification'
import { AuthProvider } from 'src/contexts/JWTAuthContext'
import useSettings from 'src/hooks/useSettings'
import { createTheme } from 'src/theme'
import routes, { renderRoutes } from 'src/routes'
import { getBuildDate } from './utils/getBuildDate'
import 'moment/locale/it'

import 'tscharts/dist/style.css'

const jss = create({ plugins: [...jssPreset().plugins, rtl()] })
const history = createBrowserHistory()

// utility to know the build date printed in dev console
console.log('Build date: ', getBuildDate())

const App = () => {
  const { settings } = useSettings()

  // creo il tema
  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  })

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils} locale='it'>
          <SnackbarProvider
            maxSnack={5}
            TransitionComponent={Slide}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            autoHideDuration={4000}
            // preventDuplicate
          >
            <Router history={history}>
              <AuthProvider>
                <GlobalStyles />
                <ScrollReset />
                {/* <GoogleAnalytics /> */}
                {/* <CookiesNotification /> */}
                {/* <SettingsNotification /> */}
                {renderRoutes(routes)}
              </AuthProvider>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  )
}

export default App
