import { memo } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import {
  Box,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableRow,
  makeStyles
} from '@material-ui/core'
import { customFilterContractTypes } from 'src/views/overview/MapsView/filtersUtils'

// stile CSS generale
const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%'
  },
  iconContainer: {
    marginLeft: 10
  }
}))

// componente principale
const PlantContractDataTable = ({ className, ...rest }) => {
  const classes = useStyles()
  const { anagraphic } = useSelector((state) => state.pvPlantView)

  const contractType = customFilterContractTypes.find(el => el.value === anagraphic.contractType)

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        title='Anagrafica'
        style={{ paddingTop: 14, paddingBottom: 4 }}
      />
      <Box>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Nome cliente</TableCell>
              <TableCell>{anagraphic.referentClient}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Tipo contratto</TableCell>
              <TableCell>{contractType && contractType !== undefined ? contractType.label : ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Telefono</TableCell>
              <TableCell>{anagraphic.referentPhone}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Referente A2A</TableCell>
              <TableCell>{anagraphic.referentName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Contatto O&M</TableCell>
              <TableCell>{anagraphic.operationAndMaintenaceName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Telefono</TableCell>
              <TableCell>{anagraphic.operationAndMaintenacePhone}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>{anagraphic.operationAndMaintenaceEmail}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Card>
  )
}

PlantContractDataTable.propTypes = {
  className: PropTypes.string
}

export default memo(PlantContractDataTable)
