import { useState, forwardRef, useCallback } from 'react'
import classnames from 'classnames'
import { useSnackbar, SnackbarContent } from 'notistack'
// import { useHistory } from 'react-router'
import {
  Collapse,
  Paper,
  Typography,
  Card,
  CardActions,
  Button,
  IconButton,
  SvgIcon,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  makeStyles
} from '@material-ui/core'
import { basicColors } from 'src/theme'

import {
  X as CloseIcon,
  ChevronDown as ExpandMoreIcon
} from 'react-feather'

const useStyles = makeStyles(theme => ({
  root: {
    pointerEvents: 'auto',
    [theme.breakpoints.up('sm')]: {
      minWidth: '344px !important'
    }
  },
  card: {
    width: '100%'
  },
  successBk: {
    backgroundColor: '#43a047'
  },
  warningBk: {
    backgroundColor: '#ffa41d'
  },
  errorBk: {
    backgroundColor: '#d32f2f'
  },
  lightText: {
    color: basicColors.whiteText
  },
  darkText: {
    color: basicColors.darkText
  },
  typography: {
    fontWeight: 'bold'
    // color: basicColors.darkText
  },
  actionRoot: {
    padding: '8px 8px 8px 16px',
    justifyContent: 'space-between'
  },
  icons: {
    marginLeft: 'auto'
  },
  expand: {
    padding: '8px 8px',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  collapse: {
    padding: 16
  },
  checkIcon: {
    fontSize: 20,
    color: '#b3b3b3',
    paddingRight: 4
  },
  button: {
    padding: 0,
    textTransform: 'none'
  }
}))

const AnomalySnackbarContent = forwardRef((props, ref) => {
  const classes = useStyles()
  const { closeSnackbar } = useSnackbar()
  // const history = useHistory()
  const [expanded, setExpanded] = useState(false)
  // console.log('-------------- props.variant => ', props.variant)

  const handleExpandClick = useCallback(() => {
    setExpanded((oldExpanded) => !oldExpanded)
  }, [])

  const handleDismiss = useCallback(() => {
    closeSnackbar(props.id)
  }, [props.id, closeSnackbar])

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <Card
        className={classnames(classes.card, props.variant === 'warning' ? classes.warningBk : props.variant === 'success' ? classes.successBk : props.variant === 'error' ? classes.errorBk : classes.warningBk)}
      >
        <CardActions classes={{ root: classes.actionRoot }}>
          <Typography
            variant='subtitle2'
            className={classnames(classes.typography, props.variant === 'warning' ? classes.darkText : classes.whiteText)}
          >
            {props.message}
          </Typography>
          <div className={classes.icons}>
            <IconButton
              aria-label='Mostra di più'
              className={classnames(classes.expand, { [classes.expandOpen]: expanded })}
              onClick={handleExpandClick}
            >
              <SvgIcon size='small'>
                <ExpandMoreIcon color={props.variant === 'warning' ? basicColors.darkText : basicColors.whiteText} />
              </SvgIcon>
            </IconButton>
            {props.history && props.plantId && props.plantId !== ''
              ? (
                <Button
                  onClick={() => {
                    props.history.push(`/app/view/plant/${props.plantId}?tab=anomalies&anomalyId=${props.anomalyId}`)
                    handleDismiss()
                  }}
                  style={{ color: props.variant === 'warning' ? basicColors.darkText : basicColors.whiteText }}
                >
                  Mostra
                </Button>
                )
              : ''}
            <IconButton className={classes.expand} onClick={handleDismiss}>
              <SvgIcon size='small'>
                <CloseIcon color={props.variant === 'warning' ? basicColors.darkText : basicColors.whiteText} />
              </SvgIcon>
            </IconButton>
          </div>
        </CardActions>
        {/* contenuto collassato */}
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <Paper
            className={classnames(classes.collapse, props.variant === 'warning' ? classes.warningBk : props.variant === 'success' ? classes.successBk : props.variant === 'error' ? classes.errorBk : classes.warningBk)}
            style={{ borderRadius: 0 }}
          >
            <TableContainer component={Paper}>
              <Table aria-labelledby='anomalyStatus' aria-label='Stato anomalia'>
                <TableBody>
                  <TableRow>
                    <TableCell variant='head'>Risorse coinvolte</TableCell>
                    <TableCell>
                      {props.relatedResources && props.relatedResources.length > 0
                        ? props.relatedResources.map(device => device.name).join(', ')
                        : 'Non ci sono dispositivi associati a questa anomalia'}
                    </TableCell>
                  </TableRow>
                  {props.severity
                    ? (
                      <TableRow>
                        <TableCell variant='head'>Severity</TableCell>
                        <TableCell>{props.severity}</TableCell>
                      </TableRow>
                      )
                    : null}
                  <TableRow>
                    <TableCell variant='head'>Descrizione</TableCell>
                    <TableCell>{props.description || '-'}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Collapse>
      </Card>
    </SnackbarContent>
  )
})

export default AnomalySnackbarContent
