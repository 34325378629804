import { useState } from 'react'
import clsx from 'clsx'
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  MenuItem,
  Paper,
  Radio,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core'
import { Edit as EditIcon, Trash2 as TrashIcon } from 'react-feather'
import { v4 as uuidv4 } from 'uuid'
import PerfectScrollbar from 'react-perfect-scrollbar'
import OrientationDialog from 'src/components/Planimetry/OrientationDialog'
import { HexColorPicker } from 'react-colorful'
import ConfirmationDialogRaw from 'src/components/ConfirmationDialogRaw'
import { libraryDevices } from '../../libraryDevices'

const useStyles = makeStyles(() => ({
  root: {}
}))

function GeneratorContent ({ saveOrientation, deleteOrientation, modifyOrientation, className, ...rest }) {
  const classes = useStyles()
  const { selectedObject, element, setElement, distribution, config, orientations, devices /* , additionalData */ } = rest
  // const currentOrientation = (additionalData && additionalData !== undefined && additionalData.orientation) || ''
  const currentOrientation =
    element && element !== undefined
      ? element.config.length > 0
          ? element.config[0].data && element.config[0].data.orientation
              ? element.config[0].data.orientation
              : ''
          : ''
      : ''

  const color = element && element.config.length > 0
    ? element.config[0].data
        ? element.config[0].data.draw && element.config[0].data.draw.backgroundColor
            ? element.config[0].data.draw.backgroundColor
            : '#0000FF'
        : '#0000FF'
    : '#0000FF'

  const configDevices = element && element !== undefined ? (element.config.length > 0 ? element.config[0].devices : []) : []

  // console.log('decodeFromApi - element: ', element)
  // console.log('handleRadioChange - configDevices: ', configDevices)
  const selected = configDevices && configDevices.length > 0 ? configDevices[0].deviceId : ''

  const currentPanelConfig =
    configDevices &&
    configDevices.reduce((acc, configDevice) => {
      acc[configDevice.deviceId] = configDevice.additionalData

      return acc
    }, {})

  const [selectedDevice, setSelectedDevice] = useState(selected)
  const [selectedOrientation, setSelectedOrientation] = useState(currentOrientation)
  const [currentColor, setCurrentColor] = useState(color)

  const [showOrientationNameInput, setShowOrientationNameInput] = useState(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [orientationToDelete, setOrientationToDelete] = useState(null)
  const [orientationToModify, setOrientationToModify] = useState(null)

  const inverters = devices
    ? devices
        .filter((device) => device.deviceType.category === 'Inverter')
        .sort(function (a, b) {
          if (a.metadata && b.metadata && a.metadata.inverterNum && b.metadata.inverterNum) {
          // console.log(a, b)
            return parseInt(a.metadata.inverterNum) - parseInt(b.metadata.inverterNum)
          } else {
            return 0
          }
        })
    : []
  const { rows, headerCols } = selectedDevice && selectedDevice !== '' ? distribution[selectedDevice] || {} : {}

  const handleDeleteOrientation = (confirmed, orientationUuid) => {
    if (orientationUuid && orientationUuid !== undefined) {
      if (confirmed) {
        deleteOrientation(orientationUuid)
      }
      setOrientationToDelete(null)
      setShowConfirmDialog(false)
    }
  }

  const handleModifyOrientation = () => {
    modifyOrientation(orientationToModify)
    setOrientationToModify(false)
    setShowOrientationNameInput(false)
  }

  const handleNewOrientation = (orientationName) => {
    const newOrientationId = uuidv4()

    const newOrientation = {
      uuid: newOrientationId,
      name: orientationName
    }
    saveOrientation(newOrientation)
    setSelectedOrientation(newOrientationId)
    setElement((prevElement) => {
      if (prevElement.config.length > 0) {
        prevElement.config[0].data = {
          ...prevElement.config[0].data,
          orientation: newOrientationId
        }
      } else {
        prevElement.config.push({
          devices: [],
          data: {
            orientation: newOrientationId,
            draw: {}
          }
        })
      }

      return { ...prevElement }
    })
    setShowOrientationNameInput(false)
  }

  const handleRadioChange = (checked, rowIndex, colIndex) => {
    /* const radioElement = {
      colIndex,
      checked: !checked
    } */
    const radioElement = {
      colIndex
    }
    setElement((prevEl) => {
      let prevElement = JSON.parse(JSON.stringify(prevEl))
      // device selezionato dalla select
      const currentDevice =
        prevElement && prevElement.config.length > 0
          ? prevElement.config[0].devices.find((device) => device.deviceId === selectedDevice)
          : null

      // se il device esiste
      if (currentDevice && currentDevice !== undefined) {
        // se additionalData non ha la proprietà rows la aggiungo
        if (!currentDevice.additionalData.rows) {
          currentDevice.additionalData.rows = []
        }

        // se non esiste un elemento con quell'index di riga ne creo tanti quante sono il numero di righe
        if (!currentDevice.additionalData.rows[rowIndex]) {
          while (currentDevice.additionalData.rows.length < rowIndex + 1) {
            currentDevice.additionalData.rows.push([])
          }
        }

        // console.log('handleRadioChange - rowIndex: ', rowIndex, 'colIndex: ', colIndex)
        // sovrascrivo l'elemento che rappresenta il radio button
        if (currentDevice.additionalData.rows[rowIndex]) {
          // console.log('handleRadioChange - esite la riga')
          const currentColumn = currentDevice.additionalData.rows[rowIndex].find(
            (el) => parseInt(el.colIndex) === parseInt(colIndex)
          )
          // if (currentDevice.additionalData.rows[rowIndex][colIndex - 1]) {
          if (currentColumn && currentColumn !== undefined) {
            // console.log('handleRadioChange - esiste la colonna, sto sostituendo con: ', JSON.stringify(radioElement))
            // console.log('handleRadioChange - elemento precedente: ', currentDevice.additionalData.rows[rowIndex][colIndex - 1])
            // Rimuovo l'elemento che ha colIndex
            const newColumns = currentDevice.additionalData.rows[rowIndex].filter(
              (el) => parseInt(el.colIndex) !== parseInt(colIndex)
            )
            // faccio il push del nuovo elemento con colIndex
            // newColumns.push({ ...radioElement })
            currentDevice.additionalData.rows[rowIndex] = newColumns
            // currentDevice.additionalData.rows[rowIndex][colIndex - 1] = { ...radioElement }
          } else {
            // console.log('handleRadioChange - Non esiste la colonna, sto pushando: ', JSON.stringify(radioElement))
            currentDevice.additionalData.rows[rowIndex].push({ ...radioElement })
          }
        } else {
          // console.log('handleRadioChange - Non esiste la riga, sto pushando: ', radioElement)
          currentDevice.additionalData.rows.push([{ ...radioElement }])
        }

        const newDevices = prevElement.config[0].devices.filter((device) => device.deviceId !== selectedDevice)

        newDevices.push(currentDevice)

        // console.log('handleRadioChange - changing Devices: ', newDevices)
        return {
          config: [
            {
              ...prevElement.config[0],
              devices: newDevices
            }
          ]
        }
      } else {
        // prendere il device dalla lista generale dei devices associati all'impianto e fare il push nei devices per quell'elemento di config
        const newDevice = devices && devices.find((device) => device.uuid === selectedDevice)
        if (newDevice && newDevice !== undefined) {
          const properties = {}
          const libraryDevice = libraryDevices.find((libEl) => libEl.models.includes(newDevice.deviceType.model))
          if (libraryDevice) {
            const deviceKeys = Object.keys(libraryDevice)
            deviceKeys
              .filter((key) => key !== 'models')
              .forEach((key) => {
                properties[key] = {
                  label: libraryDevice[key],
                  value: 0
                }
              })
          }

          const newRows = []
          // se non esiste un elemento con quell'index di riga ne creo tanti quante sono il numero di righe
          if (!newRows[rowIndex]) {
            while (newRows.length < rowIndex + 1) {
              newRows.push([])
            }
          }
          // altrimenti metto l'elemento corrente come elemento di quella riga
          newRows[rowIndex].push({ ...radioElement })

          const deviceAdditionalData = { rows: newRows }

          // console.log('confiiiiig: ', deviceAdditionalData)

          const elementDevice = {
            type: selectedObject.type,
            model: newDevice.deviceType.model,
            deviceId: newDevice.uuid,
            properties,
            additionalData: deviceAdditionalData
          }

          if (!prevElement) {
            prevElement = {}
          }

          if (!prevElement.config) {
            prevElement.config = []
          }

          // console.log('confiiiiig: ', prevElement.config)
          if (prevElement.config.length > 0) {
            prevElement.config[0].devices.push(elementDevice)
          } else {
            prevElement.config.push({
              devices: [elementDevice],
              data: {
                orientation: selectedOrientation,
                draw: {}
              }
            })
          }
        }

        return {
          config: [
            {
              ...prevElement.config[0]
            }
          ]
        }
      }
    })
  }

  // Metodo che verifica se un determinato inverter è pressente nell'array dei devices
  const isChecked = (inverterId) => {
    if (
      element &&
      element.config.length > 0 &&
      element.config[0].devices &&
      element.config[0].devices.find((device) => device.deviceId === inverterId) &&
      element.config[0].devices.find((device) => device.deviceId === inverterId) !== undefined
    ) {
      // console.log('element.config: ', element.config)
      return true
    } else {
      return false
    }
  }

  const tableRadioHeader =
    selectedDevice && headerCols
      ? Array(Number(headerCols) + 1)
          .fill('')
          .map((header, index) => {
            if (index === 0) {
              return <TableCell align='center' key={`empty-${index}`} />
            } else {
              return <TableCell align='center' key={`MPPT-${index}`}>{`MPPT${index}`}</TableCell>
            }
          })
      : null

  const tableRadioRows = rows
    ? rows.map((row, index) => {
        const completeRow = [0].concat(row)
        return (
          <TableRow key={`row-action-${index}`}>
            {completeRow.map((cell, cellIndex) => {
              if (cellIndex === 0) {
                return <TableCell key={`row-name-${index}`} align='center'>{`S${index + 1}`}</TableCell>
              } else {
              // console.log('handleRadioChange - currentPanelConfig: ', currentPanelConfig)
                const currentCell =
                currentPanelConfig &&
                currentPanelConfig[selectedDevice] &&
                currentPanelConfig[selectedDevice].rows &&
                currentPanelConfig[selectedDevice].rows[index] &&
                currentPanelConfig[selectedDevice].rows[index].find((el) => parseInt(el.colIndex) === parseInt(cellIndex))

                /* console.log('filter della config dell\'oggetto corrente: ', config
              .filter(el => element.config.length > 0 ? el.id !== element.config[0].id : el)) */

                const otherGeneratorsCell = config
                // rimuovo la mia configurazione dal totale delle configurazioni
                  .filter((el) => (element.config.length > 0 ? el.id !== element.config[0].id : el))
                // considero solo le configurazioni dei generatori
                  .filter((el) => el.canvasLibraryType === 'generator')
                  .map((el) => {
                  /* console.log('selectedDevice: ', selectedDevice) */
                    const currentElement =
                    el.devices && el.devices !== undefined
                      ? el.devices.find((dev) => dev.deviceId === selectedDevice)
                      : null
                    const currentRow =
                    currentElement && currentElement !== undefined
                      ? currentElement.additionalData
                          ? currentElement.additionalData.rows
                              ? currentElement.additionalData.rows.length > 0
                                  ? currentElement.additionalData.rows[index]
                                  : null
                              : null
                          : null
                      : null

                    /* console.log('otherGenerator - currentRow: ', currentRow) */
                    if (currentRow) {
                    // const existingCell = currentRow.find(cell => (cell.colIndex === cellIndex && cell.checked === true))
                      const existingCell = currentRow.find((cell) => parseInt(cell.colIndex) === parseInt(cellIndex))
                      if (existingCell) {
                        return existingCell
                      } else {
                        return null
                      }
                    } else {
                      return null
                    }
                  })
                  .filter((el) => el)
                /* console.log('otherGenerator - otherGeneratorsCell: ', otherGeneratorsCell) */
                /* console.log('otherGenerator - currentCell: ', currentCell) */

                // let isChecked = (currentCell && currentCell.checked) || false
                let isChecked = !!currentCell
                // const isDisabled = (otherGeneratorsCell && otherGeneratorsCell.length > 0 && otherGeneratorsCell[0].checked) || false
                const isDisabled = cell === 0 || !!(otherGeneratorsCell && otherGeneratorsCell.length > 0 && otherGeneratorsCell[0])
                // console.log(currentCell, 'completeRow')

                if (isDisabled) {
                  isChecked = false
                }

                return (
                  <TableCell align='center' key={`row-${index}-cell-${cellIndex}`}>
                    <Radio
                      color='primary'
                      disabled={isDisabled}
                      checked={isChecked}
                      onClick={() => handleRadioChange(isChecked, index, cellIndex)}
                    />
                  </TableCell>
                )
              }
            })}
          </TableRow>
        )
      })
    : null

  return (
    <div className={clsx(classes.root, className)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Typography variant='h4'>Generatore</Typography>
          <Box my={2}>
            <>
              {showOrientationNameInput
                ? (
                  <OrientationDialog
                    orientationToModify={orientationToModify}
                    setOrientationToModify={setOrientationToModify}
                    handleModifyOrientation={handleModifyOrientation}
                    handleNewOrientation={handleNewOrientation}
                    open={showOrientationNameInput}
                    onClose={() => {
                      setShowOrientationNameInput(false)
                    }}
                  />
                  )
                : null}
              {showConfirmDialog && orientationToDelete
                ? (
                  <ConfirmationDialogRaw
                    open={showConfirmDialog}
                    onClose={(confirmed) => handleDeleteOrientation(confirmed, orientationToDelete.uuid)}
                    title='Elimina Orientamento'
                    description={`Sei sicuro di voler eliminare l'orientamento "${orientationToDelete.name || ''}"?`}
                  />
                  )
                : null}
              <TextField
                color='primary'
                size='small'
                variant='outlined'
                select
                fullWidth
                SelectProps={{
                  renderValue: (selected) => (
                    <ListItemText style={{ marginTop: 0, marginBottom: 0 }}>
                      {orientations.find((or) => or.uuid === selected)
                        ? orientations.find((or) => or.uuid === selected).name
                        : ''}
                    </ListItemText>
                  )
                }}
                label='Seleziona Orientamento'
                value={selectedOrientation || ''}
                onChange={(e) => {
                  setSelectedOrientation(e.target.value)
                  setElement((prevElement) => {
                    if (prevElement.config.length > 0) {
                      if (!prevElement.config[0].data) {
                        prevElement.config[0].data = {}
                      }
                      prevElement.config[0].data = {
                        ...prevElement.config[0].data,
                        orientation: e.target.value
                      }
                    } else {
                      prevElement.config.push({
                        devices: [],
                        data: {
                          orientation: e.target.value,
                          draw: {}
                        }
                      })
                    }

                    return { ...prevElement }
                  })
                }}
              >
                {orientations.map((el) => (
                  <MenuItem dense key={el.uuid || el.id} value={el.uuid || el.id}>
                    <ListItemText>{el.name}</ListItemText>
                    <ListItemSecondaryAction>
                      <Tooltip title='Modifica Orientamento'>
                        <IconButton
                          onClick={() => {
                            setShowOrientationNameInput(true)
                            setOrientationToModify(el)
                          }}
                          edge='start'
                        >
                          <SvgIcon fontSize='small'>
                            <EditIcon />
                          </SvgIcon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Elimina Orientamento'>
                        <IconButton
                          onClick={() => {
                            setShowConfirmDialog(true)
                            setOrientationToDelete(el)
                          }}
                          edge='end'
                        >
                          <SvgIcon fontSize='small'>
                            <TrashIcon />
                          </SvgIcon>
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </MenuItem>
                ))}
                <Divider />
                <MenuItem dense className={classes.transparentMenuItem}>
                  <Button
                    onClick={() => {
                      setShowOrientationNameInput(true)
                      setOrientationToModify(null)
                    }}
                    fullWidth
                  >
                    Aggiungi orientamento
                  </Button>
                </MenuItem>
              </TextField>
            </>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant='h6'>Colore di sfondo</Typography>
          <Box my={2} width='100%'>
            <HexColorPicker
              style={{ height: '80px' }} color={currentColor} onChange={setCurrentColor} onMouseUp={(e) => {
                e.persist()
                setElement((prevElement) => {
                  // console.log(prevElement)
                  if (prevElement.config.length > 0) {
                    if (!prevElement.config[0].data) {
                      prevElement.config[0].data = {}
                    }
                    if (!prevElement.config[0].data.draw) {
                      prevElement.config[0].data.draw = {}
                    }
                    prevElement.config[0].data.draw = {
                      ...prevElement.config[0].data.draw,
                      backgroundColor: currentColor
                    }
                  } else {
                    prevElement.config.push({
                      devices: [],
                      data: {
                        draw: {
                          backgroundColor: currentColor
                        }
                      }
                    })
                  }
                  return { ...prevElement }
                })
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt={2}>
            <Paper variant='outlined'>
              <Box p={2}>
                <Box mb={3}>
                  <Typography variant='body2'>Seleziona le stringhe ed i relativi MPPT</Typography>
                </Box>
                <TextField
                  variant='outlined'
                  size='small'
                  name='inverter'
                  select
                  fullWidth
                  label='inverter'
                  value={selectedDevice}
                  SelectProps={{
                    renderValue: (renderSelected) => (
                      <ListItemText style={{ marginTop: 0, marginBottom: 0 }}>
                        {inverters.find((or) => or.uuid === renderSelected)
                          ? inverters.find((or) => or.uuid === renderSelected).name
                          : ''}
                      </ListItemText>
                    )
                  }}
                  onChange={(e) => {
                    e.persist()
                    setElement((prevEl) => {
                      // oggetto completo del device selezionato tramite checkbox
                      const checkedDevice = devices.find((dev) => dev.uuid === e.target.value)
                      // se l'oggetto del device selezionato esiste
                      if (checkedDevice && checkedDevice !== undefined) {
                        // creo la struttura dell'oggetto da pushare in devices
                        const properties = {}
                        const libraryDevice = libraryDevices.find((libEl) =>
                          libEl.models.includes(checkedDevice.deviceType.model)
                        )
                        if (libraryDevice) {
                          const deviceKeys = Object.keys(libraryDevice)
                          deviceKeys
                            .filter((key) => key !== 'models')
                            .forEach((key) => {
                              properties[key] = {
                                label: libraryDevice[key],
                                value: 0
                              }
                            })
                        }

                        const elementDevice = {
                          type: selectedObject.type,
                          model: checkedDevice.deviceType.model,
                          deviceId: checkedDevice.uuid,
                          properties,
                          additionalData: {
                            rows: []
                          }
                        }

                        if (prevEl.config.length > 0) {
                          if (!prevEl.config[0].devices) {
                            prevEl.config[0] = {
                              ...prevEl.config[0],
                              devices: []
                            }
                          }
                          const sameDevice = prevEl.config[0].devices.find(
                            (configEl) => configEl.deviceId === e.target.value
                          )
                          // se il device è nell'array lo rimuovo
                          if (!(sameDevice && sameDevice !== undefined)) {
                            prevEl.config[0].devices.push(elementDevice)
                          }
                        } else {
                          prevEl.config.push({
                            devices: [{ ...elementDevice }],
                            data: {}
                          })
                        }
                        return {
                          config: [
                            {
                              ...prevEl.config[0]
                            }
                          ]
                        }
                      }
                    })
                    // devo controllare se questo id è presente in devices,
                    // se non c'è faccio il push
                    setSelectedDevice(e.target.value)
                  }}
                >
                  {inverters.map((inverter) => (
                    <MenuItem dense value={inverter.uuid} key={inverter.uuid}>
                      <ListItemIcon>
                        <Checkbox
                          color='primary'
                          edge='start'
                          onClick={(e) => {
                            e.stopPropagation()
                            setElement((prevEl) => {
                              // oggetto completo del device selezionato tramite checkbox
                              const checkedDevice = devices.find((dev) => dev.uuid === inverter.uuid)
                              // se l'oggetto del device selezionato esiste
                              if (checkedDevice && checkedDevice !== undefined) {
                                // creo la struttura dell'oggetto da pushare in devices
                                const properties = {}
                                const libraryDevice = libraryDevices.find((libEl) =>
                                  libEl.models.includes(checkedDevice.deviceType.model)
                                )
                                if (libraryDevice) {
                                  const deviceKeys = Object.keys(libraryDevice)
                                  deviceKeys
                                    .filter((key) => key !== 'models')
                                    .forEach((key) => {
                                      properties[key] = {
                                        label: libraryDevice[key],
                                        value: 0
                                      }
                                    })
                                }

                                const elementDevice = {
                                  type: selectedObject.type,
                                  model: checkedDevice.deviceType.model,
                                  deviceId: checkedDevice.uuid,
                                  properties,
                                  additionalData: {}
                                }

                                if (prevEl.config.length > 0) {
                                  if (!prevEl.config[0].devices) {
                                    prevEl.config[0] = {
                                      ...prevEl.config[0],
                                      devices: []
                                    }
                                  }
                                  const sameDevice = prevEl.config[0].devices.find(
                                    (configEl) => configEl.deviceId === inverter.uuid
                                  )
                                  // se il device è nell'array lo rimuovo
                                  if (sameDevice && sameDevice !== undefined) {
                                    const newDevices = prevEl.config[0].devices.filter(
                                      (dev) => dev.deviceId !== inverter.uuid
                                    )
                                    prevEl.config[0].devices = newDevices
                                    // se il device non è nell'array lo pusho
                                  } else {
                                    prevEl.config[0].devices.push(elementDevice)
                                  }
                                } else {
                                  prevEl.config.push({
                                    devices: [{ ...elementDevice }],
                                    data: {}
                                  })
                                }
                                return {
                                  config: [
                                    {
                                      ...prevEl.config[0]
                                    }
                                  ]
                                }
                              }
                            })
                            return false
                          }}
                          checked={isChecked(inverter.uuid)}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText>{inverter.name}</ListItemText>
                    </MenuItem>
                  ))}
                </TextField>
                {selectedDevice
                  ? (
                    <Box p={-2}>
                      <PerfectScrollbar>
                        <Box minWidth={700}>
                          <Table size='small'>
                            <TableHead>
                              <TableRow>
                                {tableRadioHeader || <TableCell>Crea le distribuzioni per impostare la stringa</TableCell>}
                              </TableRow>
                            </TableHead>
                            <TableBody>{tableRadioRows || null}</TableBody>
                          </Table>
                        </Box>
                      </PerfectScrollbar>
                    </Box>
                    )
                  : null}
              </Box>
            </Paper>
          </Box>
        </Grid>
      </Grid>

    </div>
  )
}
export default GeneratorContent
