export const createGuestHeaders = (token) => {
  if (token) {
    return [{
      key: 'x-use-public-link',
      value: true
    }, {
      key: 'x-public-link-token',
      value: token
    }]
  } else {
    return []
  }
}
