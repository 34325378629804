import { useState } from 'react'
import clsx from 'clsx'
import { Avatar, Box, Button, Checkbox, Divider, FormControl, FormControlLabel, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Radio, RadioGroup, Tooltip, Typography } from '@material-ui/core'
import PropertiesModal from './PropertiesModal'
import { elementsSection, getGraphType } from './utils'
import { basicColors } from 'src/theme'
import { Trash as TrashIcon, ChevronRight as ChevronRightIcon, X as XIcon } from 'react-feather'

const useStyles = makeStyles((theme) => ({
  root: {},
  divider: {
    backgroundColor: basicColors.blueGrayLight
  },
  sectionTitle: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main
  },
  listItem: {
    fontSize: 16,
    width: 28,
    height: 28,
    marginRight: 16
  },
  buttonText: {
    fontSize: 12
  },
  radio: {
    marginTop: -8,
    marginBottom: -8
  }
}))

// Numero limite di grandezze da poter visualizzare contemporaneamente
const limitNumber = 10

function TrendContent ({
  startDate,
  endDate,
  filters,
  setFilters,
  viewType,
  devices = [],
  openPropertiesModal,
  setOpenPropertiesModal,
  className,
  ...rest
}) {
  const classes = useStyles()

  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentProperties, setCurrentProperties] = useState([])
  // Funzione che può ricevere in ingresso raw | quarter | hourly | daily e ritorna true | false
  const disableAggregation = (value) => {
    const daysDiff = endDate.diff(startDate, 'days')
    // const monthsDiff = endDate.diff(startDate, 'months')
    const hoursDiff = endDate.diff(startDate, 'hours')
    /* // Se il periodo è superiore a un mese, non posso richiedere aggregazione giornaliera
    if (value === 'daily') {
      return monthsDiff > 1
    } */
    // Se il periodo è superiore ad una settimana, non posso richiedere aggregazione oraria
    if (value === 'hourly') {
      return daysDiff > 7
    }
    // Se il periodo è superiore ad un giorno, non posso richiedere aggregazione quartoraria
    if (value === 'quarter') {
      return daysDiff > 1
    }
    // Se il periodo è superiore a 3 ore, non posso richiedere aggregazione oraria
    if (value === 'raw') {
      return hoursDiff > 3
    }
    return false
  }

  // funzione che al cambiodel tipo di aggregazione aggiorna l'elemento del filtro corrispondente
  const changeAggregationType = (aggregationValue, aggregationIndex) => {
    setFilters(prevFilters => {
      const newFilter = {
        ...prevFilters[viewType][aggregationIndex],
        aggregationType: aggregationValue
      }

      const newFilters = prevFilters[viewType].map((el, index) => index === aggregationIndex ? newFilter : el)
      return {
        ...prevFilters,
        [viewType]: newFilters
      }
    })
  }

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {openPropertiesModal
        ? (
          <PropertiesModal
            currentProperties={currentProperties}
            devices={devices}
            open={openPropertiesModal}
            confirmProperties={({ currentProperties }) => setFilters(prevFilters => {
              // const newElements = [...prevFilters[viewType][currentIndex].elements, { ...currentElement, properties: currentProperties }]
              const newElements = [...prevFilters[viewType][currentIndex].elements, ...currentProperties]
              const newFilter = {
                ...prevFilters[viewType][currentIndex],
                elements: newElements
              }
              if (newFilter.aggregationType !== undefined) {
                newFilter.aggregationType = ''
              }
              const newFilters = prevFilters[viewType].map((el, index) => index === currentIndex ? newFilter : el)
              return {
                ...prevFilters,
                [viewType]: newFilters
              }
            })}
            onClose={() => setOpenPropertiesModal(false)}
          />
          )
        : null}
      <Typography variant='body2'>{elementsSection[viewType] ? elementsSection[viewType].description : ''}</Typography>
      {filters[viewType].map((el, elIndex) => (
        <div key={`trend-${elIndex}`}>
          <Box my={2} display='flex' alignItems='center' justifyContent='space-between'>
            <Typography component='div' variant='h6' className={classes.sectionTitle}>{`Grafico ${elIndex + 1}`}</Typography>
            {elIndex !== 0 && el.show
              ? (
                <Button
                  onClick={() => setFilters(prevFilters => {
                    const currentFilters = prevFilters[viewType]
                    currentFilters.splice(elIndex, 1, { show: false, elements: [] })

                    return {
                      ...prevFilters,
                      [viewType]: [...currentFilters]
                    }
                  })}
                  classes={{ text: classes.buttonText }}
                  size='small'
                  endIcon={<XIcon size={12} />}
                >
                  Nascondi
                </Button>
                )
              : null}
          </Box>
          {elIndex === 0 || el.show
            ? (
              <>
                <Box mt={2}>
                  <Button
                    fullWidth
                    variant='outlined'
                    disabled={(filters[viewType] && filters[viewType][elIndex] && filters[viewType][elIndex].elements && filters[viewType][elIndex].elements.length >= limitNumber)}
                    onClick={() => {
                      setCurrentIndex(elIndex)
                      setCurrentProperties(filters[viewType][elIndex].elements)
                      setOpenPropertiesModal(true)
                    }}
                    endIcon={<ChevronRightIcon />}
                  >
                    Aggiungi variabili da visualizzare
                  </Button>
                </Box>
                <Box mt={2}>
                  <Typography variant='body2'>{`Elementi Aggiunti (${(filters[viewType] && filters[viewType][elIndex] && filters[viewType][elIndex].elements && filters[viewType][elIndex].elements.length) || 0}/${limitNumber})`}</Typography>
                </Box>
                <Box mt={2}>
                  <List>
                    {filters[viewType] && filters[viewType][elIndex] && filters[viewType][elIndex].elements
                      .map((element, index) => (
                        <div key={`${element.deviceId}-${index}`}>
                          <ListItem>
                            <Avatar className={classes.listItem}>{index + 1}</Avatar>
                            <ListItemText primary={`${element.displayName} (${getGraphType(element.graphType)})`} />
                            <ListItemSecondaryAction>
                              <Tooltip title='Elimina'>
                                <IconButton
                                  size='small'
                                  edge='end'
                                  onClick={() => {
                                    setFilters(prevFilters => {
                                      const newAddedElements = prevFilters[viewType][elIndex].elements
                                        .filter(property => !(property.deviceId === element.deviceId && property.name === element.name))
                                      const newFilter = {
                                        ...prevFilters[viewType][elIndex],
                                        elements: newAddedElements
                                      }
                                      const allGraphsArray = prevFilters[viewType].map((el, index) => index === elIndex ? newFilter : el)
                                      return {
                                        ...prevFilters,
                                        [viewType]: allGraphsArray
                                      }
                                    })
                                  }}
                                >
                                  <TrashIcon color={basicColors.darkRed} size={20} />
                                </IconButton>
                              </Tooltip>
                            </ListItemSecondaryAction>
                          </ListItem>
                          <Divider className={classes.divider} />
                        </div>
                      ))}
                  </List>
                </Box>
                {filters[viewType] && filters[viewType][elIndex] && filters[viewType][elIndex].elements && filters[viewType][elIndex].elements.length > 0
                  ? (
                    <Box mt={2}>
                      <FormControl component='fieldset'>
                        <Typography style={{ marginBottom: 16 }} variant='body2'>Tipo di aggregazione</Typography>
                        <RadioGroup aria-label='aggregation type' value={filters[viewType][elIndex].aggregationType || ''} onChange={(e) => changeAggregationType(e.target.value, elIndex)} name='aggregationType'>
                          <FormControlLabel value='raw' disabled={disableAggregation('raw')} control={<Radio className={classes.radio} color='primary' size='small' />} label='Dati puntuali' />
                          <FormControlLabel value='quarter' disabled={disableAggregation('quarter')} control={<Radio className={classes.radio} color='primary' size='small' />} label='Dati quartorari' />
                          <FormControlLabel value='hourly' disabled={disableAggregation('hourly')} control={<Radio className={classes.radio} color='primary' size='small' />} label='Dati orari' />
                          <FormControlLabel value='daily' disabled={disableAggregation('daily')} control={<Radio color='primary' className={classes.radio} size='small' />} label='Dati giornalieri' />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                    )
                  : null}
              </>
              )
            : (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={el.show || false}
                    disabled={!(filters[viewType] && filters[viewType][0] && filters[viewType][0].elements && filters[viewType][0].elements.length > 0)}
                    onChange={(e) => setFilters(prevFilters => {
                      const currentFilters = prevFilters[viewType]
                      const currentGraph = currentFilters[elIndex]
                      currentGraph.show = e.target.checked
                      currentFilters.splice(elIndex, 1, currentGraph)

                      return {
                        ...prevFilters,
                        [viewType]: [...currentFilters]
                      }
                    })}
                    color='primary'
                  />
                }
                label='Mostra secondo grafico'
              />
              )}
        </div>
      ))}
    </div>
  )
}
export default TrendContent
