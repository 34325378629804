import { memo } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import Chart from 'react-apexcharts'
import { europeNum } from 'src/utils/general'
import LoadingCard from 'src/components/LoadingCard'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  }
}))

function PrDeviation ({ data = {}, height = null, width = null, className, ...rest }) {
  const classes = useStyles()

  const customTooltip = function (seriesName, labelText) {
    function setAttributes (el, attrs) {
      for (const key in attrs) {
        el.setAttribute(key, attrs[key])
      }
    }
    const serie = document.querySelector('#prDeviation svg')
    // console.log('serie', serie)
    const svgText = document.createElementNS('http://www.w3.org/2000/svg', 'text')
    // console.log('x: ', labelText, Number(labelText))
    setAttributes(svgText, {
      id: 'prText',
      transform: 'translate(6 4) rotate(-1.3)'
    })
    // console.log('svgText', svgText)
    const svgTextPath = document.createElementNS('http://www.w3.org/2000/svg', 'textPath')
    setAttributes(svgTextPath, {
      href: '#' + seriesName,
      style: 'font-family: Arial Black; font-size: 12px; fill: #000',
      startOffset: '2%'
    })
    if (labelText !== '') {
      if (labelText !== Infinity && !isNaN(labelText)) {
        // svgTextPath.innerHTML = labelText !== '-' ? europeNum(labelText) + ' %' : '-'
        svgTextPath.innerHTML = labelText !== '-' ? europeNum(labelText - 100) + '%' : '-'
      } else {
        svgTextPath.innerHTML = '-'
      }
    }
    if (svgText && serie) {
      svgText.appendChild(svgTextPath)
      if (serie.querySelectorAll('#prText').length < 2) {
        // Aggiungo i nuovi text
        serie.appendChild(svgText)
      } else {
        // Ripulisco dai text precedenti
        serie.querySelectorAll('#prText').forEach(el => el.remove())
        serie.appendChild(svgText)
      }
    }
  }

  const customTooltips = function (data) {
    const series = {
      data: [data.kwh.value, data.pr.value],
      names: ['apexcharts-radialbarTrack-0', 'apexcharts-radialbarTrack-1']
    }
    // console.log('series.data: ', series.data)
    for (let s = 0; s < series.data.length; s++) {
      customTooltip(series.names[s], series.data[s])
    }
  }

  const options = {
    series: Object.keys(data).length > 0 ? [data.kwh.value, data.pr.value] : [],
    chart: {
      height: height || 350,
      width: width || 350,
      type: 'radialBar',
      events: {
        mounted: function () {
          // console.log('chart is drawed!')
          customTooltips(data)
        },
        updated: function () {
          // console.log('chart is drawed!')
          customTooltips(data)
        }
      }
    },
    stroke: {
      lineCap: 'round'
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        offsetX: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 10,
          size: '45px',
          background: 'transparent',
          image: undefined
        },
        dataLabels: {
          name: {
            show: true,
            offsetY: -20,
            fontSize: '13px',
            formatter: function (val) {
              return val
            }
          },
          value: {
            show: true,
            color: '#fff',
            fontSize: '28px',
            offsetY: 10,
            formatter: function (val) {
              return europeNum(val) + '%'
            }
          },
          total: {
            show: true,
            label: 'PR Reale',
            color: '#449CEE',
            fontSize: '13px',
            formatter: function () {
              return europeNum(data.pr.realValue, 2)
            }
          }
        },
        track: {
          show: true,
          startAngle: 0,
          endAngle: 270,
          background: '#053244',
          strokeWidth: '100%',
          margin: 5,
          dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            blur: 4,
            opacity: 0.4
          }
        }
      }
    },
    colors: ['#54F286', '#449CEE'],
    labels: Object.keys(data).length > 0 ? [data.kwh.name, data.pr.name] : [],
    legend: {
      show: true,
      floating: true,
      fontSize: '12px',
      position: 'left',
      offsetX: 2,
      offsetY: 5,
      labels: {
        useSeriesColors: false,
        colors: '#fff'
      },
      markers: {
        size: 0
      },
      formatter: function (seriesName, opts) {
        if (seriesName === data.kwh.name) {
          // return 'Produzione: ' + europeNum(opts.w.globals.series[opts.seriesIndex] - 100) + '%'
          return 'Produzione'
        } else {
          // return 'PR: ' + europeNum(opts.w.globals.series[opts.seriesIndex] - 100) + '%'
          return 'PR'
        }
      },
      itemMargin: {
        vertical: 4
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          show: false
        }
      }
    }]
  }

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {Object.keys(data).length > 0
        ? (
          <Chart
            id='prDeviation'
            options={options}
            series={options.series}
            type={options.chart.type}
            height={options.chart.height}
            width={options.chart.width}
          />
          )
        : (
          <LoadingCard />
          )}
    </div>
  )
}
export default memo(PrDeviation)
