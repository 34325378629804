import { memo } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import Chart from 'react-apexcharts'
import { europeNum } from 'src/utils/general'
import LoadingCard from 'src/components/LoadingCard'
// import { alog } from 'src/utils/apioLog'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
    // width: '100%'
  }
}))

function EnergyDeviation ({ data = [], width = null, height = null, className, ...rest }) {
  const classes = useStyles()
  // ['E. Attesa', 'E. Prodotta', 'E. Immessa']
  const values = data.map(el => el.value === '-' ? null : el.value)
  const labels = data.map(el => el.label)
  // console.log('cards: ', values)
  // console.log('cards: ', labels)

  const productionData = {
    data: values,
    colors: ['#14f7ff', '#00ff7f', '#FFFF00'],
    track: '#2A3337',
    labels
  }

  const normalizeData = function (firstIndex, data) {
    const dataCopy = JSON.parse(JSON.stringify(data))
    // Cerco l'indice del massimo valore nell'array
    const maxIndex = dataCopy.indexOf(Math.max(...dataCopy))
    // Indeice dell'array per il cui valore dobbiamo normalizzare la serie
    const normalizationIndex = maxIndex !== firstIndex ? maxIndex : firstIndex
    for (let s = 0; s < dataCopy.length; s++) {
      if (s !== normalizationIndex) {
        // console.log('dataCopy[s] is: ', dataCopy[s], 'dataCopy[firstIndex] is: ', dataCopy[firstIndex])
        dataCopy[s] = Number(((dataCopy[s] / data[normalizationIndex]) * 100).toFixed(2))
      }
    }
    if (dataCopy.length > 0) {
      dataCopy[normalizationIndex] = 100
    } else {
      dataCopy[normalizationIndex] = 0
    }
    return dataCopy
  }

  const customTooltip = function (seriesName, labelText) {
    function setAttributes (el, attrs) {
      for (const key in attrs) {
        el.setAttribute(key, attrs[key])
      }
    }
    const serie = document.querySelector('#energyDeviation svg')
    // console.log('serie', serie)
    const svgText = document.createElementNS('http://www.w3.org/2000/svg', 'text')
    // console.log('x: ', labelText, Number(labelText))
    setAttributes(svgText, {
      id: 'textProva',
      transform: 'translate(6 6) rotate(-1.3)'
    })
    // console.log('svgText', svgText)
    const svgTextPath = document.createElementNS('http://www.w3.org/2000/svg', 'textPath')
    setAttributes(svgTextPath, {
      href: '#' + seriesName,
      style: 'font-family: Arial Black; font-size: 10px; fill: #000',
      startOffset: '2%'
    })
    if (labelText !== '') {
      if (labelText !== Infinity && !isNaN(labelText)) {
        svgTextPath.innerHTML = labelText !== '-' ? europeNum(labelText) + ' %' : '-'
      } else {
        svgTextPath.innerHTML = '-'
      }
    }
    if (svgText && serie) {
      svgText.appendChild(svgTextPath)
      if (serie.querySelectorAll('#textProva').length < 2) {
        // Aggiungo i nuovi text
        serie.appendChild(svgText)
      } else {
        // Ripulisco dai text precedenti
        serie.querySelectorAll('#textProva').forEach(el => el.remove())
        serie.appendChild(svgText)
      }
    }
  }

  const customTooltips = function (data) {
    // alog('energyDeviation: ', data)
    const series = {
      data: data.data.map((el, index) => index === 2 ? Number((el / data.data[1] * 100).toFixed(2)) : Number((el / data.data[0] * 100).toFixed(2))),
      names: ['apexcharts-radialbarTrack-0', 'apexcharts-radialbarTrack-1', 'apexcharts-radialbarTrack-2']
    }
    // console.log('energyDeviation: ', data)
    for (let s = 1; s < series.data.length; s++) {
      customTooltip(series.names[s], series.data[s])
    }
  }

  const options = {
    series: normalizeData(0, productionData.data),
    chart: {
      height: height || 300,
      width: width || 300,
      type: 'radialBar',
      events: {
        mounted: function () {
          // console.log('chart is drawed!')
          customTooltips(productionData)
        },
        updated: function () {
          // console.log('chart is drawed!')
          customTooltips(productionData)
        }
      }
    },
    stroke: {
      lineCap: 'round'
    },
    tooltip: {
      enabled: true,
      shared: false,
      followCursor: false,
      intersect: false,
      inverseOrder: false,
      custom: undefined,
      fillSeriesColor: false,
      theme: 'dark',
      style: {
        fontSize: '12px',
        fontFamily: undefined
      },
      onDatasetHover: {
        highlightDataSeries: false
      },
      x: {
        show: false
      },
      y: {
        show: false,
        formatter: function (value, { config }) {
          const currentSeries = config.series
          const currentIndex = currentSeries.findIndex(el => Number(el) === Number(value))
          let newValue = value
          if (currentIndex > -1) {
            // newValue = Number((Number(values[currentIndex]) / Number(values[0]) * 100).toFixed(2))
            newValue = `${europeNum(Number(values[currentIndex]), 1)} kWh`
          } else {
            newValue = `${europeNum(newValue)} %`
          }
          return newValue
        }
      },
      marker: {
        show: false
      },
      fixed: {
        enabled: false,
        position: 'bottomRight',
        offsetX: 0,
        offsetY: 0
      }
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        offsetX: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 10,
          size: '20px',
          background: 'rgba(0,0,0,0)',
          image: undefined
        },
        dataLabels: {
          show: false,
          name: {
            show: false
          }
        },
        track: {
          show: true,
          startAngle: 0,
          endAngle: 270,
          background: productionData.track,
          strokeWidth: '100%',
          opacity: 1,
          margin: 5,
          dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 0.3
          }
        }
      }
    },
    colors: productionData.colors,
    labels: productionData.labels,
    legend: {
      show: true,
      floating: true,
      fontSize: '12px',
      position: 'left',
      offsetX: 0,
      offsetY: 18,
      labels: {
        useSeriesColors: true
        // colors: ['#fff']
      },
      markers: {
        width: 0,
        height: 0,
        strokeWidth: 0,
        strokeColor: '#fff',
        fillColors: undefined,
        radius: 0,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0
      },
      formatter: function (seriesName) {
        return seriesName
      },
      itemMargin: {
        vertical: 2
      }
    },
    annotations: {
      position: 'front',
      points: [{
        x: 100,
        y: 100,
        seriesIndex: 0,
        marker: {
          size: 2,
          fillColor: '#fff',
          strokeColor: '#333',
          strokeWidth: 3,
          shape: 'circle',
          radius: 2,
          OffsetX: 0,
          OffsetY: 0,
          cssClass: ''
        },
        label: {
          text: 'Prova'
        }
      }]
    }
  }

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {Object.keys(data).length > 0
        ? (
          <Chart
            id='energyDeviation'
            options={options}
            series={options.series}
            type={options.chart.type}
            height={options.chart.height}
            width={options.chart.width}
          />
          )
        : (
          <LoadingCard />
          )}
    </div>
  )
}
export default memo(EnergyDeviation)
