import moment from 'moment'
const monthsArray = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre']

/*
 * Funzione che prende in ingresso l'oggetto di risposta del backend e restituisce un oggetto
 * contenente gli oggetti nel formato utile al frontend per consentire le interazioni con l'interfaccia
 *
 * Input: {
 *    orientations: [],
 *    plant: {},
 *    devices: [],
 *    dataloggers: []
 *  }
 *
 * Output: {
 *    plant: {},
 *    generators: [],
 *    orientations: []
 *  }
 */
export const decodeFromApi = (apiResponse) => {
  // Singoli elementi che serviranno per assemblare l'oggetto ritornato per il frontend
  const { plant, devices, dataloggers } = apiResponse || {}
  // Estrapolo le informazioni dall'oggetto 'plant' per preparare i dati per lo store redux
  const { name, uuid, metadata } = plant || {}
  const { baseline, years } = metadata

  // Dalla baseline devo ricostruire gli oggetti di baselineConfig e baseline
  // Array di percentageLoss della nuova struttura della baseline
  const percentageLossArray = years ? years.map(el => Number(el.percentageLoss)) : []
  // Primi 12 mesi della baseline
  const firstBaselineYear = baseline.slice(0, 12)
  // Configurazione di baseline
  const baselineConfig = monthsArray.map(singleMonth => {
    const currentMonthElement = firstBaselineYear.find(el => el.month.includes(singleMonth.toLocaleLowerCase()))
    if (currentMonthElement) {
      const currentIrradiationValue = Number(currentMonthElement.irradiation)
      const currentProducibilityValue = Number(currentMonthElement.producibility) / (1 - Number(percentageLossArray[0] || 0) / 100)

      return {
        month: singleMonth,
        irradiation: currentIrradiationValue,
        producibility: Number(currentProducibilityValue.toFixed(2))
      }
    }

    return {
      month: singleMonth,
      irradiation: 0,
      producibility: 0
    }
  })

  const newBaselineMonths = monthsArray.map((singleMonth, monthIndex) => {
    // Elemento del mese i-esimo della configurazione di baseline
    const configProducibility = monthIndex < baselineConfig.length ? baselineConfig[monthIndex] : null
    let producibilityValue = configProducibility ? configProducibility.producibility : 0
    // Array della producibilità per il mese i-esimo, dove ogni suo elemento rappresenta un anno diverso di contratto
    const producibilityArray = configProducibility
      ? percentageLossArray.map(percentageEl => {
          const currentProdElement = Number(producibilityValue) - Number(producibilityValue) * Number(percentageEl) / 100
          producibilityValue = currentProdElement
          return isNaN(currentProdElement) ? 0 : Number(currentProdElement.toFixed(2))
        })
      : [0]

    return {
      month: singleMonth,
      producibility: producibilityArray
    }
  })
  // Nuovo oggetto di baseline che rispecchia il modello dati aggiornato
  const newBaseline = {
    percentageLoss: percentageLossArray,
    months: newBaselineMonths
  }

  // Aggiungo ai dataloggers ricevuti l'informazione dei devices associati ad essi
  const addedDataloggers = dataloggers.map((datalogger) => {
    // Prendo tutti i devices con nodeId uguale all'uuid del datalogger
    const dataloggerDevices = devices.filter((device) => device.nodeId === datalogger.uuid)
    return {
      ...datalogger,
      includes: {
        devices: dataloggerDevices
      }
    }
  })

  const resultObject = {
    plant: {
      ...metadata,
      baselineConfig,
      baseline: newBaseline,
      name,
      uuid,
      addedDataloggers
    }
  }

  return resultObject
}

/*
  * Funzione che prende in ingresso l'oggetto 'plant' presente nello store e restituisce un oggetto
  * che potrà essere passato come body alla chiamata POST al backend
  *
  * Input: {
  *    orientations: [],
      generators: [],
      name: '',
      uuid: '', // solo in caso si tratti di una modifica
      addedDataloggers: [],
      address: {}
      baseline: [],
      contractDuration: 0,
      distribution: {},
      endDate: '',
      startDate: '',
      maintenance: true | false,
      manufacturability: {},
      module: {},
      peakPower: 0,
      planimetry: {},
      plantType: '',
      totalDistribution: 0
      years: []
  *  }
  *
  * Output: {
  *    plant: {},
  *    orientations: [],
  *    generators: []
  *  }
*/
export const encodeForApi = (plant) => {
  // Rimuovo i campi che non servono al backend da plant
  delete plant.dataloggers
  delete plant.plants
  delete plant.panels
  delete plant.activeStep
  delete plant.currentBaselinePage
  delete plant.selectedDatalogger
  delete plant.selectedDistributionDatalogger
  delete plant.selectedDataloggerDistribution
  delete plant.selectedManufacturability
  delete plant.year

  // Trasformo le date da stringhe formattate a ISOString per passarle al backend
  if (plant.startDate) {
    plant.startDate = new Date(
      plant.startDate
        .split('/')
        .reverse()
        .join('-')
    ).toISOString()
  }
  if (plant.endDate) {
    plant.endDate = new Date(
      plant.endDate
        .split('/')
        .reverse()
        .join('-')
    ).toISOString()
  }
  // Converto la potenza di picco in numero per poter eseguire i calcoli più agilmente lato backend
  if (plant.peakPower) {
    plant.peakPower = Number(plant.peakPower)
  }

  const { name, uuid, ...metadata } = plant
  const { baseline, baselineConfig, startDate } = metadata

  const newBaseline = []
  if (startDate) {
    // Numero di anni di durata del contratto estratto dal numero di anni della baseline
    const contractDuration = (baseline.percentageLoss && baseline.percentageLoss.length) || 0
    const delta = (contractDuration) * 12
    const startDateCopy = moment(startDate)

    startDateCopy.add(newBaseline.length, 'M')
    for (let s = 0; s < delta; s++) {
      // Indice dell' elemento della configurazione di baseline del mese corrente
      const elementConfigIndex = baselineConfig.findIndex(el => el.month.toLowerCase() === startDateCopy.format('MMMM'))
      if (elementConfigIndex > -1) {
        // Valore dell'irraggiamento di baseline per il mese corrente
        const irradiationValue = baselineConfig[elementConfigIndex] && baselineConfig[elementConfigIndex].irradiation ? baselineConfig[elementConfigIndex].irradiation : 0
        // Valore dell'indice corrispondente all'anno che sto considerando
        const yearIndex = Math.floor(s / 12)
        // Valore di perdita percentuale per l'anno che sto considerando
        const currentPercentageLoss = Number(baseline.percentageLoss[yearIndex])
        // Valore di producibilità del mese nell'anno precedente (se l'annp precedente non esiste, mi rifaccio alla configurazione)
        const currentProducibilityValue = yearIndex === 0
          ? baselineConfig[elementConfigIndex] && baselineConfig[elementConfigIndex].producibility ? Number(baselineConfig[elementConfigIndex].producibility) : 0
          : baseline &&
            baseline.months &&
            baseline.months[elementConfigIndex] &&
            baseline.months[elementConfigIndex].producibility &&
            baseline.months[elementConfigIndex].producibility[yearIndex - 1]
            ? baseline.months[elementConfigIndex].producibility[yearIndex - 1]
            : null

        const producibilityValue =
          currentProducibilityValue
            ? (Number(currentProducibilityValue) - (Number(currentProducibilityValue) * currentPercentageLoss / 100))
            : 0

        newBaseline.push({
          month: startDateCopy.format('MMMM YYYY'),
          producibility: isNaN(producibilityValue) ? 0 : Number(producibilityValue.toFixed(2)),
          irradiation: irradiationValue
        })
      }
      startDateCopy.add(1, 'M')
    }
  }

  // array che va a sostituire years (struttura della vecchia baseline) in caso di nuova baseline
  const newYears = baseline.percentageLoss.map(el => ({ percentageLoss: el }))

  // Passo solo id e nome dei dataloggers associati all'impianto
  const addedDataloggers = metadata.addedDataloggers.map(el => ({ uuid: el.uuid, name: el.name }))

  // Elimino la configurazione della baseline dall'oggetto da mandare al backend
  delete metadata.baselineConfig

  const newPlant = {
    plant: {
      name,
      metadata: {
        ...metadata,
        addedDataloggers,
        baseline: newBaseline,
        years: newYears,
        engine: {
          brand: '',
          model: '',
          power: '',
          pci: '',
          ...metadata.engine
        },
        alternator: {
          brand: '',
          model: '',
          power: '',
          threshold: '',
          ...metadata.alternator
        },
        anagraphic: {
          peakPower: '',
          plantCode: '',
          contractType: '',
          referentName: '',
          referentClient: '',
          referentRole: '',
          referentEmail: '',
          referentPhone: '',
          monitoringName: '',
          monitoringEmail: '',
          monitoringPhone: '',
          operationAndMaintenaceName: '',
          operationAndMaintenacePhone: '',
          operationAndMaintenaceEmail: '',
          ...metadata.anagraphic
        }
      }
    }
  }

  // Se l'impianto ha un uuid lo inserisco nell'oggetto plant
  if (uuid && uuid !== undefined) {
    newPlant.plant.uuid = uuid
  }

  return newPlant
}
