import { createSlice } from '@reduxjs/toolkit'
import { decodeFromApi, encodeForApi } from 'src/utils/adapters/cchp/plantAdapter'

import api from 'src/utils/api'
import { alog } from 'src/utils/apioLog'

const initialState = {
  dataloggers: [],
  loadData: false,
  addedDataloggers: [],
  maintenance: false,
  activeStep: 0,
  hasAutosave: false,
  plantType: 'cchp',
  selectedDatalogger: '',
  selectedDataloggerDistribution: '',
  documents: [],
  interventionType: '',
  calorificValue: 0,
  inputFuel: '',
  hasByPass: false,
  engine: {
    brand: '',
    model: '',
    power: '',
    threshold: ''
  },
  alternator: {
    brand: '',
    model: '',
    power: '',
    pci: ''
  },
  anagraphic: {
    peakPower: '',
    plantCode: '',
    vatId: '',
    contractType: '',
    referentName: '',
    referentClient: '',
    referentRole: '',
    referentEmail: '',
    referentPhone: '',
    monitoringName: '',
    monitoringEmail: '',
    monitoringPhone: '',
    operationAndMaintenaceName: '',
    operationAndMaintenacePhone: '',
    operationAndMaintenaceEmail: ''
  },
  name: '',
  peakPower: '',
  totalDistribution: 0,
  startDate: null,
  contractDuration: 1,
  endDate: '',
  warranty: {
    type: '',
    value: null
  },
  years: [],
  year: '',
  address: '',
  logoURL: null,
  baselineConfig: [
    { month: 'Gennaio', irradiation: '', producibility: '' },
    { month: 'Febbraio', irradiation: '', producibility: '' },
    { month: 'Marzo', irradiation: '', producibility: '' },
    { month: 'Aprile', irradiation: '', producibility: '' },
    { month: 'Maggio', irradiation: '', producibility: '' },
    { month: 'Giugno', irradiation: '', producibility: '' },
    { month: 'Luglio', irradiation: '', producibility: '' },
    { month: 'Agosto', irradiation: '', producibility: '' },
    { month: 'Settembre', irradiation: '', producibility: '' },
    { month: 'Ottobre', irradiation: '', producibility: '' },
    { month: 'Novembre', irradiation: '', producibility: '' },
    { month: 'Dicembre', irradiation: '', producibility: '' }
  ],
  baseline: {
    percentageLoss: [''],
    months: [
      { month: 'Gennaio', producibility: [''] },
      { month: 'Febbraio', producibility: [''] },
      { month: 'Marzo', producibility: [''] },
      { month: 'Aprile', producibility: [''] },
      { month: 'Maggio', producibility: [''] },
      { month: 'Giugno', producibility: [''] },
      { month: 'Luglio', producibility: [''] },
      { month: 'Agosto', producibility: [''] },
      { month: 'Settembre', producibility: [''] },
      { month: 'Ottobre', producibility: [''] },
      { month: 'Novembre', producibility: [''] },
      { month: 'Dicembre', producibility: [''] }
    ]
  }
}

const slice = createSlice({
  name: 'cchpPlant',
  initialState,
  reducers: {
    setLoadData (state, actions) {
      const { loading } = actions.payload
      state.loadData = loading
    },
    setCurrentPlant (state, actions) {
      const { plant } = actions.payload
      // console.log('decodeFromApi - values: ', plant, orientations, generators)
      const { calorificValue, interventionType, inputFuel, hasByPass, engine, alternator, warranty, hasAutosave, logoURL, name, uuid, anagraphic, baselineConfig, addedDataloggers, address, baseline, contractDuration, endDate, startDate, maintenance, peakPower, plantType, totalDistribution, years, documents } = plant
      const { engine: initEngine, alternator: initAlternator, warranty: initWarranty, hasAutosave: initHasAutosave, documents: initDocuments, baseline: initBaseline, anagraphic: initAnagraphic, baselineConfig: initBaselineConfig } = initialState

      // l'impianto da modificare potrebbe non avere un oggetto garanzia (è stato creato precedentemente all'inserimento di questa sezione)
      // se l'oggetto è presente lo setto nel'impianto corrente
      if (warranty) {
        state.warranty = warranty
        // altrimenti setto il valore di default dell'oggetto warranty che si utilizza per un nuovo impianto
      } else {
        state.warranty = initWarranty
      }

      // l'impianto da modificare potrebbe non avere un oggetto engine (è stato creato precedentemente all'inserimento di questa sezione)
      // se l'oggetto è presente lo setto nel'impianto corrente
      if (engine) {
        state.engine = engine
        // altrimenti setto il valore di default dell'oggetto engine che si utilizza per un nuovo impianto
      } else {
        state.engine = initEngine
      }

      // l'impianto da modificare potrebbe non avere un oggetto alternator (è stato creato precedentemente all'inserimento di questa sezione)
      // se l'oggetto è presente lo setto nel'impianto corrente
      if (alternator) {
        state.alternator = alternator
        // altrimenti setto il valore di default dell'oggetto alternator che si utilizza per un nuovo impianto
      } else {
        state.alternator = initAlternator
      }

      // l'impianto da modificare potrebbe non avere un oggetto anagrafica (è stato creato precedentemente all'inserimento di questa sezione)
      // se l'oggetto è presente lo setto nel'impianto corrente
      if (anagraphic) {
        state.anagraphic = anagraphic
        // altrimenti setto il valore di default dell'oggetto anagraphic che si utilizza per un nuovo impianto
      } else {
        state.anagraphic = initAnagraphic
      }

      // L'impianto da modificare potrebbe non avere un oggetto baselineConfig (è stato creato precedentemente rispetto al refactor di questa sezione)
      // se l'oggetto è presente lo setto nell'impianto corrente
      if (baselineConfig) {
        state.baselineConfig = baselineConfig
        // altrimenti setto il valore di default dell'oggetto baselineConfig che si utilizza per un nuovo impianto
      } else {
        state.baselineConfig = initBaselineConfig
      }
      // L'impianto da modificare potrebbe non avere un oggetto baseline (è stato creato precedentemente rispetto al refactor di questa sezione)
      // se l'oggetto è presente lo setto nell'impianto corrente
      if (baseline && !Array.isArray(baseline)) {
        state.baseline = baseline
        // altrimenti setto il valore di default dell'oggetto baseline che si utilizza per un nuovo impianto
      } else {
        state.baseline = initBaseline
      }
      // Se ci sono documenti salvati li setto altrimenti imposto l'array vuoto
      if (documents) {
        state.documents = documents
      } else {
        state.documents = initDocuments
      }
      // Se c'è il logo salvato lo setto altrimenti imposto l'oggetto a null
      if (logoURL) {
        state.logoURL = logoURL
      } else {
        state.logoURL = null
      }

      // Se c'è l'autosave attivato, lo setto
      if (hasAutosave) {
        state.hasAutosave = hasAutosave
      } else {
        state.hasAutosave = initHasAutosave
      }

      state.name = name
      state.uuid = uuid
      state.interventionType = interventionType || ''
      state.calorificValue = calorificValue || 0
      state.inputFuel = inputFuel || ''
      state.hasByPass = hasByPass || false
      state.addedDataloggers = addedDataloggers
      state.address = address
      state.contractDuration = contractDuration
      state.endDate = endDate || ''
      state.startDate = startDate || null
      state.maintenance = maintenance
      state.peakPower = peakPower
      state.plantType = plantType
      state.totalDistribution = totalDistribution
      state.years = years
      state.loadData = false
    },
    setAddress (state, action) {
      const { newAddress } = action.payload
      state.address = newAddress
    },
    setPlantType (state, action) {
      const { plantType } = action.payload
      state.plantType = plantType
    },
    setWarrantyValue (state, action) {
      const { value, name } = action.payload
      state.warranty[name] = value
    },
    setEngineValue (state, action) {
      const { value, name } = action.payload
      state.engine[name] = value
    },
    setAlternatorValue (state, action) {
      const { value, name } = action.payload
      state.alternator[name] = value
    },
    setAutosave (state, action) {
      const { hasAutosave } = action.payload
      state.hasAutosave = hasAutosave
    },
    setLogo (state, action) {
      const { url } = action.payload
      if (state.uuid) {
        api.postResource('forceSummaryRefresh', { path: `/${state.uuid}/summary` })
      }
      state.logoURL = url
    },
    resetPlant (state) {
      const { warranty, anagraphic, hasAutosave, dataloggers, addedDataloggers, maintenance, activeStep, plantType, selectedDatalogger, name, peakPower, startDate, contractDuration, endDate, years, year, address, baseline, baselineConfig, documents } = initialState
      if (state.uuid) {
        delete state.uuid
      }
      if (state.devices) {
        delete state.devices
      }
      state.warranty = warranty
      state.dataloggers = dataloggers
      state.anagraphic = anagraphic
      state.addedDataloggers = addedDataloggers
      state.maintenance = maintenance
      state.activeStep = activeStep
      state.hasAutosave = hasAutosave
      state.plantType = plantType
      state.contractDuration = contractDuration
      state.selectedDatalogger = selectedDatalogger
      state.peakPower = peakPower
      state.startDate = startDate
      state.endDate = endDate
      state.years = years
      state.documents = documents
      state.name = name
      state.logoURL = null
      state.year = year
      state.address = address
      state.baseline = baseline
      state.baselineConfig = baselineConfig
    },
    setBaseline (state, action) {
      const { baseline } = action.payload
      state.baseline = baseline
    },
    updateBaseline (state, action) {
      const { monthIndex, producibilityIndex, name, value } = action.payload
      state.baseline.months[monthIndex][name][producibilityIndex] = value
    },
    updatePercentageLoss (state, action) {
      const { index, value } = action.payload
      const newPercentageLoss = state.baseline.percentageLoss.map((el, percentageIndex) => percentageIndex === index ? Number(value) : el)
      state.baseline.percentageLoss = newPercentageLoss
    },
    updateBaselineConfig (state, action) {
      const { index, name, value } = action.payload
      state.baselineConfig[index][name] = value
    },
    setYearsValues (state, action) {
      const { value, name } = action.payload
      const currentYearIndex = state.years.findIndex(el => el.year === state.year)
      if (currentYearIndex > -1) {
        state.years[currentYearIndex][name] = value
      }
    },
    changeAnagraphicValues (state, action) {
      const { value, name } = action.payload
      if (state.anagraphic !== undefined) {
        if (state.anagraphic[name] !== undefined) {
          state.anagraphic[name] = value
        }
      }
    },
    changeValues (state, action) {
      const { value, name } = action.payload
      if (state[name] !== undefined) {
        state[name] = value
      }
    },
    setDeviceTypeDocuments (state, action) {
      const { documents, deviceTypeId } = action.payload
      // Creo una copia dell'array dei datalogger aggiunti all'impianto
      const addedDataloggers = JSON.parse(JSON.stringify(state.addedDataloggers))
      const updatedDataloggers = addedDataloggers.map(datalogger => {
        if (datalogger.includes && datalogger.includes.devices) {
          const devices = [...datalogger.includes.devices]
          const newDevices = devices.map(device => {
            const deviceType = device.deviceType
            if (deviceType && deviceType.uuid === deviceTypeId) {
              const newDeviceType = {
                ...deviceType,
                metadata: {
                  ...deviceType.metadata,
                  documents
                }
              }

              return {
                ...device,
                deviceType: newDeviceType
              }
            }

            return device
          })

          return {
            ...datalogger,
            includes: {
              ...datalogger.includes,
              devices: newDevices
            }
          }
        }

        return datalogger
      })
      // console.log('state.addedDataloggers: ', updatedDataloggers)
      state.addedDataloggers = updatedDataloggers
    },
    setPlantDocuments (state, action) {
      const { documents } = action.payload
      state.documents = documents
    },
    setStep (state, action) {
      const { step } = action.payload
      state.activeStep = step
    },
    setDataloggers (state, action) {
      const { dataloggers } = action.payload
      state.dataloggers = dataloggers
    },
    removeDatalogger (state, action) {
      const { dataloggerId } = action.payload
      // Datalogger disponibili
      const currentDataloggers = Object.assign([], state.dataloggers)
      // Datalogger assegnati all'impianto
      const addedDataloggers = Object.assign([], state.addedDataloggers)
      const currentDistribution = JSON.parse(JSON.stringify(state.distribution))
      const currentPlanimetryConfig = JSON.parse(JSON.stringify(state.planimetry.config))
      const selectedDataloggerIndex = addedDataloggers.findIndex(datalogger => datalogger.uuid === dataloggerId)
      const selectedDatalogger = addedDataloggers.splice(selectedDataloggerIndex, 1)
      if (selectedDatalogger) {
        // Rimetto l'oggetto datalogger solo se non è già presente nell'array dei datalogger disponibili
        const existingDatalogger = currentDataloggers.find(datalogger => datalogger.uuid === dataloggerId)
        if (!existingDatalogger) {
          currentDataloggers.push(selectedDatalogger[0])
        }

        // svuoto le distribuzioni create per i devices sottesi al datalogger eliminato
        const devices = selectedDatalogger[0].includes && selectedDatalogger[0].includes.devices

        devices.forEach(device => {
          if (currentDistribution[device.uuid] && currentDistribution[device.uuid] !== undefined) {
            delete currentDistribution[device.uuid]
          }

          currentPlanimetryConfig.forEach(configElement => {
            if (configElement.devices) {
              // rimuovo i devices dall'array devices di ogni elemento di configurazione
              const newDevices = configElement.devices.filter(el => el.deviceId !== device.uuid)
              // assegno il nuovo array di devices filtrato
              configElement.devices = newDevices
            }
          })
        })

        state.distribution = currentDistribution
        state.dataloggers = currentDataloggers
        state.addedDataloggers = addedDataloggers
        state.planimetry.config = currentPlanimetryConfig
      }
    },
    updateAddedDatalogger (state, action) {
      const { dataloggerId, newDevice } = action.payload
      // Creo una copia dell'array dei datalogger aggiunti all'impianto
      const addedDataloggers = JSON.parse(JSON.stringify(state.addedDataloggers))
      // Filtro tutti i datalogger che non sono quello selezionato
      const otherDataloggers = addedDataloggers.filter(el => el.uuid !== dataloggerId)
      // Prendo il datalogger selezionato
      const selectedDatalogger = addedDataloggers.find(el => el.uuid === dataloggerId)
      // console.log('datasheet - selectedDatalogger: ', selectedDatalogger)
      // Se il datalogger selezionato esiste
      if (selectedDatalogger && selectedDatalogger !== undefined) {
        // Se il datalogger ha l'array di device
        if (selectedDatalogger.includes && selectedDatalogger.includes.devices) {
          // Prendo tutti i devices di questo datalogger
          const dataloggerDevices = JSON.parse(JSON.stringify(selectedDatalogger.includes.devices))
          const newDataloggerDevices = dataloggerDevices.map(el => {
            if (el.uuid === newDevice.uuid) {
              el.metadata.documents = newDevice.metadata.documents
            }

            return el
          })
          selectedDatalogger.includes.devices = newDataloggerDevices
          otherDataloggers.push(selectedDatalogger)
          // console.log('datasheet - otherDataloggers: ', otherDataloggers)
          state.addedDataloggers = otherDataloggers
        }
      }
    },
    selectDatalogger (state, action) {
      const { dataloggerId } = action.payload
      const currentDataloggers = Object.assign([], state.dataloggers)
      const addedDataloggers = Object.assign([], state.addedDataloggers)
      const selectedDataloggerIndex = currentDataloggers.findIndex(datalogger => datalogger.uuid === dataloggerId)
      const selectedDatalogger = currentDataloggers.splice(selectedDataloggerIndex, 1)
      if (selectedDatalogger) {
        addedDataloggers.push(selectedDatalogger[0])
        state.dataloggers = currentDataloggers
        state.addedDataloggers = addedDataloggers
        // console.log('PlantComponent - devices on addedDataloggers: ', state.addedDataloggers)
      }
    }
  }
})

export const reducer = slice.reducer

export const updateAddedDatalogger = (dataloggerId, newDevice) => dispatch => {
  dispatch(slice.actions.updateAddedDatalogger({ dataloggerId, newDevice }))
}

export const setStep = (step) => dispatch => {
  dispatch(slice.actions.setStep({ step }))
}

export const updatePlantDocuments = (documents) => dispatch => {
  dispatch(slice.actions.setPlantDocuments({ documents }))
}

export const updateDeviceTypeDocuments = (documents, deviceTypeId) => dispatch => {
  dispatch(slice.actions.setDeviceTypeDocuments({ documents, deviceTypeId }))
}

export const addDatalogger = (dataloggerId) => dispatch => {
  dispatch(slice.actions.selectDatalogger({ dataloggerId }))
}

export const deleteDatalogger = (dataloggerId) => dispatch => {
  dispatch(slice.actions.removeDatalogger({ dataloggerId }))
}

export const getDataloggers = ({ skip = 0, limit = 50, filter = null, hasNoPlant = null }) => async dispatch => {
  const params = {
    skip,
    limit,
    sortorder: 'DESC',
    include: 'devices'
  }

  if (filter) {
    params.filter = filter
  }

  if (hasNoPlant !== null) {
    params.hasNoPlant = hasNoPlant
  }

  const dataloggers = await api.getResource('nodesList', { params })
  dispatch(slice.actions.setDataloggers({ dataloggers }))
}

export const createPlant = (plant, reset = true, userIsSaving = false) => async dispatch => {
  const { uuid } = plant
  const body = encodeForApi(plant)

  try {
    const response = await api.postResource('dashboard', { path: '/plants', body })
    const createdPlant = { ...response }
    if (!uuid || userIsSaving) {
      await api.postResource('forceSummaryRefresh', { path: `/${createdPlant.uuid}/summary` })
    }
    if (reset) {
      dispatch(slice.actions.resetPlant())
    }
    return { createdPlant, error: false }
  } catch (e) {
    alog('creation error: ', e)
    return { createdPlant: null, error: true }
  }
}

export const resetInitState = () => dispatch => {
  dispatch(slice.actions.resetPlant())
}

export const getCurrentPlant = (plantId, firstLoad = false) => async dispatch => {
  if (plantId) {
    if (firstLoad) {
      dispatch(slice.actions.setLoadData({ loading: true }))
    }
    const options = {
      path: `/plants/${plantId}`
    }
    const response = await api.getResource('dashboard', options)
    const { orientations, plant, dataloggers, devices } = response || {}
    const adaptedPlant = decodeFromApi({ orientations, plant, dataloggers, devices })
    dispatch(slice.actions.setCurrentPlant(adaptedPlant))
  }
}

export const changeContractValue = (value, name) => dispatch => {
  if (name === 'percentageLoss' || name === 'percentageDeviation') {
    dispatch(slice.actions.setYearsValues({ value, name }))
  } else if (name === 'manufacturabilityValue') {
    dispatch(slice.actions.setManufacturabilityValue({ value, name }))
  }
  dispatch(slice.actions.changeValues({ value, name }))
}

export const changeAnagraphicValue = (value, name) => dispatch => {
  dispatch(slice.actions.changeAnagraphicValues({ value, name }))
}

export const changePlantType = (plantType) => dispatch => {
  dispatch(slice.actions.setPlantType({ plantType }))
}

export const changeWarrantyValue = (value, name) => dispatch => {
  dispatch(slice.actions.setWarrantyValue({ value, name }))
}

export const changeEngineValue = (value, name) => dispatch => {
  dispatch(slice.actions.setEngineValue({ value, name }))
}

export const changeAlternatorValue = (value, name) => dispatch => {
  dispatch(slice.actions.setAlternatorValue({ value, name }))
}

export const setBaseline = (baseline) => dispatch => {
  dispatch(slice.actions.setBaseline({ baseline }))
}

export const modifyBaselineValue = (monthIndex, producibilityIndex, name, value) => dispatch => {
  dispatch(slice.actions.updateBaseline({ monthIndex, producibilityIndex, name, value }))
}

export const modifyPercentageLoss = (index, value) => dispatch => {
  dispatch(slice.actions.updatePercentageLoss({ index, value }))
}

export const modifyBaselineConfig = (index, name, value) => dispatch => {
  dispatch(slice.actions.updateBaselineConfig({ index, name, value }))
}

export const setAddress = (newAddress) => dispatch => {
  dispatch(slice.actions.setAddress({ newAddress }))
}

export const updateLogo = (url = null) => dispatch => {
  dispatch(slice.actions.setLogo({ url }))
}

export const togglePlantAutosave = (hasAutosave) => dispatch => {
  dispatch(slice.actions.setAutosave({ hasAutosave }))
}
