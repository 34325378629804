import PropTypes from 'prop-types'
// import { basicColors } from 'src/theme'
import {
  // colors,
  // Box,
  Tooltip,
  Typography,
  Grid,
  makeStyles
  // withStyles,
} from '@material-ui/core'
// import { useSnackbar } from 'notistack'

// import {
//   // Edit as EditIcon,
// } from 'react-feather'

// stile CSS generale
const useStyles = makeStyles((theme) => ({
  root: {},
  barContainer: {
    // display: 'flex',
    // flexDirection: 'row',
    width: '100%',
    marginTop: 10
    // height: 26,
  },
  categoryText: {
    color: 'white',
    textAlign: 'center',
    padding: 0,
    margin: 0
  }
}))

// componente principale
const AnomaliesCategoryBar = ({ className, anomaliesCategoryStatus }) => {
  const classes = useStyles()
  // const { enqueueSnackbar } = useSnackbar()
  // console.log('anomaliesBar =>', anomaliesBar)

  // all'avvio aggiorno i dati
  // useEffect(() => {
  //   dispatch(getPlants())
  //   // return () => {}
  // }, [dispatch])

  return (
    <Grid container spacing={1} className={classes.barContainer}>
      {anomaliesCategoryStatus.map((category, index) => {
        return (
          <Grid key={index} item xs={12} md>
            <Typography component='p' variant='caption' className={classes.categoryText} style={{ paddingBottom: 6 }}>
              {category.label}: <b>{category.value}</b>
            </Typography>
            <Grid container layout='row' alignItems='center' justifyContent='center'>
              {category.subCategory.map((sub, subIndex) => {
                return (
                  <Grid key={subIndex} item xs>
                    <Tooltip title={`${sub.label}: ${sub.value}`} aria-label={`${sub.label}: ${sub.value}`}>
                      <Typography
                        component='p'
                        variant='caption'
                        className={classes.categoryText}
                        style={{ paddingTop: 6, borderTop: '5px solid ' + sub.color }}
                      >
                        {sub.label}: <b>{sub.value}</b>
                      </Typography>
                    </Tooltip>
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        )
      })}
    </Grid>
  )
}

AnomaliesCategoryBar.propTypes = {
  className: PropTypes.string,
  anomaliesCategoryStatus: PropTypes.array
  // setRefreshUsers: PropTypes.func
}

AnomaliesCategoryBar.defaultProps = {
  anomaliesCategoryStatus: []
}

export default AnomaliesCategoryBar
