import { createContext, useCallback, useReducer } from 'react'
import api from 'src/utils/api'
import { configuration } from 'src/config'

const initialTopBarState = {
  name: '-',
  peakPower: '-',
  startDate: '-',
  plantType: '',
  contractDuration: '-',
  endDate: '-',
  uuid: null,
  maintenance: false,
  engine: {},
  documents: [],
  recalculations: null
}

const { projectId } = configuration

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_RECALCULATIONS': {
      const { recalculations } = action.payload
      return {
        ...state,
        recalculations
      }
    }
    case 'SET_INFOS': {
      const {
        name,
        peakPower,
        startDate,
        plantType,
        contractDuration,
        endDate,
        uuid,
        engine,
        maintenance,
        documents
      } = action.payload

      return {
        ...state,
        name,
        peakPower,
        startDate,
        engine: engine ?? {},
        plantType,
        contractDuration,
        endDate,
        uuid,
        maintenance,
        documents
      }
    }
    case 'RESET_INFOS': {
      return { ...initialTopBarState }
    }
    default: {
      return { ...state }
    }
  }
}

const TopBarContext = createContext({
  ...initialTopBarState,
  setInfos: () => Promise.resolve(),
  resetInfos: () => Promise.resolve(),
  getRecalculations: () => Promise.resolve(),
  executeRecalculations: () => Promise.resolve()
})

export const TopBarProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialTopBarState)

  const setInfos = useCallback((plant) => {
    if (plant) {
      const {
        name,
        peakPower,
        startDate,
        plantType,
        contractDuration,
        endDate,
        uuid,
        maintenance,
        engine,
        documents
      } = plant

      dispatch({
        type: 'SET_INFOS',
        payload: {
          name,
          peakPower,
          startDate,
          contractDuration,
          plantType,
          engine: engine ?? {},
          endDate,
          uuid,
          maintenance,
          documents
        }
      })
    }
  }, [])

  const resetInfos = useCallback(() => {
    dispatch({ type: 'RESET_INFOS' })
  }, [])

  const executeRecalculations = useCallback(async (plantId, timeFrom, timeTo) => {
    if (plantId && timeFrom && timeTo) {
      const recalculationBody = {
        projectId,
        plantId,
        from: timeFrom,
        to: timeTo
      }
      try {
        const response = await api.postResource('plantMetricsRecalculation', { body: recalculationBody, fullResponse: true })
        const status = response?.data?.status
        if (status || status === 200) {
          const currentRecalculationList = [response?.data?.data || {}]
          dispatch({
            type: 'SET_RECALCULATIONS',
            payload: {
              recalculations: currentRecalculationList
            }
          })
          return true
        }
      } catch (e) {
        return false
      }
    }
  }, [])

  const getRecalculations = useCallback(async (plant) => {
    const { uuid } = plant

    try {
      const recalculationsList = await api.getResource('plantMetricsRecalculation', { path: `?plantId=${uuid}` })
      dispatch({
        type: 'SET_RECALCULATIONS',
        payload: {
          recalculations: recalculationsList
        }
      })
    } catch (e) {
      console.log('getRecalculations: ', e)
    }
  }, [state])

  return (
    <TopBarContext.Provider
      value={{
        ...state,
        setInfos,
        resetInfos,
        getRecalculations,
        executeRecalculations
      }}
    >
      {children}
    </TopBarContext.Provider>
  )
}

export default TopBarContext
