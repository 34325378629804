// import { useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import LoadingCard from '../LoadingCard'
import Chart from 'react-apexcharts'
import { europeNum } from 'src/utils/general'
// import moment from 'moment'
import { alog } from 'src/utils/apioLog'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  }
}))

function StringPRChart({ data = [], width = null, height = null, className, ...rest }) {
  const classes = useStyles()
  // const [chartLoaded, setchartLoaded] = useState(false)
  // console.log(data)

  const normalizeOptions = (data, options) => {
    if (options.series) {
      alog('data', data, 'STRING-PR-CHART')
      options.series = data.map((string, stringIndex) => ({
        // name: `String: ${string.name}`,
        name: `${string.name}`,
        type: 'line',
        // show: stringIndex < 5,
        // orientationName: el.orientationName,
        data: string?.values?.map(value => ({
          x: value[0],
          y: value[1]
        })) || []
      }))

      const mapValues = data.map(string => string?.values?.map(value => value[1]))
      const max = Math.max(...mapValues.flat())
      const min = Math.min(...mapValues.flat())

      options.yaxis.push({
        max: max < 1 ? 1.05 : max * 1.05,
        min: min > 0 ? 0 : min
        // tooltip: {
        //   enabled: true
        // }
        // crosshairs: {
        //   show: true
        // }
      })
    }

    return options
  }

  const options = {
    series: [],
    chart: {
      type: 'line',
      width: width || '100%',
      background: '#024660',
      height: height || '95%',
      toolbar: {
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false
        }
      },
      animations: {
        enabled: false
        // easing: 'easeinout',
        // speed: 600,
        // animateGradually: {
        //   enabled: false
        //   // delay: 150
        // }
        // dynamicAnimation: {
        //   enabled: false,
        //   speed: 350
        // }
      },
      events: {
        // beforeMount: undefined,
        // mounted: function (chartCxt, opt) {
        //   console.log('chartCxt', chartCxt)
        //   console.log('opt', opt)
        //   if (!chartLoaded) {
        //     setchartLoaded(true)
        //     // nascondo tutte le serie tranne le prime 5
        //     // const series = chartContext.w.globals.series
        //     // const series = chartContext.w.config.series
        //     // const series = chartContext.w.config.series
        //     const series = opt?.config?.series || []
        //     console.log('series', series)
        //     if (series?.length > 5) {
        //       for (let i = 5; i < series.length; i++) {
        //         chartCxt?.hideSeries(series[i]?.name)
        //       }
        //     }
        //   }
        // }
        // updated: undefined,
        // click: undefined,
        // legendClick: undefined,
        // markerClick: undefined,
      }
    },
    // stroke: {
    //   curve: 'smooth'
    // },
    colors: ['#31eeef', '#31eeef', '#eaf360', '#eaf360', '#3ef075', '#3ef075', '#ffb2bc', '#ffb2bc', '#EA8F06', '#EA8F06', '#3F784C', '#3F784C', '#FE6D73', '#FE6D73', '#8FCB9B', '#8FCB9B', '#8B95C9', '#8B95C9', '#AA4586', '#AA4586'],
    // tooltip: {
    //   shared: false,
    //   intersect: true
    //   // fixed: {
    //   //   enabled: true,
    //   //   position: 'topRight',
    //   //   offsetX: 0,
    //   //   offsetY: 0
    //   // }
    // },
    tooltip: {
      shared: true,
      y: {
        show: false,
        formatter: function (value) {
          return value !== undefined && value !== null ? `${europeNum(value, 3)}` : '-'
        }
      }
    },
    xaxis: {
      type: 'datetime',
      labels: {
        show: true,
        rotateAlways: false,
        // maxHeight: 120,
        // formatter: (value) => { return moment(value).format('DD/MM/YY') },
        datetimeUTC: true,
        datetimeFormatter: {
          year: 'yyyy',
          month: "MMM 'yy",
          day: 'dd MMM',
          hour: 'HH:mm'
        }
      }
    },
    yaxis: [],
    legend: {
      show: true,
      labels: {
        useSeriesColors: true
      }
    },
    grid: {
      // show: false,
      borderColor: '#90A4AE'
    },
    theme: {
      mode: 'dark',
      palette: 'palette1'
    }
  }

  const normalizedOptions = data.length > 0 ? normalizeOptions(data, options) : options

  alog('normalizedOptions: ', normalizedOptions, 'STRING-PR-CHART')
  // alog('data: ', data, 'LinearRegressionChart')

  return (
    <div
      className={clsx(classes.root, className)} {...rest}
    >
      {
        Object.keys(data).length > 0
          ? (
            <Chart
              options={normalizedOptions}
              series={normalizedOptions.series || undefined}
              type={(normalizedOptions.chart && normalizedOptions.chart.type) || 'line'}
              height={(normalizedOptions.chart && normalizedOptions.chart.height) || 0}
              width={(normalizedOptions.chart && normalizedOptions.chart.width) || 0}
            />
          )
          : (
            <LoadingCard />
          )
      }
    </div>
  )
}
export default StringPRChart
