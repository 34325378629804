import { useState, useEffect, useCallback, memo } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'
import { makeStyles } from '@material-ui/core'
import NavBar from './NavBar'
import { TopBarProvider } from 'src/contexts/TopBarContext'
import useSocket from 'src/hooks/useSocket'
import useAuth from 'src/hooks/useAuth'
import { alog } from 'src/utils/apioLog'
import { configuration } from 'src/config'
import { useSnackbar } from 'notistack'
import AnomalySnackbarContent from 'src/layouts/DashboardLayout/AnomalySnackbarContent'
import api from 'src/utils/api'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 220
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}))

const DashboardLayout = ({ children }) => {
  const classes = useStyles()
  const [isMobileNavOpen, setMobileNavOpen] = useState(false)
  const history = useHistory()
  const { user } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const { lastMessage } = useSocket(configuration.webSocket, user.uuid)

  // funzione che fa apparire la notifica per nuova anomalia
  const anomalyNotification = useCallback(async ({
    text,
    variant = 'warning',
    plantId = null,
    anomalyId = null,
    severity = null,
    description = null,
    relatedResources = null
  }) => {
    // opzioni per lo snackbar
    const options = {
      variant,
      autoHideDuration: 30000,
      content: (key, message) => (
        <AnomalySnackbarContent
          id={key}
          message={message}
          plantId={plantId}
          anomalyId={anomalyId}
          history={history}
          variant={variant}
          severity={severity}
          description={description}
          relatedResources={relatedResources}
        />
      )
    }

    enqueueSnackbar(text, options)
  }, [enqueueSnackbar, history])

  // ad ogni nuovo messaggio faccio i miei controlli
  useEffect(() => {
    const shouldISendANotification = async () => {
      if (lastMessage && lastMessage.data) {
        const thisEvent = JSON.parse(lastMessage.data)
        alog('event =>', thisEvent, 'main')
        if (thisEvent.type === 'venera.anomaly.created' || thisEvent.type === 'venera.anomaly.closed') {
          const newAnomaly = thisEvent.data
          // mi prendo i dati relativi alle risorse collegate
          let relatedResources = []
          try {
            relatedResources = await api.getResource('anomaliesList', {
              path: `/${newAnomaly.uuid}/resources`
            })
          } catch (err) {
            console.error('Errore mentre prendo le risorse relative alla nuova anomalia')
          }
          let plantName = null
          try {
            const plantResource = await api.getResource('plantsList', {
              path: `/${newAnomaly.plantId}`,
              params: {
                fields: 'name'
              }
            })
            plantName = plantResource.name || null
          } catch (err) {
            console.error('Errore mentre prendo le risorse relative alla nuova anomalia')
          }
          // mi preparo i dati per la notifica
          const name = newAnomaly.metadata && newAnomaly.metadata.message ? newAnomaly.metadata.message : newAnomaly.configuration ? newAnomaly.configuration.name : 'del ' + newAnomaly.updatedAt
          const category = newAnomaly.configuration ? newAnomaly.configuration.category : ''
          let thisVariant = thisEvent.type === 'venera.anomaly.closed' ? 'success' : thisEvent.type === 'venera.anomaly.created' ? 'warning' : 'warning'
          // controllo se sta a severity 5
          if (thisEvent.type === 'venera.anomaly.created' && newAnomaly.severity[newAnomaly.severity.length - 1].level === 5) {
            thisVariant = 'error'
          }
          // mando la notifica
          anomalyNotification({
            text: `${plantName || 'Impianto'}: anomalia ${category || 'NOCATEGORIA'} - ${name}`,
            variant: thisVariant,
            plantId: newAnomaly.plantId || '',
            anomalyId: newAnomaly.uuid || '',
            severity: newAnomaly.severity[newAnomaly.severity.length - 1].level || null,
            description: newAnomaly.configuration.description || null,
            relatedResources: relatedResources && relatedResources.length > 0 ? relatedResources : null
          })
        }
      }
    }

    shouldISendANotification()
  }, [lastMessage, anomalyNotification])

  return (
    <div className={classes.root}>
      <TopBarProvider>
        <NavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
        />
        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              {children}
            </div>
          </div>
        </div>
      </TopBarProvider>
    </div>
  )
}

DashboardLayout.propTypes = {
  children: PropTypes.node
}

export default memo(DashboardLayout)
