import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'prismjs/prism'
import 'prismjs/components/prism-bash'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-jsx'
import 'nprogress/nprogress.css'
import 'src/__mocks__'
import 'src/assets/css/prism.css'
// import 'src/mixins/chartjs'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { enableES5 } from 'immer'
import * as serviceWorker from 'src/serviceWorker'
import * as Sentry from '@sentry/browser'
import store from 'src/store'
import { SettingsProvider } from 'src/contexts/SettingsContext'
import App from 'src/App'
import { configuration } from './config'

enableES5()

if (configuration.sentry.environment === 'production') {
  Sentry.init({ dsn: configuration.sentry.dsn, environment: configuration.sentry.environment })
}

ReactDOM.render(
  <Provider store={store}>
    <SettingsProvider>
      <App />
    </SettingsProvider>
  </Provider>,
  document.getElementById('root')
)

serviceWorker.register()
