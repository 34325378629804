import { useState } from 'react'
import clsx from 'clsx'
import { Box, makeStyles, MenuItem, Typography, List, TextField, ListItem, Avatar, ListItemText, ListItemSecondaryAction, Tooltip, IconButton, Divider } from '@material-ui/core'
import { basicColors } from 'src/theme'
import { elementsSection } from './utils'
import { Trash as TrashIcon } from 'react-feather'
import { alog } from 'src/utils/apioLog'

const useStyles = makeStyles(() => ({
  root: {},
  divider: {
    backgroundColor: basicColors.blueGrayLight
  },
  listItem: {
    fontSize: 16,
    width: 28,
    height: 28,
    marginRight: 16
  }
}))

function DeviationContent ({ filters, setFilters, devices = [], viewType, className, ...rest }) {
  const classes = useStyles()
  const [elementSelect, setElementSelect] = useState('')
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Typography variant='body2'>{elementsSection[viewType] ? elementsSection[viewType].description : ''}</Typography>
      {filters[viewType] && filters[viewType].map((el, elIndex) => (
        <div key={`deviation-${elIndex}`}>
          <Box mt={2}>
            <TextField
              label='Aggiungi Elementi'
              size='small'
              value={elementSelect}
              disabled={
                (filters[viewType] && filters[viewType][elIndex] && filters[viewType][elIndex].elements && filters[viewType][elIndex].elements.find(el => el.uuid === 'plant') !== undefined) ||
                (filters[viewType] && filters[viewType][elIndex] && filters[viewType][elIndex].elements && filters[viewType][elIndex].elements.length >= 5)
              }
              onChange={(e) => {
                setFilters(prevFilters => {
                  const currentElementId = e.target.value
                  alog('devices: ', devices, 'DeviationContent')
                  // Se sto selezionando una regressione dell'intero impianto
                  if (currentElementId === 'plant') {
                    const newFilter = {
                      ...prevFilters[viewType][elIndex],
                      elements: [{ uuid: currentElementId, name: 'Impianto' }]
                    }
                    const allGraphsArray = prevFilters[viewType].map((el, index) => index === elIndex ? newFilter : el)
                    return {
                      ...prevFilters,
                      [viewType]: allGraphsArray
                    }
                    // Altrimenti se sto selezionando un orientamento
                  } else {
                    const currentElement = devices.find(el => el.uuid === currentElementId)
                    // Se l'elemento selezionato esiste e se non si trova già negli elementi aggiunti lo aggiungo
                    if (!(prevFilters[viewType] && prevFilters[viewType][elIndex] && prevFilters[viewType][elIndex].elements && prevFilters[viewType][elIndex].elements.find(el => el.uuid === currentElementId)) && currentElement) {
                      let newArray = []
                      if (currentElement.deviceType && currentElement.deviceType.category === 'Inverter') {
                        const invertersArray = prevFilters[viewType][elIndex].elements.filter(el => (el.deviceType && el.deviceType.category === 'Inverter'))
                        newArray = [...invertersArray, currentElement]
                      } else {
                        const notInvertersArray = prevFilters[viewType][elIndex].elements.filter(el => !(el.deviceType && el.deviceType.category === 'Inverter'))
                        newArray = [...notInvertersArray, currentElement]
                      }
                      const newFilter = {
                        ...prevFilters[viewType][elIndex],
                        elements: newArray
                      }
                      const allGraphsArray = prevFilters[viewType].map((el, index) => index === elIndex ? newFilter : el)
                      return {
                        ...prevFilters,
                        [viewType]: allGraphsArray
                      }
                    }
                  }

                  return prevFilters
                })
                setElementSelect('')
              }}
              variant='outlined'
              fullWidth
              select
            >
              <MenuItem value='plant'>Impianto</MenuItem>
              {devices.map(el => (
                <MenuItem key={el.uuid} value={el.uuid}>{el.name}</MenuItem>
              ))}
            </TextField>
          </Box>
          <Box mt={2}>
            <Typography variant='body2'>{`Elementi Aggiunti (${(filters[viewType] && filters[viewType][elIndex] && filters[viewType][elIndex].elements && filters[viewType][elIndex].elements.length) || 0}/5)`}</Typography>
          </Box>
          <Box mt={2}>
            <List>
              {filters[viewType] && filters[viewType][elIndex] && filters[viewType][elIndex].elements && filters[viewType][elIndex].elements.map((el, index) => (
                <div key={el.uuid}>
                  <ListItem>
                    <Avatar className={classes.listItem}>{index + 1}</Avatar>
                    <ListItemText primary={el.name} />
                    <ListItemSecondaryAction>
                      <Tooltip title='Elimina'>
                        <IconButton
                          size='small'
                          edge='end'
                          onClick={() => {
                            setFilters(prevFilters => {
                              // console.log(prevFilters[viewType][elIndex])
                              // console.log(prevFilters[viewType])
                              const newAddedElements = prevFilters[viewType][elIndex].elements.filter(addedEl => addedEl.uuid !== el.uuid)
                              const newFilter = {
                                ...prevFilters[viewType][elIndex],
                                elements: newAddedElements
                              }
                              const allGraphsArray = prevFilters[viewType].map((el, index) => index === elIndex ? newFilter : el)
                              return {
                                ...prevFilters,
                                [viewType]: allGraphsArray
                              }
                            })
                          }}
                        >
                          <TrashIcon color={basicColors.darkRed} size={20} />
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider className={classes.divider} />
                </div>
              ))}
            </List>
          </Box>
        </div>
      ))}
    </div>
  )
}
export default DeviationContent
