import { useEffect, useState, memo } from 'react'
// import PropTypes from 'prop-types'
import { Box, Grid, makeStyles, useMediaQuery } from '@material-ui/core'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import {
  updateAnomaliesData,
  updatedDeselected,
  anomaliesLimit,
  getAnomaliesDevices,
  putForceCloseAnomaly,
  putEditAnomaly
} from 'src/slices/pv/plantView'
// import useIsMountedRef from 'src/hooks/useIsMountedRef'
// import PerfectScrollbar from 'react-perfect-scrollbar'
import AnomaliesPeriod from 'src/components/PeriodSelection'
import AnomaliesCategoryBar from 'src/components/charts/AnomaliesCategoryBar'
import AnomaliesStatusCard from '../../shared/AnomaliesStatusCard'
import AnomalySidenav from '../../shared/AnomalySidenav'
import AvgHoursGraphView from 'src/components/charts/AvgHoursGraphView'
import DisserviceHoursGraphView from 'src/components/charts/DisserviceHoursGraphView'
import TimeDistributionGraphView from 'src/components/charts/TimeDistributionGraphView'
import AnomaliesListView from './AnomaliesListView'
import moment from 'moment'
import { getDatesFromPeriod } from '../utils'
import { alog } from 'src/utils/apioLog'
import { useSnackbar } from 'notistack'

// STILI DEL COMPONENTE
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    overflowX: 'hidden',
    height: `calc(100% - 48px - ${theme.spacing(4)}px - 80px)`
  },
  smallViewport: {
    overflowY: 'scroll'
    // height: '100% !important'
  }
}))

const identifierToCheck = ['components.inverter.production']

// COMPONENTE PRINCIPALE
const Anomalies = ({ className, ...rest }) => {
  const classes = useStyles()
  const { plantId } = useParams()
  const {
    anomaliesList,
    // anomalyConfig,
    anomaliesGeneralStatus,
    anomaliesCategoryStatus,
    avgHoursGraph,
    disserviceHours,
    timeDistribution
  } = useSelector((state) => state.pvPlantView)
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  // variabili di stato usate per gestire i cambiamenti dei valori dello store
  // questo è necessario perché l'utente può visualizzare date diversi da quelli presi dalla query (selezionano o deselezionando)
  // const [currentAnomaliesList, setCurrentAnomaliesList] = useState(JSON.parse(JSON.stringify(anomaliesList)))
  const [currentCategoryStatus, setCurrentCategoryStatus] = useState(JSON.parse(JSON.stringify(anomaliesCategoryStatus)))
  const [currentGeneralStatus, setCurrentGeneralStatus] = useState(JSON.parse(JSON.stringify(anomaliesGeneralStatus)))
  const [currentAvgHoursGraph, setCurrentAvgHoursGraph] = useState(JSON.parse(JSON.stringify(avgHoursGraph)))
  const [currentDisserviceHours, setCurrentDisserviceHours] = useState(JSON.parse(JSON.stringify(disserviceHours)))
  const [currentTimeDistribution, setCurrentTimeDistribution] = useState(JSON.parse(JSON.stringify(timeDistribution)))
  // variabili con skip e limit
  // const [skip, setSkip] = useState(0)
  // const [limit, setLimit] = useState(30)
  // Variabile di stato che rappresenta lo stato di caricamento dei dati
  const [isLoading, setIsLoading] = useState(false)
  // periodo selezionato tramite le chips
  const [customPeriod, setCustomPeriod] = useState('live')
  const [selectedDate, setSelectedDate] = useState(null)
  // variabile che mi gestisce l'apertura della sidenav
  const [openAnomaly, setOpenAnomaly] = useState(false)
  // variabile con i dati per la visualizzazione di un'anomalia nella sidenav
  const [selectedAnomaly, setSelectedAnomaly] = useState({})
  // variabile con i filtri correnti
  const [currentFilters, setCurrentFilters] = useState(null)
  // variabile che mi dice se il periodo è cambiato
  const [periodHasChanged, setPeriodHasChanged] = useState(true)

  // al mount e all'aggiornamento delle variabili riprendo i dati
  useEffect(() => {
    setIsLoading(true)
    dispatch(updatedDeselected({ type: 'all' }))
    const refDate = (selectedDate && selectedDate.toDate()) || new Date()
    // Prendo le date minima e massima da passare alle api
    const { minDate, maxDate } = getDatesFromPeriod(customPeriod, refDate)
    dispatch(updateAnomaliesData({ from: minDate, to: maxDate, plantId, q: currentFilters || null }))
    // setIsLoading(false)
    const anomaliesInterval = setInterval(() => {
      alog('dentro polling anomalie', null, 'anomalie')
      if (
        customPeriod === 'live' && (!selectedDate || (selectedDate.toDate() && moment(selectedDate).isSame(new Date(), 'day')))
      ) {
        // alog('faccio dispatch', null, 'anomalie')
        dispatch(updateAnomaliesData({ from: minDate, to: maxDate, plantId, isPolling: true, q: currentFilters || null }))
      }
    }, 60000)
    return () => {
      clearInterval(anomaliesInterval)
      // dispatch(resetPlantInfo())
    }
  }, [dispatch, plantId, customPeriod, selectedDate, currentFilters])

  // quando aggiorno la lista setto il loader a false
  useEffect(() => {
    // alog('ANOMALIES LIST CHANGED', null, 'anomalie')
    // vedo se sono tutti selezionati o meno
    if (anomaliesList[0] !== '-') {
      const isThereAnUnselectedAnomaly = anomaliesList.some((anomaly) => anomaly.selected === false)
      // console.log('isThereAnUnselectedAnomaly => ', isThereAnUnselectedAnomaly)
      if (!isThereAnUnselectedAnomaly) {
        alog('tutte le anomalie sono selezionate', null, 'anomalie')
        // setto tutte le variabili al valore totale
        setCurrentCategoryStatus(JSON.parse(JSON.stringify(anomaliesCategoryStatus)))
        setCurrentGeneralStatus(JSON.parse(JSON.stringify(anomaliesGeneralStatus)))
        setCurrentAvgHoursGraph(JSON.parse(JSON.stringify(avgHoursGraph)))
        setCurrentDisserviceHours(JSON.parse(JSON.stringify(disserviceHours)))
        setCurrentTimeDistribution(JSON.parse(JSON.stringify(timeDistribution)))
      } else {
        alog('c\'è almeno un\'anomalia non selezionata', null, 'anomalie')
        // mi preparo le variabili
        let newGeneralStatus = JSON.parse(JSON.stringify(anomaliesGeneralStatus))
        let newCategoryStatus = JSON.parse(JSON.stringify(anomaliesCategoryStatus))
        let newAvgGraph = JSON.parse(JSON.stringify(avgHoursGraph))
        let newDissGraph = JSON.parse(JSON.stringify(disserviceHours))
        let newTimeGraph = JSON.parse(JSON.stringify(timeDistribution))
        const thisPeriod = 'live'
        const type = 'remove'
        // mi scorro le anomalie e per quelle non selezionate correggo i grafici
        anomaliesList.forEach((item) => {
          if (item.selected === false) {
            const thisConfig = item.configuration
            if (thisConfig) {
              newGeneralStatus = returnGeneralStatus(newGeneralStatus, type, item)
              newCategoryStatus = returnNewCategroyStatus(newCategoryStatus, thisConfig, type)
              const thisCategory = newCategoryStatus.find((category) => category.name === thisConfig.category)
              if (thisCategory) {
                const thisSubCategory = thisCategory.subCategory.find((sub) => sub.name === thisConfig.subCategory)
                // console.log('thisSubCategory => ', thisSubCategory)
                if (thisSubCategory) {
                  // ora mi faccio i dovuti calcoli
                  newDissGraph = returnDisserviceHours(newDissGraph, thisSubCategory, thisCategory, type, item)
                  newAvgGraph = returnAvgHours(newAvgGraph, thisSubCategory, thisCategory, type, item, newDissGraph)
                  newTimeGraph = returnTimeDistribution(newTimeGraph, thisSubCategory, thisCategory, type, item, thisPeriod)
                }
              }
            }
          }
        })
        // setto i valori aggiornati
        setCurrentCategoryStatus(JSON.parse(JSON.stringify(newCategoryStatus)))
        setCurrentGeneralStatus(JSON.parse(JSON.stringify(newGeneralStatus)))
        setCurrentAvgHoursGraph(JSON.parse(JSON.stringify(newAvgGraph)))
        setCurrentDisserviceHours(JSON.parse(JSON.stringify(newDissGraph)))
        setCurrentTimeDistribution(JSON.parse(JSON.stringify(newTimeGraph)))
      }
      setPeriodHasChanged(false)
    }
    setIsLoading(false)
    // return () => {}
  }, [
    anomaliesList,
    anomaliesCategoryStatus,
    anomaliesGeneralStatus,
    avgHoursGraph,
    disserviceHours,
    timeDistribution
    // currentAnomaliesList,
  ])

  // funzione che aggiorna la lista per il pagination
  const getMoreAnomalies = (skip) => {
    const refDate = (selectedDate && selectedDate.toDate()) || new Date()
    // Prendo le date minima e massima da passare alle api
    const { minDate, maxDate } = getDatesFromPeriod(customPeriod, refDate)
    dispatch(updateAnomaliesData({ skip, from: minDate, to: maxDate, plantId, updateGraph: false, q: currentFilters || null }))
  }

  // funzione che torna il corretto categoryStatus in base al changeSelection
  const returnGeneralStatus = (prevState, type, item) => {
    const newState = JSON.parse(JSON.stringify(prevState))
    // controllo se è un'anomalia aperta o chiusa
    if (!item.endedAt || item.endedAt === '') {
      // aperta
      newState.openAnomalies =
        type === 'remove' ? newState.openAnomalies - 1 : type === 'add' ? newState.openAnomalies + 1 : newState.openAnomalies
    } else {
      // chiusa
      newState.closedAnomalies =
        type === 'remove'
          ? newState.closedAnomalies - 1
          : type === 'add'
            ? newState.closedAnomalies + 1
            : newState.closedAnomalies
    }
    // ora gestisco le ore di disservizio totali
    if (identifierToCheck.indexOf(item.configuration.identifier) > -1) {
      newState.disserviceHours =
        type === 'remove'
          ? newState.disserviceHours - moment.duration(item.graphDuration)
          : type === 'add'
            ? newState.disserviceHours + moment.duration(item.graphDuration)
            : newState.disserviceHours
      // newState.disserviceHours = Number(newState.disserviceHours).toFixed(0)
    }
    return newState
  }

  // funzione che torna il corretto categoryStatus in base al changeSelection
  const returnNewCategroyStatus = (prevState, thisConfig, type) => {
    const newState = JSON.parse(JSON.stringify(prevState))
    const categoryIndex = newState.findIndex((category) => category.name === thisConfig.category)
    if (categoryIndex > -1) {
      newState[categoryIndex].value =
        type === 'remove'
          ? newState[categoryIndex].value - 1
          : type === 'add'
            ? newState[categoryIndex].value + 1
            : newState[categoryIndex].value
      const subCategoryIndex = newState[categoryIndex].subCategory.findIndex((sub) => sub.name === thisConfig.subCategory)
      if (subCategoryIndex > -1) {
        newState[categoryIndex].subCategory[subCategoryIndex].value =
          type === 'remove'
            ? newState[categoryIndex].subCategory[subCategoryIndex].value - 1
            : type === 'add'
              ? newState[categoryIndex].subCategory[subCategoryIndex].value + 1
              : newState[categoryIndex].subCategory[subCategoryIndex].value
      }
    }
    return newState
  }

  // fuzione che ritorna il grafico con le ore totali aggiornate al changeSelection
  const returnDisserviceHours = (prevState, thisSubCategory, thisCategory, type, item) => {
    // alog('ORE TOTALI', null, 'anomalie')
    const newDissGraph = JSON.parse(JSON.stringify(prevState))
    const thisGraphIndex = newDissGraph.findIndex((data) => {
      return data.subCategory === thisSubCategory.name && data.category === thisCategory.name
    })
    if (thisGraphIndex > -1) {
      // console.log('DENTRO IF ORE TOTALI')
      // formula -> rimuovo questa durata dalla somma
      if (type === 'remove') {
        // è stato tolto un elemento, riformulo i grafici
        newDissGraph[thisGraphIndex].value = newDissGraph[thisGraphIndex].value - moment.duration(item.graphDuration)
      } else if (type === 'add') {
        // è stato aggiungo un elemento, riformulo i grafici
        newDissGraph[thisGraphIndex].value = newDissGraph[thisGraphIndex].value + moment.duration(item.graphDuration)
      }
      return newDissGraph
    } else {
      return prevState
    }
  }

  // fuzione che ritorna il grafico con le ore medie aggiornate al changeSelection
  const returnAvgHours = (prevState, thisSubCategory, thisCategory, type, item, dissHoursGraph) => {
    // alog('ORE MEDIE', null, 'anomalie')
    const newAvgGraph = JSON.parse(JSON.stringify(prevState))
    const thisGraphIndex = newAvgGraph.findIndex((data) => {
      return data.subCategory === thisSubCategory.name && data.category === thisCategory.name
    })
    const dissGraphIndex = dissHoursGraph.findIndex((data) => {
      return data.subCategory === thisSubCategory.name && data.category === thisCategory.name
    })
    if (thisGraphIndex > -1) {
      if (thisSubCategory.value === 0) {
        newAvgGraph[thisGraphIndex].value = 0
      } else {
        newAvgGraph[thisGraphIndex].value = dissHoursGraph[dissGraphIndex].value / thisSubCategory.value
      }

      // formula -> newValue = (avgDuration * totalSubCatNum - thisDuration) / (totalSubCatNum - 1)
      return newAvgGraph
    } else {
      return prevState
    }
  }

  // fuzione che ritorna il grafico con la distribuzione nel tempo aggiornato al changeSelection
  const returnTimeDistribution = (prevState, thisSubCategory, thisCategory, type, item, customPeriod) => {
    // alog('DISTRIBUZIONE NEL TEMPO', null, 'anomalie')
    // console.log('PRE newTimeGraph => ', prevState)
    // console.log('item => ', item)
    const newTimeGraph = JSON.parse(JSON.stringify(prevState))
    const thisAnomaly = JSON.parse(JSON.stringify(item))
    // const now = moment()
    const thisGraphIndex = newTimeGraph.findIndex((data) => {
      return data.name === thisSubCategory.name && data.category === thisCategory.name
    })
    if (thisGraphIndex > -1) {
      // mi scorro le severity e corretto i dati del gragico
      thisAnomaly.severity.forEach((currentSev, index) => {
        // se non ha end oppure è di oggi vado avanti, quelle che sono finire ieri non ricadono in questo grafico
        let thisStart = moment(currentSev.startedAt)
        let thisEnd = currentSev.endedAt ? moment(currentSev.endedAt) : null
        const periodDiff = customPeriod === 'live' ? 'hours' : customPeriod === 'year' ? 'months' : 'days'
        // sistemo le date
        if (customPeriod === 'live') {
          thisStart = thisStart.set({ minute: 0, second: 0, millisecond: 0 }).toISOString()
          if (thisEnd) {
            thisEnd = thisEnd.set({ minute: 0, second: 0, millisecond: 0 }).toISOString()
          }
        } else {
          thisStart = thisStart.set({ hours: 0, minute: 0, second: 0, millisecond: 0 }).toISOString()
          if (thisEnd) {
            thisEnd = thisEnd.set({ hours: 0, minute: 0, second: 0, millisecond: 0 }).toISOString()
          }
        }
        // console.log('thisStart => ', thisStart)
        // console.log('thisEnd => ', thisEnd)
        // console.log('periodDiff => ', periodDiff)
        // console.log('dentro primo if')
        newTimeGraph[thisGraphIndex].data.forEach((d) => {
          if (
            Number(currentSev.level) === d.y &&
            moment(d.x).diff(moment(thisStart), periodDiff) >= 0 &&
            (!thisEnd || (thisEnd && moment(thisEnd).diff(moment(d.x), periodDiff) >= 0))
          ) {
            // console.log('dentro ultimo if')
            // (!thisEnd || thisEnd === '' || moment(thisEnd).diff(moment(d.x), periodDiff) > 0)
            // console.log('dentro secondo if, level => ', currentSev.level)
            if (type === 'remove') {
              if (d.z === 1) {
                d.z = null
              } else {
                d.z = d.z - 1
              }
            } else if (type === 'add') {
              if (d.z === null) {
                d.z = 1
              } else {
                d.z = d.z + 1
              }
            }
          }
        })
        // if (!thisEnd || thisEnd === '' || moment(now).diff(moment(thisEnd), 'days') === 0) {}
      })
      return newTimeGraph
    } else {
      return prevState
    }
    // console.log('POST newTimeGraph => ', newTimeGraph)
  }

  // funzione che viene lanciata al change della selezione delle anomalie
  const onChangeSelection = (type, item) => {
    // alog('type => ', type, 'anomalie')
    // alog('item => ', item, 'anomalie')
    // controllo che tipo di cambiamento c'è stato
    // se è all rimetto i totali
    if (type === 'all') {
      dispatch(updatedDeselected({ type }))
      setCurrentGeneralStatus(JSON.parse(JSON.stringify(anomaliesGeneralStatus)))
      setCurrentCategoryStatus(JSON.parse(JSON.stringify(anomaliesCategoryStatus)))
      setCurrentAvgHoursGraph(JSON.parse(JSON.stringify(avgHoursGraph)))
      setCurrentDisserviceHours(JSON.parse(JSON.stringify(disserviceHours)))
      setCurrentTimeDistribution(JSON.parse(JSON.stringify(timeDistribution)))
    } else {
      // faccio dispatch all'array con i deselezionati
      dispatch(updatedDeselected({ type, uuid: item.uuid }))
      // mi trovo categoria e subCategoria per fare i calcoli
      const thisConfig = item.configuration
      if (thisConfig) {
        // in base al type vado a sistemare la category/subCategory e le anomalie totali delle card
        const newGeneralStatus = returnGeneralStatus(currentGeneralStatus, type, item)
        setCurrentGeneralStatus(newGeneralStatus)
        // let newCategoryStatus = JSON.parse(JSON.stringify(currentCategoryStatus))
        const newCategoryStatus = returnNewCategroyStatus(currentCategoryStatus, thisConfig, type)
        setCurrentCategoryStatus(newCategoryStatus)
        // console.log('newCategoryStatus => ', newCategoryStatus)
        // cerco la categoria principale
        const thisCategory = newCategoryStatus.find((category) => category.name === thisConfig.category)
        // console.log('thisCategory => ', thisCategory)
        if (thisCategory) {
          // cerco la subcategory
          const thisSubCategory = thisCategory.subCategory.find((sub) => sub.name === thisConfig.subCategory)
          // console.log('thisSubCategory => ', thisSubCategory)
          if (thisSubCategory) {
            // ora mi faccio i dovuti calcoli
            alog('MI FACCIO I CALCOLI', null, 'anomalie')
            // ORE TOTALI
            const newDissGraph = returnDisserviceHours(currentDisserviceHours, thisSubCategory, thisCategory, type, item)
            setCurrentDisserviceHours(newDissGraph)
            // ORE MEDIE
            const newAvgGraph = returnAvgHours(currentAvgHoursGraph, thisSubCategory, thisCategory, type, item, newDissGraph)
            setCurrentAvgHoursGraph(newAvgGraph)
            // DISTRIBUZIONE NEL TEMPO
            const newTimeGraph = returnTimeDistribution(
              currentTimeDistribution,
              thisSubCategory,
              thisCategory,
              type,
              item,
              customPeriod
            )
            setCurrentTimeDistribution(newTimeGraph)
          }
        }
      }
    }
  }

  // funzione che viene lanciata al click su una riga delle anomalie
  const onAnomalyOpen = (newSelected) => {
    // alog('selectedAnomaly => ', newSelected, 'anomalie')
    setSelectedAnomaly(newSelected)
    setOpenAnomaly(true)
  }

  // funzione che chiude la side
  const onAnomalyClose = () => {
    // console.log('selectedAnomaly => ', newSelected)
    setOpenAnomaly(false)
  }

  // funzione che forza la chiusura di un'anomalia
  const forceCloseAnomaly = async (_anomaly) => {
    alog('forceCloseAnomaly', _anomaly, 'anomalie')
    const newAnomaly = JSON.parse(JSON.stringify(_anomaly))
    try {
      // mando la query per chiudere l'anomalia
      await putForceCloseAnomaly(_anomaly.uuid)
      // aggiorno i dati per la sidenav
      newAnomaly.endedAt = moment().toISOString()
      newAnomaly.severity[newAnomaly.severity.length - 1].endedAt = moment().toISOString()
      // aggiorno l'anomalia della sidenav
      setSelectedAnomaly(newAnomaly)
      enqueueSnackbar('L\'anomalia è stata chiusa!', {
        variant: 'success'
      })
      // refresho i dati delle anomalie
      setTimeout(() => {
        const refDate = (selectedDate && selectedDate.toDate()) || new Date()
        // Prendo le date minima e massima da passare alle api
        const { minDate, maxDate } = getDatesFromPeriod(customPeriod, refDate)
        dispatch(updateAnomaliesData({ from: minDate, to: maxDate, plantId, q: currentFilters || null }))
      }, 900)
    } catch (err) {
      console.error('Error while forcing close anomaly, err => ', err)
      enqueueSnackbar('C\'è stato un errore nel chiudere l\'anomalia. Riprovare più tardi.', {
        variant: 'error'
      })
    }
  }

  // funzione che modifica i campi di un'anomalia
  const editAnomaly = async (newAnomaly) => {
    alog('editAnomaly, newAnomaly =>', newAnomaly, 'anomalie')
    try {
      const objToSend = {
        notes: newAnomaly.notes || '',
        externalUrl: newAnomaly.externalUrl || ''
      }
      await putEditAnomaly(newAnomaly.uuid, objToSend)
      // aggiorno l'anomalia della sidenav
      setSelectedAnomaly(newAnomaly)
      enqueueSnackbar('L\'anomalia è stata aggiornata!', {
        variant: 'success'
      })
      // refresho i dati delle anomalie
      setTimeout(() => {
        const refDate = (selectedDate && selectedDate.toDate()) || new Date()
        // Prendo le date minima e massima da passare alle api
        const { minDate, maxDate } = getDatesFromPeriod(customPeriod, refDate)
        dispatch(updateAnomaliesData({ from: minDate, to: maxDate, plantId, q: currentFilters || null }))
      }, 900)
    } catch (err) {
      console.error('Error while editing anomaly')
      enqueueSnackbar('C\'è stato un errore nel modificare l\'anomalia. Riprovare più tardi.', {
        variant: 'error'
      })
    }
  }

  // funzione lanciata al cambio dei filtri delle anomalie
  const filterChanged = async (newFilters) => {
    if (Object.keys(newFilters).length > 0) {
      alog('newFilters maggiore di 0 =>', newFilters, 'anomalie')
      setCurrentFilters(newFilters)
    } else {
      setCurrentFilters(null)
    }
  }

  const isExtraSmall = useMediaQuery(theme => theme.breakpoints.down('xs'))
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const isMedium = useMediaQuery(theme => theme.breakpoints.down('md'))

  return (
    <Box mt={1} className={isMedium ? clsx(classes.root, className, classes.smallViewport) : clsx(classes.root, className)} {...rest}>
      {/* style={{ height: '92.5%', overflowX: 'hidden' }} */}
      <Grid container spacing={1} alignItems='center'>
        <Grid item xs={12} md={4}>
          {/* <AnomaliesPeriodBar anomaliesPeriod={customPeriod} onChangePeriod={setCustomPeriod} /> */}
          <AnomaliesPeriod
            isLoading={isLoading}
            selectedDate={selectedDate}
            setSelectedDate={(date) => {
              setCurrentFilters(null)
              setSelectedDate(date)
              setPeriodHasChanged(true)
            }}
            customPeriod={customPeriod}
            setCustomPeriod={(period) => {
              setCurrentFilters(null)
              setCustomPeriod(period)
              setPeriodHasChanged(true)
            }}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <AnomaliesStatusCard anomaliesGeneralStatus={currentGeneralStatus} />
        </Grid>
      </Grid>
      {!isExtraSmall && (
        <AnomaliesCategoryBar anomaliesCategoryStatus={currentCategoryStatus} />
      )}
      <Grid
        container
        spacing={1}
        style={{ marginTop: 6, height: isMedium && !isSmall ? 700 : 'Calc(100% - 140px)', minHeight: isMedium && !isSmall ? '90%' : null }}
      >
        {/* lista anomalie */}
        <Grid item xs={12} sm={6} md={5} style={{ height: '100%' }}>
          <AnomaliesListView
            anomaliesList={anomaliesList}
            totalAnomalies={currentCategoryStatus.reduce((acc, category) => acc + category.value, 0)}
            limit={anomaliesLimit}
            getMoreAnomalies={getMoreAnomalies}
            onRowClick={onAnomalyOpen}
            onSelectedChanged={onChangeSelection}
            onFilterChange={filterChanged}
            periodHasChanged={periodHasChanged}
          />
        </Grid>
        {/* grafici */}
        <Grid item xs={12} sm={6} md={7} style={{ height: '100%' }}>
          <Grid container spacing={1} style={{ height: !isSmall ? '41%' : '50.45%' }}>
            <Grid item xs={12} md={6} style={{ height: '100%' }}>
              <AvgHoursGraphView avgHoursGraph={currentAvgHoursGraph} anomaliesCategoryStatus={currentCategoryStatus} />
            </Grid>
            <Grid item xs={12} md={6} style={{ height: '100%' }}>
              <DisserviceHoursGraphView
                disserviceHours={currentDisserviceHours}
                anomaliesCategoryStatus={currentCategoryStatus}
              />
            </Grid>
          </Grid>
          {!isSmall && (
            <Grid container spacing={1} style={{ marginTop: 4, height: '60%' }}>
              <Grid item xs={12} style={{ height: '100%' }}>
                <TimeDistributionGraphView
                  timeDistribution={currentTimeDistribution}
                  anomaliesCategoryStatus={currentCategoryStatus}
                  anomaliesPeriod={customPeriod}
                  selectedDate={selectedDate}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        {isSmall && (
          <Grid container spacing={1} style={{ marginTop: 4, height: '40%' }}>
            <Grid item xs={12} style={{ height: '100%' }}>
              <TimeDistributionGraphView
                timeDistribution={currentTimeDistribution}
                anomaliesCategoryStatus={currentCategoryStatus}
                anomaliesPeriod={customPeriod}
                selectedDate={selectedDate}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <AnomalySidenav
        open={openAnomaly}
        anomaly={selectedAnomaly}
        getAnomaliesDevices={getAnomaliesDevices}
        onClose={onAnomalyClose}
        onEdit={editAnomaly}
        onForceClose={forceCloseAnomaly}
      />
    </Box>
  )
}

export default memo(Anomalies)
