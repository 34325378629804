import { useState, memo } from 'react'
import clsx from 'clsx'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Tooltip, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Cancel'
import { Eye as ViewIcon, DownloadCloud as DownloadIcon } from 'react-feather'
import PDFViewerDrawer from 'src/components/PDFViewerDrawer'
import { basicColors } from 'src/theme'

const useStyles = makeStyles(theme => ({
  root: {},
  downloadButton: {
    color: basicColors.darkGreen,
    marginLeft: theme.spacing(2)
  },
  viewFileButton: {
    color: theme.palette.primary.main
  }
}))

function DownloadDatasheetsDialog ({ open, onClose, list = [], className, ...rest }) {
  const classes = useStyles()

  // variabile che mostra o nasconde il drawer per la visualizzazione del PDF
  const [showPDFViewer, setShowPDFViewer] = useState(false)
  // variabile che contiene nome e url del file da visualizzare
  const [selectedFile, setSelectedFile] = useState({ name: null, url: null })

  return (
    <>
      <PDFViewerDrawer
        file={selectedFile.url}
        title={selectedFile.name}
        open={showPDFViewer}
        onClose={() => {
          setShowPDFViewer(false)
          setSelectedFile({ name: null, url: null })
        }}
      />
      <Dialog fullWidth maxWidth='sm' className={clsx(classes.root, className)} open={open} onClose={onClose} {...rest}>
        <DialogTitle>
          <Box display='flex' alignItems='center' justifyContent='space-between'>
            <Typography component='div' variant='h4'>
              Scarica Documenti
            </Typography>
            <IconButton className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          {list && list.length > 0
            ? (
              <List>
                {list.map(el => (
                  <ListItem key={el.url}>
                    <ListItemText primaryTypographyProps={{ noWrap: true, style: { maxWidth: '80%' } }}>
                      <Tooltip title={el.name}>
                        <span>{el.name}</span>
                      </Tooltip>
                    </ListItemText>
                    <ListItemSecondaryAction>
                      <Tooltip title='Visualizza datasheet'>
                        <span>
                          <IconButton
                            onClick={() => {
                              setSelectedFile({ name: el.name, url: el.url })
                              setShowPDFViewer(true)
                            }}
                            className={classes.viewFileButton}
                            size='small'
                            disabled={!(el.url && el.url !== '')}
                          >
                            <ViewIcon
                              fontSize={14}
                            />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title='Scarica datasheet'>
                        <span>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation()
                              if (el.url && el.url !== '') {
                                const a = document.createElement('a')
                                document.body.appendChild(a)
                                a.style = 'display: none'
                                a.href = el.url
                                a.click()
                                window.URL.revokeObjectURL(el.url)
                              }
                            }}
                            className={classes.downloadButton}
                            size='small'
                            disabled={!(el.url && el.url !== '')}
                          >
                            <DownloadIcon
                              fontSize={14}
                            />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>)
            : null}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button variant='contained' color='secondary' onClick={onClose}>
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default memo(DownloadDatasheetsDialog)
