import { useState } from 'react'
import clsx from 'clsx'
import { Box, Button, Tooltip, Divider, IconButton, ListItemSecondaryAction, ListItemText, makeStyles, MenuItem, TextField, Typography, SvgIcon, Grid } from '@material-ui/core'
import { v4 as uuidv4 } from 'uuid'
import {
  Edit as EditIcon,
  Trash2 as TrashIcon
} from 'react-feather'
import { HexColorPicker } from 'react-colorful'
import ConfirmationDialogRaw from 'src/components/ConfirmationDialogRaw'
import OrientationDialog from '../../OrientationDialog'

const useStyles = makeStyles(() => ({
  root: {}
}))

function WeatherStationContent ({ saveOrientation, deleteOrientation, modifyOrientation, className, ...rest }) {
  const classes = useStyles()
  const { orientations, element, setElement/* , additionalData */ } = rest
  // const currentOrientation = (additionalData && additionalData !== undefined && additionalData.orientation) || ''
  const currentOrientation = element && element !== undefined
    ? element.config.length > 0
        ? element.config[0].data && element.config[0].data.orientation
            ? element.config[0].data.orientation
            : ''
        : ''
    : ''
  const number = element && element.config.length > 0
    ? element.config[0].data
        ? element.config[0].data.draw && element.config[0].data.draw.number
            ? element.config[0].data.draw.number
            : 0
        : 0
    : 0
  const color = element && element.config.length > 0
    ? element.config[0].data
        ? element.config[0].data.draw && element.config[0].data.draw.backgroundColor
            ? element.config[0].data.draw.backgroundColor
            : 'white'
        : 'white'
    : 'white'
  const [selectedOrientation, setSelectedOrientation] = useState(currentOrientation)
  const [showOrientationNameInput, setShowOrientationNameInput] = useState(false)
  const [currentNumber, setCurrentNumber] = useState(number)
  const [currentColor, setCurrentColor] = useState(color)

  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [orientationToDelete, setOrientationToDelete] = useState(null)
  const [orientationToModify, setOrientationToModify] = useState(null)

  const handleDeleteOrientation = (confirmed, orientationUuid) => {
    if (orientationUuid && orientationUuid !== undefined) {
      if (confirmed) {
        deleteOrientation(orientationUuid)
      }
      setOrientationToDelete(null)
      setShowConfirmDialog(false)
    }
  }

  const handleModifyOrientation = () => {
    modifyOrientation(orientationToModify)
    setOrientationToModify(false)
    setShowOrientationNameInput(false)
  }

  const handleNewOrientation = (orientationName) => {
    const newOrientationId = uuidv4()

    const newOrientation = {
      uuid: newOrientationId,
      name: orientationName
    }
    saveOrientation(newOrientation)
    setSelectedOrientation(newOrientationId)
    setElement(prevElement => {
      if (prevElement.config.length > 0) {
        // se l'elemento non ha l'oggetto data lo imposto a oggetto vuoto
        if (!prevElement.config[0].data) {
          prevElement.config[0].data = {}
        }
        prevElement.config[0].data =
        {
          ...prevElement.config[0].data,
          orientation: newOrientationId
        }
      } else {
        prevElement.config.push({
          devices: [],
          data: {
            orientation: newOrientationId,
            draw: {}
          }
        })
      }

      return { ...prevElement }
    })
    setShowOrientationNameInput(false)
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Typography variant='h6'>Centralina meteo</Typography>
          <Box my={2}>
            <>
              {showOrientationNameInput
                ? (
                  <OrientationDialog
                    orientationToModify={orientationToModify}
                    setOrientationToModify={setOrientationToModify}
                    handleModifyOrientation={handleModifyOrientation}
                    handleNewOrientation={handleNewOrientation}
                    open={showOrientationNameInput}
                    onClose={() => { setShowOrientationNameInput(false) }}
                  />
                  )
                : null}
              {showConfirmDialog && orientationToDelete
                ? (
                  <ConfirmationDialogRaw
                    open={showConfirmDialog}
                    onClose={(confirmed) => handleDeleteOrientation(confirmed, orientationToDelete.uuid)}
                    title='Elimina Orientamento'
                    description={`Sei sicuro di voler eliminare l'orientamento "${orientationToDelete.name || ''}"?`}
                  />
                  )
                : null}
              <TextField
                color='primary'
                size='small'
                variant='outlined'
                select
                fullWidth
                SelectProps={{
                  renderValue: (selected) => <ListItemText style={{ marginTop: 0, marginBottom: 0 }}>{orientations.find(or => or.uuid === selected) ? orientations.find(or => or.uuid === selected).name : ''}</ListItemText>
                }}
                label='Seleziona Orientamento'
                value={selectedOrientation || ''}
                onChange={e => {
                  setSelectedOrientation(e.target.value)
                  setElement(prevElement => {
                    if (prevElement.config.length > 0) {
                      // se l'elemento non ha l'oggetto data lo imposto a oggetto vuoto
                      if (!prevElement.config[0].data) {
                        prevElement.config[0].data = {}
                      }
                      prevElement.config[0].data =
                      {
                        ...prevElement.config[0].data,
                        orientation: e.target.value
                      }
                    } else {
                      prevElement.config.push({
                        devices: [],
                        data: {
                          orientation: e.target.value,
                          draw: {}
                        }
                      })
                    }

                    return { ...prevElement }
                  })
                }}
              >
                {orientations.map(el => (
                  <MenuItem dense key={el.uuid || el.id} value={el.uuid || el.id}>
                    <ListItemText>{el.name}</ListItemText>
                    <ListItemSecondaryAction>
                      <Tooltip title='Modifica Orientamento'>
                        <IconButton
                          onClick={() => {
                            setShowOrientationNameInput(true)
                            setOrientationToModify(el)
                          }} edge='start'
                        >
                          <SvgIcon fontSize='small'>
                            <EditIcon />
                          </SvgIcon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Elimina Orientamento'>
                        <IconButton
                          onClick={() => {
                            setShowConfirmDialog(true)
                            setOrientationToDelete(el)
                          }} edge='end'
                        >
                          <SvgIcon fontSize='small'>
                            <TrashIcon />
                          </SvgIcon>
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </MenuItem>
                ))}
                <Divider />
                <MenuItem
                  dense
                  className={classes.transparentMenuItem}
                >
                  <Button onClick={() => setShowOrientationNameInput(true)} fullWidth>
                    Aggiungi orientamento
                  </Button>
                </MenuItem>
              </TextField>
              <Box mt={2} width='100%'>
                <TextField
                  fullWidth
                  size='small'
                  variant='outlined'
                  label='Numero'
                  type='number'
                  placeholder='0'
                  value={currentNumber || ''}
                  onChange={(e) => {
                    e.persist()
                    setElement(prevElement => {
                      if (prevElement.config.length > 0) {
                        // se l'elemento non ha l'oggetto data lo imposto a oggetto vuoto
                        if (!prevElement.config[0].data) {
                          prevElement.config[0].data = {}
                        }
                        // se l'elemento non ha l'oggetto draw lo imposto a oggetto vuoto
                        if (!prevElement.config[0].data.draw) {
                          prevElement.config[0].data.draw = {}
                        }
                        prevElement.config[0].data.draw = {
                          ...prevElement.config[0].data.draw,
                          number: e.target.value
                        }
                      } else {
                        prevElement.config.push({
                          devices: [],
                          data: {
                            draw: {
                              number: e.target.value
                            }
                          }
                        })
                      }
                      return { ...prevElement }
                    })
                    setCurrentNumber(e.target.value)
                  }}
                />
              </Box>
            </>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant='h6'>Colore di sfondo</Typography>
          <Box my={2} width='100%'>
            <HexColorPicker
              style={{ height: '100px' }} color={currentColor} onChange={setCurrentColor} onMouseUp={(e) => {
                e.persist()
                setElement((prevElement) => {
                  // console.log(prevElement)
                  if (prevElement.config.length > 0) {
                    if (!prevElement.config[0].data.draw) {
                      prevElement.config[0].data.draw = {}
                    }
                    prevElement.config[0].data.draw = {
                      ...prevElement.config[0].data.draw,
                      backgroundColor: currentColor
                    }
                  } else {
                    prevElement.config.push({
                      devices: [],
                      data: {
                        draw: {
                          backgroundColor: currentColor
                        }
                      }
                    })
                  }
                  return { ...prevElement }
                })
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}
export default WeatherStationContent
