import { createSlice } from '@reduxjs/toolkit'
import api from 'src/utils/api'
import { alog } from 'src/utils/apioLog'

// funzione che torna la percentuale passati 2 valori
const percentage = (partialValue, totalValue) => {
  const results = (100 * partialValue) / totalValue
  return results.toFixed(0)
}

// coordinate dell'Italia sotto forma di array di array dove ogni punto a latitudine e longitudine
// minimo 3 punti (per avere una figura chiusa)
// centro dell'italia ha le seguenti coordinate = [43, 12] ([LNG,LAT])
const geoItaly = [
  [6.240234374999999, 36.491973470593685],
  [19.423828125, 36.491973470593685],
  [19.423828125, 47.30903424774781],
  [6.240234374999999, 47.30903424774781],
  [6.240234374999999, 36.491973470593685]
]

// array con un valore numero per gli stati
const statusToNumber = {
  ok: 0,
  maintenance: 1,
  warning: 1,
  error: 2
}
// funzione che ordina array impianti normalizzati per stato
const statusSort = () => {
  // se c'è da fare qualcosa in più va qui
  return (a, b) => {
    // considerando che vogliono vedere prima gli impianti in errore
    // e che questi hanno status.general === 'error', assegno un valore ad ogni parola
    const x = statusToNumber[a.status.general]
    const y = statusToNumber[b.status.general]
    if (x > y) {
      return -1
    }

    if (x < y) {
      return 1
    }

    return b.powerPeak - a.powerPeak
    // const result = x > y ? -1 : x < y ? 1 : 0
    // return result
  }
}

// variabile base di un impianto per mostrare i dati in tabella e mappa
// LEGENDA valore dello status
// se il dato non è possibile reperirlo il value è null
// general: ‘ok’ - ‘maintenance’ - ‘error’
// climate: ‘sun’ - ‘cloud’ - ‘rain’ - ‘night’
// performance: ‘up’ - ‘down’
// online: true/false
// const basicNormPlant0 = {
// 	uuid: 'demo',
// 	name: 'Impianto X',
// 	plantType: 'PV',
// 	powerPeak: 200,
// 	production: '59',
// 	pr: '0.5',
// 	irradiation: '10',
// 	moduleTemp: '15',
// 	generalTemp: '15',
// 	status: {
// 		general: 'ok',
// 		climate: 'sun',
// 		performance: 'up',
// 		online: 'online',
// 	},
// 	location: {
// 		address: 'via Mare Adriatico 75, 65010, Spoltore (PE)',
// 		geolocation: {
// 			lnt: '',
// 			lng: '',
// 		},
// 	},
// }
// impianto in manutenzione
// const basicNormPlant1 = {
// 	uuid: 'demo',
// 	name: 'Impianto X',
// 	plantType: 'PV',
// 	powerPeak: 200,
// 	production: '59',
// 	pr: '0.5',
// 	irradiation: '10',
// 	moduleTemp: '15',
// 	generalTemp: '15',
// 	status: {
// 		general: 'maintenance',
// 		climate: 'cloud',
// 		performance: 'up',
// 		online: 'offline',
// 	},
// 	location: {
// 		address: 'via Mare Adriatico 75, 65010, Spoltore (PE)',
// 		geolocation: {
// 			lnt: '',
// 			lng: '',
// 		},
// 	},
// }
// impianto in errore
// const basicNormPlant2 = {
// 	uuid: 'demo',
// 	name: 'Impianto X',
// 	plantType: 'PV',
// 	powerPeak: 200,
// 	production: '59',
// 	pr: '0.5',
// 	irradiation: '10',
// 	moduleTemp: '15',
// 	generalTemp: '15',
// 	status: {
// 		general: 'error',
// 		climate: 'rain',
// 		performance: 'down',
// 		online: 'offline',
// 	},
// 	location: {
// 		address: 'via Mare Adriatico 75, 65010, Spoltore (PE)',
// 		geolocation: {
// 			lnt: '',
// 			lng: '',
// 		},
// 	},
// }

// stato dello slice, tutte le variabili principali
const initialState = {
  getHasError: null,
  filters: {
    address: ''
  },
  plants: [],
  normalizedPlants: ['-'],
  statusBar: {
    total: 0,
    error: {
      value: 0,
      percentage: 0
    },
    warning: {
      value: 0,
      percentage: 0
    },
    good: {
      value: 0,
      percentage: 0
    }
  },
  plantsType: ['Tutti', 'PV', 'LED', 'Industry Consumption', 'CHP', 'CCHP', 'CA', 'TRS']
}

// SLICE = state + reducers
const slice = createSlice({
  name: 'maps',
  initialState,
  reducers: {
    setNormalizedPlants (state, action) {
      const { plants, hasError } = action.payload
      alog('plants payload => ', plants, 'mapslice')
      // console.log('plants hasError => ', hasError)
      if (hasError) {
        state.getHasError = hasError
      } else {
        // state.normalizedPlants = [
        //  ...Array(16).fill(basicNormPlant0),
        //  ...Array(4).fill(basicNormPlant2),
        //  ...Array(1).fill(basicNormPlant1),
        // ]
        // mi preparo il nuovo array di impianti
        const newPlants = []
        plants.forEach((plant) => {
          const toPush = {
            uuid: plant.uuid,
            name: plant.name,
            plantType: plant.metadata.plantType || '',
            powerPeak: plant.metadata.peakPower || '0',
            engine: plant.metadata.engine || {},
            production: '59',
            pr: '0.5',
            irradiation: '10',
            moduleTemp: '15',
            generalTemp: '15',
            status: {
              general: plant.status.general || null,
              climate: plant.status.climate || null,
              performance: plant.status.performance || null,
              online: plant.status.online || null
            },
            location: plant.location
          }
          newPlants.push(toPush)
        })
        // assegno il nuovo array alla mia variabile di stato
        state.normalizedPlants = newPlants
        // faccio il sort in base allo status
        state.normalizedPlants.sort(statusSort())
        // calcolo i valori per lo statusBar
        if (state.normalizedPlants.length > 0) {
          state.statusBar.total = state.normalizedPlants.length
          state.statusBar.error.value = state.normalizedPlants.filter(
            (el) => el.status.general === 'error'
          ).length
          state.statusBar.warning.value = state.normalizedPlants.filter(
            (el) => el.status.general === 'maintenance'
          ).length
          state.statusBar.warning.value = state.normalizedPlants.filter(
            (el) => el.status.general === 'warning'
          ).length
          state.statusBar.good.value = state.normalizedPlants.filter(
            (el) => el.status.general === 'ok'
          ).length
          // calcolo le percentuali
          state.statusBar.error.percentage = percentage(
            state.statusBar.error.value,
            state.statusBar.total
          )
          state.statusBar.warning.percentage = percentage(
            state.statusBar.warning.value,
            state.statusBar.total
          )
          state.statusBar.good.percentage = percentage(
            state.statusBar.good.value,
            state.statusBar.total
          )
        }
      }
    },
    resetMapsViewToDefault (state) {
      state.getHasError = null
      state.filters = { address: '' }
      // state.plants = []
      // state.normalizedPlants = ['-']
      // state.statusBar = {
      //   total: 0,
      //   error: {
      //     value: 0,
      //     percentage: 0
      //   },
      //   warning: {
      //     value: 0,
      //     percentage: 0
      //   },
      //   good: {
      //     value: 0,
      //     percentage: 0
      //   }
      // }
      state.plantsType = ['Tutti', 'PV', 'LED', 'Industry Consumption', 'CHP', 'CCHP', 'CA', 'TRS']
      state = { ...initialState }
    }
  }
})

export const reducer = slice.reducer

// Se non vengono passati dati di geolocalizzazione imposto l'italia
// puoi vedre il quadrato dell'italia qui: https://geojson.io/#map=6/42.569/12.415
export const getPlants = ({ q = null, coordinates = geoItaly, filter = null }) => async (
  dispatch
) => {
  const params = {}

  if (q) {
    params.q = JSON.stringify(q)
  } else {
    params.coordinates = JSON.stringify(coordinates)
  }

  if (filter) {
    params.filter = filter
  }

  let plants = []
  let hasError = null
  try {
    plants = await api.getResource('plantsMap', { params })
    hasError = null
  } catch (err) {
    console.error('plantsMap err => ', err)
    hasError = 'generic'
  }
  dispatch(slice.actions.setNormalizedPlants({ plants, hasError }))
}

// funzione che azzera la view mappe
export const resetMapsView = () => (dispatch) => {
  dispatch(slice.actions.resetMapsViewToDefault())
}
