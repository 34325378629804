import { useEffect, useState } from 'react'
import clsx from 'clsx'
import { Box, Card, IconButton, makeStyles, Tooltip, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, CardContent, InputBase, Grid, FormControl, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { updateAddedDatalogger, addDatalogger, changeContractValue, changeModuleValue, changeModuleValues, changeModuleValuesAndOrientations, deleteDatalogger, getDataloggers, updateDeviceTypeDocuments, updatePlantDocuments, changeContractValueAndOrientations, changeSelectedDistributionPower } from 'src/slices/pv/plant'
import { Eye as ViewIcon, Trash2 as DeleteIcon, FilePlus as LoadIcon, PlusCircle as PlusIcon, DownloadCloud as DownloadIcon } from 'react-feather'
import { getAllDeviceTypes, getAllDocuments, getDevices, getDevicesNumber } from 'src/utils/plantOperations'
import ConfirmationDialogRaw from 'src/components/ConfirmationDialogRaw'
import { useSnackbar } from 'notistack'
import { deleteDocuments, loadDocuments } from 'src/views/systems/SystemsListView/AddPlantDialog/shared/utils'
import { basicColors } from 'src/theme'
import { withStyles } from '@material-ui/styles'
import { calculateOrientationsSurfaces, getTableHeaders } from './utils'
import LoadFilesDialog from '../shared/LoadFilesDialog'
import LoadDatasheetDialog from '../shared/LoadDatasheetDialog'
import PDFViewerDrawer from 'src/components/PDFViewerDrawer'

const useStyles = makeStyles(theme => ({
  root: {},
  container: {
    margin: theme.spacing(0, -2, -3, -2)
  },
  stepDescription: {
    color: theme.palette.text.secondary
  },
  deleteButton: {
    color: theme.palette.alert.error,
    marginLeft: theme.spacing(2)
  },
  downloadButton: {
    color: basicColors.darkGreen,
    marginLeft: theme.spacing(2)
  },
  viewFileButton: {
    color: theme.palette.primary.main
  },
  cardDescription: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2)
  },
  iconButton: {
    color: theme.palette.text.primary,
    width: 16,
    height: 16
  },
  input: {
    color: basicColors.blueGrayDark
  }
}))

const LightTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#BBE4F1',
    color: basicColors.blueGrayDark,
    fontWeight: '600'
  },
  body: {
    backgroundColor: basicColors.lightBlue2,
    color: basicColors.blueGrayDark
  }
}))(TableCell)

const DarkTableCell = withStyles(() => ({
  head: {
    backgroundColor: basicColors.secondary,
    color: 'white',
    fontWeight: '600'
  },
  body: {
    backgroundColor: '#107FA9',
    color: 'white',
    fontWeight: '600'
  }
}))(TableCell)

function PlantComponents ({ className, ...rest }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const { uuid, documents, distribution, useInvertersForProducedEnergy, useProductionMeterPower, planimetry, orientations, dataloggers, addedDataloggers, selectedDatalogger, module } = useSelector(state => state.pvPlant)

  // console.log('PlantComponent - dataloggers: ', dataloggers)
  // console.log('PlantComponent - module: ', module)
  // console.log('PlantComponent - addedDataloggers: ', addedDataloggers)
  // console.log('PlantComponent - devices: ', devices)

  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  // variabile che mostra o nasconde il drawer per la visualizzazione del PDF
  const [showPDFViewer, setShowPDFViewer] = useState(false)
  // variabile che contiene nome e url del file da visualizzare
  const [selectedFile, setSelectedFile] = useState({ name: null, url: null })
  const [dataloggerToDelete, setDataloggerToDelete] = useState(null)
  // Variabile per mostrare o nascondere la dialog di caricamento del datasheet
  const [showLoadDialog, setShowLoadDialog] = useState(false)
  // Variabile per mostrare o nascondere la dialog di caricamento del datasheet
  const [showLoadFilesDialog, setShowLoadFilesDialog] = useState(false)
  // Variabile per salvare la lista dei dispositivi da passare alla dialog di caricamento datasheet
  const [selectedDevices, setSelectedDevices] = useState(null)

  // Funzione che gestisce la selezione tramite select e l'aggiunta del datalogger selezionato alla lista dei dataloggers di impianto
  const handleDataloggerChange = (event) => {
    dispatch(addDatalogger(event.target.value))
  }

  // Funzione che rimuove i dataloggers dalla lista dei dataloggers aggiunti ad un impianto
  const handleDeleteDatalogger = (confirmed, dataloggerId) => {
    if (dataloggerId && dataloggerId !== undefined) {
      if (confirmed) {
        dispatch(deleteDatalogger(dataloggerId))
        enqueueSnackbar('Datalogger eliminato con successo!', {
          variant: 'success'
        })
      }
      setDataloggerToDelete(null)
      setShowConfirmDialog(false)
    }
  }

  const handleChangeModuleValues = (moduleIndex, value, key) => {
    dispatch(changeModuleValues(moduleIndex, value, key))
  }

  const handleDimensionsChange = (moduleIndex, value, key) => {
    // const moduleWidth = key === 'width' ? value : module.rows && module.rows.length > 0 ? module.rows[0].width : 0
    // const moduleHeight = key === 'height' ? value : module.rows && module.rows.length > 0 ? module.rows[0].height : 0
    // Superficie in metri quadri
    // const surface = (moduleWidth / 1000) * (moduleHeight / 1000) * (moduleEfficency / 100)
    // const newOrientations = calculateOrientationsSurfaces(surface, distribution, planimetry.config, orientations)

    let plantPowerPeak = 0
    Object.keys(distribution).forEach(key => {
      const currentDistributionPower = typeof distribution[key].power === 'string'
        ? Number(distribution[key].power.replaceAll(',', '.'))
        : distribution[key].power
      if (currentDistributionPower) {
        // Valore di potenza massima prima dell'update
        const currentMaxPower = module.rows && module.rows.length > 0 ? Number(module.rows[0].maxPower) : null
        if (currentMaxPower) {
          // console.log('PlantComponents - currentDistributionPower: ', currentDistributionPower)
          // console.log('PlantComponents - currentMaxPower: ', currentMaxPower)
          // console.log('PlantComponents - value: ', value)
          const newCurrentDistributionPower = Number(currentDistributionPower) / Number(currentMaxPower) * Number(value)
          // console.log('PlantComponents - newCurrentDistributionPower: ', newCurrentDistributionPower)
          dispatch(changeSelectedDistributionPower(Number(newCurrentDistributionPower.toFixed(2)), key))
          plantPowerPeak += newCurrentDistributionPower
        } else {
          dispatch(changeSelectedDistributionPower(Number(currentDistributionPower.toFixed(2)), key))
          plantPowerPeak += currentDistributionPower
        }
      }
    })

    const moduleEfficency = key === 'efficency' ? value : module.rows && module.rows.length > 0 ? Number(module.rows[0].efficency) / 100 : 0
    const moduleMaxPower = key === 'maxPower' ? value : module.rows && module.rows.length > 0 ? Number(module.rows[0].maxPower) : 0
    // Superficie in metri quadri
    // const surface = (moduleWidth / 1000) * (moduleHeight / 1000) * (moduleEfficency / 100)
    const newOrientations = calculateOrientationsSurfaces(moduleEfficency, moduleMaxPower, distribution, planimetry.config, orientations)
    // console.log('PlantComponents - newOrientations: ', newOrientations)
    dispatch(changeModuleValuesAndOrientations(moduleIndex, value, key, newOrientations))
    dispatch(changeContractValueAndOrientations(plantPowerPeak.toFixed(2), 'peakPower', newOrientations))
  }

  const handleChange = (data, name) => {
    if (name === 'moduleType') {
      dispatch(changeModuleValue(data, name))
    } else {
      dispatch(changeContractValue(data, name))
    }
  }

  // Funzione che carica i file di impianto o di tipo di device
  const loadFiles = async (files, deviceType, deviceTypeId) => {
    const response = await loadDocuments(files, deviceType, deviceTypeId)
    const responseLength = (response && response.length) || -1
    if (responseLength > 0) {
      const lastResponseElement = response[responseLength - 1]
      if (deviceType === 'plants') {
        const { metadata } = lastResponseElement || {}
        const { documents } = metadata || {}
        if (documents) {
          dispatch(updatePlantDocuments(documents))
        }
      } else {
        // TODO: aggiornare solo i documenti nel deviceType
        if (lastResponseElement) {
          const { metadata } = lastResponseElement || {}
          const { documents } = metadata || {}
          if (documents) {
            dispatch(updateDeviceTypeDocuments(documents, lastResponseElement.uuid))
          }

          // dispatch(updateAddedDatalogger(lastResponseElement.nodeId, lastResponseElement))
        }
      }
    }
  }

  // Funzione che elimina i file di impianto o di tipo di device
  const deleteFiles = async (fileIndex, deviceType, deviceTypeId) => {
    try {
      const response = await deleteDocuments(fileIndex, deviceType, deviceTypeId)
      if (response) {
        if (deviceType === 'plants') {
          const { metadata } = response || {}
          const { documents } = metadata || {}
          if (documents) {
            dispatch(updatePlantDocuments(documents))
          }
        } else {
          // TODO: aggiornare solo i documenti nel deviceType
          const { metadata } = response || {}
          const { documents } = metadata || {}
          if (documents) {
            dispatch(updateDeviceTypeDocuments(documents, response.uuid))
          }
          // dispatch(updateAddedDatalogger(response.nodeId, response))
        }
        enqueueSnackbar('Documento eliminato con successo!', {
          variant: 'success'
        })
      } else {
        enqueueSnackbar('Errore durante la cancellazione del documento', {
          variant: 'error'
        })
      }
    } catch (e) {
      enqueueSnackbar('Errore durante la cancellazione del documento', {
        variant: 'error'
      })
    }
  }

  const handleChangePowerFlags = (data, name) => {
    dispatch(changeContractValue(data, name))
  }

  useEffect(() => {
    dispatch(getDataloggers({ hasNoPlant: 'true' }))
  }, [dispatch])

  const allDocuments = [...documents]
    .map((doc, docIndex) => ({ ...doc, type: 'plants', resourceName: 'Impianto', resourceId: uuid, fileIndex: docIndex }))
    .concat(getAllDocuments({ dataloggers: addedDataloggers }))

  const allDeviceTypes = [{ uuid, name: 'Impianto', documents }].concat(getAllDeviceTypes({ dataloggers: addedDataloggers }))

  return (
    <>
      <PDFViewerDrawer
        file={selectedFile.url}
        title={selectedFile.name}
        open={showPDFViewer}
        onClose={() => {
          setShowPDFViewer(false)
          setSelectedFile({ name: null, url: null })
        }}
      />
      {
        showLoadDialog
          ? (
            <LoadDatasheetDialog
              onLoad={updateAddedDatalogger}
              devices={selectedDevices}
              open={showLoadDialog}
              viewFile={(file) => {
                setSelectedFile(file)
                setShowPDFViewer(true)
              }}
              onClose={() => {
                setShowLoadDialog(false)
                setSelectedDevices(null)
              }}
            />)
          : null
      }
      {showConfirmDialog && dataloggerToDelete
        ? (
          <ConfirmationDialogRaw
            open={showConfirmDialog}
            onClose={(confirmed) => handleDeleteDatalogger(confirmed, dataloggerToDelete.uuid)}
            title='Elimina Datalogger'
            description={`Sei sicuro di voler eliminare il datalogger "${dataloggerToDelete.name || ''}"?`}
          />)
        : null}
      <Formik
        initialValues={{
          selectedDatalogger
        }}
        validationSchema={Yup.object().shape({
          plantType: Yup.string().max(255).required('Seleziona il tipo di impianto')
        })}
      >
        {({
          errors,
          touched,
          values
        }) => (
          <form
            noValidate
            className={clsx(classes.root, className)}
            {...rest}
          >
            <Box my={3}>
              <Box mb={2}>
                <Typography className={classes.stepDescription} variant='body2'>Aggiungi i dispositivi associati</Typography>
              </Box>
              <TextField
                error={Boolean(touched.selectedDatalogger && errors.selectedDatalogger)}
                fullWidth
                select
                size='small'
                name='selectedDatalogger'
                helperText={touched.selectedDatalogger && errors.selectedDatalogger}
                label='Aggiungi un datalogger'
                value={values.selectedDatalogger}
                onChange={handleDataloggerChange}
                variant='outlined'
              >
                {dataloggers.length === 0
                  ? <MenuItem value={null} disabled>Non ci sono dataloggers disponibili</MenuItem>
                  : dataloggers.map(device => (
                    <MenuItem key={device.uuid} value={device.uuid}>
                      {device.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Box>
            {addedDataloggers.length > 0
              ? (
                <>
                  <Typography variant='body2'>Datalogger aggiunti</Typography>
                  <Box mt={2}>
                    <Card>
                      <Table size='small'>
                        <TableHead>
                          <TableRow>
                            <DarkTableCell>Nome</DarkTableCell>
                            <DarkTableCell>N. inverter</DarkTableCell>
                            <DarkTableCell>N. centraline</DarkTableCell>
                            <DarkTableCell>N. contatori</DarkTableCell>
                            <DarkTableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {addedDataloggers && addedDataloggers.map(datalogger => {
                            return (
                              <TableRow key={datalogger.uuid}>
                                <LightTableCell>{datalogger.name}</LightTableCell>
                                <LightTableCell>
                                  <Box display='flex' justifyContent='space-between' alignItems='center'>
                                    {getDevicesNumber({ datalogger, type: 'Inverter' })}
                                    <Tooltip title='Carica il datasheet'>
                                      <span>
                                        <IconButton
                                          disabled={getDevicesNumber({ datalogger, type: 'Inverter' }) === 0}
                                          onClick={() => {
                                            setShowLoadDialog(true)
                                            setSelectedDevices(getDevices({ dataloggers: [datalogger], type: 'Inverter' }))
                                          }}
                                          size='small'
                                        >
                                          <LoadIcon size={22} style={getDevicesNumber({ datalogger, type: 'Inverter' }) === 0 ? { stroke: 'rgba(38,50,56,0.5)' } : { stroke: 'rgba(38,50,56,1)' }} />
                                        </IconButton>
                                      </span>
                                    </Tooltip>
                                  </Box>
                                </LightTableCell>
                                <LightTableCell>
                                  <Box display='flex' justifyContent='space-between' alignItems='center'>
                                    {getDevicesNumber({ datalogger, type: 'SunMeter' })}
                                    <Tooltip title='Carica il datasheet'>
                                      <span>
                                        <IconButton
                                          disabled={getDevicesNumber({ datalogger, type: 'SunMeter' }) === 0}
                                          onClick={() => {
                                            setShowLoadDialog(true)
                                            setSelectedDevices(getDevices({ dataloggers: [datalogger], type: 'SunMeter' }))
                                          }} size='small'
                                        >
                                          <LoadIcon size={22} style={getDevicesNumber({ datalogger, type: 'SunMeter' }) === 0 ? { stroke: 'rgba(38,50,56,0.5)' } : { stroke: 'rgba(38,50,56,1)' }} />
                                        </IconButton>
                                      </span>
                                    </Tooltip>
                                  </Box>
                                </LightTableCell>
                                <LightTableCell>
                                  <Box display='flex' justifyContent='space-between' alignItems='center'>
                                    {getDevicesNumber({ datalogger, type: 'EnergyMeter' })}
                                    <Tooltip title='Carica il datasheet'>
                                      <span>
                                        <IconButton
                                          disabled={getDevicesNumber({ datalogger, type: 'EnergyMeter' }) === 0}
                                          onClick={() => {
                                            setShowLoadDialog(true)
                                            setSelectedDevices(getDevices({ dataloggers: [datalogger], type: 'EnergyMeter' }))
                                          }} size='small'
                                        >
                                          <LoadIcon size={22} style={getDevicesNumber({ datalogger, type: 'EnergyMeter' }) === 0 ? { stroke: 'rgba(38,50,56,0.5)' } : { stroke: 'rgba(38,50,56,1)' }} />
                                        </IconButton>
                                      </span>
                                    </Tooltip>
                                  </Box>
                                </LightTableCell>
                                <LightTableCell align='right'>
                                  <IconButton
                                    onClick={() => {
                                      setShowConfirmDialog(true)
                                      setDataloggerToDelete(datalogger)
                                    }}
                                    className={classes.deleteButton}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </LightTableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </Card>
                  </Box>
                </>
                )
              : (
                <Typography variant='body2'>Nessun datalogger associato all&apos; impianto</Typography>
                )}
            <Grid container spacing={2} style={{ marginTop: 16 }}>
              <Grid item xs={12} md={6}>
                <Typography variant='body2'>Contabilizzazione energia prodotta</Typography>
                <FormControl component='fieldset' style={{ marginTop: 16, width: '100%' }}>
                  <RadioGroup
                    name='useInvertersForProducedEnergy'
                    value={`${useInvertersForProducedEnergy}`}
                    onChange={(event) => { handleChangePowerFlags(event.target.value === 'true', 'useInvertersForProducedEnergy') }}
                  >
                    <Box display='flex' alignItems='center'>
                      <FormControlLabel
                        value='false'
                        control={<Radio color='primary' />}
                        label='Contatore'
                      />
                      <FormControlLabel
                        style={{ marginLeft: 32 }}
                        value='true'
                        control={<Radio color='primary' />}
                        label='Inverter'
                      />
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant='body2'>Contabilizzazione potenza prodotta</Typography>
                <FormControl component='fieldset' style={{ marginTop: 16, width: '100%' }}>
                  <RadioGroup
                    name='useProductionMeterPower'
                    value={`${useProductionMeterPower}`}
                    onChange={(event) => { handleChangePowerFlags(event.target.value === 'true', 'useProductionMeterPower') }}
                  >
                    <Box display='flex' alignItems='center'>
                      <FormControlLabel
                        value='false'
                        control={<Radio color='primary' />}
                        label='Contatore'
                      />
                      <FormControlLabel
                        style={{ marginLeft: 32 }}
                        value='true'
                        control={<Radio color='primary' />}
                        label='Inverter'
                      />
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Box mt={2}>
              <Typography variant='body2'>Moduli</Typography>
            </Box>
            <Box my={1}>
              <Card>
                <CardContent>
                  <Box display='flex' alignItems='center'>
                    <Typography component='div' className={classes.cardDescription} variant='body2'>
                      Caratteristiche
                    </Typography>
                    <TextField
                      error={Boolean(touched.moduleType && errors.moduleType)}
                      fullWidth
                      size='small'
                      name='moduleType'
                      helperText={touched.moduleType && errors.moduleType}
                      label='Modello Modulo'
                      value={module.model}
                      onChange={(e) => handleChange(e.target.value, 'moduleType')}
                      variant='outlined'
                    />
                  </Box>
                </CardContent>
                {
                  module.rows.map((module, index) => (
                    <Table size='small' key={`module-${index}`}>
                      <TableHead>
                        <TableRow>
                          {getTableHeaders(module).map(title => (
                            <DarkTableCell key={title}>{title}</DarkTableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          {Object.keys(module).map((key, keyIndex) => (
                            <LightTableCell key={`module-row-${keyIndex}`} align='center'>
                              <InputBase
                                classes={{ input: classes.input }}
                                fullWidth
                                type='number'
                                placeholder='0'
                                onChange={(e) => (key === 'efficency' || key === 'maxPower')
                                  ? handleDimensionsChange(index, e.target.value, key)
                                  : handleChangeModuleValues(index, e.target.value, key)}
                                value={module[key] || ''}
                              />
                            </LightTableCell>
                          ))}
                        </TableRow>
                      </TableBody>
                    </Table>
                  ))
                }
              </Card>
            </Box>
            <Box mt={2}>
              <Typography variant='body2'>Documenti</Typography>
            </Box>
            <Box my={1}>
              <Card>
                {showLoadFilesDialog
                  ? <LoadFilesDialog
                      loadFiles={loadFiles}
                      deviceTypes={allDeviceTypes}
                      open={showLoadFilesDialog}
                      onClose={() => setShowLoadFilesDialog(false)}
                    />
                  : null}
                <CardContent>
                  <Box display='flex' alignItems='center' justifyContent='space-between'>
                    <Typography variant='h4'>Documenti di progetto</Typography>
                    <Tooltip title={uuid ? 'Aggiungi documento' : 'Salva l\'impianto prima di poter caricare documenti'}>
                      <span>
                        <IconButton size='small' disabled={!uuid} onClick={() => setShowLoadFilesDialog(true)}>
                          <PlusIcon style={uuid ? { stroke: '#fff' } : {}} />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Box>
                </CardContent>
                <CardContent>
                  {allDocuments.length > 0
                    ? (
                      <div className={classes.container}>
                        <Table size='small'>
                          <TableHead>
                            <TableRow>
                              <DarkTableCell>Documento</DarkTableCell>
                              <DarkTableCell>Tipo di documento</DarkTableCell>
                              <DarkTableCell align='right'>Azioni</DarkTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {allDocuments.map(doc => (
                              <TableRow key={doc.url}>
                                <LightTableCell>{doc.name}</LightTableCell>
                                <LightTableCell>{doc.resourceName}</LightTableCell>
                                <LightTableCell align='right'>
                                  <Box display='flex' justifyContent='flex-end'>
                                    <Tooltip title='Visualizza documento'>
                                      <span>
                                        <IconButton
                                          onClick={() => {
                                            setSelectedFile({ name: doc.name, url: doc.url })
                                            setShowPDFViewer(true)
                                          }}
                                          className={classes.viewFileButton}
                                          size='small'
                                        >
                                          <ViewIcon fontSize={14} />
                                        </IconButton>
                                      </span>
                                    </Tooltip>
                                    <Tooltip title='Scarica documento'>
                                      <span>
                                        <IconButton
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            if (doc.url && doc.url !== '') {
                                              const a = document.createElement('a')
                                              document.body.appendChild(a)
                                              a.style = 'display: none'
                                              a.href = doc.url
                                              a.click()
                                              window.URL.revokeObjectURL(doc.url)
                                            }
                                          }}
                                          className={classes.downloadButton}
                                          size='small'
                                          disabled={!(doc.url && doc.url !== '')}
                                        >
                                          <DownloadIcon
                                            fontSize={14}
                                          />
                                        </IconButton>
                                      </span>
                                    </Tooltip>
                                    <Tooltip title='Elimina documento'>
                                      <IconButton onClick={() => deleteFiles(doc.fileIndex, doc.type, doc.resourceId)} className={classes.deleteButton} size='small'>
                                        <DeleteIcon fontSize={14} />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                </LightTableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </div>
                      )
                    : null}
                </CardContent>
              </Card>
            </Box>
          </form>
        )}
      </Formik>
    </>
  )
}
export default PlantComponents
