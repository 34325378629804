import { createSlice } from '@reduxjs/toolkit'

import moment from 'moment'
import api from 'src/utils/api'
import { alog } from 'src/utils/apioLog'
import { createGuestHeaders } from 'src/utils/createGuestHeaders'
import { isDefined } from 'src/utils/general'

export const anomaliesLimit = 40
let anomaliesSkip = 0

// funzione che ritorna i dati per la lista delle anomalie sistemati per la view della lista
export const normalizeAnomaly = (anomaly, categories, deselectedList, plant, from, to) => {
  // mi preparo l'oggetto finale
  const finalObj = {
    ...anomaly,
    name: 'N.F.',
    description: 'N.F.',
    category: '',
    subCategory: {
      label: '',
      color: ''
    },
    selected: true
  }
  // cerco config e categorie
  const thisConfig = anomaly.configuration
  if (thisConfig) {
    // controllo il nome, se c'è il messaggio metto quello altrimenti metto il name della configurazione
    if (anomaly.metadata && anomaly.metadata.message) {
      finalObj.name = anomaly.metadata.message
    } else {
      finalObj.name = thisConfig.name
    }
    finalObj.description = thisConfig.description
    // cerco la categoria principale
    const thisCategory = categories.find(
      (category) => category.name === thisConfig.category
    )
    if (thisCategory) {
      finalObj.category = thisCategory.label
      // cerco la subcategory
      const thisSubCategory = thisCategory.subCategory.find(
        (sub) => sub.name === thisConfig.subCategory
      )
      if (thisSubCategory) {
        finalObj.subCategory.label = thisSubCategory.label
        finalObj.subCategory.color = thisSubCategory.color
      }
    }
  }
  // controllo se ha i campi nuovi
  if (!finalObj.notes) {
    finalObj.notes = ''
  }
  if (!finalObj.externalUrl) {
    finalObj.externalUrl = ''
  }
  // controllo se avevo già questa anomalia nella list precedente
  const thisAnomalyIndex = deselectedList.indexOf(anomaly.uuid)
  if (thisAnomalyIndex > -1) {
    finalObj.selected = false
  }
  // setto la durata per i grafici
  let realFrom = new Date(finalObj.startedAt)
  let realTo = new Date(to)
  // Se la fine dell'intervallo è nel futuro (esempio: è lunedi e chiedo la settimana)
  if (realTo.getTime() > Date.now()) {
    realTo = new Date()
  }
  if (realFrom.getTime() < new Date(from).getTime()) {
    realFrom = new Date(from)
  }
  if (finalObj.endedAt && realTo.getTime() > new Date(finalObj.endedAt).getTime()) {
    realTo = new Date(finalObj.endedAt)
  }

  return finalObj
}

// STATO dello slice
const defaultPlant = {
  gettingData: true,
  hasError: null,
  currentTab: 'overview',
  documents: [],
  uuid: '',
  name: '-',
  maintenance: false,
  anagraphic: {
    peakPower: '-',
    plantCode: '-',
    vatId: '-',
    contractType: '-',
    referentName: '-',
    referentClient: '-',
    referentRole: '-',
    referentEmail: '-',
    referentPhone: '-',
    monitoringName: '-',
    monitoringEmail: '-',
    monitoringPhone: '-',
    operationAndMaintenaceName: '-',
    operationAndMaintenacePhone: '-',
    operationAndMaintenaceEmail: '-'
  },
  plantType: '-',
  engine: {},
  location: {},
  sunriseMinutesOffset: 0,
  sunsetMinutesOffset: 0,
  startDate: '-',
  contractDuration: '-',
  endDate: '-',
  address: {
    inputValue: ''
  },
  peakPower: '',
  dataloggers: ['-'],
  devices: [],
  pulseCounters: [],
  cogenerators: [],
  energyMeters: [],
  analytics: {
    dailyGlobalPerformance: null,
    pdischp: null,
    podActivepower: null,
    pdr: null,
    pgaschp: null,
    phtchp: null,
    plord: null,
    paux: null,
    productionpower: null,
    pvapchp: null,
    qdischp: null,
    qeaux: null,
    qelord: null,
    qgaschp: null,
    qhtchp: null,
    qvapchp: null
  },
  anomaliesBar: {
    communication: {
      label: 'Comunicazione',
      total: 0,
      status: 'ok'
    },
    energy: {
      label: 'Energia',
      total: 0,
      status: 'ok'
    },
    components: {
      label: 'Componenti',
      total: 0,
      status: 'ok'
    }
  },
  baseline: [],
  anomaliesList: ['-'],
  delesectedAnomalies: [],
  anomaliesGeneralStatus: {
    total: '0',
    openAnomalies: '0',
    closedAnomalies: '0',
    disserviceHours: '0'
  },
  anomaliesCategoryStatus: [
    {
      label: 'Componenti',
      name: 'Componenti',
      value: 0,
      subCategory: [
        {
          label: 'Inverter',
          name: 'Inverter',
          value: 0,
          color: '#B40000'
        },
        {
          label: 'Moduli',
          name: 'Moduli',
          value: 0,
          color: '#E10000'
        },
        {
          label: 'Protezioni',
          name: 'Protezioni',
          value: 0,
          color: '#FA3509'
        },
        {
          label: 'Centralina',
          name: 'Centralina',
          value: 0,
          color: '#FB6342'
        }
      ]
    },
    {
      label: 'Energia',
      name: 'Energia',
      value: 0,
      subCategory: [
        {
          label: 'Energia',
          name: 'Energia',
          value: 0,
          color: '#EA8F06'
        },
        {
          label: 'Perform.',
          name: 'Performance',
          value: 0,
          color: '#FFAF36'
        },
        {
          label: 'Potenza',
          name: 'Potenza',
          value: 0,
          color: '#FFC969'
        },
        {
          label: 'C. Ambie.',
          name: 'Condizioni Ambientali',
          value: 0,
          color: '#FFE290'
        }
      ]
    },
    {
      label: 'Comunicazione',
      name: 'Comunicazione',
      value: 0,
      subCategory: [
        {
          label: 'Datalogger',
          name: 'Datalogger',
          value: 0,
          color: '#A3A3A3'
        },
        {
          label: 'Inverter',
          name: 'Inverter',
          value: 0,
          color: '#B9B9B9'
        },
        {
          label: 'Contatore',
          name: 'Contatore',
          value: 0,
          color: '#D0D0D0'
        },
        {
          label: 'Centralina',
          name: 'Centralina',
          value: 0,
          color: '#E7E7E7'
        }
      ]
    }
  ],
  avgHoursGraph: ['-'],
  disserviceHours: ['-'],
  timeDistribution: ['-']
}

// SLICE con reducers
const slice = createSlice({
  name: 'plantView',
  initialState: JSON.parse(JSON.stringify(defaultPlant)),
  reducers: {
    setCurrentTab (state, action) {
      const { tab } = action.payload
      state.currentTab = tab
    },
    setGettingData (state, action) {
      const { value } = action.payload
      state.gettingData = value
    },
    setInfoToDefault (state) {
      // const { value } = action.payload
      state.analytics = JSON.parse(JSON.stringify(defaultPlant.analytics))
      state.currentTab = JSON.parse(JSON.stringify(defaultPlant.currentTab))
      state.anomaliesList = JSON.parse(JSON.stringify(defaultPlant.anomaliesList))
      state.delesectedAnomalies = JSON.parse(JSON.stringify(defaultPlant.delesectedAnomalies))
      state.anomaliesGeneralStatus = JSON.parse(JSON.stringify(defaultPlant.anomaliesGeneralStatus))
      state.anomaliesCategoryStatus = JSON.parse(JSON.stringify(defaultPlant.anomaliesCategoryStatus))
      state.avgHoursGraph = JSON.parse(JSON.stringify(defaultPlant.avgHoursGraph))
      state.disserviceHours = JSON.parse(JSON.stringify(defaultPlant.disserviceHours))
      state.timeDistribution = JSON.parse(JSON.stringify(defaultPlant.timeDistribution))
      // Resetto questi valori per la topbar
      state.name = '-'
      state.documents = []
      state.engine = {}
      state.peakPower = '-'
      state.startDate = '-'
      state.contractDuration = '-'
      state.endDate = '-'
      state.uuid = null
      state.maintenance = false
    },
    toggleMaintenance (state, action) {
      const { maintenance } = action.payload
      state.maintenance = maintenance
    },
    setChpPlant (state, action) {
      const {
        newPlant,
        newAnomaliesBar,
        newDataloggers,
        newDevices,
        hasError,
        newAnalytics
      } = action.payload
      alog('payload newPlant - chp => ', newDevices, 'chpViewSlice')
      if (hasError) {
        state.hasError = hasError
      } else {
        // resetto l'errore
        state.hasError = null
        // aggiorno l'impianto
        state.uuid = newPlant && newPlant.uuid ? newPlant.uuid : defaultPlant.uuid
        state.name = newPlant && newPlant.name ? newPlant.name : defaultPlant.name
        state.documents = newPlant && newPlant.metadata && newPlant.metadata.documents ? newPlant.metadata.documents : defaultPlant.documents
        state.maintenance = Object.prototype.hasOwnProperty.call(newPlant?.metadata ?? {}, 'maintenance')
          ? newPlant.metadata.maintenance
          : false
        // imposto che ho preso i dati la prima volta
        state.gettingData = false
        state.anomaliesBar = {
          communication: {
            label: 'Comunicazione',
            total: newAnomaliesBar && newAnomaliesBar.communication ? newAnomaliesBar.communication.total : 0,
            status: newAnomaliesBar && newAnomaliesBar.communication
              ? newAnomaliesBar.communication.status
              : 'ok'
          },
          energy: {
            label: 'Energia',
            total: newAnomaliesBar && newAnomaliesBar.energy ? newAnomaliesBar.energy.total : 0,
            status: newAnomaliesBar && newAnomaliesBar.energy ? newAnomaliesBar.energy.status : 'ok'
          },
          components: {
            label: 'Componenti',
            total: newAnomaliesBar && newAnomaliesBar.components ? newAnomaliesBar.components.total : 0,
            status: newAnomaliesBar && newAnomaliesBar.components ? newAnomaliesBar.components.status : 'ok'
          }
        }
        state.dataloggers = newDataloggers || defaultPlant.dataloggers
        state.engine = newPlant.metadata.engine || defaultPlant.engine
        // separo i vari devices
        state.pulseCounters = newDevices.filter(device => device.deviceType?.category === 'PulseCounter') || []
        state.cogenerators = newDevices.filter(device => device.deviceType?.category === 'Cogeneratore') || []
        state.energyMeters = newDevices.filter(
          (device) => device.deviceType && device.deviceType.category === 'EnergyMeter'
        ) || []
        state.location = Object.prototype.hasOwnProperty.call(newPlant, 'location')
          ? newPlant.location
          : defaultPlant.location
        state.anagraphic = {
          peakPower: Object.prototype.hasOwnProperty.call(newPlant.metadata, 'anagraphic')
            ? newPlant.metadata.anagraphic.peakPower
            : '-',
          plantCode: Object.prototype.hasOwnProperty.call(newPlant.metadata, 'anagraphic')
            ? newPlant.metadata.anagraphic.plantCode
            : '-',
          vatId: Object.prototype.hasOwnProperty.call(newPlant.metadata, 'anagraphic')
            ? newPlant.metadata.anagraphic.vatId
            : '-',
          contractType: Object.prototype.hasOwnProperty.call(newPlant.metadata, 'anagraphic')
            ? newPlant.metadata.anagraphic.contractType
            : '-',
          referentName: Object.prototype.hasOwnProperty.call(newPlant.metadata, 'anagraphic')
            ? newPlant.metadata.anagraphic.referentName
            : '-',
          referentClient: Object.prototype.hasOwnProperty.call(newPlant.metadata, 'anagraphic')
            ? newPlant.metadata.anagraphic.referentClient
            : '-',
          referentRole: Object.prototype.hasOwnProperty.call(newPlant.metadata, 'anagraphic')
            ? newPlant.metadata.anagraphic.referentRole
            : '-',
          referentEmail: Object.prototype.hasOwnProperty.call(newPlant.metadata, 'anagraphic')
            ? newPlant.metadata.anagraphic.referentEmail
            : '-',
          referentPhone: Object.prototype.hasOwnProperty.call(newPlant.metadata, 'anagraphic')
            ? newPlant.metadata.anagraphic.referentPhone
            : '-',
          monitoringName: Object.prototype.hasOwnProperty.call(newPlant.metadata, 'anagraphic')
            ? newPlant.metadata.anagraphic.monitoringName
            : '-',
          monitoringEmail: Object.prototype.hasOwnProperty.call(newPlant.metadata, 'anagraphic')
            ? newPlant.metadata.anagraphic.monitoringEmail
            : '-',
          monitoringPhone: Object.prototype.hasOwnProperty.call(newPlant.metadata, 'anagraphic')
            ? newPlant.metadata.anagraphic.monitoringPhone
            : '-',
          operationAndMaintenaceName: Object.prototype.hasOwnProperty.call(newPlant.metadata, 'anagraphic')
            ? newPlant.metadata.anagraphic.operationAndMaintenaceName
            : '-',
          operationAndMaintenacePhone: Object.prototype.hasOwnProperty.call(newPlant.metadata, 'anagraphic')
            ? newPlant.metadata.anagraphic.operationAndMaintenacePhone
            : '-',
          operationAndMaintenaceEmail: Object.prototype.hasOwnProperty.call(newPlant.metadata, 'anagraphic')
            ? newPlant.metadata.anagraphic.operationAndMaintenaceEmail
            : '-'
        }
        state.plantType = Object.prototype.hasOwnProperty.call(newPlant.metadata, 'plantType')
          ? newPlant.metadata.plantType
          : defaultPlant.plantType
        state.address = Object.prototype.hasOwnProperty.call(newPlant.metadata, 'address')
          ? newPlant.metadata.address
          : defaultPlant.address
        state.startDate = Object.prototype.hasOwnProperty.call(newPlant.metadata, 'startDate')
          ? newPlant.metadata.startDate
          : defaultPlant.startDate
        state.contractDuration = Object.prototype.hasOwnProperty.call(newPlant.metadata, 'contractDuration')
          ? newPlant.metadata.contractDuration
          : defaultPlant.contractDuration
        state.endDate = Object.prototype.hasOwnProperty.call(newPlant.metadata, 'endDate')
          ? newPlant.metadata.endDate
          : defaultPlant.endDate
        state.peakPower = Object.prototype.hasOwnProperty.call(newPlant.metadata, 'peakPower')
          ? newPlant.metadata.peakPower
          : defaultPlant.peakPower
        state.baseline = Object.prototype.hasOwnProperty.call(
          newPlant.metadata, 'baseline')
          ? newPlant.metadata.baseline
          : defaultPlant.baseline
        state.sunriseMinutesOffset = Object.prototype.hasOwnProperty.call(newPlant.metadata, 'sunriseMinutesOffset')
          ? newPlant.metadata.sunriseMinutesOffset
          : defaultPlant.sunriseMinutesOffset
        state.sunsetMinutesOffset = Object.prototype.hasOwnProperty.call(newPlant.metadata, 'sunsetMinutesOffset')
          ? newPlant.metadata.sunsetMinutesOffset
          : defaultPlant.sunsetMinutesOffset
        state.analytics = {
          dailyGlobalPerformance: isDefined(newAnalytics?.dailyGlobalPerformance) && !newAnalytics?.dailyGlobalPerformanceDataMissing ? newAnalytics?.dailyGlobalPerformance : null,
          quarterGlobalPerformance: isDefined(newAnalytics?.quarterGlobalPerformance) && !newAnalytics?.quarterGlobalPerformanceDataMissing ? newAnalytics?.quarterGlobalPerformance : null,
          pdischp: isDefined(newAnalytics?.pdischp) ? newAnalytics?.pdischp : null,
          pdr: isDefined(newAnalytics?.pdr) ? newAnalytics?.pdr : null,
          podActivepower: isDefined(newAnalytics?.podActivepower) ? newAnalytics?.podActivepower : null,
          plord: isDefined(newAnalytics?.plord) ? newAnalytics?.plord : null,
          paux: isDefined(newAnalytics?.paux) ? newAnalytics?.paux : null,
          pgaschp: isDefined(newAnalytics?.pgaschp) ? newAnalytics?.pgaschp : null,
          phtchp: isDefined(newAnalytics?.phtchp) ? newAnalytics?.phtchp : null,
          productionpower: isDefined(newAnalytics?.productionpower) ? newAnalytics?.productionpower : null,
          qeaux: isDefined(newAnalytics?.qeaux) ? newAnalytics?.qeaux : null,
          qelord: isDefined(newAnalytics?.qelord) ? newAnalytics?.qelord : null,
          qgaschp: isDefined(newAnalytics?.qgaschp) ? newAnalytics?.qgaschp : null,
          qhtchp: isDefined(newAnalytics?.qhtchp) ? newAnalytics?.qhtchp : null,
          qvapchp: isDefined(newAnalytics?.qvapchp) ? newAnalytics?.qvapchp : null,
          qdischp: isDefined(newAnalytics?.qdischp) ? newAnalytics?.qdischp : null,
          energynetchp: isDefined(newAnalytics?.energynetchp) ? newAnalytics?.energynetchp : null
        }
      }
    },
    setChpAnomalies (state, action) {
      const {
        from,
        to,
        hasError,
        updateGraph,
        newAnomaliesList,
        newAnomaliesGeneralStatus,
        newAnomaliesCategoryStatus,
        newAvgHoursGraph,
        newDisserviceHours,
        newTimeDistribution
      } = action.payload

      // controllo che non ci siano errori
      if (hasError) {
        state.hasError = hasError
      } else if (!state.hasError) {
        const plantInfo = {
          location: Object.keys(state.location).length > 0 ? state.location : null,
          metadata: {
            sunriseMinutesOffset: state.sunriseMinutesOffset || 0,
            sunsetMinutesOffset: state.sunsetMinutesOffset || 0
          }
        }
        // controllo se è pagination oppure no
        if (!updateGraph) {
          // pagination
          if (newAnomaliesList.length > 0) {
            const deselection = JSON.parse(
              JSON.stringify(state.delesectedAnomalies)
            )
            const oldArray = JSON.parse(JSON.stringify(state.anomaliesList))
            const newArray = newAnomaliesList.map((anomaly) => normalizeAnomaly(
              anomaly,
              state.anomaliesCategoryStatus,
              deselection,
              plantInfo,
              from,
              to
            ))
            state.anomaliesList = oldArray.concat(newArray)
          }
        } else {
          // mi setto le anonalie
          if (newAnomaliesList.length > 0) {
            const deselection = JSON.parse(
              JSON.stringify(state.delesectedAnomalies)
            )
            const updatedList = newAnomaliesList.map((anomaly) => normalizeAnomaly(
              anomaly,
              state.anomaliesCategoryStatus,
              deselection,
              plantInfo,
              from,
              to
            ))
            state.anomaliesList = updatedList
          } else {
            state.anomaliesList = []
          }
          // mi setto i totali
          state.anomaliesGeneralStatus.total = newAnomaliesGeneralStatus.total
          state.anomaliesGeneralStatus.openAnomalies = newAnomaliesGeneralStatus.openAnomalies
          state.anomaliesGeneralStatus.closedAnomalies = newAnomaliesGeneralStatus.closedAnomalies
          state.anomaliesGeneralStatus.disserviceHours = newAnomaliesGeneralStatus.disserviceHours
          // mi setto le categorie
          // ora creo una copia da aggiornare
          const categoryStatusCopy = JSON.parse(
            JSON.stringify(state.anomaliesCategoryStatus)
          )
          categoryStatusCopy.forEach((category) => {
            if (
              newAnomaliesCategoryStatus[category.name] &&
              Object.prototype.hasOwnProperty.call(newAnomaliesCategoryStatus[category.name], 'count')
            ) {
              category.value = newAnomaliesCategoryStatus[category.name].count || 0
              category.subCategory.forEach((subCategory) => {
                if (
                  newAnomaliesCategoryStatus[category.name].subcategories[
                    subCategory.name
                  ] &&
                  newAnomaliesCategoryStatus[category.name].subcategories[
                    subCategory.name
                  ].count
                ) {
                  subCategory.value = newAnomaliesCategoryStatus[category.name].subcategories[
                    subCategory.name
                  ].count || 0
                } else {
                  subCategory.value = 0
                }
              })
            } else {
              category.value = 0
            }
          })
          state.anomaliesCategoryStatus = categoryStatusCopy
          // mi setto i dati per i grafici
          state.avgHoursGraph = newAvgHoursGraph || []
          state.disserviceHours = newDisserviceHours || []
          state.timeDistribution = newTimeDistribution || []
        }
      }
    },
    setDeselectedAnomalies (state, action) {
      const { type, uuid } = action.payload
      let newDelesection = JSON.parse(
        JSON.stringify(state.delesectedAnomalies)
      )
      if (type === 'all') {
        newDelesection = []
      } else if (type === 'add') {
        const thisIndex = newDelesection.indexOf(uuid)
        if (thisIndex > -1) {
          newDelesection.splice(thisIndex, 1)
        }
      } else if (type === 'remove') {
        newDelesection.push(uuid)
      }
      state.delesectedAnomalies = newDelesection
    }
  }
})

export const { reducer } = slice

// funzione che gestisce il cambio della tab
export const handleTabsChange = (newTab) => (dispatch) => {
  dispatch(slice.actions.setCurrentTab({ tab: newTab }))
}

// funzione che azzera l'impianto
export const resetPlantInfo = () => (dispatch) => {
  dispatch(slice.actions.setInfoToDefault())
}

// funzione che aggiorna l'impianto mostrato
export const updatedPlantInfo = ({
  filter = null,
  plantId,
  guestToken = null
}) => async (dispatch) => {
  // setto la variabile di visualizzazione per rerender
  const params = {}
  if (filter) {
    params.filter = filter
  }
  let fullData = {}
  let hasError = null
  let customHeaders = []
  if (guestToken) {
    customHeaders = createGuestHeaders(guestToken)
  }
  try {
    fullData = await api.getResource('plantDetails', {
      params,
      path: `/${plantId}/overview`,
      customHeaders
    })

    hasError = null
  } catch (err) {
    console.error('Error while getting plantDetails err => ', err)
    hasError = 'generic'
  }
  alog('FULL PLANT => ', fullData, 'chpViewSlice')
  // invio i dati alla funzione che setta l'impianto
  dispatch(
    slice.actions.setChpPlant({
      newPlant: fullData?.plant || {},
      newAnomaliesBar: fullData?.anomalies || [],
      newDataloggers: fullData?.dataloggers || [],
      newDevices: fullData?.devices || [],
      newAnalytics: fullData?.analytics || [],
      hasError
    })
  )
  // aggiorno la variabile di visualizzazione
}

// funzione che aggiorna la lista delle anomalie
export const updateAnomaliesData = ({
  skip = 0,
  limit = anomaliesLimit,
  from = null,
  to = null,
  plantId,
  updateGraph = true,
  isPolling = false,
  q = null
}) => async (dispatch) => {
  const listParams = {
    sortby: 'endedAt',
    sortorder: 'ASC',
    skip,
    limit,
    plantId
  }
  // controllo le date
  if (!from && !to) {
    from = moment().set({
      hours: 0, minute: 0, second: 0, millisecond: 1
    })
    from = moment(from).toISOString()
    to = moment(from).add(1, 'day')
    to = moment(to).toISOString()
  }
  listParams.timeFrom = from
  listParams.timeTo = to
  // controllo se è cambiato il periodo o meno
  if (isPolling) {
    listParams.limit = listParams.limit + anomaliesSkip
  } else {
    anomaliesSkip = skip
  }
  // controllo se ci sono filtri speciali
  if (q && Object.keys(q).length > 0) {
    listParams.q = JSON.stringify(q)
  }
  // preparo le variabili da passare al dispatch
  let hasError = null
  let newAnomaliesList = []
  let analyticsData = {}
  try {
    const listResponse = await api.getResource('plantDetails', {
      params: listParams,
      path: `/${plantId}/anomalies/list`
    })
    alog('listResponse => ', listResponse, 'anomalie')
    newAnomaliesList = listResponse
    // aggiorno la durata delle anomalie aperte

    if (updateGraph) {
      const analyticsParams = {
        timeFrom: from,
        timeTo: to
      }
      if (q && Object.keys(q).length > 0) {
        analyticsParams.q = JSON.stringify(q)
      }
      const analyticsResponse = await api.getResource('plantDetails', {
        fullResponse: true,
        path: `/${plantId}/anomalies`,
        params: analyticsParams
      })
      analyticsData = analyticsResponse.data
      alog('analyticsData => ', analyticsData, 'anomalie')
    }
  } catch (err) {
    console.error('Error while getting updateAnomaliesData err => ', err)
    hasError = 'generic'
  }
  // invio i dati alla funzione che setta la lista delle anomalie e le var per i grafici
  dispatch(
    slice.actions.setChpAnomalies({
      from: from,
      to: to,
      hasError,
      updateGraph,
      newAnomaliesList,
      newAnomaliesGeneralStatus: analyticsData.generalStatus || {},
      newAnomaliesCategoryStatus: analyticsData.categoryStatus || [],
      newAvgHoursGraph: analyticsData.avgHoursGraph || [],
      newDisserviceHours: analyticsData.disserviceHours || [],
      newTimeDistribution: analyticsData.timeDistribution || []
    })
  )
}

// funzione che gestisce la variabile con le anomalie deselezionate
export const updatedDeselected = ({ type = null, uuid = null }) => (dispatch) => {
  if (type) {
    dispatch(slice.actions.setDeselectedAnomalies({ type, uuid }))
  }
}

// funzione che esegue il toggle del campo maintenance
export const toggleMaintenance = (maintenance, plantId) => async (dispatch) => {
  try {
    await api.putResource('plantsList', { path: `/${plantId}`, body: { 'metadata.maintenance': maintenance } })
    dispatch(slice.actions.toggleMaintenance({ maintenance }))
    return true
  } catch (e) {
    return false
  }
}

// funzione prende i devices relativi all'anomalie
export const getAnomaliesDevices = async (anomalyId) => {
  const thisDevices = {
    hasError: false,
    devices: []
  }
  if (anomalyId) {
    try {
      const resourceResponse = await api.getResource('anomaliesList', {
        path: `/${anomalyId}/resources`
      })
      thisDevices.devices = resourceResponse
    } catch (err) {
      console.error('anomaliesDevices error => ', err)
      thisDevices.hasError = true
    }
  } else {
    thisDevices.hasError = true
  }

  return thisDevices
}

// funzione edita un'anomalia
export const putEditAnomaly = async (anomalyId, objToSend) => {
  alog('putEditAnomaly objToSend => ', objToSend, 'anomalia')
  if (objToSend && anomalyId) {
    try {
      const editResponse = await api.putResource('anomaliesList', {
        path: `/${anomalyId}`,
        body: objToSend
      })
      alog('editResponse => ', editResponse, 'anomalia')
    } catch (err) {
      console.error('putEditAnomaly error => ', err)
    }
  }
}

// funzione che forza la chiusura delle anomalie
export const putForceCloseAnomaly = async (anomalyId) => {
  if (anomalyId) {
    try {
      const closeResponse = await api.putResource('anomaliesList', {
        path: `/${anomalyId}/close`
      })
      alog('closeResponse => ', closeResponse, 'anomalia')
    } catch (err) {
      console.error('putForceCloseAnomaly error => ', err)
    }
  }
}
