import { useCallback, useState } from 'react'
import clsx from 'clsx'
import { Box, CircularProgress, Grid, makeStyles, Tooltip, Typography, Avatar } from '@material-ui/core'
import { Image as ImageIcon, Trash as DeleteIcon } from 'react-feather'
import { useDropzone } from 'react-dropzone'
import ConfirmationDialogRaw from 'src/components/ConfirmationDialogRaw'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%'
  },
  logo: {
    maxWidth: '100%',
    maxHeight: 74,
    width: 'auto',
    height: 'auto'
  },
  dropZoneDisabled: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(2),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    opacity: 0.6
  },
  dropZone: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(2),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      opacity: 0.5,
      cursor: 'pointer'
    }
  },
  imageDropZone: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(2),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      opacity: 0.5,
      cursor: 'pointer'
    }
  },
  dragActive: {
    backgroundColor: theme.palette.action.active,
    opacity: 0.5
  },
  cancelButton: {
    zIndex: 99,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.error.main,
    borderRadius: '50%',
    width: 24,
    height: 24,
    position: 'absolute',
    right: -6,
    top: -6
  },
  cicle: {
    color: theme.palette.primary.main
  },
  avatar: {
    height: 40,
    width: 40,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}))

function LogoLoader ({
  image = null,
  loadData = null,
  deleteData = null,
  disabled = false,
  placeholder = null,
  disableTooltip = 'Salva l\'impianto per poter caricare il logo',
  className
}) {
  const classes = useStyles()

  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // funzione che gestisce il drop del file
  const handleDrop = useCallback(async (acceptedFiles) => {
    // setFiles((prevFiles) => [...prevFiles].concat(acceptedFiles))
    // Devo chiamare la funzione che effettua il caricamento del logo
    const logo = acceptedFiles.length > 0 ? acceptedFiles[acceptedFiles.length - 1] : null
    if (loadData) {
      setIsLoading(true)
      await loadData(logo)
      setIsLoading(false)
    }
  }, [loadData])

  // funzione che gestisce la cancellazione del file
  const handleDeleteLogo = async (confirmed) => {
    if (confirmed) {
      setIsLoading(true)
      await deleteData()
      setIsLoading(false)
    }
    setShowConfirmDialog(false)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    multiple: false,
    accept: '.png, .jpg, .jpeg, image/jpeg, image/png'
  })

  return (
    <>
      {showConfirmDialog
        ? (
          <ConfirmationDialogRaw
            open={showConfirmDialog}
            onClose={(confirmed) => handleDeleteLogo(confirmed)}
            title='Elimina Immagine'
            description={'Sei sicuro di voler eliminare l\'immagine?'}
          />
          )
        : null}
      {image && !disabled
        ? (
          <Box width='100%' style={{ position: 'relative' }}>
            <div
              className={classes.cancelButton}
              onClick={(e) => {
                e.stopPropagation()
                setShowConfirmDialog(true)
              }}
            >
              <DeleteIcon size='16' />
            </div>
          </Box>
          )
        : null}
      <div
        className={clsx(
          classes.root,
          className,
          {
            [classes.dropZone]: !disabled && !image,
            [classes.imageDropZone]: !!image && !disabled,
            [classes.dropZoneDisabled]: disabled,
            [classes.dragActive]: isDragActive && !disabled
          })}
        {...getRootProps()}
      >
        <input disabled={disabled} {...getInputProps()} />
        {disabled && !image
          ? (
            <Tooltip title={disableTooltip}>
              <Grid container>
                <Grid item xs={12}>
                  {placeholder
                    ? (
                      <div style={{ textAlign: 'center' }}>
                        <Avatar className={classes.avatar} src={placeholder} />
                      </div>
                      )
                    : (
                      <div style={{ textAlign: 'center' }}>
                        <ImageIcon size={32} />
                      </div>
                      )}
                </Grid>
                <Grid item xs={12}>
                  <Box width='100%' textAlign='center'>
                    <Typography color='textPrimary' variant='caption'>
                      Carica immagine
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Tooltip>)
          : image
            ? isLoading
                ? (
                  <CircularProgress className={classes.cicle} variant='indeterminate' size={20} thickness={4} />
                  )
                : (
                  <img alt='Logo' className={classes.logo} src={image} />
                  )
            : (
              <Grid container>
                <Grid item xs={12}>
                  {placeholder
                    ? (
                      <div style={{ textAlign: 'center' }}>
                        <Avatar className={classes.avatar} src={placeholder} />
                      </div>
                      )
                    : (
                      <div style={{ textAlign: 'center' }}>
                        <ImageIcon size={32} />
                      </div>
                      )}
                </Grid>
                <Grid item xs={12}>
                  <Box width='100%' textAlign='center'>
                    <Typography color='textPrimary' variant='caption'>
                      Carica immagine
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              )}
      </div>
    </>
  )
}
export default LogoLoader
