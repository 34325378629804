import preval from 'preval.macro'

export const getBuildDate = () => {
  const buildDate = preval`
    const moment = require('moment')
    const buildTimestamp = moment().format('DD/MM/YYYY HH:mm:ss')
    module.exports = buildTimestamp
  `

  return buildDate
}
