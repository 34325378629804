import { useCallback, useState } from 'react'
import clsx from 'clsx'
import { Link, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, MenuItem, TextField, Tooltip, Typography, useMediaQuery } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { useDropzone } from 'react-dropzone'
import bytesToSize from 'src/utils/bytesToSize'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogRoot: {
    margin: 0,
    padding: theme.spacing(3)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  dropZone: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(4),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      opacity: 0.5,
      cursor: 'pointer'
    }
  },
  dragActive: {
    backgroundColor: theme.palette.action.active,
    opacity: 0.5
  },
  image: {
    width: 80
  },
  list: {
    marginTop: theme.spacing(1),
    maxHeight: 200,
    overflowY: 'auto'
  }
}))

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props
  const classes = useStyles()
  return (
    <MuiDialogTitle disableTypography className={classes.dialogRoot} {...other}>
      <Typography variant='h3'>{children}</Typography>
      {onClose
        ? (
          <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
          )
        : null}
    </MuiDialogTitle>
  )
}

function LoadFilesDialog ({ deviceTypes = [], loadFiles = null, open, onClose, className, ...rest }) {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const [selectedDeviceType, setSelectedDeviceType] = useState((deviceTypes.length > 0 && deviceTypes[0].uuid) || '')
  const [files, setFiles] = useState([])
  const [isUploading, setIsUploading] = useState(0)

  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('sm'))

  // funzione che gestisce il drop del file
  const handleDrop = useCallback((acceptedFiles) => {
    // console.log('acceptedFiles =>', acceptedFiles)
    setFiles((prevFiles) => [...prevFiles].concat(acceptedFiles))
    // setFiles(acceptedFiles)
  }, [])

  // Funzione che rimuove i file caricati
  const handleRemoveFile = () => {
    setFiles([])
  }

  // funzione che si occupa del caricamento dell'upload del file sul server
  const loadData = async () => {
    setIsUploading(1)
    try {
      if (loadFiles) {
        const selectedElement = deviceTypes.find(el => el.uuid === selectedDeviceType)
        if (selectedElement) {
          const elementType = selectedElement.name === 'Impianto' ? 'plants' : 'devicetypes'
          await loadFiles(files, elementType, selectedDeviceType)
        }
      }
      setIsUploading(2)
      setSelectedDeviceType('')
      setFiles([])
      enqueueSnackbar('Datasheet caricato con successo!', {
        variant: 'success'
      })
      onClose()
    } catch (err) {
      setIsUploading(0)
      enqueueSnackbar('Errore durante il caricamento del datasheet', {
        variant: 'error'
      })
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    multiple: false,
    accept: '.pdf, application/pdf'
  })

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Dialog
        fullScreen={fullScreen}
        maxWidth='sm'
        fullWidth
        open={open}
        onClose={onClose}
      >
        <DialogTitle onClose={onClose}>
          Carica documenti
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            Carica documenti di progetto riguardanti i device o l&apos;intero impianto
          </DialogContentText>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                disabled={isUploading !== 0}
                margin='normal'
                label='Tipo di device'
                value={selectedDeviceType}
                variant='outlined'
                size='small'
                onChange={(event) => setSelectedDeviceType(event.target.value)}
              >
                {deviceTypes.map((deviceType, index) => (
                  <MenuItem key={index} value={deviceType.uuid}>
                    {deviceType.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 16 }}>
              <div
                className={clsx({
                  [classes.dropZone]: true,
                  [classes.dragActive]: isDragActive
                })}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <Grid container>
                  <Grid item xs={12} sm={3}>
                    <div style={{ textAlign: 'center', padding: '-16px' }}>
                      <img
                        alt='Seleziona il Datasheet'
                        className={classes.image}
                        src='/static/images/undraw_add_file2_gvbb.svg'
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <div>
                      <Typography gutterBottom variant='h4'>
                        Seleziona il documento
                      </Typography>
                      <Box mt={2}>
                        <Typography color='textPrimary' variant='body1'>
                          Trascina i file o clicca qui per <Link underline='always'>sfogliare</Link> nelle tue cartelle
                        </Typography>
                      </Box>
                    </div>
                  </Grid>
                </Grid>
              </div>
              {files.length > 0 && (
                <>
                  <List className={classes.list}>
                    {files.map((file, i) => (
                      <ListItem divider={i < files.length - 1} key={i}>
                        <ListItemIcon>
                          <FileCopyIcon />
                        </ListItemIcon>
                        <Tooltip title={file.name}>
                          <ListItemText
                            primary={file.name}
                            primaryTypographyProps={{ noWrap: true, style: { maxWidth: '80%' }, variant: 'h5' }}
                            secondary={bytesToSize(file.size)}
                          />
                        </Tooltip>
                      </ListItem>
                    ))}
                  </List>
                </>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {files.length > 0 && (
            <Button onClick={handleRemoveFile} color='primary' style={{ marginRight: 'auto' }} disabled={isUploading !== 0}>
              Rimuovi documenti
            </Button>
          )}
          <Button
            onClick={loadData}
            disabled={!files.length > 0 || !selectedDeviceType || isUploading !== 0}
            color='secondary'
            variant='contained'
            style={{
              backgroundColor: isUploading === 2 ? 'green' : '',
              color: isUploading === 2 ? 'white' : ''
            }}
          >
            {isUploading === 0 && 'Carica i documenti'}
            {isUploading === 1 && 'Inviando i documenti...'}
            {isUploading === 2 && 'Documenti caricati!'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
export default LoadFilesDialog
