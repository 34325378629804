import { useState } from 'react'
import clsx from 'clsx'
import { X as XIcon, ChevronRight as ChevronRightIcon, ArrowLeft as ArrowLeftIcon } from 'react-feather'
import { Avatar, List, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, ListItem, makeStyles, Typography, ListItemText, ListItemSecondaryAction, ListItemIcon, Checkbox, ButtonGroup } from '@material-ui/core'
import { basicColors } from 'src/theme'
import { alog } from 'src/utils/apioLog'

const useStyles = makeStyles(() => ({
  root: {},
  divider: {
    backgroundColor: basicColors.blueGrayLight
  },
  listItem: {
    fontSize: 16,
    width: 28,
    height: 28,
    marginRight: 16
  }
}))

// Numero limite di grandezze da poter visualizzare contemporaneamente
const limitNumber = 10

function PropertiesModal ({ currentProperties: currentSelectedProperties, confirmProperties, devices = [], open, onClose, className, ...rest }) {
  const classes = useStyles()

  alog('currentSelectedProperties', currentSelectedProperties, 'propertiesmodal')

  const [section, setSection] = useState('elements')
  // Device selezionato
  const [currentElement, setCurrentElement] = useState(null)
  // Properties del device selezionato
  const [currentProperties, setCurrentProperties] = useState(null)

  // Funzione che preso un device, restituisce la lista delle sue proprietà
  const getDeviceProperties = (device) => {
    alog('device', device, 'PROPERTIES')
    // controllo se tra le proprietà già selezionate ce ne sono alcune di questo dispositivo
    const propertiesForCurrentDevice = currentSelectedProperties.filter(el => el.deviceId === device.uuid)
    // se il device ho l'oggetto deviceType e quindi l'oggetto properties (non è un orientamento)
    if (device.deviceType && device.deviceType.properties) {
      return Object.keys(device.deviceType.properties)
        .map((key) => {
          const foundDevice = propertiesForCurrentDevice.find(el => el.name === key)
          return {
            ...device.deviceType.properties[key],
            name: key,
            selected: !!foundDevice,
            graphType: foundDevice ? foundDevice.graphType : 'line'
          }
        })
        .filter(el => (el.uom !== '-' && el.displayName !== 'cabinettemp'))
      // se è un orientamento forzo le scelte a Energia (energy) e Irraggiamento (irradiation)
    } else {
      return [{
        name: 'energy',
        uom: 'kWh',
        displayName: 'Energia',
        selected: false,
        graphType: 'line'
      },
      {
        name: 'irradiation',
        displayName: 'Irraggiamento',
        uom: `kWh/m${String.fromCodePoint(0x00B2)}`,
        selected: false,
        graphType: 'line'
      }]
    }

    // return []
  }

  // Funzione che prende in ingresso un array di deviceProperties e ritorna una stringa dei primi 2 nomi di proprietà e del numero delle restanti se sono più di 2
  const getPropertiesListName = (properties) => {
    // console.log(properties)
    if (properties.length > 2) {
      return `${properties.slice(0, 2)
        .map(el => el.displayName === 'activepowertot' ? 'Potenza' : el.displayName === 'totalenergy' ? 'Energia' : el.displayName)
        .join(', ')} e altri ${properties.length - 2}`
    }

    return properties
      .map(el => el.displayName === 'activepowertot' ? 'Potenza' : el.displayName === 'totalenergy' ? 'Energia' : el.displayName)
      .join(', ')
  }

  // funzione che cambia il tipo di grafico da barra a linea e viceversa
  const changeGraphType = (type, propertyIndex) => {
    setCurrentProperties(prevProperties => {
      const changingProperty = prevProperties[propertyIndex]
      changingProperty.graphType = type
      const newProperties = prevProperties.map((el, index) => index === propertyIndex ? changingProperty : el)
      return newProperties
    })
  }

  // funzione che decide se una checkbox deve essere disabilitata
  const isDisabled = (prop, currDevice, currProps) => {
    alog('prop', prop, 'isDisabled')
    alog('currDevice', currDevice, 'isDisabled')
    alog('currProps', currProps, 'isDisabled')
    // Per sapere se devo disabilitare una checkbox devo sapere
    // 1. se questa checkbox è già selezionata
    const isSelected = prop.selected
    // 2. Quante propietà del device corrente ho già selezionato
    const propsBelongsToDevice = currentSelectedProperties.filter(el => el.deviceId === currDevice.uuid).length
    // 3. Tutte le proprietà che ho già selezionato
    const alreadySelectedProps = currentSelectedProperties.length
    // 4. le proprietà che sto selezionando attualmente
    const currentProps = currProps.filter(el => el.selected === true).length
    // 5. se ho raggiunto il limite
    const reachedSelectionLimit = (alreadySelectedProps - propsBelongsToDevice + currentProps) >= limitNumber

    return !isSelected && reachedSelectionLimit
  }

  // funzione che ritorna il contenuto della modal
  const getModalContent = (sectionType) => {
    switch (sectionType) {
      case 'elements': {
        return (

          <>
            <Box my={2}>
              <Typography variant='body2'>
                Seleziona un dispositivo per selezionare le variabili da visualizzare
              </Typography>
            </Box>
            <List>
              {devices.map((device, index) => (
                <div key={device.uuid}>
                  <ListItem
                    button
                    onClick={() => {
                      setSection('properties')
                      setCurrentElement(device)
                      setCurrentProperties(getDeviceProperties(device))
                    }}
                  >
                    <Avatar className={classes.listItem}>{index + 1}</Avatar>
                    <ListItemText primary={device.name} secondary={getPropertiesListName(getDeviceProperties(device))} />
                    <ListItemSecondaryAction>
                      <ChevronRightIcon size={20} />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider className={classes.divider} />
                </div>
              ))}
            </List>
          </>
        )
      }
      case 'properties': {
        return (
          <>
            <Box my={2} display='flex' alignItems='center'>
              <IconButton
                onClick={() => {
                  setSection('elements')
                  setCurrentElement(null)
                  setCurrentProperties(null)
                }}
                style={{ marginRight: 8 }}
                size='small'
              >
                <ArrowLeftIcon color={basicColors.primary} />
              </IconButton>
              <Typography variant='body2'>
                <span style={{ fontWeight: 700, color: basicColors.primary }}>{`${currentElement.name}:`}</span> variabili da visualizzare
              </Typography>
            </Box>
            <List>
              {
                currentProperties && currentProperties.map((property, propertyIndex) => (
                  <ListItem dense key={property.displayName}>
                    <ListItemIcon>
                      <Checkbox
                        onChange={(e) => {
                          e.persist()
                          setCurrentProperties(prevProperties => {
                            const changingProperty = prevProperties[propertyIndex]
                            changingProperty.selected = e.target.checked
                            const newProperties = prevProperties.map((el, index) => index === propertyIndex ? changingProperty : el)
                            return newProperties
                          })
                        }}
                        color='primary'
                        edge='start'
                        disabled={isDisabled(property, currentElement, currentProperties)}
                        /*  disabled={(
                           !property.selected &&
                           (!currentSelectedProperties
                             .some(el => el.deviceId === currentElement.uuid)
                             ? (currentSelectedProperties.length + currentProperties.filter(el => el.selected === true).length) >= 5
                             : currentProperties.filter(el => el.selected === true).length) >= 5)} */
                        checked={property.selected}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText primary={property.displayName === 'activepowertot' ? 'Potenza' : property.displayName === 'totalenergy' ? 'Energia' : property.displayName} />
                    <ListItemSecondaryAction>
                      <ButtonGroup size='small'>
                        <Button onClick={() => changeGraphType('line', propertyIndex)} variant='contained' color={property.graphType === 'line' ? 'primary' : 'secondary'}>Linea</Button>
                        <Button onClick={() => changeGraphType('column', propertyIndex)} variant='contained' color={property.graphType === 'column' ? 'primary' : 'secondary'}>Barra</Button>
                      </ButtonGroup>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))
              }
            </List>
          </>
        )
      }
      default: {
        return null
      }
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='sm'
      className={clsx(classes.root, className)}
      {...rest}
    >
      <DialogTitle>
        <Box my={-1} display='flex' alignItems='center' justifyContent='space-between'>
          {section === 'elements'
            ? <Typography component='div' variant='h4'>Seleziona un elemento</Typography>
            : section === 'properties'
              ? (
                <Typography component='div' variant='h4'>
                  {
                    `Seleziona le proprietà (${(!currentSelectedProperties
                      .some(el => el.deviceId === currentElement.uuid)
                      ? (currentSelectedProperties.length + currentProperties.filter(el => el.selected === true).length)
                      : currentProperties.filter(el => el.selected === true).length)}/${limitNumber})`
                  }
                </Typography>)
              : 'Seleziona'}
          <IconButton onClick={() => onClose()}>
            <XIcon color={basicColors.whiteText} />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {getModalContent(section)}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box px={2} width='100%' display='flex' justifyContent='space-between' alignItems='center'>
          <Button onClick={() => {
            setCurrentElement(null)
            setCurrentProperties(null)
            onClose()
          }}
          >
            Chiudi
          </Button>
          {section === 'properties'
            ? (
              <Button
                variant='contained'
                size='small'
                color='primary'
                // TODO: aggiungere le proprietà gia selezionate
                disabled={!(currentProperties.filter(el => el.selected === true).length > 0)}
                onClick={() => {
                  const selectedProperties = currentProperties
                    .filter(prop => prop.selected === true)
                    .map(el => ({ ...el, displayName: `${currentElement.name} - ${el.displayName === 'activepowertot' ? 'Potenza' : el.displayName === 'totalenergy' ? 'Energia' : el.displayName}`, deviceId: currentElement.uuid, deviceType: currentElement.deviceType && currentElement.deviceType.category ? 'device' : 'orientation' }))

                  const currentSelected = currentSelectedProperties.map(el => `${el.deviceId}${el.name}`)
                  const newSelected = selectedProperties.filter(el => !currentSelected.includes(`${el.deviceId}${el.name}`))
                  confirmProperties({ currentElement, currentProperties: newSelected })
                  onClose()
                }}
              >
                Conferma
              </Button>
              )
            : null}
        </Box>
      </DialogActions>
    </Dialog>
  )
}
export default PropertiesModal
