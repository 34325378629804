import clsx from 'clsx'
import CloseIcon from '@material-ui/icons/Cancel'
import { v4 as uuidv4 } from 'uuid'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core'
import { alog } from 'src/utils/apioLog'

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 99999999
  }
}))

function ObjectConfigDialog ({
  setElement,
  children,
  element,
  editingObject,
  generateCanvasObjectFromLibrary,
  config,
  setConfig,
  selectedObject,
  setCanvasElements,
  open,
  onClose,
  className
}) {
  const classes = useStyles()

  // Metodo che crea o modifica un oggetto per essere interpretato dal canvas e la sua configurazione specifica
  const handleSave = () => {
    // console.log('handleSave')
    const thisSelected = JSON.parse(JSON.stringify(selectedObject))
    // id univoco che lega un oggetto fabric al suo oggetto di configurazione
    const canvasId = editingObject ? editingObject.id : uuidv4()
    // Creare l'oggetto di configurazione
    const elementConfig = { ...element.config[0] }
    // assegno l'id per accoppiare l'oggetto con l'oggetto di canvas
    elementConfig.id = canvasId
    // assegno il tipo di oggetto all'elemento di configurazione
    elementConfig.canvasLibraryType = thisSelected.type
    // se stiamo creando un generatore
    if (elementConfig.canvasLibraryType === 'generator') {
      // Se l'elemento di config non ha un uuid
      if (!(elementConfig.uuid && elementConfig.uuid !== undefined)) {
        // generiamo l'uuid lato UI dato che il generatore è un device virtuale
        elementConfig.uuid = uuidv4()
      }
    } else {
      // se stiamo creando qualunque altro oggetto associato ad un device fisico
      if (elementConfig.devices && elementConfig.devices.length > 0) {
        // prendiamo l'uuid del device fisico e lo assegnamo come uuid dell'elemento di configurazione
        // console.log('devicesDebug: ', elementConfig.devices[0].deviceId)
        elementConfig.uuid = elementConfig.devices[0].deviceId
      }
    }

    if (
      Object.prototype.hasOwnProperty.call(selectedObject.event, 'onLoad') &&
      typeof selectedObject.event.onLoad === 'function'
    ) {
      // console.log('ObjectConfigDialog onLoad: ', thisSelected.draw)
      // Quando inseriremo informazioni di default relative al disegno queste saranno in:
      // elementConfig.data.draw quindi bisognerà spostare la chiamata dell'onLoad all'interno di generateCanvasObjectFromLibrary
      alog('onLoad - thisSelected.draw: ', thisSelected.draw, 'planimetry')
      thisSelected.draw = selectedObject.event.onLoad(thisSelected.draw, elementConfig)
    }

    if (!editingObject || editingObject === undefined) {
      // creo il nuovo elemento da inserire nel canvas
      const newElement = generateCanvasObjectFromLibrary(thisSelected)
      // assegno l'id all'elemento canvas
      newElement.id = canvasId

      // se l'elemento è un gruppo, assegno l'id ai suoi sottoelementi
      if (newElement.type === 'group') {
        newElement._objects.forEach(obj => {
          obj.groupId = canvasId
        })
      }

      setCanvasElements([newElement])
    }

    // console.log('Set dell\' array di config')
    // 3. settare l'oggetto di configurazione nell'array di config
    const currentConfig = JSON.parse(JSON.stringify(config))
    // console.log('saveConfig - save global config: ', currentConfig)
    const otherConfigElements = currentConfig.filter((el) => el.id !== canvasId)
    // console.log('saveConfig - filteredConfig: ', otherConfigElements)
    otherConfigElements.push(elementConfig)
    // console.log('saveConfig - filteredConfig with new Element: ', otherConfigElements)

    setConfig(otherConfigElements)
    setElement({ config: [] })
    onClose()
  }

  return (
    <Dialog fullWidth maxWidth='md' className={clsx(classes.root, className)} open={open} onClose={onClose}>
      <DialogTitle>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography component='div' variant='h4'>
            Imposta le caratteristiche
          </Typography>
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>{children}</DialogContent>
      <Divider />
      <DialogActions>
        <Button variant='contained' color='primary' onClick={handleSave}>
          Salva
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default ObjectConfigDialog
