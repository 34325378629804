import { useState } from 'react'
// basic documentation
// https://www.notion.so/pelvspace/Energy-Device-Log-Modal-7d7a86548430499d836eed89bdcd64fe
// import clsx from 'clsx'
import PropTypes from 'prop-types'
import moment from 'moment'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import { europeNum } from 'src/utils/general'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  useMediaQuery,
  useTheme,
  Grid,
  Button,
  // TextField,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  ListItemText,
  MenuItem,
  Dialog,
  // DialogActions,
  DialogContent,
  SvgIcon,
  // DialogContentText,
  // DialogTitle,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Box,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Typography,
  makeStyles,
  withStyles
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { FileText as FileIcon, ChevronLeft as LeftIcon, ChevronRight as RightIcon } from 'react-feather'
import { alog } from 'src/utils/apioLog'

// DialogTitle personalizzato
const titleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
})
const DialogTitle = withStyles(titleStyles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h4'>{children}</Typography>
      {onClose
        ? (
          <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
          )
        : null}
    </MuiDialogTitle>
  )
})

// Stili del componente
const useStyles = makeStyles((theme) => ({
  root: {},
  fullWidth: {
    width: '100%'
  },
  // tableContainer: {
  //  maxHeight: '100%',
  // },
  datePicker: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  selectLabel: {
    backgroundColor: theme.palette.background.paper,
    paddingRight: 8,
    paddingLeft: 8
  }
}))

// funzione che torna l'height di un nodo
// const getElemHeight = (elemId, decrease) => {
//   const elem = document.getElementById(elemId)
//   // console.log('elem => ', elem)
//   if (elem) {
//     // console.log('DeviceLogs height => ', elem.clientHeight)
//     return decrease ? elem.clientHeight - decrease : elem.clientHeight
//   } else {
//     return 'auto'
//   }
// }

const addAnHour = (date) => {
  if (date) {
    return moment(date)
      .add({ hours: '1' })
      .toISOString()
  } else {
    return null
  }
}
const now = moment()
  .set({ minute: 0, second: 0, millisecond: 0 })
  .toISOString()
const from = moment(now).toISOString()
const to = addAnHour(now)

// const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]

function ModalDeviceLogs ({
  open,
  onClose,
  data,
  initialRows,
  columnsLabels,
  name,
  initialFrom,
  initialTo,
  onPeriodChange,
  toogleDownloadExcel,
  onLoad
}) {
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const maxWidth = 'lg'
  // const [tableHeight, setTableHeight] = useState(null)
  // var per download excel
  // const [showDownloadExcel, setShowDownloadExcel] = useState(false)
  // const [excelFrom, setExcelFrom] = useState(moment(initialFrom))
  // const [excelTo, setExcelTo] = useState(moment(initialTo))

  // mi preparo le date
  const [selectedDay, setSelectedDay] = useState(moment(initialFrom))
  // variabile con l'array delle colonne da visualizzare
  const [selectedColumns, setSelectedColumns] = useState(['all'])
  // console.log('DeviceLogs selectedColumns => ', selectedColumns)

  // mi trovo le colonne
  const columns = columnsLabels ? columnsLabels.map((col) => col.prop) : Object.keys(data)
  // console.log('DeviceLogs columns => ', columns)
  // mi trovo le righe
  const rows = initialRows

  // variabile che mi specifica se ho dati altrimenti nasconde tabella
  // di default è true perchè poi mi scorro i dati e se ne trovo 1 metto a false
  let dataIsEmpty = true
  // controllo se ho i dati
  if (Object.keys(data).length > 0) {
    Object.keys(data).forEach((key) => {
      if (data[key].length > 0) {
        dataIsEmpty = false
      }
    })
  }

  // varibile con stringa per maxData Error Message
  // const maxDateErrorMessage = 'Data massima raggiunta'

  const returnCellValue = (valuesArray, ref, prop) => {
    // console.log('valuesArray => ', valuesArray)
    // console.log('ref => ', ref)
    if (valuesArray) {
      const elem = valuesArray.find((el) => el[0] === ref)
      if (elem) {
        if (prop === 'status') {
          return elem[1]
        } else {
          return europeNum(elem[1], 2)
        }
      } else {
        return ''
      }
    } else {
      return ''
    }
  }

  // const updatedMaxHeight = () => {
  //   // console.log('DeviceLogs dentro updatedMaxHeight')
  //   setTableHeight(getElemHeight('logsTableContainer'))
  // }

  // funzione che cambia l'ora al calendario
  const handleChangeHour = async (newFrom, newTo) => {
    // console.log('changeHour newFrom => ', newFrom)
    // console.log('changeHour newTo => ', newTo)

    // chiamo il refresh dei dati
    if (onPeriodChange) {
      // setTableHeight(null)
      await onPeriodChange(newFrom, newTo)
      // updatedMaxHeight()
    }
  }

  // funzione che manda avanti o indietro di un'ora il calendario
  const handleQuickPeriodChange = (action) => {
    let dateToSend = moment(selectedDay)
    if (action === 'prev') {
      dateToSend = moment(dateToSend).subtract({ hours: '1' })
    } else if (action === 'next') {
      dateToSend = moment(dateToSend).add({ hours: '1' })
    }
    const from = moment(dateToSend).toISOString()
    const to = addAnHour(dateToSend)
    handleChangeHour(from, to)
    setSelectedDay(dateToSend)
  }

  const handleClose = () => {
    setSelectedColumns(['all'])
    onClose()
  }

  // lancio l'onLoad
  if (onLoad) {
    // alog('Lancio onload', null, 'devicelog')
    onLoad()
  }

  const isExtraSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='logs-dialog'
      aria-describedby='logs-dialog'
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      fullWidth
      // onEntered={updatedMaxHeight}
    // {...other}
    >
      <DialogTitle disableTypography id='log-dialog-title' onClose={handleClose}>
        Log {name}
      </DialogTitle>
      <Box pl={2} pr={2}>
        <Typography component='p' variant={!isExtraSmall ? 'body1' : 'body2'} style={{ marginBottom: 16 }}>
          Seleziona la data e l'ora per cui visualizzare i dati.
        </Typography>
        <Grid container alignItems='center' spacing={2}>
          {onPeriodChange && (
            <Grid item xs={2} sm='auto'>
              <IconButton aria-label='close' onClick={() => handleQuickPeriodChange('prev')}>
                <LeftIcon />
              </IconButton>
            </Grid>
          )}
          {onPeriodChange && (
            <Grid item xs={8} sm='auto'>
              <KeyboardDateTimePicker
                className={classes.datePicker}
                label='Data ed ora'
                size='small'
                format='DD/MM/YYYY HH:mm'
                name='selectedDay'
                disableFuture
                // maxDate={excelTo}
                // variant='inline'
                views={['date', 'hours']}
                ampm={false}
                inputVariant='outlined'
                value={selectedDay}
                onChange={(date) => {
                  alog('calendar date => ', date, 'devicelog')
                  // let dateToSend = moment(date).set({ minute: 0, second: 0, millisecond: 0 })
                  const dateToSend = moment(date)
                  const from = moment(dateToSend).toISOString()
                  const to = addAnHour(dateToSend)
                  handleChangeHour(from, to)
                  // setExcelFrom(moment(from))
                  // setExcelTo(moment(to))
                  setSelectedDay(date)
                }}
              />
            </Grid>
          )}
          {onPeriodChange && (
            <Grid item xs={2} sm='auto'>
              <IconButton aria-label='close' onClick={() => handleQuickPeriodChange('next')}>
                <RightIcon />
              </IconButton>
            </Grid>
          )}
          <Grid item xs>
            <FormControl variant='outlined' size='small' fullWidth>
              <InputLabel id='selectedColumns-label' className={classes.selectLabel}>
                Grandezze visualizzate
              </InputLabel>
              <Select
                labelId='selectedColumns-label'
                id='selectedColumns'
                // label='Grandezze da visualizzare'
                multiple
                // variant='outlined'
                disabled={dataIsEmpty}
                value={selectedColumns}
                // input={<Input />}
                renderValue={(selected) => {
                  // console.log('DeviceLogs selected => ', selected)
                  const arrayToSend = selected.map((selection) => {
                    if (selection === 'all') {
                      return 'Tutte'
                    } else {
                      const thisCol = columnsLabels.find((column) => column.prop === selection)
                      return thisCol.label
                    }
                  })
                  return arrayToSend.join(', ')
                }}
                onChange={(event) => {
                  // console.log('DeviceLogs selectedColumns onChange, event.target.value => ', event.target.value)
                  const newSelection = [...event.target.value]
                  // se non ha selezionato niente lascio all
                  if (newSelection.length === 0) {
                    setSelectedColumns(['all'])
                  } else {
                    // controllo se ci sono stati cambiamenti, in particolare per 'all'
                    const allIndexPrev = selectedColumns.indexOf('all')
                    const allIndexNew = newSelection.indexOf('all')
                    if (allIndexNew === -1) {
                      // non ho all nel nuovo, significa che non c'era prima e non è stato selezionato, pusho tutto così com'è
                      setSelectedColumns(newSelection)
                    } else {
                      // nel nuovo c'è all
                      if (allIndexPrev === -1) {
                        // non c'era all prima, quindi ha selezionato all quando prima era deselezionato, pusho solo lui
                        setSelectedColumns(['all'])
                      } else {
                        // all c'era prima ora pure ma ha selezionato anche altro quindi rimuovo all e pusho
                        newSelection.splice(allIndexNew, 1)
                        setSelectedColumns(newSelection)
                      }
                    }
                  }
                }}
              >
                <MenuItem value='all'>
                  <Checkbox checked={selectedColumns.indexOf('all') > -1} color='primary' />
                  <ListItemText primary='Tutte' />
                </MenuItem>
                {columnsLabels.map((column, index) => (
                  <MenuItem key={index} value={column.prop}>
                    <Checkbox checked={selectedColumns.indexOf(column.prop) > -1} color='primary' />
                    <ListItemText primary={column.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item xs></Grid> */}
          {!isExtraSmall && toogleDownloadExcel && (
            <Grid item>
              <Button
                color='primary'
                variant='contained'
                // startIcon={<SvgIcon fontSize='small'>{!showDownloadExcel ? <DownIcon /> : <UpIcon />}</SvgIcon>}
                startIcon={
                  <SvgIcon fontSize='small'>
                    <FileIcon />
                  </SvgIcon>
                }
                onClick={() => toogleDownloadExcel()}
                style={{ marginLeft: 12 }}
              >
                Excel
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
      <DialogContent id='logsTableContainer' style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0, overflow: 'hidden' }}>
        <PerfectScrollbar>
          {/* <TableContainer style={{ maxHeight: tableHeight }}> */}
          <TableContainer style={{ maxHeight: '75vh', height: '75vh' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align='left'>Data</TableCell>
                  {columns
                    .filter((column) => {
                      if (selectedColumns.indexOf('all') > -1) {
                        return true
                      } else {
                        return selectedColumns.indexOf(column) > -1
                      }
                    })
                    .map((column, index) => {
                      let finalIndex = index
                      if (selectedColumns.indexOf('all') === -1) {
                        finalIndex = columnsLabels.findIndex((col) => col.prop === column)
                      }
                      return (
                        <TableCell key={index} align='center'>
                          {columnsLabels[finalIndex].label || column}
                        </TableCell>
                      )
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataIsEmpty
                  ? (
                    <TableRow>
                      <TableCell align='left' colSpan={columns.length} style={{ paddingLeft: 30 }}>
                        <p style={{ marginBottom: 24 }}>Non ci sono dati da visualizzare per il periodo selezionato.</p>
                        <img src='/static/images/no-data.svg' style={{ width: '23vw' }} />
                      </TableCell>
                    </TableRow>
                    )
                  : (
                      rows.map((row, rowIndex) => {
                        return (
                          <TableRow key={rowIndex}>
                            <TableCell align='left'>{typeof row === 'string' ? row : moment(row).format('DD/MM/YYYY HH:mm')}</TableCell>
                            {columns
                              .filter((column) => {
                                if (selectedColumns.indexOf('all') > -1) {
                                  return true
                                } else {
                                  return selectedColumns.indexOf(column) > -1
                                }
                              })
                              .map((column, columnIndex) => {
                                return (
                                  <TableCell key={columnIndex} align='center'>
                                    {returnCellValue(data[column], row, column)}
                                  </TableCell>
                                )
                              })}
                          </TableRow>
                        )
                      })
                    )}
              </TableBody>
            </Table>
          </TableContainer>
        </PerfectScrollbar>
      </DialogContent>
    </Dialog>
  )
}

ModalDeviceLogs.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object,
  initialRows: PropTypes.array,
  columnsLabels: PropTypes.array,
  name: PropTypes.string,
  initialFrom: PropTypes.string,
  initialTo: PropTypes.string,
  // hoursToShow: PropTypes.number,
  // dataFrequency: PropTypes.number,
  onPeriodChange: PropTypes.func,
  toogleDownloadExcel: PropTypes.func,
  onLoad: PropTypes.func
}

ModalDeviceLogs.defaultProps = {
  data: {},
  initialRows: [],
  columnsLabels: [],
  name: 'Dispositivo',
  initialFrom: from,
  initialTo: to
  // hoursToShow: 1,
  // dataFrequency: 1,
}

export default ModalDeviceLogs
