import { basicColors } from 'src/theme'
import { TableRow, withStyles } from '@material-ui/core'

const styledBy = (property, mapping) => (props) => {
  // console.log('mapping => ', mapping)
  // console.log('props => ', props)
  if (props.status === null || props.status === undefined) {
    props.status = 'default'
  }
  return mapping[props[property]]
}
const plantsRowStyles = {
  root: {
    background: styledBy('status', {
      default: 'white',
      ok: basicColors.lightGreen,
      0: basicColors.lightGreen,
      maintenance: basicColors.lightYellow,
      warning: basicColors.lightYellow,
      1: basicColors.lightYellow,
      error: basicColors.lightRed,
      2: basicColors.lightRed,
      anomaly_energy: basicColors.lightOrange,
      anomaly_communication: basicColors.lightYellow,
      anomaly_component: basicColors.lightRed
    }),
    cursor: 'pointer',
    color: basicColors.darkText
  }
}
export const StatusTableRow = withStyles(plantsRowStyles)(({ classes, status, ...other }) => (
  <TableRow className={classes.root} {...other} />
))
