import { useState } from 'react'
// basic documentation
// https://www.notion.so/pelvspace/Energy-Device-Log-Modal-7d7a86548430499d836eed89bdcd64fe
// import clsx from 'clsx'
import PropTypes from 'prop-types'
import moment from 'moment'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
// import { europeNum } from 'src/utils/general'
// import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  useMediaQuery,
  useTheme,
  Grid,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Dialog,
  DialogActions,
  DialogContent,
  SvgIcon,
  Box,
  CircularProgress,
  // DialogContentText,
  // DialogTitle,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
  makeStyles,
  withStyles
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { FileText as FileIcon } from 'react-feather'
import { basicColors } from 'src/theme'
import { alog } from 'src/utils/apioLog'

// DialogTitle personalizzato
const titleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
})
const DialogTitle = withStyles(titleStyles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h4'>{children}</Typography>
      {onClose
        ? (
          <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
          )
        : null}
    </MuiDialogTitle>
  )
})

// Stili del componente
const useStyles = makeStyles((theme) => ({
  root: {},
  fullWidth: {
    width: '100%'
  },
  // tableContainer: {
  // 	maxHeight: '100%',
  // },
  datePicker: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  selectLabel: {
    backgroundColor: theme.palette.background.paper,
    paddingRight: 8,
    paddingLeft: 8
  }
}))

const addAnHour = (date) => {
  if (date) {
    return moment(date)
      .add({ hours: '1' })
      .toISOString()
  } else {
    return null
  }
}
const now = moment()
  .set({ minute: 0, second: 0, millisecond: 0 })
  .toISOString()
const from = moment(now).toISOString()
const to = addAnHour(now)

function ModalExcelDeviceLogs ({
  open,
  onClose,
  deviceId,
  name,
  // aggregationType,
  devices,
  initialFrom,
  initialTo,
  downloadExcel,
  isExcelTooBig
}) {
  // console.log('downloadExcel => ', downloadExcel)
  // console.log('ExcelLogs deviceId => ', deviceId)
  // console.log('ExcelLogs aggregationType => ', aggregationType)
  // console.log('ExcelLogs initialFrom => ', initialFrom)
  // console.log('ExcelLogs initialTo => ', initialTo)
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const maxWidth = 'sm'
  // var per download excel
  const [isPreparingExcel, setIsPreparingExcel] = useState(false)
  const [excelFrom, setExcelFrom] = useState(moment(initialFrom))
  const [excelTo, setExcelTo] = useState(moment(initialTo))
  const [daysOfDiff, setDaysOfDiff] = useState(excelTo.diff(excelFrom, 'days'))
  // console.log('daysOfDiff => ', daysOfDiff)

  // varibile con stringa per maxData Error Message
  const maxDateErrorMessage = 'Data massima raggiunta'

  // variabile con l'array delle possibili aggregazioni
  const aggregations = [
    {
      label: 'Puntuali',
      value: 'raw'
    },
    {
      label: 'Quarto orari',
      value: 'quarter'
    },
    {
      label: 'Orari',
      value: 'hourly'
    },
    {
      label: 'Giornalieri',
      value: 'daily'
    }
    // {
    //   label: 'Settimanali',
    //   value: 'weekly'
    // },
    // {
    //   label: 'Mensili',
    //   value: 'monthly'
    // }
  ]
  // variabile con l'aggregazione da scaricare
  const [selectedAggregation, setSelectedAggregation] = useState('raw')
  // funzione che torna true o false in base al periodo selezionato
  const disableAggregations = (aggregation) => {
    // console.log('disableAggregations daysOfDiff => ', daysOfDiff)
    // controllo la grandezza del periodo
    if (aggregation === 'raw') {
      return !(daysOfDiff <= 3)
    } else if (aggregation === 'quarter') {
      // return !(daysOfDiff < 7)
      return !(daysOfDiff < 94)
    } else if (aggregation === 'hourly') {
      return !(daysOfDiff < 366)
    } else if (aggregation === 'daily') {
      return !(daysOfDiff > 3)
    } else if (aggregation === 'weekly') {
      return !(daysOfDiff >= 7)
    } else if (aggregation === 'monthly') {
      return !(daysOfDiff >= 31)
    } else {
      return true
    }
  }
  // funzione che in base al periodo cambia, se necessario, il radio selezionato
  const shouldIChangeSelectedAggregation = (daysOfPeriod) => {
    if (
      daysOfPeriod <= 3 &&
      (selectedAggregation === 'daily' || selectedAggregation === 'weekly' || selectedAggregation === 'monthly')
    ) {
      setSelectedAggregation('raw')
    } else if (
      daysOfPeriod > 3 &&
      daysOfPeriod < 7 &&
      (selectedAggregation === 'raw' || selectedAggregation === 'weekly' || selectedAggregation === 'monthly')
    ) {
      setSelectedAggregation('quarter')
    } else if (
      daysOfPeriod >= 7 &&
      daysOfPeriod < 31 &&
      (selectedAggregation === 'raw' || selectedAggregation === 'quarter' || selectedAggregation === 'monthly')
    ) {
      setSelectedAggregation('hourly')
    } else if (
      daysOfPeriod >= 31 &&
      (selectedAggregation === 'raw' || selectedAggregation === 'quarter' || selectedAggregation === 'hourly')
    ) {
      setSelectedAggregation('daily')
    }
  }

  // funzione che abilita picker per download excel
  const handleExcelDownload = async () => {
    // console.log('dentro handleExcelDownload')
    if (isPreparingExcel === false && downloadExcel) {
      setIsPreparingExcel(true)
      // lancio la chiamata
      await downloadExcel(excelFrom, excelTo, selectedAggregation)
      setIsPreparingExcel(false)
    }
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='excel-logs-dialog'
      aria-describedby='excel-logs-dialog'
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      fullWidth
    // onEntered={disableAggregations}
    // {...other}
    >
      <DialogTitle disableTypography id='excel-log-dialog-title' onClose={handleClose}>
        Scarica log {name} in excel
      </DialogTitle>
      {!deviceId && devices.lenght === 0
        ? (
          <DialogContent>
            <Typography component='p' variant='body1'>
              Non è stato selezionato un dispositivo né ci sono dispositivi disponibili.
            </Typography>
          </DialogContent>
          )
        : (
          <DialogContent>
            <Typography component='p' variant='body1' style={{ marginBottom: 24 }}>
              Seleziona il periodo e il tipo di aggregazione per scaricare i dati.
            </Typography>
            <Grid container alignItems='center' justifyContent='flex-start' spacing={2}>
              <Grid item xs={12} sm={6}>
                <KeyboardDateTimePicker
                  className={classes.datePicker}
                  label='Da'
                  size='small'
                  fullWidth
                  format='DD/MM/YYYY HH:mm'
                  name='excelFrom'
                  disableFuture
                  maxDate={excelTo}
                // variant='inline'
                  views={['date', 'hours']}
                  ampm={false}
                  inputVariant='outlined'
                  value={excelFrom}
                  onChange={(date) => {
                    alog('excel from date => ', date, 'logexcel')
                    const dateToSend = moment(date).set({ minute: 0, second: 0, millisecond: 0 })
                    setExcelFrom(moment(dateToSend))
                    const newDays = excelTo.diff(moment(dateToSend), 'days')
                    setDaysOfDiff(newDays)
                    shouldIChangeSelectedAggregation(newDays)
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <KeyboardDateTimePicker
                  className={classes.datePicker}
                  label='A'
                  size='small'
                  fullWidth
                  format='DD/MM/YYYY HH:mm'
                  maxDateMessage={maxDateErrorMessage}
                  name='excelTo'
                  disableFuture
                  minDate={excelFrom}
                  views={['date', 'hours']}
                  ampm={false}
                // variant='inline'
                  inputVariant='outlined'
                  value={excelTo}
                  onChange={(date) => {
                    alog('excel to date => ', date, 'logexcel')
                    const dateToSend = moment(date).set({ minute: 0, second: 0, millisecond: 0 })
                    setExcelTo(moment(dateToSend))
                    const newDays = moment(dateToSend).diff(excelFrom, 'days')
                    setDaysOfDiff(newDays)
                    shouldIChangeSelectedAggregation(newDays)
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl component='fieldset' style={{ marginTop: 16 }}>
                  <FormLabel component='legend'>Tipo di aggregazione dei dati</FormLabel>
                  <RadioGroup
                    aria-label='Selected Aggregation'
                    name='selectedAggregation'
                    value={selectedAggregation}
                    onChange={(event) => {
                      alog('new selectedAggregation => ', event.target.value, 'logexcel')
                      setSelectedAggregation(event.target.value)
                    }}
                  >
                    {aggregations.map((aggregation, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          value={aggregation.value}
                          control={<Radio color='primary' />}
                          label={aggregation.label}
                          disabled={disableAggregations(aggregation.value)}
                        />
                      )
                    })}
                    {/* <FormControlLabel value='disabled' disabled control={<Radio />} label='(Disabled option)' /> */}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            {isExcelTooBig && (
              <Box p={2} mt={2} style={{ backgroundColor: basicColors.darkYellow, borderRadius: 4 }}>
                <Typography component='p' variant='body1' style={{ margin: 0, color: basicColors.darkText }}>
                  Attenzione: l'excel richiesto è troppo grande per essere scaricato. Le invieremo una mail non appena il file è pronto.
                </Typography>
              </Box>
            )}
          </DialogContent>
          )}
      <DialogActions>
        <Button fullWidth onClick={handleClose}>
          Cancel
        </Button>
        {downloadExcel && (
          <Button
            fullWidth
            onClick={handleExcelDownload}
            variant='contained'
            color='primary'
            startIcon={
              !isPreparingExcel
                ? (
                  <SvgIcon fontSize='small'>
                    <FileIcon />
                  </SvgIcon>
                  )
                : null
            }
          >
            {isPreparingExcel ? <CircularProgress color='secondary' size={24} thickness={6} /> : 'Scarical Excel'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

ModalExcelDeviceLogs.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  deviceId: PropTypes.string,
  devices: PropTypes.array,
  name: PropTypes.string,
  initialFrom: PropTypes.string,
  initialTo: PropTypes.string,
  downloadExcel: PropTypes.func,
  isExcelTooBig: PropTypes.bool
}

ModalExcelDeviceLogs.defaultProps = {
  deviceId: '',
  name: 'Dispositivo',
  devices: [],
  initialFrom: from,
  initialTo: to,
  isExcelTooBig: false
}

export default ModalExcelDeviceLogs
