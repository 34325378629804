import { memo } from 'react'
import clsx from 'clsx'
import { Box, Card, makeStyles, Table, TableBody, TableCell, TableRow, Typography, useMediaQuery } from '@material-ui/core'
import { useSelector } from 'src/store'
import { europeNum } from 'src/utils/general'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    paddingTop: 8
  },
  sectionTitle: {
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textTransform: 'uppercase',
    color: theme.palette.primary.main
  }
}))

function PlantModuleTable ({ className }) {
  const classes = useStyles()
  const { module, name, peakPower, anagraphic } = useSelector((state) => state.industryConsumptionPlantView)
  const { peakPower: anagraphicPeakPower } = anagraphic

  const isOneLineTable = useMediaQuery((theme) => theme.breakpoints.down('lg'))

  // Oggetto che rappresenta i dati da mostrare in tabella per l'impianto
  const tableData = module.reduce((acc, currentElement) => {
    acc = {
      ...acc,
      ...currentElement
    }
    return acc
  }, {
    peakPower
  })

  return (
    <Card className={clsx(classes.root, className)}>
      <Typography className={classes.sectionTitle} variant='h6'>{name || 'Impianto'}</Typography>
      {isOneLineTable
        ? (
          <Box display='flex'>
            <Table size='small'>
              <TableBody>
                <TableRow>
                  <TableCell variant='head'>P. Picco</TableCell>
                  <TableCell>{anagraphicPeakPower ? `${europeNum(anagraphicPeakPower)} W` : '-'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant='head'>P. Massima</TableCell>
                  <TableCell>{tableData.peakPower ? `${europeNum(tableData.peakPower)} W` : '-'}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          )
        : (
          <Box display='flex'>
            <Table size='small'>
              <TableBody>
                <TableRow>
                  <TableCell variant='head'>P. Picco</TableCell>
                  <TableCell>{anagraphicPeakPower ? `${europeNum(anagraphicPeakPower)} W` : '-'}</TableCell>
                  <TableCell variant='head'>P. Massima</TableCell>
                  <TableCell>{tableData.peakPower ? `${europeNum(tableData.peakPower)} W` : '-'}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          )}
    </Card>
  )
}
export default memo(PlantModuleTable)
