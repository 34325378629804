import { Redirect, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import useAuth from 'src/hooks/useAuth'
import { useThisToAccess } from 'src/hooks/usePermissions'
import { alog } from 'src/utils/apioLog'

const AuthGuard = ({ children }) => {
  const { isAuthenticated, user } = useAuth()
  const location = useLocation()
  const { answer, redirectTo } = useThisToAccess(user, location.pathname)
  // alog('user => ', user, 'guard')
  if (!answer) {
    alog('location => ', location, 'guard')
    alog('access to url => ', answer, 'guard')
    alog('redirectTo => ', redirectTo, 'guard')
  }

  if (!isAuthenticated) {
    return <Redirect to='/login' />
  }

  // controllo se può visualizzare l'url per i permessi
  if (!answer && redirectTo) {
    return <Redirect to={redirectTo} />
  }

  return (
    <>
      {children}
    </>
  )
}

AuthGuard.propTypes = {
  children: PropTypes.node
}

export default AuthGuard
