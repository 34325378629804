import { useState, useEffect, memo } from 'react'
// import clsx from 'clsx'
import PropTypes from 'prop-types'
import { basicColors } from 'src/theme'
import moment from 'moment'
import { DatePicker } from '@material-ui/pickers'
import Alert from '@material-ui/lab/Alert'
import {
  // colors,
  Box,
  Button,
  Drawer,
  AppBar,
  Toolbar,
  // Paper,
  // Tooltip,
  IconButton,
  Typography,
  TextField,
  MenuItem,
  Grid,
  makeStyles,
  useMediaQuery
  // withStyles,
} from '@material-ui/core'
// import { useSnackbar } from 'notistack'
import { alog } from 'src/utils/apioLog'

import { X as CloseIcon } from 'react-feather'

// stile CSS generale
const useStyles = makeStyles((theme) => ({
  sidenavContainer: {
    width: '40vw'
    // maxWidth: '65vw',
  },
  title: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  }
}))

const basicReport = {
  name: 'Nuovo Report',
  type: 'plant',
  plantId: [],
  plantStatus: 'ok',
  plantStatusNotes: '',
  month: null,
  year: null,
  notes: {
    section1: '',
    section2: '',
    section3: ''
  }
  // fileGenerationStatus: 'queued'
}

const months = [
  'Gennaio',
  'Febbraio',
  'Marzo',
  'Aprile',
  'Maggio',
  'Giugno',
  'Luglio',
  'Agosto',
  'Settembre',
  'Ottobre',
  'Novembre',
  'Dicembre'
]

// componente principale
const PlantReportSidenav = ({ className, open, reportToEdit, plantName, plantId, onSave, onEdit, onClose }) => {
  const classes = useStyles()
  // const { enqueueSnackbar } = useSnackbar()
  const isExtraSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const isMedium = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const now = moment()
  const lastMonth = moment(now).set('month', moment(now).month() - 1)
  const isEditing = !!reportToEdit
  const [currentReport, setCurrentReport] = useState(reportToEdit ? JSON.parse(JSON.stringify(reportToEdit)) : JSON.parse(JSON.stringify(basicReport)))
  const [selectedDate, handleDateChange] = useState(reportToEdit ? moment(now).year(reportToEdit.month).month(reportToEdit.month) : lastMonth)

  useEffect(() => {
    alog('effect reportToEdit =>', reportToEdit, 'report')
    if (reportToEdit) {
      let newReport = JSON.parse(JSON.stringify(reportToEdit))
      // controllo che abbia tutti i dati
      newReport = {
        ...newReport,
        notes: newReport.notes && newReport.notes.section1
          ? newReport.notes
          : {
              section1: '',
              section2: '',
              section3: ''
            },
        plantStatus: newReport.plantStatus ? newReport.plantStatus : 'ok',
        plantStatusNotes: newReport.plantStatusNotes ? newReport.plantStatusNotes : ''
      }
      alog('effect newReport =>', newReport, 'report')
      setCurrentReport(newReport)
    } else {
      setCurrentReport(JSON.parse(JSON.stringify(basicReport)))
    }
    // alog('effect currentReport =>', currentReport, 'report')
    // return () => {}
  }, [reportToEdit])

  return (
    <Drawer anchor='right' open={open} onClose={onClose}>
      <Box
        display='block'
        className={classes.sidenavContainer}
        style={{ width: isExtraSmall ? '100vw' : isSmall ? '73vw' : isMedium ? '50vw' : '40vw' }}
      >
        <AppBar position='sticky'>
          <Toolbar
            style={{
              backgroundColor: basicColors.secondary,
              paddingLeft: 16,
              paddingRight: 16
            }}
          >
            <Typography variant='h4' className={classes.title} display='block' noWrap>
              {isEditing
                ? currentReport.name || 'Report'
                : 'Genera un nuovo Report Impianto'}
            </Typography>
            <div className={classes.grow} />
            <IconButton aria-label='Chiudi report' onClick={onClose} color='inherit'>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div style={{ padding: 16 }}>
          <Grid container spacing={2} style={{ marginBottom: 16 }}>
            {!isEditing && (
              <Grid item xs={12}>
                <Typography variant='body1' display='block' style={{ marginBottom: 16 }}>
                  Compila tutti i campi obbligatori per generare il report
                </Typography>
                <DatePicker
                  fullWidth
                  required
                  autoOk
                  variant='inline'
                  inputVariant='outlined'
                  views={['year', 'month']}
                  label='Mese di riferimento'
                  // helperText='With min and max'
                  minDate={new Date('2020-01-01')}
                  // disableFuture
                  maxDate={lastMonth}
                  value={selectedDate}
                  onChange={handleDateChange}
                  style={{ marginBottom: 6 }}
                />
                <Alert severity='info'><b>Attenzione</b>: se è già presente un report per il periodo selezionato, questo verrà sostituito!</Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant='body2' display='block' style={{ marginBottom: 16 }}>
                Informazioni per il Report Impianti
              </Typography>
              <TextField
                id='plantStatus'
                select
                fullWidth
                required
                name='plantStatus'
                label="Stato dell'impianto"
                value={currentReport.plantStatus}
                variant='outlined'
                onChange={(event) => {
                  alog('new plantStatus =>', event.target.value, 'report')
                  setCurrentReport((prevState) => {
                    return {
                      ...prevState,
                      plantStatus: event.target.value
                    }
                  })
                }}
                style={{ marginBottom: 16 }}
                // helperText="Please select your currency"
              >
                <MenuItem value='ok'>Ok</MenuItem>
                <MenuItem value='warning'>Attenzione</MenuItem>
                <MenuItem value='error'>Errore</MenuItem>
              </TextField>
              {/* <TextField
                id='plantStatusNotes'
                fullWidth
                required
                label='Note sullo stato dell’impianto'
                name='plantStatusNotes'
                multiline
                value={currentReport.plantStatusNotes}
                minRows={3}
                maxRows={6}
                variant='outlined'
                onChange={(event) => {
                  // alog('new plantStatusNotes =>', event.target.value, 'report')
                  const newNote = event.target.value
                  setCurrentReport((prevState) => {
                    return {
                      ...prevState,
                      plantStatusNotes: newNote
                    }
                  })
                }}
              /> */}
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body2' display='block' style={{ marginBottom: 16 }}>
                Informazioni per questo Report dell’impianto
              </Typography>
              <TextField
                id='section1'
                fullWidth
                label='Note Sezione 1'
                name='section1'
                multiline
                value={currentReport.notes.section1}
                minRows={3}
                maxRows={6}
                variant='outlined'
                style={{ marginBottom: 16 }}
                onChange={(event) => {
                  // alog('new notes.section1 =>', event.target.value, 'report')
                  const newNote = event.target.value
                  setCurrentReport((prevState) => {
                    return {
                      ...prevState,
                      notes: {
                        ...prevState.notes,
                        section1: newNote
                      }
                    }
                  })
                }}
              />
              <TextField
                id='section2'
                fullWidth
                label='Note Sezione 2'
                name='section2'
                multiline
                value={currentReport.notes.section2}
                minRows={3}
                maxRows={6}
                variant='outlined'
                style={{ marginBottom: 16 }}
                onChange={(event) => {
                  // alog('new notes.section2 =>', event.target.value, 'report')
                  const newNote = event.target.value
                  setCurrentReport((prevState) => {
                    return {
                      ...prevState,
                      notes: {
                        ...prevState.notes,
                        section2: newNote
                      }
                    }
                  })
                }}
              />
              <TextField
                id='section3'
                fullWidth
                label='Note Sezione 3'
                name='section3'
                multiline
                value={currentReport.notes.section3}
                minRows={3}
                maxRows={6}
                variant='outlined'
                onChange={(event) => {
                  // alog('new notes.section3 =>', event.target.value, 'report')
                  const newNote = event.target.value
                  setCurrentReport((prevState) => {
                    return {
                      ...prevState,
                      notes: {
                        ...prevState.notes,
                        section3: newNote
                      }
                    }
                  })
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Box width='100%' display='flex' alignItems='center' justifyContent='space-between' mb={2}>
                <Button onClick={onClose}>
                  Chiudi
                </Button>
                <Button
                  onClick={() => {
                    // replico l'oggetto
                    const reportToSend = JSON.parse(JSON.stringify(currentReport))
                    // controllo se è nuovo o se sto editando
                    if (isEditing) {
                      onEdit(reportToSend)
                    } else {
                      // mi setto la variabile
                      const thisMonth = moment(selectedDate).month()
                      const thisYear = moment(selectedDate).year()
                      reportToSend.month = thisMonth
                      reportToSend.year = thisYear
                      reportToSend.plantId = [plantId]
                      reportToSend.name = `Report ${plantName} ${months[reportToSend.month]} ${reportToSend.year}`
                      onSave(reportToSend)
                    }
                  }}
                  disabled={!selectedDate || !currentReport.plantStatus}
                  variant='contained'
                  color='primary'
                >
                  {isEditing
                    ? 'Rigenera Report'
                    : 'Genera Report'}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Box>
    </Drawer>
  )
}

PlantReportSidenav.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool.isRequired,
  plantName: PropTypes.string,
  plantId: PropTypes.string,
  reportToEdit: PropTypes.object,
  periods: PropTypes.array,
  onSave: PropTypes.func,
  onEdit: PropTypes.func,
  onClose: PropTypes.func
}

PlantReportSidenav.defaultProps = {
  open: false,
  reportToEdit: {
    name: 'Nuovo Report',
    type: 'plant',
    plantId: [],
    plantStatus: 'ok',
    plantStatusNotes: '',
    month: null,
    year: null,
    notes: {
      section1: '',
      section2: '',
      section3: ''
    }
  },
  plantName: 'Impianto',
  plantId: '',
  periods: []
}

export default memo(PlantReportSidenav)
