import { memo } from 'react'
import clsx from 'clsx'
import { Box, makeStyles } from '@material-ui/core'
import LoadingCard from '../LoadingCard'
import Chart from 'react-apexcharts'
import { europeNum } from 'src/utils/general'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    position: 'relative'
  },
  dataLoading: {
    position: 'absolute',
    zIndex: 100,
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

function LineConsumptionGraph ({ data = {}, width = null, height = null, isLoading, className, ...rest }) {
  const classes = useStyles()

  const options = {
    chart: {
      type: 'bar',
      width: width || '100%',
      height: height || 350,
      foreColor: '#fff',
      stacked: true,
      animations: {
        enabled: true
      },
      toolbar: {
        show: false
      }
    },
    grid: {
      show: false
    },
    plotOptions: {
      bar: {
        horizontal: true
      }
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    colors: (data.series && data.series.map(serie => serie.color)) || [],
    dataLabels: {
      enabled: true,
      formatter: function (value, { seriesIndex, dataPointIndex, w }) {
        if (seriesIndex === 2) {
          return `${europeNum(w.config.series[1].data[dataPointIndex] - w.config.series[0].data[dataPointIndex])} Wh`
        } else {
          return `${europeNum(value)} Wh`
        }
      }
    },
    xaxis: {
      labels: {
        formatter: (value) => { return `${Math.floor(Number(value))} Wh` }
      },
      categories: data.categories || [],
      crosshairs: {
        show: false,
        width: 1
      }
    },
    yaxis: {
      crosshairs: {
        show: false,
        width: 1
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      followCursor: true,
      theme: 'dark',
      x: {
        show: false
      },
      y: {
        show: false,
        formatter: function (value, { seriesIndex, dataPointIndex, w }) {
          if (seriesIndex === 2) {
            const delta = w.config.series[1].data[dataPointIndex] - w.config.series[0].data[dataPointIndex]
            return delta ? `${europeNum(delta)} Wh` : '-'
          } else {
            return value ? `${europeNum(value)} Wh` : '-'
          }
        }
      }
    },
    fill: {
      opacity: 1
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
      offsetX: 20
    }
  }

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {
        Object.keys(data).length > 0 && isLoading
          ? (<Box width='100%' height='100%' className={classes.dataLoading} />)
          : null
      }
      {
        Object.keys(data).length > 0
          ? (
            <Chart
              options={options}
              series={data.series.map(el => ({ ...el, data: el.data.map(dataEl => Math.abs(dataEl)) }))}
              type={(options.chart && options.chart.type) || 'bar'}
              height={options.chart.height}
              width={options.chart.width}
            />)
          : <LoadingCard />
      }
    </div>
  )
}
export default memo(LineConsumptionGraph)
