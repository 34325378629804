import { memo } from 'react'
import clsx from 'clsx'
// import numeral from 'numeral';
// import moment from 'moment'
import PropTypes from 'prop-types'
import { basicColors } from 'src/theme'
import {
  // colors,
  // Box,
  // Tooltip,
  Grid,
  makeStyles
  // withStyles,
} from '@material-ui/core'
// import { useSnackbar } from 'notistack'

// import {
//   // Edit as EditIcon,
// } from 'react-feather'

// stile CSS generale
const useStyles = makeStyles((theme) => ({
  root: {},
  barContainer: {
    // display: 'flex',
    // flexDirection: 'row',
    width: '100%',
    height: 26
  },
  bar: {
    height: '100%',
    paddingTop: 6,
    color: basicColors.whiteText,
    // textTransform: 'uppercase',
    fontSize: 13,
    fontWeight: 500,
    textAlign: 'center',
    fontFamily: 'Roboto',
    // width: '33%',
    backgroundColor: basicColors.nav
  },
  goodBar: {
    backgroundColor: basicColors.darkGreen,
    color: basicColors.background
  },
  warningBar: {
    backgroundColor: basicColors.darkYellow,
    color: basicColors.background
  },
  errorBar: {
    backgroundColor: basicColors.darkRed,
    color: basicColors.whiteText
  }
}))

// componente principale
const AnomaliesStatusBar = ({ anomaliesBar, className }) => {
  const classes = useStyles()
  // const { enqueueSnackbar } = useSnackbar()
  // console.log('anomaliesBar =>', anomaliesBar)

  // all'avvio aggiorno i dati
  // useEffect(() => {
  //   dispatch(getPlants())
  //   // return () => {}
  // }, [dispatch])

  return (
    <Grid container className={classes.barContainer}>
      {Object.keys(anomaliesBar).map((key, index) => {
        return (
          <Grid
            key={index}
            item
            xs
            className={clsx(
              classes.bar,
              anomaliesBar[key].status === 'warning' || anomaliesBar[key].status === 'maintenance'
                ? classes.warningBar
                : anomaliesBar[key].status === 'error'
                  ? classes.errorBar
                  : null
            )}
          >
            <p>
              {anomaliesBar[key].label}: <b>{anomaliesBar[key].total || 0}</b>
            </p>
          </Grid>
        )
      })}
    </Grid>
  )
}

AnomaliesStatusBar.propTypes = {
  className: PropTypes.string
  // customers: PropTypes.array.isRequired,
  // setRefreshUsers: PropTypes.func
}

// AnomaliesStatusBar.defaultProps = {
//   customers: []
// }

export default memo(AnomaliesStatusBar)
