import { useEffect, useState, memo } from 'react'
// import PropTypes from 'prop-types'
import { Box, Button, Dialog, DialogActions, Grid, Paper, useMediaQuery, useTheme } from '@material-ui/core'
// import { useDispatch, useSelector } from 'react-redux'
// import useIsMountedRef from 'src/hooks/useIsMountedRef'
// import PerfectScrollbar from 'react-perfect-scrollbar'
import PlanimetryView from './PlanimetryView'
import PrGraphView from './PrGraphView'
import PlantContractDataTable from './PlantContractDataTable'
import AnomaliesStatusBar from '../../shared/AnomaliesStatusBar'
import { useDispatch, useSelector } from 'react-redux'
import { updatedPlantInfo } from 'src/slices/pv/plantView'
import { useParams } from 'react-router-dom'
import { alog } from 'src/utils/apioLog'

// STILI DEL COMPONENTE
// const useStyles = makeStyles((theme) => ({
//  root: {},
// }))

// COMPONENTE PRINCIPALE
const Overview = () => {
  // const classes = useStyles()
  const { plantId } = useParams()
  const theme = useTheme()
  alog('plantId => ', plantId, 'platviewpv')
  const { hasError, anomaliesBar } = useSelector((state) => state.pvPlantView)
  const dispatch = useDispatch()

  const [showAnagraphic, setShowAnagraphic] = useState(false)

  useEffect(() => {
    // if (plantId && plantId !== 'demo') {
    //   console.log('faccio il primo dispatch')
    //   dispatch(updatedPlantInfo({ plantId }))
    // }
    const overviewInterval = setInterval(() => {
      console.log('dentro interval')
      dispatch(updatedPlantInfo({ plantId }))
    }, 60000)
    return () => {
      clearInterval(overviewInterval)
      // dispatch(resetPlantInfo())
    }
  }, [dispatch, plantId, hasError])

  const isExtraSmall = useMediaQuery(theme => theme.breakpoints.down('xs'))
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const computedContainerHeight = `calc(100% - 48px - ${theme.spacing(6)}px - 80px)`

  return (
    <Box mt={1} style={{ height: computedContainerHeight, width: '100%' }}>
      {
        showAnagraphic
          ? (
            <Dialog
              fullWidth
              fullScreen={isSmall}
              maxWidth='sm'
              open={showAnagraphic}
              onClose={() => setShowAnagraphic(false)}
            >
              <PlantContractDataTable />
              {isSmall && (
                <DialogActions>
                  <Button variant='outlined' fullWidth onClick={() => setShowAnagraphic(false)}>Close</Button>
                </DialogActions>
              )}
            </Dialog>
            )
          : null
      }
      <Grid container spacing={1} style={{ height: '100%', maxHeight: '100%' }}>
        {!isExtraSmall && (
          <Grid item xs={12} sm={7} md={8} xl={9} style={{ height: 'calc(100% - 26px)' }}>
            <AnomaliesStatusBar anomaliesBar={anomaliesBar} />
            <PlanimetryView />
          </Grid>
        )}
        <Grid item xs={12} sm={5} md={4} xl={3} style={{ height: '100%' }}>
          <Grid container direction='row' style={{ maxHeight: '100%', overflowY: 'auto', paddingBottom: 10 }}>
            <Grid item xs={12} style={{ marginBottom: 8 }}>
              <PrGraphView showTable />
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <Button size='small' fullWidth onClick={() => setShowAnagraphic(true)} color='primary'>Vedi Anagrafica</Button>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default memo(Overview)
