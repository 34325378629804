import { useCallback, useEffect, useState, memo, useRef } from 'react'
import clsx from 'clsx'
import { Box, Card, Grid, LinearProgress, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import PeriodSelection from 'src/components/PeriodSelection'
import InfoCard from 'src/components/InfoCard'
import EnergyDeviation from 'src/components/charts/EnergyDeviation'
import PrDeviation from 'src/components/charts/PrDeviation'
import EnergyGraph from 'src/components/charts/EnergyGraph'
import EnergyRadiationGraph from 'src/components/charts/EnergyRadiationGraph'
import { decodeCardsFromApi, decodePrDeviationGraphFromApi, decodeEnergyGraphFromApi, decodeIrradiationGraphFromApi, getDatesFromPeriod } from '../utils'
import api from 'src/utils/api'
import { useParams } from 'react-router'
import { Skeleton } from '@material-ui/lab'
import moment from 'moment'
import ShareButton from 'src/components/ShareButton'
import { createGuestHeaders } from 'src/utils/createGuestHeaders'
import { useSelector } from 'src/store'
import { alog } from 'src/utils/apioLog'
import { useClientRect } from 'src/hooks/useClientRect'
import DownloadExcelButton from '../../shared/DownloadExcelButton'
import useAuth from 'src/hooks/useAuth'
import ReportMonthlyEnergyGraph from 'src/components/charts/ReportMonthlyEnergyGraph'
import { useDispatch } from 'react-redux'
import { resetPlantInfo, updatedPlantInfo } from 'src/slices/pv/plantView'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%'
  },
  rootProduction: {
    width: '100%',
    height: `calc(100% - 48px - ${theme.spacing(4)}px)`
  },
  section: {
    padding: theme.spacing(2),
    width: '100%',
    height: '100%'
  },
  sectionTitle: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main
  },
  topMargin: {
    marginTop: (theme.spacing(2) - 4)
  },
  smallViewport: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: '100%'
  }
}))

function Production ({ period = null, shared = false, guestToken = null, plantId: sharedPlantId = null, className, ...rest }) {
  const classes = useStyles()
  const { user } = useAuth()
  let { plantId } = useParams()
  const dispatch = useDispatch()
  // Variabile che rappresenta il nome dell'impianto
  const { name: plantName, plantType, startDate, contractDuration, baseline } = useSelector(state => state.pvPlantView)
  // Variabile di stato che rappresenta lo stato di caricamento dei dati
  const [isLoading, setIsLoading] = useState(false)
  // periodo selezionato tramite le chips
  const [customPeriod, setCustomPeriod] = useState('live')
  // data/intervallo di tempo selezionato tramite il date picker
  // const [selectedDate, setSelectedDate] = useState(moment())
  const [selectedDate, setSelectedDate] = useState(null)
  // periodo impostato dallo zoom sul grafico
  const [zoomPeriod, setZoomPeriod] = useState('live')
  // data/data iniziale del periodo di selezione dello zoom
  const [zoomDate, setZoomDate] = useState(new Date().toISOString())
  // dati delle cards
  const [cardsSettings, setCardsSettings] = useState([])
  // dati del grafico di scostamento energia
  const [energyDeviationGraph, setEnergyDeviationGraph] = useState([])
  // dati del grafico di scostamento pr
  const [prDeviationGraph, setPrDeviationGraph] = useState([])
  // dati del grafico dell'energia
  const [energyGraph, setEnergyGraph] = useState({})
  // dati del grafico dell'irraggiamento
  const [radiationGraph, setRadiationGraph] = useState({})
  // dati del grafico di producibilità
  const [producibilityGraph, setProducibilityGraph] = useState({})

  plantId = sharedPlantId || plantId

  const contractYears = useRef([])
  // console.log('FIRST contractYears.current => ', contractYears.current)
  // console.log('FIRST contractDuration => ', contractDuration)

  // Ref per calcolare l'altezza dei grafici
  const [rect, ref] = useClientRect()

  useEffect(() => {
    if (shared) {
      if (plantId && plantId !== 'demo') {
        // console.log('faccio il primo dispatch - pv')
        dispatch(updatedPlantInfo({ plantId, guestToken }))
      }
      return () => {
        // all'unmount resetto alcune variabili
        dispatch(resetPlantInfo())
      }
    }
  }, [shared, dispatch, plantId, guestToken])

  useEffect(() => {
    if (period) {
      setCustomPeriod(period)
    }
  }, [period])

  // Funzione per fare la GET dei dati
  const getData = useCallback(async (timeFrom, timeTo) => {
    console.log(timeFrom, timeTo)
    const customHeaders = createGuestHeaders(guestToken)
    if (plantId) {
      try {
        const response = await api.getResource('plantDetails', {
          fullResponse: true,
          path: `/${plantId}/production?timeFrom=${timeFrom}&timeTo=${timeTo}`,
          customHeaders
        })
        const dataObj = response.data
        return dataObj
      } catch (e) {
        return null
      }
    }
  }, [plantId, guestToken])

  // Funzione che prepara i dati presi dalle API per essere passati alle rispettive funzioni di accoppiamento dei grafici
  const convertData = (dataObj) => {
    // Oggetto dei valori delle cards
    const cardsData = {
      totalExpectedEnergy: dataObj.totalExpectedEnergy || '',
      totalExportedEnergy: dataObj.totalExportedEnergy || '',
      totalProducedEnergy: dataObj.totalProducedEnergy || '',
      totalRadiation: dataObj.totalRadiation || ''
    }
    // Array di oggetti dei valori del grafico di scostamento dell'energia
    const energyDeviationData = [
      {
        label: 'E. Attesa',
        value: dataObj.totalExpectedEnergy || ''
      },
      {
        label: 'E. Prodotta',
        value: dataObj.totalProducedEnergy || ''
      },
      {
        label: 'E. Immessa',
        value: dataObj.totalExportedEnergy || ''
      }
    ]
    // Oggetto dei valori del grafico di scostamento della produzione/pr
    const prDeviationData = {
      realPr: dataObj.realPr,
      baselinePr: dataObj.baselinePr,
      realProduction: dataObj.realProduction,
      baselineProduction: dataObj.baselineProduction
    }
    // Oggetto dei valori del grafico dell'energia
    const energyGraphData = {
      expectedEnergy: JSON.parse(JSON.stringify(dataObj.expectedEnergy)),
      producedEnergy: JSON.parse(JSON.stringify(dataObj.producedEnergy)),
      exportedEnergy: JSON.parse(JSON.stringify(dataObj.exportedEnergy))
    }
    // Oggetto dei valori del grafico dell'irraggiamento
    const radiationData = {
      power: dataObj.power ? JSON.parse(JSON.stringify(dataObj.power)) : [],
      irradiation: dataObj.punctualIrradiation ? JSON.parse(JSON.stringify(dataObj.punctualIrradiation)) : dataObj.irradiation ? JSON.parse(JSON.stringify(dataObj.irradiation)) : []
    }
    // Oggetto dei valori del grafico di producibilità
    const producibilityData = {
      dailyEnergy: dataObj.producedEnergy ? JSON.parse(JSON.stringify(dataObj.producedEnergy)) : [],
      dailyCumulatedEnergy: dataObj.cumulatedEnergy ? JSON.parse(JSON.stringify(dataObj.cumulatedEnergy)) : [],
      dailyCumulatedBaseline: dataObj.cumulatedBaseline ? JSON.parse(JSON.stringify(dataObj.cumulatedBaseline)) : []
    }

    return { producibilityData, cardsData, energyDeviationData, prDeviationData, energyGraphData, radiationData }
  }

  // Funzione che aggiorna i dati del grafico di energia
  const updateEnergyGraph = async (timeFrom = new Date(), timeTo = new Date(), period) => {
    const periodOrder = {
      quarter: 0,
      live: 1,
      week: 2,
      month: 3,
      year: 4
    }
    if (periodOrder[period] > periodOrder[customPeriod]) {
      return
    }
    setIsLoading(true)
    const timeFromISOString = moment(timeFrom).toISOString()
    const timeToISOString = moment(timeTo).toISOString()
    const dataObj = await getData(timeFromISOString, timeToISOString)
    if (dataObj) {
      const { energyGraphData } = convertData(dataObj)
      // console.log('updateEnergyGraph: ', timeFrom, timeTo)
      setZoomDate(timeFromISOString)
      setZoomPeriod(period)
      setEnergyGraph(decodeEnergyGraphFromApi(energyGraphData, period))
    }
    setIsLoading(false)
  }

  // Funzione che resetta il periodo a quello della chip selezionata
  const resetPeriod = async (pagePeriod, chartPeriod) => {
    if (pagePeriod !== chartPeriod) {
      const periodDate = (selectedDate && selectedDate.toDate()) || new Date()
      const { minDate, maxDate } = getDatesFromPeriod(pagePeriod, periodDate, null, true)
      await updateEnergyGraph(minDate, maxDate, pagePeriod)
    }
  }

  // Funzione che calcola il periodo da richiedere alle api
  const buildPeriodToFetch = async (min, max, data, period) => {
    // console.log('zoomPeriod: min/max', min, max)
    // console.log('zoomPeriod: ', period)
    if (!min && !max) {
      return
    }
    // calcolo la soglia di rimodulazione dell'asse x
    const recalculateThreshold = max - min
    // controllo che gli estremi superino o meno la soglia per la quale si vuole ricalcolare la x
    if (period === 'live' && recalculateThreshold < 6) {
      const labels = data.rawLabels || []
      if (labels.length > min) {
        // console.log('zoomPeriod - zoomDate: ', zoomDate)
        // Faccio questa conversione per le ore UTC
        const startTime = Number(labels[min].split(':')[0]) - 1
        const endTime = Number(labels[max].split(':')[0]) - 1
        // La data viene convertita in local time per avere il giorno corretto durante la richiesta dei valori per il grafico
        const localZoomDate = moment(zoomDate).toISOString(true)
        // console.log('zoomPeriod - localZoomDate: ', localZoomDate)

        const dateStart = `${localZoomDate.split('T')[0]}T${startTime < 10 ? `0${startTime}` : startTime}:00`
        const dateEnd = `${localZoomDate.split('T')[0]}T${endTime < 10 ? `0${endTime}` : endTime}:00`

        await updateEnergyGraph(dateStart, dateEnd, 'quarter')
      }
      // console.log('updateEnergyGraph - quarters: ', zoomDate)
      // TODO: fetch DATA
    } else if (period === 'week' && recalculateThreshold < 2) {
      const labels = data.rawLabels || []
      if (labels.length > min) {
        // Prendo min perchè anche se il giorno da visualizzare sarebbe l'ultimo, le labels sono in base 0
        const startLabel = `20${labels[min].split('/').reverse().join('-')}`
        const dateStart = moment(startLabel).format('YYYY-MM-DD')
        const dateEnd = moment(dateStart).add(1, 'day').format('YYYY-MM-DD')
        // Ricalcolo i dati per il grafico
        await updateEnergyGraph(dateStart, dateEnd, 'live')
      }
    } else if (period === 'month' && recalculateThreshold < 2) {
      const labels = data.rawLabels || []
      if (labels.length > min) {
        // Prendo min perchè anche se il giorno da visualizzare sarebbe l'ultimo, le labels sono in base 0
        const startLabel = `20${labels[min].split('/').reverse().join('-')}`
        const dateStart = moment(startLabel).format('YYYY-MM-DD')
        const dateEnd = moment(dateStart).add(1, 'day').format('YYYY-MM-DD')
        // Ricalcolo i dati per il grafico
        await updateEnergyGraph(dateStart, dateEnd, 'live')
      }
    } else if (period === 'year' && recalculateThreshold < 2) {
      // Prendo min perchè anche se il mese da visualizzare sarebbe l'ultimo, i mesi in moment sono in base 0
      const dateStart = moment().set('month', min).set('date', 1).format('YYYY-MM-DD')
      const dateEnd = moment(dateStart).add(1, 'month').format('YYYY-MM-DD')
      // Ricalcolo i dati per il grafico
      await updateEnergyGraph(dateStart, dateEnd, 'month')
    } else if (period === 'quarter') {
      const labels = data.rawLabels || []
      // console.log('zoooom', labels.length, recalculateThreshold)
      if (labels.length === max) {
        const dateEnd = moment(zoomDate).add(1, 'day')
        await updateEnergyGraph(zoomDate, dateEnd, 'live')
      }
    } else if (period === 'live') {
      const labels = data.rawLabels || []
      if (labels.length === max) {
        const { minDate, maxDate } = getDatesFromPeriod('week', zoomDate)
        await updateEnergyGraph(minDate, maxDate, 'week')
      }
    } else if (period === 'week') {
      const labels = data.rawLabels || []
      // console.log('zoooom', labels.length, recalculateThreshold)
      if (labels.length === max) {
        const { minDate, maxDate } = getDatesFromPeriod('month', zoomDate)
        await updateEnergyGraph(minDate, maxDate, 'month')
      }
    } else if (period === 'month') {
      const labels = data.rawLabels || []
      // console.log('zoooom', labels.length, recalculateThreshold)
      if (labels.length === max) {
        const { minDate, maxDate } = getDatesFromPeriod('year', zoomDate)
        await updateEnergyGraph(minDate, maxDate, 'year')
      }
    }

    // console.log('beforeZoom - threshold: ', recalculateThreshold)
    /* console.log('beforeZoom - chartContext: ', chartContext)
    console.log('beforeZoom - xaxis: ', min, max)
    console.log('beforeZoom - currentPeriod: ', customPeriod) */
  }

  // Funzione che dice se il periodo preso in considerazione è minore o uguale ad un giorno
  const isLesserThanDay = (period) => {
    return period === 'live'
  }

  // Parametri da passare al menu di condivisione
  const params = [{ name: 'plantId', value: plantId }, { name: 'plantName', value: plantName }, { name: 'plantType', value: plantType }]

  // Funzione che calcola e ritorna la data di inizio e di fine dell'anno da visualizzare nella view annuale
  const calculateYear = (contractStartDate, prevDate = null) => {
    const contractRef = contractStartDate ?? new Date()
    // Se sto visualizzando l'anno, allora devo prendere il primo e il mese 12 della baseline
    // Devo prendere il mese corrente
    const currentMonth = prevDate ? moment(prevDate).format('MM') : moment().format('MM')
    // Devo prendere il mese della data di inizio del contratto
    const contractMonth = moment(contractRef).format('MM')
    // Variabile che mi dice se il mese corrente viene prima o dopo il mese del contratto
    const isLessThanContractMonth = Number(currentMonth) < Number(contractMonth)
    // Devo prendere l'anno corrente
    const currentYear = isLessThanContractMonth
      ? prevDate
          ? moment(prevDate).subtract(1, 'y').format('YYYY')
          : moment().subtract(1, 'y').format('YYYY')
      : prevDate
        ? moment(prevDate).format('YYYY')
        : moment().format('YYYY')
    // Prendo il primo mese del contratto stipulato
    const contractStartDateMonth = moment(contractRef).format('MMMM')
    // Prendo il primo elemento della baseline con quell'anno
    const baselineElement = baseline.find(el => el.month === `${contractStartDateMonth} ${currentYear}`)
    if (baselineElement) {
      // Trasformo l'elemento in un formato manipolabile
      const baselineStartDate = moment(baselineElement.month, 'MMMM YYYY').toISOString()
      // const baselineStartDate = moment(baselineElement.month, 'MMMM YYYY').add(1, 'h').toISOString()
      // calcolo la data maggiorata di 12 mesi a partire dalla data di inizio di baseline
      const baselineEndDate = moment(baselineStartDate).add(12, 'month').toISOString()

      return { baselineStartDate, baselineEndDate }
    }
    return { baselineStartDate: moment(contractRef).toISOString(), baselineEndDate: moment(contractRef).add(12, 'M').toISOString() }
  }

  useEffect(() => {
    // creating the contracYears array
    contractYears.current = []
    const now = new Date()
    const startDateObj = startDate ? new Date(startDate) : new Date()
    // console.log('startDateObj => ', startDateObj)
    // console.log('contractDuration => ', contractDuration)
    let i = 0
    const maxIterations = contractDuration && typeof contractDuration === 'string' ? Number(contractDuration) : 1
    while ((i < maxIterations && contractYears.current.length === 0) || (i < maxIterations && moment(contractYears.current[contractYears.current.length - 1].end).isBefore(now))) {
      const objToPush = {
        label: `Anno ${i}`,
        start: moment(startDateObj).add(12 * i, 'M').toISOString(),
        end: moment(startDateObj).add(12 * (i + 1), 'M').toISOString()
      }

      contractYears.current.push(objToPush)
      i++
    }
    alog('SECOND contractYears.current => ', contractYears.current, 'production')

    // Funzione che fa l'inizializzazione della view, settando i dati che i grafici consumeranno
    async function initialiseView (timeFrom, timeTo, customPeriod) {
      setIsLoading(true)
      const dataObj = await getData(timeFrom, timeTo)
      if (dataObj) {
        const { producibilityData, radiationData, cardsData, energyGraphData, energyDeviationData, prDeviationData } = convertData(dataObj)
        if (isLesserThanDay(customPeriod)) {
          setRadiationGraph(decodeIrradiationGraphFromApi(radiationData, customPeriod))
        } else {
          setProducibilityGraph(producibilityData)
        }
        setCardsSettings(decodeCardsFromApi(cardsData))
        setEnergyGraph(decodeEnergyGraphFromApi(energyGraphData, customPeriod))
        setEnergyDeviationGraph(energyDeviationData)

        const decodedPr = decodePrDeviationGraphFromApi(prDeviationData)
        const prPrevValue = prDeviationGraph?.pr?.value
        const kwPrevValue = prDeviationGraph?.kwh?.value

        const prNewValue = decodedPr?.pr?.value
        const kwNewValue = decodedPr?.kwh?.value

        if (Array.isArray(prDeviationGraph) || !(prPrevValue === prNewValue && kwPrevValue === kwNewValue)) {
          setPrDeviationGraph(decodedPr)
        } else {
          const newDecodedValues = {
            ...decodedPr,
            pr: {
              ...decodedPr.pr,
              value: Number(prNewValue || 0) + 0.01
            },
            kwh: {
              ...decodedPr.kwh,
              value: Number(kwNewValue || 0) + 0.01
            }
          }
          setPrDeviationGraph(newDecodedValues)
        }
      }
      setIsLoading(false)
    }
    const periodDate = (selectedDate && selectedDate.toDate()) || new Date()
    // Prendo le date minima e massima da passare alle api
    const { minDate, maxDate } = getDatesFromPeriod(customPeriod, periodDate, null, true)
    // console.log(customPeriod, zoomPeriod)
    if (!shared) {
      if (customPeriod === 'live') {
        if (zoomPeriod === 'live') {
          // Prima GET
          initialiseView(minDate, maxDate, customPeriod)
          // Attivazione del polling
          const pollingInterval = setInterval(() => {
            initialiseView(minDate, maxDate, customPeriod)
          }, 60000)
          return () => {
            clearInterval(pollingInterval)
          }
        }
      } else if (customPeriod === 'year') {
        const yearDates = selectedDate ? calculateYear(startDate, selectedDate) : calculateYear(startDate)
        console.log(yearDates)
        // Se customPeriod e zoomPeriod sono uguali, ho cliccato per la prima volta sulla chip per la visualizzazione annuale
        if (yearDates && customPeriod === zoomPeriod) {
          const { baselineStartDate, baselineEndDate } = yearDates
          // ricalcolo le date di inizio e fine del periodo
          initialiseView(baselineStartDate, baselineEndDate, customPeriod)
        }
      } else {
        if (customPeriod === zoomPeriod) {
          initialiseView(minDate, maxDate, customPeriod)
        }
      }
    } else {
      alog('production initialization', { customPeriod, minDate, maxDate }, 'PROduction')
      if (customPeriod === 'year') {
        const yearDates = calculateYear(startDate)
        if (yearDates) {
          const { baselineStartDate, baselineEndDate } = yearDates
          // ricalcolo le date di inizio e fine del periodo
          initialiseView(baselineStartDate, baselineEndDate, customPeriod)
        }
      } else {
        initialiseView(minDate, maxDate, customPeriod)
      }
    }
  }, [customPeriod, baseline, plantId, getData, selectedDate, zoomPeriod, shared])

  const isExtraSmall = useMediaQuery(theme => theme.breakpoints.down('xs'))
  // const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const isLarge = useMediaQuery(theme => theme.breakpoints.down('lg'))

  // console.log(customPeriod)

  // Dimensione custom dello schermo
  // const isCustomScreen = window.outerWidth === 3840 && window.outerHeight === 2160

  // const graphHeight = rect ? isCustomScreen ? 1500 : rect.height - 250 : '100%'
  const graphHeight = rect ? rect.height - 250 : '100%'

  // Variabile che corrisponde all'altezza della finestra
  const windowHeight = window.innerHeight

  // Variabile che determina l'altezza dei grafici a barre
  const barChartsHeight = windowHeight < 1080 ? 240 : 300

  // Variabile che determina l'altezza dei radial
  const radialChartsHeight = windowHeight < 1080 ? 260 : 300

  return (
    <div className={isLarge ? clsx(shared ? classes.root : classes.rootProduction, className, classes.smallViewport) : clsx(shared ? classes.root : classes.rootProduction, className)} {...rest}>
      <Box mt={shared ? -2 : 2}>
        <Grid width='100%' container spacing={1}>
          {shared
            ? null
            : (
              <Grid style={{ order: 1 }} item xs={12} sm={10} md={10} xl={3}>
                <PeriodSelection
                  isLoading={isLoading}
                  // yearShift={(prevDate) => calculateYear(startDate, prevDate)}
                  minLimit={startDate}
                  selectedDate={selectedDate}
                  setSelectedDate={(date) => {
                    setZoomPeriod(customPeriod)
                    setSelectedDate(date)
                  }}
                  customPeriod={customPeriod}
                  setCustomPeriod={(period) => {
                    setCustomPeriod(period)
                    setZoomPeriod(period)
                    setZoomDate(new Date().toISOString())
                  }}
                  yearsArray={contractYears.current}
                />
              </Grid>)}
          <Grid style={{ order: isLarge ? 3 : 2 }} item xs={12} md={12} xl={shared ? 12 : 8}>
            <Box width='100%'>
              <Grid container spacing={1}>
                {
                  cardsSettings.length > 0
                    ? cardsSettings.map((card, index) => (
                      <Grid key={`card-${index}`} item xs={12} md={6} lg={3}>
                        <InfoCard icon={card.icon} values={card.values} />
                      </Grid>
                      ))
                    : (
                      <>
                        <Grid item xs={12} md={6} lg={3}>
                          <Skeleton variant='rect' height={60} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Skeleton variant='rect' height={60} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Skeleton variant='rect' height={60} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Skeleton variant='rect' height={60} />
                        </Grid>
                      </>)
                }
              </Grid>
            </Box>
          </Grid>
          {shared
            ? null
            : !isExtraSmall && (
              <Grid style={{ order: isLarge ? 2 : 3 }} item xs={2} sm={2} md={2} xl={1}>
                <Box width='100%' height='100%' display='flex' alignItems='center' justifyContent='flex-end'>
                  <Box mr={1}>
                    <DownloadExcelButton
                      selectedDate={selectedDate}
                      period={customPeriod}
                      plantId={plantId}
                      plantName={plantName}
                      calculateYear={() => calculateYear(startDate, selectedDate)}
                      exportType='production'
                      description='Scarica i dati di produzione del periodo che stai visualizzando in un file Excel'
                    />
                  </Box>
                  <ShareButton userId={user ? user.uuid : null} path='production' params={params} period={customPeriod} />
                </Box>
              </Grid>
              )}
        </Grid>
      </Box>
      <Box ref={ref} width='100%' height='100%' mt={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={8} xl={9}>
            <Card className={classes.section}>
              {isLoading
                ? (
                  <Box mt={-2} ml={-2}>
                    <LinearProgress color='primary' />
                  </Box>)
                : null}
              <Typography className={isLoading ? clsx(classes.topMargin, classes.sectionTitle) : classes.sectionTitle} variant='h6'>Energia</Typography>
              <Box width='100%' display='flex' alignItems='center' justifyContent='center'>
                <EnergyGraph
                  height={graphHeight * 0.5 < barChartsHeight ? barChartsHeight : graphHeight * 0.5}
                  canZoom={!shared}
                  showToolbar={!shared}
                  isLoading={isLoading}
                  resetPeriod={() => resetPeriod(customPeriod, zoomPeriod)}
                  recalculatePeriod={(min, max, data) => buildPeriodToFetch(min, max, data, zoomPeriod)}
                  data={energyGraph}
                />
              </Box>
              <Box width='100%' display='flex' alignItems='center' justifyContent='center'>
                {isLesserThanDay(customPeriod)
                  ? (
                    <EnergyRadiationGraph
                      height={graphHeight * 0.5 < barChartsHeight ? barChartsHeight : graphHeight * 0.5}
                      data={radiationGraph}
                    />)
                  : (
                    <ReportMonthlyEnergyGraph
                      showTitle={false}
                      isDark
                      height={graphHeight * 0.5 < barChartsHeight ? barChartsHeight : graphHeight * 0.5}
                      data={producibilityGraph}
                    />
                    )}
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} xl={3}>
            <Card className={classes.section}>
              <Typography className={classes.sectionTitle} variant='h6'>Scostamento</Typography>
              <Box width='100%' display='flex' alignItems='center' justifyContent='center'>
                <EnergyDeviation width={graphHeight * 0.5 < radialChartsHeight ? radialChartsHeight : graphHeight * 0.5} height={graphHeight * 0.5 < radialChartsHeight ? radialChartsHeight : graphHeight * 0.5} data={energyDeviationGraph} />
              </Box>
              <Box mt={5} mb={3} width='100%' display='flex' alignItems='center' justifyContent='center'>
                <PrDeviation width={graphHeight * 0.5 < radialChartsHeight ? radialChartsHeight : graphHeight * 0.5} height={graphHeight * 0.5 < radialChartsHeight ? radialChartsHeight : graphHeight * 0.5} data={prDeviationGraph} />
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}
export default memo(Production)
