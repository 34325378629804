import { memo } from 'react'
const Logo = (props) => {
  let logoUrl = '/static/logo.png'
  if (props.logocolor && props.logocolor === 'white') {
    logoUrl = '/static/logo_bianco.png'
  }

  return (
    <img
      alt='Logo'
      height='auto'
      width='auto'
      src={logoUrl}
      {...props}
    />
  )
}

export default memo(Logo)
