import { memo } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  makeStyles
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {},
  redButton: theme.redButton
}))

function ConfirmationDialogRaw (props) {
  const { open, onClose, title, description, confirmText = null, cancelText = null, ...other } = props
  const classes = useStyles()

  const handleCancel = () => {
    onClose(false)
  }

  const handleOk = () => {
    onClose(true)
  }

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      // disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby='confirm-dialog'
      aria-describedby='confirm-dialog'
      {...other}
    >
      <DialogTitle disableTypography id='confirm-dialog-title'>
        <Typography variant='h4'>{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='confirm-delete-dialog-description'>
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button fullWidth onClick={handleCancel} color='primary'>
          {cancelText || 'Annulla'}
        </Button>
        <Button fullWidth onClick={handleOk} variant='contained' className={clsx(classes.redButton)}>
          {confirmText || 'Conferma'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmationDialogRaw.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
}

export default memo(ConfirmationDialogRaw)
