import { memo } from 'react'
import clsx from 'clsx'
import moment from 'moment'
import PropTypes from 'prop-types'
import Chart from 'react-apexcharts'
// import ApexCharts from 'apexcharts'
// import PerfectScrollbar from 'react-perfect-scrollbar'
// import { fixedNum, europeNum } from 'src/utils/general'
import { basicColors } from 'src/theme'
import LoadingCard from 'src/components/LoadingCard'
import {
  // Avatar,
  // colors,
  Box,
  // Button,
  // Tooltip,
  Card,
  CardHeader,
  // CardContent,
  // Typography,
  makeStyles
  // withStyles,
} from '@material-ui/core'
// import { useSnackbar } from 'notistack'

// import {
//  // Edit as EditIcon,
//  WifiOff as DisconnectedIcon,
// } from 'react-feather'

// variaible con le dimenzioni del grafico
const graphSize = {
  height: 280,
  width: 600
}

// variabile con il format per il periodo
const periodXFormat = {
  live: 'HH:mm',
  week: 'dd/MM',
  month: 'dd/MM',
  year: 'MM/yy'
}

// stile CSS generale
const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    maxHeight: '100%',
    width: '100%'
  },
  boxContainer: {
    width: '100%',
    height: '82%',
    // maxHeight: 'calc(100% - 43px)',
    paddingLeft: 12,
    paddingRight: 12,
    paddingBottom: 16,
    // display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'center',
    // justifyContent: 'center',
    zIndex: 1000
  }
}))

// componente principale
const TimeDistributionGraphView = ({
  noCard = false,
  width = null,
  height = null,
  className,
  timeDistribution,
  anomaliesCategoryStatus,
  anomaliesPeriod,
  selectedDate,
  ...rest
}) => {
  // console.log('showTable =>', showTable)
  const classes = useStyles()
  // const { enqueueSnackbar } = useSnackbar()
  const refDate = selectedDate ? moment(selectedDate) : moment()
  // variabile che specifica il livello di severity nella Y
  // const severityNum = [2, 5, 8, 11, 14]
  let isAllNull = true
  timeDistribution.forEach((category) => {
    if (category && category.data && category.data.length > 0) {
      isAllNull = false
    }
  })
  // console.log('IS ALL NULL => ', isAllNull)

  // var dataSet = JSON.parse(JSON.stringify(timeDistribution))
  let chartData = {
    series: timeDistribution[0] && !isAllNull ? JSON.parse(JSON.stringify(timeDistribution)) : [],
    chart: {
      height: graphSize.height,
      width: graphSize.width,
      type: 'bubble'
    },
    options: {
      chart: {
        id: 'bubbleDisserviceHours',
        width: '100%',
        height: 'auto',
        toolbar: {
          show: false
        },
        animations: {
          enabled: false
        },
        zoom: {
          enabled: false
        }
        // events: {
        //   legendClick: function (chartContext, seriesIndex, config) {}
        // }
      },
      colors: [],
      labels: [],
      // plotOptions: {
      //  bubble: {
      //   minBubbleRadius: 1,
      //   maxBubbleRadius: 5,
      //  },
      // },
      markers: {
        strokeColors: [],
        strokeWidth: 3
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        opacity: 0
      },
      tooltip: {
        theme: 'dark',
        // inverseOrder: true,
        // followCursor: true,
        intersect: true,
        // shared: true,
        onDatasetHover: {
          highlightDataSeries: false
        },
        marker: {
          show: true
        },
        x: {
          show: true,
          // format: 'dd/MM/yyyy HH:mm',
          formatter: function (value) {
            return value ? moment(value).format('DD/MM/yyyy HH:mm') : value
          }
        },
        // y: {
        //  title: {
        //   formatter: (seriesName) => '',
        //  },
        // },
        z: {
          title: 'Sv: '
        },
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          // console.log('series => ', series)
          // console.log('seriesIndex => ', seriesIndex)
          // console.log('dataPointIndex => ', dataPointIndex)
          // console.log('w => ', w)
          // mi ricerco le serie che matchano il mio punto
          const thisDate = w.config.series[seriesIndex].data[dataPointIndex].x
          const thisSev = w.config.series[seriesIndex].data[dataPointIndex].y
          const thisPoints = []
          // carico il titolo
          thisPoints.push(
            '<div class="arrow_box" style="padding-bottom: 4px;">' +
            `<p style="font-size: 13px; color: ${basicColors.primary};">Severity: ${thisSev}<p/>` +
            '</div>'
          )
          // mi trovo tutte le serie che hanno almeno 1 dato che matcha questa severity e questo periodo
          const dataForPoints = {}
          // mi scorro le serie
          w.config.series.forEach((serie, sIndex) => {
            // mi scorro i dati della serie
            serie.data.forEach((data, dIndex) => {
              if (data.x === thisDate && data.y === thisSev && data.z !== 0 && data.z !== null) {
                // controllo se ho già salvato questo numero di anonalie
                if (dataForPoints[data.z]) {
                  dataForPoints[data.z].push({
                    color: serie.color,
                    name: serie.name
                  })
                } else {
                  dataForPoints[data.z] = [
                    {
                      color: serie.color,
                      name: serie.name
                    }
                  ]
                }
                // thisPoints.push(
                //  `<div class="arrow_box" style="padding-bottom: 4px; padding-top: 4px;">` +
                //   `<p style="font-size: 13px; color: ${serie.color};">${serie.name}<p/>` +
                //   // `<span style="font-weight: 500; font-size: 11px;">Severity: <b>${data.y}</b></span>` +
                //   // `<br />` +
                //   `<span style="font-weight: 500; font-size: 12px;">N. anomalie: <b>${data.z}</b></span>` +
                //   `</div>`
                // )
              }
            })
          })
          // mi costruisco l'array dei tooltip
          Object.keys(dataForPoints).forEach((anomaliesNum) => {
            // inseriesco il numero di anomalie
            thisPoints.push(
              '<div class="arrow_box" style="padding-bottom: 4px; padding-top: 4px;">' +
              `<p style="font-weight: 600; font-size: 12px; color: ${basicColors.primary}">${anomaliesNum} ${anomaliesNum > 1 ? 'anomalie' : 'anomalia'
              } per:<p/>`
            )
            dataForPoints[anomaliesNum].forEach((serie) => {
              thisPoints.push(`<span style="font-size: 12px; color: ${serie.color};">${serie.name}</span><br />`)
            })
            // chiudo il div
            thisPoints.push('</div>')
          })
          // chiudo con un po' di padding
          thisPoints.push('<div style="padding-top: 4px;"></div>')
          // console.log('thisPoints => ', thisPoints)
          return thisPoints.join('')
        }
      },
      xaxis: {
        type: 'datetime',
        // tickPlacement: 'between',
        // tickAmount:
        //  anomaliesPeriod === 'live'
        //   ? 24
        //   : anomaliesPeriod === 'week'
        //   ? 7
        //   : anomaliesPeriod === 'month'
        //   ? 31
        //   : anomaliesPeriod === 'year'
        //   ? 12
        //   : 7,
        labels: {
          show: true,
          datetimeUTC: false,
          showDuplicates: false,
          style: {
            colors: basicColors.whiteText
          },
          // formatter: function(val) {
          //  return val ? moment(val).format(periodXFormat[anomaliesPeriod]) : val
          // },
          format: periodXFormat[anomaliesPeriod]
        },
        crosshairs: {
          show: true,
          width: 1,
          position: 'back',
          opacity: 0.9,
          stroke: {
            color: '#b6b6b6',
            width: 1,
            dashArray: 0
          },
          fill: {
            type: 'solid',
            color: '#B1B9C4',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5
            }
          }
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: 12
            // fontFamily: 0,
          }
        }
      },
      yaxis: {
        min: 0,
        max: 6,
        tickAmount: 6,
        labels: {
          show: true,
          maxWidth: 160,
          offsetX: 2,
          offsetY: 1,
          style: {
            colors: basicColors.whiteText
          },
          formatter: (val) => {
            // let count = -1
            // let last
            // let value = ''
            // for (var s = val; s >= 2; s -= 3) {
            //  //console.log('s vale: ', s)
            //  count++
            //  last = s
            // }
            // if (last === 2) {
            //  // console.log('count vale: ', count)
            //  value = 'Sv.' + (count + 1)
            // }
            let value = 'Sv.' + val
            if (val === 0 || val === 6) {
              value = ''
            }
            return value
          }
        }
      },
      grid: {
        show: true,
        borderColor: 'rgba(0,0,0,0.35)',
        // row: {
        //  colors: [],
        //  opacity: 0.3,
        // },
        xaxis: {
          lines: {
            show: false
          }
        },
        yaxis: {
          lines: {
            show: true
          }
        }
      },
      legend: {
        showForNullSeries: false,
        showForZeroSeries: false,
        offsetY: 20,
        fontSize: '11px',
        position: 'top',
        horizontalAlign: 'center',
        // height: 40,
        labels: {
          colors: [basicColors.whiteText]
        },
        // markers: {
        //  onClick: function(chart, seriesIndex, opts) {
        //   console.log('chart => ', chart)
        //   console.log('chaseriesIndexrt => ', seriesIndex)
        //   console.log('opts => ', opts)
        //  },
        // },
        onItemClick: {
          toggleDataSeries: false
        }
      }
    }
  }

  const normalizeData = function (newChartData) {
    // newChartData.series = series
    let maxDate, minDate
    // let totalDate = []
    // let yAxisMax = []
    // mi scorro la serie
    newChartData.series.forEach((serie, index) => {
      const thisCategory = anomaliesCategoryStatus.find((category) => {
        return category.name === serie.category
      })
      if (thisCategory) {
        const thisSubCategory = thisCategory.subCategory.find((subCategory) => {
          return subCategory.name === serie.name
        })
        // controllo se ho trovato la categoria
        if (thisSubCategory) {
          // metto il colore specifico
          newChartData.options.colors.push(thisSubCategory.color)
          // newChartData.options.labels.push(thisSubCategory.label)
          newChartData.series[index].color = thisSubCategory.color
          newChartData.series[index].name = thisSubCategory.label
          newChartData.options.labels[index] = thisSubCategory.label
          newChartData.options.markers.strokeColors.push(thisSubCategory.color)
        } else {
          // metto il colore di default
          newChartData.options.colors.push(basicColors.primary)
          newChartData.options.markers.strokeColors.push(basicColors.primary)
        }
      } else {
        // metto il colore di default
        newChartData.options.colors.push(basicColors.primary)
        newChartData.options.markers.strokeColors.push(basicColors.primary)
      }
      // mi salvo i valori totali che mi interessano
      // serie.data.forEach((data, dataIndex) => {
      //  data.y = severityNum[data.y - 1]
      //  // totalDate.push(data.x)
      //  yAxisMax.push(data.y)
      // })
    })
    // mi prendo min e max del tempo
    // min e max saranno la mezzanotte di oggi e le 23:59 di oggi
    minDate = moment(refDate).set({ hours: 0, minute: 0, second: 0, millisecond: 1 })
    maxDate = moment(refDate).set({
      hours: 23,
      minute: 59,
      second: 59,
      millisecond: 900
    })
    if (anomaliesPeriod === 'live') {
      // aggiungo i periodi a min e max
      minDate = moment(minDate).subtract(1, 'h')
      maxDate = moment(maxDate)
        // .set({ hours: 0, minute: 0, second: 0, millisecond: 1 })
        .add(1, 'h')
    } else if (anomaliesPeriod === 'week') {
      // il min sarà il lunedì di questa settimana, il max la domenica
      //  su momento 0 è domenica, 6 è sabato, 7 è la prossima domenica
      minDate = moment(minDate).startOf('week')
      minDate = moment(minDate).subtract(1, 'd')
      maxDate = moment(maxDate).endOf('week')
      maxDate = moment(maxDate)
        .set({ hours: 0, minute: 0, second: 0, millisecond: 1 })
        .add(1, 'd')
    } else if (anomaliesPeriod === 'month') {
      // il min sarà l'1 del mese e il max sarà il 30/31 del mese
      minDate = moment(minDate).startOf('month')
      minDate = moment(minDate).subtract(1, 'd')
      maxDate = moment(maxDate).endOf('month')
      maxDate = moment(maxDate)
        .set({ hours: 0, minute: 0, second: 0, millisecond: 1 })
        .add(1, 'd')
    } else if (anomaliesPeriod === 'year') {
      // sarà l'anno corrente
      minDate = moment(minDate).startOf('year')
      minDate = moment(minDate).subtract(1, 'M')
      maxDate = moment(maxDate).endOf('year')
      maxDate = moment(maxDate)
        .set({ hours: 0, minute: 0, second: 0, millisecond: 1 })
        .add(1, 'M')
    }
    // console.log('minDate => ', minDate)
    // console.log('maxDate => ', maxDate)
    newChartData.options.xaxis.min = moment(minDate).unix() * 1000
    // newChartData.options.xaxis['min'] = moment(minDate).toISOString()
    newChartData.options.xaxis.max = moment(maxDate).unix() * 1000
    // newChartData.options.xaxis['max'] = moment(maxDate).toISOString()
    // preparo la colorazione per la Y (coloro le fasce delle severity)
    // yAxisMax = yAxisMax.sort(function(a, b) {
    // return a - b
    // })
    // var colorSeverityCounter = 0
    // console.log('yAxisMax is: ', yAxisMax[yAxisMax.length - 1])
    // coloro le zone delle severity diversamente
    // for (var l = 0; l < yAxisMax[yAxisMax.length - 1] + 5; l++) {
    //  if (colorSeverityCounter === 0) {
    //  newChartData.options.grid.row.colors.push('rgba(0,0,0,0)')
    // }
    // if (colorSeverityCounter === 1 || colorSeverityCounter === 2) {
    //  newChartData.options.grid.row.colors.push('rgba(255,255,255,0.2)')
    // }
    // if (colorSeverityCounter < 3) {
    //  colorSeverityCounter++
    // } else {
    //  colorSeverityCounter = 0
    // }
    // }
    // newChartData.options.grid.row.colors.push('rgba(0,0,0,0)')

    // newChartData.options.yaxis['max'] = yAxisMax[yAxisMax.length - 1] + 2
    // newChartData.options.yaxis['tickAmount'] = newChartData.options.yaxis.max
    // console.log('normalize options is: ', options)
    return newChartData
  }
  chartData = normalizeData(chartData)
  // console.log('time chartData => ', chartData)

  return noCard
    ? timeDistribution[0] !== '-'
        ? (
          <Chart
            id={chartData.options.chart.id}
            options={chartData.options}
            series={chartData.series}
            type={chartData.chart.type}
            width={width || '100%'}
            height={height || '100%'}
          />
          )
        : (
          <LoadingCard />
          )
    : (
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader
          title='Distribuzione nel tempo'
          // subheader='September 14, 2016'
          style={{ paddingBottom: 0 }}
        />
        <Box className={classes.boxContainer}>
          {timeDistribution[0] !== '-'
            ? (
              <Chart
                id={chartData.options.chart.id}
                options={chartData.options}
                series={chartData.series}
                type={chartData.chart.type}
                width='100%'
                height='100%'
              />
              )
            : (
              <LoadingCard />
              )}
        </Box>
      </Card>
      )
}

TimeDistributionGraphView.propTypes = {
  className: PropTypes.string,
  anomaliesPeriod: PropTypes.string,
  selectedDate: PropTypes.object,
  timeDistribution: PropTypes.array,
  anomaliesCategoryStatus: PropTypes.array
  // setRefreshUsers: PropTypes.func
}

TimeDistributionGraphView.defaultProps = {
  anomaliesPeriod: 'live',
  selectedDate: null,
  timeDistribution: [],
  anomaliesCategoryStatus: []
}

export default memo(TimeDistributionGraphView)
