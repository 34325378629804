import { memo } from 'react'
import clsx from 'clsx'
import { Box, makeStyles, useMediaQuery } from '@material-ui/core'
import Chart from 'react-apexcharts'
import LoadingCard from 'src/components/LoadingCard'
import { europeNum } from 'src/utils/general'
// import { alog } from 'src/utils/apioLog'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    position: 'relative'
  },
  dataLoading: {
    position: 'absolute',
    zIndex: 100,
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

function EnergyGraph ({ data = {}, canZoom = true, width = null, height = null, showToolbar = true, isLoading, resetPeriod = null, recalculatePeriod = null, className, ...rest }) {
  const classes = useStyles()
  const isExtraSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  const dataNormalize = function (dataSet, options) {
    const cumulated = {
      0: {
        data: [0],
        type: 'line',
        color: '#31eeef',
        name: 'E. Attesa Cumulata'
      },
      1: {
        data: [0],
        type: 'line',
        color: '#3ef075',
        name: 'E. Prodotta Cumulata'
      },
      2: {
        data: [0],
        type: 'line',
        color: '#eaf360',
        name: 'E. Immessa Cumulata'
      }
    }

    options.yaxis.push({
      show: !isExtraSmall,
      seriesName: Object.keys(dataSet).length > 0 ? dataSet.series[0].name : '',
      // max: Object.keys(dataSet).length > 0 ? dataSet.axis.yMax : 0,
      labels: {
        minWidth: '100%',
        style: {
          colors: Object.keys(dataSet).length > 0 ? dataSet.axis.color : ''
        },
        formatter: (value) => { return Math.floor(Number(value)) + ' kWh' }
      }
    })

    if (Object.keys(dataSet).length > 0) {
      for (let s = 0; s < dataSet.series.length; s++) {
        options.colors.push(dataSet.series[s].color)
        if (typeof cumulated[String(s)] !== 'undefined') {
          // Numero di elementi massimi presenti in data (elementi di energia Attesa)
          const totalLength = dataSet.series[s].data.length
          // Numero di elementi non nulli presenti nei valori di energia prodotta
          const productionEnergyLength = dataSet.series.find(el => el.name === 'E. Prodotta') ? dataSet.series.find(el => el.name === 'E. Prodotta').data.filter(el => el !== null).length : 0
          for (let n = 0; n < totalLength; n++) {
            if (n !== 0) {
              if (n < productionEnergyLength) {
                cumulated[String(s)].data[n] = Number(cumulated[String(s)].data[n - 1]) + Number(dataSet.series[s].data[n])
              } else {
                cumulated[String(s)].data[n] = null
              }
            } else {
              cumulated[String(s)].data[n] = Number(dataSet.series[s].data[n])
            }

            const fixedNumber = cumulated[String(s)].data[n] !== null ? Number(cumulated[String(s)].data[n]).toFixed(1) : null

            cumulated[String(s)].data[n] = fixedNumber !== null ? Number(fixedNumber) : null
          }
        }
        if (s !== 0) {
          options.yaxis.push({
            seriesName: dataSet.series[0].name,
            show: false,
            labels: {
              style: {
                colors: dataSet.axis.color
              },
              formatter: (value) => {
                return value ? Math.floor(Number(value)) + ' kWh' : '-'
              }
            }
          })
        }
        options.series.push({
          name: dataSet.series[s].name,
          type: (dataSet.series[s] && dataSet.series[s].type) || 'column',
          data: dataSet.series[s].data

        })
      }
    }

    let cumulatedCount = 0
    // calcolo il massimo valore tra tutte le grandezze
    let cumulatedMax = 0
    for (const s in cumulated) {
      const currentData = cumulated[s].data.filter(el => el !== null)
      const currentMax = Math.max(...currentData)
      // Se il massimo attuale è minore di quello trovato lo aggiorno
      if (cumulatedMax < currentMax) {
        cumulatedMax = currentMax
      }
    }
    // console.log(cumulated)
    // console.log(cumulatedMax)
    for (const s in cumulated) {
      options.series.push({
        name: cumulated[s].name,
        type: (cumulated[s] && cumulated[s].type) || 'line',
        data: cumulated[s].data
      })
      if (cumulatedCount === 0) {
        cumulatedCount++
        options.yaxis.push({
          seriesName: cumulated[0].name,
          opposite: true,
          show: !isExtraSmall,
          min: 0,
          max: cumulatedMax * 1.1,
          labels: {
            style: {
              colors: Object.keys(dataSet).length > 0 ? dataSet.axis.color : ''
            },
            formatter: (value) => {
              return Math.floor(Number(value)) + ' kWh'
            }
          }
        })
      } else {
        options.yaxis.push({
          seriesName: cumulated[0].name,
          opposite: true,
          show: false,
          min: 0,
          max: cumulatedMax * 1.1,
          labels: {
            style: {
              colors: Object.keys(dataSet).length > 0 ? dataSet.axis.color : ''
            },
            formatter: (value) => {
              // console.log('fornmatter cumulated > 0 Valuse is: ', value)
              return Math.floor(Number(value)) + ' kWh'
            }
          }
        })
      }

      options.colors.push(cumulated[s].color)
    }

    // alog('options: ', options, 'energygraph')
    return options
  }

  const options = {
    chart: {
      width: width || '100%',
      // id: 'energyGraph',
      // group: 'energy',
      height: height || '300px',
      foreColor: '#fff',
      type: 'line',
      animations: {
        enabled: true
      },
      toolbar: {
        show: showToolbar,
        offsetX: -20,
        offsetY: -20,
        tools: {
          zoom: canZoom,
          zoomin: canZoom,
          zoomout: canZoom,
          reset: canZoom,
          download: false,
          selection: false,
          pan: false
        }
      },
      events: {
        zoomed: canZoom
          ? async function (chartContext, { xaxis }) {
              const { min, max } = xaxis
              await recalculatePeriod(min, max, data)
            }
          : undefined,
        beforeResetZoom: canZoom
          ? async function (chartContext, { xaxis }) {
              await resetPeriod()
            }
          : undefined
      }
    },
    stroke: {
      curve: 'smooth'
    },
    labels: Object.keys(data).length > 0 ? data.labels : [],
    colors: [],
    series: [],
    tooltip: {
      shared: true,
      theme: 'dark',
      x: {
        show: false
      },
      y: {
        show: false,
        formatter: function (value) {
          return value !== undefined && value !== null ? `${europeNum(value)} kWh` : '-'
        }
      }
    },
    yaxis: [],
    xaxis: {
      type: 'category',
      categories: [],
      labels: {
        show: !isExtraSmall
      }
    },
    legend: {
      labels: {
        colors: Object.keys(data).length > 0 ? data.axis.color : [],
        useSeriesColors: false
      }
    }
  }

  const normalizedOptions = Object.keys(data).length > 0 ? dataNormalize(data, options) : options

  // alog('series - energyChart: ', normalizedOptions, 'energygraph')
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {
        Object.keys(data).length > 0 && isLoading
          ? (<Box width='100%' height='100%' className={classes.dataLoading} />)
          : null
      }
      {Object.keys(data).length > 0
        ? (
          <Chart
            options={normalizedOptions}
            series={normalizedOptions.series}
            type={(normalizedOptions.chart && normalizedOptions.chart.type) || 'line'}
            height={normalizedOptions.chart.height}
            width={normalizedOptions.chart.width}
          />
          )
        : (
          <LoadingCard />
          )}
    </div>
  )
}
export default memo(EnergyGraph)
