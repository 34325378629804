import moment from 'moment'
import { europeNum } from 'src/utils/general'
import { CornerUpRight as CornerUpRightIcon, TrendingUp as TrendingUpIcon, Grid as GridIcon, Sliders as SlidersIcon, Activity as ActivityIcon } from 'react-feather'

// Funzione che presi in ingresso il typo di periodo e la data di inizio ritornano data di inizio e data di fine
export const getDatesFromPeriod = (period, now, formatString = null) => {
  let maxDate, minDate
  if (period === 'live') {
    // min e max saranno la mezzanotte di oggi e le 23:59 di oggi
    minDate = moment(now).set({ hours: 0, minute: 0, second: 0, millisecond: 1 })
    maxDate = moment(now).set({ hours: 23, minute: 59, second: 59, millisecond: 900 })
  } else if (period === 'week') {
    // il min sarà il lunedì di questa settimana, il max la domenica
    //  su momento 0 è domenica, 6 è sabato, 7 è la prossima domenica
    minDate = moment(now).startOf('week')
    maxDate = moment(now).endOf('week')
  } else if (period === 'month') {
    // il min sarà l'1 del mese e il max sarà il 30/31 del mese
    // (secondo la doc moment gestisce da solo che se setti 31 ed ha 30 giorni imposta 30 e non 31)
    minDate = moment(now).startOf('month')
    maxDate = moment(now).endOf('month')
  } else if (period === 'year') {
    // sarà l'anno corrente
    minDate = moment(now).set({ hours: 0, minute: 0, second: 0, millisecond: 1 })
    minDate = moment(minDate)
      .month(0)
      .date(1)
    maxDate = moment(now).set({
      hours: 23,
      minute: 59,
      second: 59,
      millisecond: 900
    })
    maxDate = moment(maxDate)
      .month(11)
      .date(31)
  }

  return formatString
    ? { minDate: moment(minDate).format(formatString), maxDate: moment(maxDate).format(formatString) }
    : { minDate: moment(minDate).toISOString(), maxDate: moment(maxDate).toISOString() }
}

// Funzione che prende in ingresso i dati ricevuti dall'api e li formatta per le card
export const decodeCardsFromApi = (dataObj) => {
  // Oggetto che contiene la configurazione delle cards
  const labelIconConfig = {
    totalPeakPower: {
      icon: <ActivityIcon />,
      label: 'Potenza di picco'
    },
    totalPlants: {
      icon: <GridIcon />,
      label: 'Numero totale impianti'
    },
    totalExpectedEnergy: {
      icon: <CornerUpRightIcon />,
      label: 'Energia Attesa'
    },
    totalProducedEnergy: {
      icon: <TrendingUpIcon />,
      label: 'Energia Prodotta'
    },
    totalExportedEnergy: {
      icon: <SlidersIcon />,
      label: 'Energia Immessa'
    }
  }

  return Object.keys(dataObj).map((key) => ({
    icon: (labelIconConfig[key] && labelIconConfig[key].icon) || null,
    values: labelIconConfig[key] && labelIconConfig[key].label && Array.isArray(labelIconConfig[key].label)
      ? labelIconConfig[key].label.map((label, index) => ({
          label,
          value: dataObj[key][index] !== '-'
            ? dataObj[key][index] >= 100000
                ? `${europeNum(dataObj[key][index] / 1000)} Mwh`
                : `${europeNum(dataObj[key][index])} kWh`
            : '-'
        }))
      : [
          {
            label: (labelIconConfig[key] && labelIconConfig[key].label) || '',
            value:
            key === 'totalPlants'
              ? europeNum(Number(dataObj[key]).toFixed(0))
              : dataObj[key] !== null && dataObj[key] !== undefined && dataObj[key] !== '-'
                ? dataObj[key] >= 100000
                    ? `${europeNum(dataObj[key] / 1000)} Mwh`
                    : `${europeNum(dataObj[key])} kWh`
                : '-'
          }
        ]
  }))
}

// funzione che prende in ingresso i dati ricevuti dall'api e li formatta per il grafico di scostamento della produzione/pr
export const decodePrDeviationGraphFromApi = (dataObj) => {
  const { realPr, realProduction, baselinePr, baselineProduction } = dataObj
  const prValue = realPr && baselinePr ? Math.floor((Number(realPr) / Number(baselinePr)) * 100) : 0
  const productionValue =
    realProduction && baselineProduction ? Math.floor((Number(realProduction) / Number(baselineProduction)) * 100) : 0

  return {
    kwh: {
      value: productionValue,
      realValue: realProduction,
      name: 'Produz. reale/attesa'
    },
    pr: {
      value: prValue,
      realValue: realPr,
      name: 'PR reale/atteso'
    }
  }
}

// funzione che prende in ingresso i dati ricevuti dall'api e li formatta per il grafico dell'energia
export const decodeEnergyGraphFromApi = (dataObj, customPeriod) => {
  const periodXFormat = {
    live: 'HH:mm',
    quarter: 'HH:mm',
    week: 'DD/MM/YY',
    month: 'DD/MM/YY',
    year: 'MM/YY'
  }
  const graphConfig = {
    expectedEnergy: {
      type: 'column',
      color: '#8bb9dd',
      name: 'E. Attesa'
    },
    exportedEnergy: {
      type: 'column',
      color: '#fce903',
      name: 'E. Immessa'
    },
    producedEnergy: {
      type: 'column',
      color: '#63b521',
      name: 'E. Prodotta'
    }
  }
  // Prendo le labels (le date) dall'array dell'energia attesa che comprende tutte quelle del periodo selezionato
  const labels = dataObj.expectedEnergy.map((el) => moment(el.label).format(periodXFormat[customPeriod]))
  const maxLength = dataObj.producedEnergy.length
  // lunghezza massima dell'array delle energie
  // console.log('maxLength: ', maxLength)
  const series = Object.keys(dataObj).map((key) => {
    let data = (dataObj[key] && dataObj[key].map((el) => el.value)) || []
    // Se sto manipolando l'energia attesa, annullo tutti i valori che vanno oltre l'ultimo valore utile (ora/giorno/mese) a seconda della view
    // Faccio questa operazione assumendo che il valore massimo dei dati che voglio conservare, siano in numero uguale a quelli dell'energia prodotta
    if (key === 'expectedEnergy') {
      const normalizedData = data.map((el, index) => (index < maxLength ? el : null)).filter((el) => el !== null)
      // console.log('normalizedData: ', normalizedData)
      // console.log('normalizedData - data: ', data)
      data = normalizedData
    }
    while (data.length < labels.length) {
      data.push(null)
    }
    return {
      data,
      ...graphConfig[key]
    }
  })
  // oggetto formattato da ritornare per visualizzare il grafico
  const energyGraph = {
    labels,
    axis: {
      color: '#fff'
      // yMax: 800
    },
    series
  }

  return energyGraph
}

// funzione che prende in ingresso i dati ricevuti dall'api e li formatta per il grafico della performance
export const decodePerformanceGraphFromApi = (dataObj) => {
  const labelsObj = {
    pr: ['PR'],
    expectedEnergy: ['Energia', 'Attesa'],
    outOfService: ['Fuori', 'Servizio'],
    energyAnomalies: ['Anomalia', 'Energia'],
    monitoringAnomalies: ['Anomalia', 'Monitoraggio'],
    maintenances: ['Interventi', 'in Campo']
  }

  const labels = Object.keys(dataObj.group1.data).map(key => labelsObj[key] ? labelsObj[key] : null).filter(el => el !== null)
  // Funzione che preso in ingresso l'oggetto dei dati restituisce il valore massimo dei dati da assegnare come massima y al grafico
  const getYmax = (data) => {
    const dataArray = Object.keys(data).map(key => {
      return data[key] ? Object.values(data[key].data).length > 2 ? Object.values(data[key].data).slice(2) : [] : []
    })

    const allData = dataArray.flat()

    return Math.max(...allData)
  }

  const performanceGraph = {
    labels,
    axis: {
      color: '#fff',
      yMax: getYmax(dataObj) || 1
    }
  }

  Object.keys(dataObj).forEach(key => {
    const data = dataObj[key] ? Object.values(dataObj[key].data) : []
    const name = dataObj[key] ? dataObj[key].name : ''

    performanceGraph[key] = {
      data,
      name
    }
  })

  // console.log('getYmax: ', performanceGraph)
  return performanceGraph
}
