import { memo } from 'react'
import clsx from 'clsx'
import moment from 'moment'
import PropTypes from 'prop-types'
// import PerfectScrollbar from 'react-perfect-scrollbar'
import { basicColors } from 'src/theme'
// import { europeNum } from 'src/utils/general'
// import LoadingCard from 'src/components/LoadingCard'
import {
  // Box,
  Grid,
  Card,
  CardContent,
  // SvgIcon,
  // Tooltip,
  // Typography,
  makeStyles,
  Typography
  // withStyles,
} from '@material-ui/core'
// import { useSnackbar } from 'notistack'

import {
  // AlertOctagon as TotalIcon,
  Clock as HoursIcon,
  Clipboard as OpenIcon,
  Archive as ClosedIcon
  // Activity as EnergyIcon,
} from 'react-feather'

// stile CSS generale
const useStyles = makeStyles((theme) => ({
  root: {
    // height: '100%',
    // width: '100%',
  },
  cardContent: {
    padding: '8px 16px !important'
  },
  cardTitle: {
    textTransform: 'uppercase',
    color: basicColors.blueGrayLight
    // fontSize: '0.67rem',
    // fontWeight: '500'
  },
  cardIconContainer: {
    backgroundColor: basicColors.background,
    borderRadius: '50%',
    padding: 8,
    height: 'Calc(24px + 16px)'
  }
}))

// componente principale
const AnomaliesPeriodBar = ({ className, anomaliesGeneralStatus, ...rest }) => {
  // console.log('anomaliesPeriod =>', anomaliesPeriod)
  const classes = useStyles()
  // const { enqueueSnackbar } = useSnackbar()
  // const dispatch = useDispatch()
  const thisHours = moment.duration(anomaliesGeneralStatus.disserviceHours).asHours()

  return (
    <Grid
      container
      spacing={1}
      alignItems='center'
      justifyContent='space-between'
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Grid item xs={12} sm>
        <Card>
          <CardContent className={classes.cardContent}>
            <Grid container alignItems='center'>
              <Grid item xs>
                <Typography component='div' variant='caption' className={classes.cardTitle} noWrap>
                  A. Aperte
                </Typography>
                <Typography component='div' variant='h3'>{anomaliesGeneralStatus.openAnomalies}</Typography>
              </Grid>
              <Grid item className={classes.cardIconContainer}>
                <OpenIcon fontSize='large' />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm>
        <Card>
          <CardContent className={classes.cardContent}>
            <Grid container alignItems='center'>
              <Grid item xs>
                <Typography component='div' variant='caption' className={classes.cardTitle} noWrap>
                  A. Chiuse
                </Typography>
                <Typography component='div' variant='h3'>{anomaliesGeneralStatus.closedAnomalies}</Typography>
              </Grid>
              <Grid item className={classes.cardIconContainer}>
                <ClosedIcon />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm>
        <Card>
          <CardContent className={classes.cardContent}>
            <Grid container alignItems='center'>
              <Grid item xs>
                <Typography component='div' variant='caption' className={classes.cardTitle} noWrap>
                  Ore disservizio
                </Typography>
                <Typography component='div' variant='h3'>{Number(thisHours).toFixed(0)}</Typography>
              </Grid>
              <Grid item className={classes.cardIconContainer}>
                <HoursIcon />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      {/* <Grid item xs={12} md>
        <Card>
          <CardContent className={classes.cardContent}>
            <Grid container alignItems='center'>
              <Grid item xs>
                <Typography variant='caption' className={classes.cardTitle}>
                  Anomalie Aperte
                </Typography>
                <Typography variant='h4'>{anomaliesGeneralStatus.openAnomalies}</Typography>
              </Grid>
              <Grid item className={classes.cardIconContainer}>
                <OpenIcon />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid> */}
      {/* <Grid item xs={12} sm>
        <Card>
          <CardContent className={classes.cardContent}>
            <Grid container alignItems='center'>
              <Grid item xs>
                <Typography variant='caption' className={classes.cardTitle}>
                  Energia Persa
                </Typography>
                <Typography variant='h4'>{europeNum(anomaliesGeneralStatus.wastedEnergy)} kWh</Typography>
              </Grid>
              <Grid item className={classes.cardIconContainer}>
                <EnergyIcon />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid> */}
    </Grid>
  )
}

AnomaliesPeriodBar.propTypes = {
  className: PropTypes.string,
  anomaliesGeneralStatus: PropTypes.object
}

AnomaliesPeriodBar.defaultProps = {
  anomaliesGeneralStatus: {
    total: '-',
    disserviceHours: '-',
    openAnomalies: '-',
    closedAnomalies: '-',
    wastedEnergy: '-'
  }
}

export default memo(AnomaliesPeriodBar)
