import ls from 'local-storage'

// Funzione che si occupa di creare l'header di autorizzazione e popolarlo con il token presente nel localStorage
export default function createHeaders (params = null, overrideAuthToken = null, disableAuth = null, customHeaders = [], responseType = null) {
  const token = ls.get('accessToken')

  const headers = {
    headers: {}
  }

  if ((overrideAuthToken || token) && !disableAuth) {
    headers.headers.authorization = overrideAuthToken || `Bearer ${token}`
  }

  if (customHeaders.length > 0) {
    customHeaders.forEach(customHeader => {
      const { key, value } = customHeader
      if (key && value) {
        headers.headers[key] = value
      }
    })
  }

  if (params) {
    headers.params = params
  }

  if (responseType) {
    headers.responseType = responseType
  }

  return headers
}
