import moment from 'moment'
import { libraryDevices } from 'src/components/Planimetry/libraryDevices'

/*
 * Funzione che prende in ingresso l'oggetto di risposta del backend e restituisce un oggetto
 * contenente gli oggetti nel formato utile al frontend per consentire le interazioni con l'interfaccia
 *
 * Input: {
 *    powerLines: [],
 *    plant: {},
 *    devices: [],
 *    dataloggers: []
 *  }
 *
 * Output: {
 *    plant: {},
 *    powerLines: []
 *  }
 */
export const decodeFromApi = (apiResponse) => {
  // Singoli elementi che serviranno per assemblare l'oggetto ritornato per il frontend
  const { powerLines, plant, devices, dataloggers } = apiResponse || {}
  // Estrapolo le informazioni dall'oggetto 'plant' per preparare i dati per lo store redux
  const { name, uuid, metadata } = plant || {}
  const { planimetry, baseline, module } = metadata

  // Dalla baseline devo ricostruire gli oggetti di baselineConfig e baseline
  const baselineConfig = baseline.map((el, index) => ({ label: el.label || `M${index + 1}`, month: el.month, startDate: el.startDate }))
  const newBaseline = baseline.map((el, index) => ({ label: el.label || `M${index + 1}`, month: el.month, consumption: el.consumption, lightHours: el.lightHours }))

  // console.log('adaptedPlant -baseline : ', newBaseline)

  // Aggiungo ai dataloggers ricevuti l'informazione dei devices associati ad essi
  const addedDataloggers = dataloggers
    ? dataloggers.map((datalogger) => {
      // Prendo tutti i devices con nodeId uguale all'uuid del datalogger
        const dataloggerDevices = devices.filter((device) => device.nodeId === datalogger.uuid)
        // console.log('decodeFromApi - dataloggerDevices: ', dataloggerDevices)
        return {
          ...datalogger,
          includes: {
            devices: dataloggerDevices
          }
        }
      })
    : []

  // Ridispongo le powerlines in modo da essere lette dall'interfaccia
  const newPowerLines = powerLines ? powerLines.map(el => ({ ...el, parent: el.parent.name })) : []

  // Ricostruisco la config della planimetry basandomi sui dati ricevuti dalle risorse primarie
  const newConfig =
    planimetry && planimetry.config && planimetry.config !== undefined
      ? planimetry.config.map((configEl) => {
          let newConfigEl = {}
          const { id, uuid, canvasLibraryType, data } = configEl
          // se sto ciclando un elemento di config di un generatore
          const newDevices = devices
          // filtro i devices prendendo solo quelli con uuid uguale all'uuid della config
            .filter((device) => device.uuid === uuid)
            .map((device) => {
            // prendo il modello del device
              const deviceModel = device.deviceType.model
              // popolo l'oggetto properties per ogni device partendo dalle properties del device
              const properties = {}
              const libraryDevice = libraryDevices.find((libEl) => libEl.models.includes(deviceModel))
              if (libraryDevice) {
                const deviceKeys = Object.keys(libraryDevice)
                deviceKeys
                  .filter((key) => key !== 'models')
                  .forEach((key) => {
                    properties[key] = {
                      label: libraryDevice[key],
                      value: 0
                    }
                  })
              }

              const deviceEl = {
                type: device.deviceType.category.toLowerCase(),
                model: device.deviceType.model,
                deviceId: device.uuid,
                // sto assegnando le properties dai devices, ma credo che per il momento dovrò prenderle dal file di libreria
                properties,
                // properties: device.deviceType.properties,
                additionalData: {}
              }

              return deviceEl
            })

          newConfigEl = {
            id,
            uuid,
            canvasLibraryType,
            data: {
              ...data,
              draw: (data && data.draw) || {}
            },
            devices: newDevices
          }

          // console.log('decodeFromApi - newConfigEl: ', newConfigEl)
          return newConfigEl
        })
      : []

  const newPlanimetry = {
    draw: (planimetry && planimetry.draw) || [],
    config: newConfig
  }

  // Se module non è aggiornato al nuovo formato, lo setto ad array vuoto
  const newModule = !Array.isArray(module) ? [] : module

  const resultObject = {
    powerLines: newPowerLines,
    plant: {
      ...metadata,
      module: newModule,
      baselineConfig,
      baseline: newBaseline,
      name,
      uuid,
      planimetry: newPlanimetry,
      addedDataloggers
    }
  }

  return resultObject
}

/*
  * Funzione che prende in ingresso l'oggetto 'plant' presente nello store e restituisce un oggetto
  * che potrà essere passato come body alla chiamata POST al backend
  *
  * Input: {
  *   powerLines: [],
      name: '',
      uuid: '', // solo in caso si tratti di una modifica
      addedDataloggers: [],
      address: {}
      baseline: [],
      baselineConfig: {},
      contractDuration: 0,
      endDate: '',
      startDate: '',
      maintenance: true | false,
      module: {},
      peakPower: 0,
      planimetry: {},
      plantType: '',
  *  }
  *
  * Output: {
  *    plant: {},
  *    powerLines: [],
  *  }
*/
export const encodeForApi = (plantStore) => {
  // Estrapolo l'array delle powerLines e l'oggetto impianto dallo store
  const { powerLines, ...plant } = plantStore

  const planimetry = { ...plant.planimetry }

  // console.log('plantAdapter - planimetry: ', JSON.parse(JSON.stringify(planimetry)))
  // Controllo che tutti le linee abbiano un padre
  const newPowerLines = powerLines
    .filter(el => el.name && el.name !== '')
    .map(el => {
      // Prendo la configurazione del punto luce associato alla linea corrente
      const currentLightPointConfig = planimetry && planimetry.config && planimetry.config.find(configEl => configEl.canvasLibraryType === 'lightPoint' && configEl.data && configEl.data.line && configEl.data.line.uuid === el.uuid)
      // Prendo il device associato a quell'elemento di configurazione
      const associatedDevice = currentLightPointConfig && currentLightPointConfig.devices && currentLightPointConfig.devices.length > 0 && currentLightPointConfig.devices[0].deviceId
      const currentParent = powerLines.find(currParent => currParent.name === el.parent)
      if (currentParent) {
        return {
          ...el,
          power: Number(el.power),
          num: Number(el.num),
          parent: { uuid: currentParent.uuid, name: currentParent.name },
          meterId: associatedDevice || null
        }
      } else {
        return {
          ...el,
          power: Number(el.power),
          num: Number(el.num),
          parent: { uuid: el.uuid, name: el.parent },
          meterId: associatedDevice || null
        }
      }
    })

  // console.log('plantAdapter - powerLines: ', newPowerLines)

  // Bisogna cancellare il campo 'devices' dai singoli elementi di config
  const newConfig = planimetry.config.map((configEl) => {
    // se l'elemento di config ha devices li cancello
    if (configEl.devices && configEl.devices !== undefined) {
      configEl.devices = []
    }

    return configEl
  })

  // creo il nuovo oggetto planimetry con la nuova config senza orientamenti e devices
  const newPlanimetry = {
    draw: planimetry.draw,
    config: newConfig
  }

  // Rimuovo i campi che non servono al backend da plant
  delete plant.dataloggers
  delete plant.activeStep
  delete plant.selectedDatalogger

  // Trasformo le date da stringhe formattate a ISOString per passarle al backend
  if (plant.startDate) {
    plant.startDate = new Date(
      plant.startDate
        .split('/')
        .reverse()
        .join('-')
    ).toISOString()
  }
  if (plant.endDate) {
    plant.endDate = new Date(
      plant.endDate
        .split('/')
        .reverse()
        .join('-')
    ).toISOString()
  }
  // Converto la potenza di picco in numero per poter eseguire i calcoli più agilmente lato backend
  if (plant.peakPower) {
    plant.peakPower = Number(plant.peakPower)
  }

  const { name, uuid, ...metadata } = plant
  const { baseline, baselineConfig, module } = metadata

  const monthsList = {
    1: 'gennaio',
    2: 'febbraio',
    3: 'marzo',
    4: 'aprile',
    5: 'maggio',
    6: 'giugno',
    7: 'luglio',
    8: 'agosto',
    9: 'settembre',
    10: 'ottobre',
    11: 'novenbre',
    12: 'dicembre'
  }

  let lastMonth = null
  let lastDate = null
  // La baseline avrà la stessa struttura ma ci sarà aggiunta la data della configurazione
  const newBaseline = baseline.map((el, index) => {
    const startDate = baselineConfig[index].startDate

    // Inverto il giorno con il mese per passare la stringa corretta a moment
    const startDateArray = startDate?.split('/') || null
    if (startDateArray) {
      lastMonth = startDateArray[1]
      const internationalStartDate = [startDateArray[1], startDateArray[0], startDateArray[2]].join('/')
      lastDate = internationalStartDate
      return { ...el, month: moment(internationalStartDate).format('MMMM'), startDate }
    } else {
      const monthIndex = Number(lastMonth) + 1 > 12 ? 1 : Number(lastMonth) + 1
      const month = monthsList[monthIndex]
      lastMonth = monthIndex
      const newStartDate = lastDate ? moment(lastDate).add(1, 'M').format('DD/MM/yyyy') : moment().format('DD/MM/yyyy')
      const lastDateArray = newStartDate.split('/')
      const internationalnewStartDate = [lastDateArray[1], lastDateArray[0], lastDateArray[2]].join('/')
      lastDate = internationalnewStartDate

      return { ...el, month, startDate: newStartDate }
    }
  })

  // Passo solo id e nome dei dataloggers associati all'impianto
  const addedDataloggers = metadata.addedDataloggers.map(el => ({ uuid: el.uuid, name: el.name }))

  // Setto tutti i moduli inseriti per l'impianto come caricati, in modo da permettere il caricamento dei datasheet
  const newModule = module.map(el => ({ ...el, saved: true }))

  // Elimino la configurazione della baseline dall'oggetto da mandare al backend
  delete metadata.baselineConfig

  const newPlant = {
    plant: {
      name,
      metadata: {
        ...metadata,
        addedDataloggers,
        module: newModule,
        baseline: newBaseline,
        anagraphic: {
          peakPower: '',
          plantCode: '',
          contractType: '',
          referentName: '',
          referentClient: '',
          referentRole: '',
          referentEmail: '',
          referentPhone: '',
          monitoringName: '',
          monitoringEmail: '',
          monitoringPhone: '',
          operationAndMaintenaceName: '',
          operationAndMaintenacePhone: '',
          operationAndMaintenaceEmail: '',
          ...metadata.anagraphic
        },
        planimetry: newPlanimetry
      }
    },
    powerLines: newPowerLines
  }

  // Se l'impianto ha un uuid lo inserisco nell'oggetto plant
  if (uuid && uuid !== undefined) {
    newPlant.plant.uuid = uuid
  }

  return newPlant
}
