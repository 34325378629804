import { useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import { Box, Card, Grid, LinearProgress, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import PeriodSelection from 'src/components/PeriodSelection'
import InfoCard from 'src/components/InfoCard'
import { decodeCardsFromApi, decodeLineConsumptionGraphFromApi, getDatesFromPeriod } from '../utils'
import api from 'src/utils/api'
import { useParams } from 'react-router'
import { Skeleton } from '@material-ui/lab'
import ShareButton from 'src/components/ShareButton'
import { createGuestHeaders } from 'src/utils/createGuestHeaders'
import { useSelector } from 'src/store'
import { alog } from 'src/utils/apioLog'
import { useClientRect } from 'src/hooks/useClientRect'
import LineConsumptionGraph from 'src/components/charts/LineConsumptionGraph'
import DownloadExcelButton from '../../shared/DownloadExcelButton'
import useAuth from 'src/hooks/useAuth'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: `calc(100% - ${theme.spacing(2)}px - 80px)`
  },
  section: {
    padding: theme.spacing(2),
    width: '100%',
    height: '100%'
  },
  sectionTitle: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main
  },
  topMargin: {
    marginTop: (theme.spacing(2) - 4)
  },
  smallViewport: {
    overflowY: 'scroll',
    height: '100%'
  }
}))

function Consumption ({ period = null, shared = false, guestToken = null, plantId: sharedPlantId = null, className, ...rest }) {
  const classes = useStyles()
  const { user } = useAuth()
  let { plantId } = useParams()
  // Variabile che rappresenta il nome dell'impianto
  const { name: plantName, plantType } = useSelector(state => state.industryConsumptionPlantView)
  // Variabile di stato che rappresenta lo stato di caricamento dei dati
  const [isLoading, setIsLoading] = useState(false)
  // periodo selezionato tramite le chips
  const [customPeriod, setCustomPeriod] = useState('live')
  // data/intervallo di tempo selezionato tramite il date picker
  const [selectedDate, setSelectedDate] = useState(null)
  // dati delle cards
  const [cardsSettings, setCardsSettings] = useState([])
  // dati del grafico delle linee
  const [lineConsumptionGraph, setLineConsumptionGraph] = useState({})

  plantId = sharedPlantId || plantId

  // Ref per calcolare l'altezza dei grafici
  const [rect, ref] = useClientRect()

  useEffect(() => {
    if (period) {
      setCustomPeriod(period)
    }
  }, [period])

  // Funzione per fare la GET dei dati
  const getData = useCallback(async (timeFrom, timeTo) => {
    const customHeaders = createGuestHeaders(guestToken)
    if (plantId) {
      try {
        const response = await api.getResource('plantDetails', {
          fullResponse: true,
          path: `/${plantId}/consumption?timeFrom=${timeFrom}&timeTo=${timeTo}`,
          customHeaders
        })
        const dataObj = response.data
        return dataObj
      } catch (e) {
        return null
      }
    }
  }, [plantId, guestToken])

  // Funzione che prepara i dati presi dalle API per essere passati alle rispettive funzioni di accoppiamento dei grafici
  const convertData = (dataObj) => {
    // Oggetto dei valori delle cards
    const cardsData = {
      powerRating: dataObj.powerRating || '',
      power: dataObj.power || '',
      totalEnergy: dataObj.totalEnergy || '',
      lightHours: dataObj.lightHours || ''
    }

    // Oggetto dei valori del grafico dei consumi
    const linesConsumptionGraphData = dataObj.powerlines ? dataObj.powerlines.map(line => ({ ...line.totalEnergy, uuid: line.uuid, name: line.name })) : []

    // console.log('consumption: ', linesConsumptionGraphData)
    return { cardsData, linesConsumptionGraphData }
  }

  // Parametri da passare al menu di condivisione
  const params = [{ name: 'plantId', value: plantId }, { name: 'plantName', value: plantName }, { name: 'plantType', value: plantType }]

  useEffect(() => {
    // Funzione che fa l'inizializzazione della view, settando i dati che i grafici consumeranno
    async function initialiseView (timeFrom, timeTo) {
      setIsLoading(true)
      const dataObj = await getData(timeFrom, timeTo)
      if (dataObj) {
        alog('initialiseView - dataObj: ', dataObj, 'consumption')
        const { cardsData, linesConsumptionGraphData } = convertData(dataObj)
        alog('initialiseView - cardsData: ', cardsData, 'consumption')
        alog('initialiseView - cardsData: ', linesConsumptionGraphData, 'consumption')
        setCardsSettings(decodeCardsFromApi(cardsData))
        // setEnergyGraph(decodeEnergyGraphFromApi(energyGraphData, customPeriod))
        setLineConsumptionGraph(decodeLineConsumptionGraphFromApi(linesConsumptionGraphData))
      }
      setIsLoading(false)
    }
    const periodDate = (selectedDate && selectedDate.toDate()) || new Date()
    // Prendo le date minima e massima da passare alle api
    const { minDate, maxDate } = getDatesFromPeriod(customPeriod, periodDate)
    if (!shared) {
      if (customPeriod === 'live') {
        // Prima GET
        initialiseView(minDate, maxDate)
        // Attivazione del polling
        const pollingInterval = setInterval(() => {
          initialiseView(minDate, maxDate)
        }, 60000)
        return () => {
          clearInterval(pollingInterval)
        }
      } else {
        initialiseView(minDate, maxDate)
      }
    } else {
      alog('production initialization', { customPeriod, minDate, maxDate }, 'PROduction')
      initialiseView(minDate, maxDate)
    }
  }, [customPeriod, plantId, getData, selectedDate, shared])

  const isExtraSmall = useMediaQuery(theme => theme.breakpoints.down('xs'))
  // const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const isLarge = useMediaQuery(theme => theme.breakpoints.down('lg'))

  const graphHeight = rect ? rect.height - 250 : '100%'

  return (
    <div className={isLarge ? clsx(classes.root, className, classes.smallViewport) : clsx(classes.root, className)} {...rest}>
      <Box mt={2}>
        <Grid width='100%' container spacing={1}>
          {shared
            ? null
            : (
              <Grid style={{ order: 1 }} item xs={12} sm={10} md={10} xl={3}>
                <PeriodSelection
                  isLoading={isLoading}
                  selectedDate={selectedDate}
                  setSelectedDate={(date) => {
                    setSelectedDate(date)
                  }}
                  customPeriod={customPeriod}
                  setCustomPeriod={(period) => {
                    setCustomPeriod(period)
                  }}
                />
              </Grid>)}
          <Grid style={{ order: isLarge ? 3 : 2 }} item xs={12} md={12} xl={shared ? 12 : 8}>
            <Box width='100%'>
              <Grid container spacing={1}>
                {
                  cardsSettings.length > 0
                    ? cardsSettings.map((card, index) => (
                      <Grid key={`card-${index}`} item xs={12} sm={6} lg={3}>
                        <InfoCard icon={card.icon} values={card.values} />
                      </Grid>
                      ))
                    : (
                      <>
                        <Grid item xs={12} sm={6} lg={3}>
                          <Skeleton variant='rect' height={60} />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                          <Skeleton variant='rect' height={60} />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                          <Skeleton variant='rect' height={60} />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                          <Skeleton variant='rect' height={60} />
                        </Grid>
                      </>)
                }
              </Grid>
            </Box>
          </Grid>
          {shared
            ? null
            : !isExtraSmall && (
              <Grid style={{ order: isLarge ? 2 : 3 }} item xs={2} md={2} xl={1}>
                <Box width='100%' height='100%' display='flex' alignItems='center' justifyContent='flex-end'>
                  <Box mr={1}>
                    <DownloadExcelButton
                      selectedDate={selectedDate}
                      period={customPeriod}
                      plantId={plantId}
                      plantName={plantName}
                      exportType='consumption'
                      description='Scarica i dati dei consumi del periodo che stai visualizzando in un file Excel'
                    />
                  </Box>
                  <ShareButton userId={user ? user.uuid : null} path='industryConsumption' params={params} period={customPeriod} />
                </Box>
              </Grid>
              )}
        </Grid>
      </Box>
      <Box ref={ref} width='100%' height='100%' mt={1}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Card className={classes.section}>
              {isLoading
                ? (
                  <Box mt={-2} ml={-2}>
                    <LinearProgress color='primary' />
                  </Box>)
                : null}
              <Typography className={isLoading ? clsx(classes.topMargin, classes.sectionTitle) : classes.sectionTitle} variant='h6'>Consumi</Typography>
              <Box width='100%' display='flex' alignItems='center' justifyContent='center'>
                <LineConsumptionGraph
                  height={graphHeight < 300 ? 300 : graphHeight}
                  isLoading={isLoading}
                  data={lineConsumptionGraph}
                />
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}
export default Consumption
