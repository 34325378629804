import { Suspense, Fragment, lazy } from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
import DashboardLayout from 'src/layouts/DashboardLayout'
// import DocsLayout from 'src/layouts/DocsLayout';
import MainLayout from 'src/layouts/MainLayout'
// import HomeView from 'src/views/home/HomeView';
import LoadingScreen from 'src/components/LoadingScreen'
import AuthGuard from 'src/components/AuthGuard'
import GuestGuard from 'src/components/GuestGuard'
import ShareLayout from 'src/layouts/ShareLayout'
import ReportLayout from 'src/layouts/ReportLayout'
import VerticalReportLayout from 'src/layouts/VerticalReportLayout'

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment
        const Layout = route.layout || Fragment
        const Component = route.component

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
              </Guard>
            )}
          />
        )
      })}
    </Switch>
  </Suspense>
)

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    // guard: GuestGuard,
    path: '/account/activate',
    component: lazy(() => import('src/views/auth/ConfirmEmailView'))
  },
  {
    exact: true,
    // guard: GuestGuard,
    path: '/account/newemail',
    component: lazy(() => import('src/views/auth/ConfirmNewEmailView'))
  },
  {
    exact: true,
    // guard: GuestGuard,
    path: '/password/reset',
    component: lazy(() => import('src/views/auth/ResetPasswordView'))
  },
  // {
  //   exact: true,
  //   path: '/login-unprotected',
  //   component: lazy(() => import('src/views/auth/LoginView'))
  // },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/shared/:token',
    layout: ShareLayout,
    component: lazy(() => import('src/views/share/ShareView'))
  },
  {
    exact: true,
    // guard: GuestGuard,
    path: '/report/plantsummary',
    layout: VerticalReportLayout,
    component: lazy(() => import('src/views/reports/PlantSummary'))
  },
  {
    exact: true,
    // guard: GuestGuard,
    path: '/report/plant',
    layout: ReportLayout,
    component: lazy(() => import('src/views/reports/Plant'))
  },
  {
    exact: true,
    path: '/report/plants',
    layout: ReportLayout,
    component: lazy(() => import('src/views/reports/Plants'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/overview/anomalies',
        component: lazy(() => import('src/views/overview/AnomaliesView'))
      },
      {
        exact: true,
        path: '/app/overview/analytics',
        component: lazy(() => import('src/views/overview/AnalyticsView'))
      },
      {
        exact: true,
        path: '/app/overview/maps',
        component: lazy(() => import('src/views/overview/MapsView'))
      },
      {
        exact: true,
        path: '/app/overview/reports',
        component: lazy(() => import('src/views/overview/ReportsView'))
      },
      {
        exact: true,
        path: '/app/view/plant/:plantId',
        component: lazy(() => import('src/views/plant/PlantView'))
      },
      // {
      //   exact: true,
      //   path: '/app/report/plant/:plantId',
      //   component: lazy(() => import('src/views/plant/ResumeeView'))
      // },
      {
        exact: true,
        path: '/app/settings/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/app/management/users',
        component: lazy(() => import('src/views/users/usersListView'))
      },
      {
        exact: true,
        path: '/app/management/roles',
        component: lazy(() => import('src/views/users/rolesView'))
      },
      {
        exact: true,
        path: '/app/management/groups',
        component: lazy(() => import('src/views/users/groupsView'))
      },
      {
        exact: true,
        path: '/app/management/devices',
        component: lazy(() => import('src/views/devices/DevicesListView'))
      },
      {
        exact: true,
        path: '/app/management/plants',
        component: lazy(() => import('src/views/systems/SystemsListView'))
      },
      // {
      //   exact: true,
      //   path: '/app/management/users/:userId',
      //   component: lazy(() => import('src/views/users/CustomerDetailsView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/management/users/:userId/edit',
      //   component: lazy(() => import('src/views/users/CustomerEditView'))
      // },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to='/app/overview/maps' />
        // component: () => <Redirect to='/app/settings/account' />
      },
      {
        component: () => <Redirect to='/404' />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      // {
      //   exact: true,
      //   path: '/',
      //   component: HomeView
      // },
      // {
      //   exact: true,
      //   path: '/pricing',
      //   component: lazy(() => import('src/views/pricing/PricingView'))
      // },
      {
        component: () => <Redirect to='/app' />
      }
    ]
  }
]

export default routes
