import { configuration } from 'src/config'

/* Url di base per le varie risorse utilizzate all'interno della dasboard */
export default {
  accounts: `${configuration.apiUrl}/accounts`,
  settings: `${configuration.apiUrl}/projects/${configuration.projectId}/settings`,
  projectAccounts: `${configuration.apiUrl}/projects/${configuration.projectId}/accounts`,
  logout: `${configuration.apiUrl}/accounts/logout`,
  apikeys: `${configuration.apiUrl}/projects/${configuration.projectId}/apikeys`,
  usersList: `${configuration.apiUrl}/projects/${configuration.projectId}/dashboard/users`,
  groupslist: `${configuration.apiUrl}/projects/${configuration.projectId}/groups`,
  aclRules: `${configuration.apiUrl}/projects/${configuration.projectId}/aclrules`,
  groupmemberships: `${configuration.apiUrl}/projects/${configuration.projectId}/groupmemberships`,
  roles: `${configuration.apiUrl}/projects/${configuration.projectId}/roles`,
  plants: `${configuration.apiUrl}/projects/${configuration.projectId}/plants`,
  plantsList: `${configuration.apiUrl}/projects/${configuration.projectId}/dashboard/components/plants`,
  nodes: `${configuration.apiUrl}/projects/${configuration.projectId}/nodes`,
  nodesList: `${configuration.apiUrl}/projects/${configuration.projectId}/dashboard/components/nodes`,
  nodeTypes: `${configuration.apiUrl}/projects/${configuration.projectId}/nodetypes`,
  nodeConfig: `${configuration.apiUrl}/projects/${configuration.projectId}`,
  devices: `${configuration.apiUrl}/projects/${configuration.projectId}/dashboard/components/devices`,
  deviceTypes: `${configuration.apiUrl}/projects/${configuration.projectId}/devicetypes`,
  orientations: `${configuration.apiUrl}/projects/${configuration.projectId}/orientations`,
  dashboard: `${configuration.apiUrl}/projects/${configuration.projectId}/dashboard`,
  forceSummaryRefresh: `${configuration.apiUrl}/projects/${configuration.projectId}/dashboard/plants`,
  jobs: `${configuration.apiUrl}/jobs`,
  uploadFiles: `${configuration.apiUrl}/projects/${configuration.projectId}/imports/measures`,
  openstreetmap: 'https://nominatim.openstreetmap.org',
  plantsMap: `${configuration.apiUrl}/projects/${configuration.projectId}/dashboard/plants/map`,
  plantDetails: `${configuration.apiUrl}/projects/${configuration.projectId}/dashboard/plants`,
  plantMetricsRecalculation: `${configuration.apiUrl}/projects/${configuration.projectId}/dashboard/recalculations`,
  logs: `${configuration.apiUrl}/projects/${configuration.projectId}/telemetry`,
  logsInExcel: `${configuration.apiUrl}/projects/${configuration.projectId}/telemetry/export`,
  anomaliesList: `${configuration.apiUrl}/projects/${configuration.projectId}/anomalies`,
  anomaliesConfig: `${configuration.apiUrl}/projects/${configuration.projectId}/anomalyConfigurations`,
  uploadDatasheet: `${configuration.apiUrl}/projects/${configuration.projectId}/uploads/devices`,
  uploads: `${configuration.apiUrl}/projects/${configuration.projectId}/uploads`,
  links: `${configuration.apiUrl}/projects/${configuration.projectId}/links`,
  reports: `${configuration.apiUrl}/projects/${configuration.projectId}/reports`
}
