import moment from 'moment'
import { libraryDevices } from 'src/components/Planimetry/libraryDevices'
import { alog } from 'src/utils/apioLog'

const monthsArray = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre']

/*
 * Funzione che prende in ingresso l'oggetto di risposta del backend e restituisce un oggetto
 * contenente gli oggetti nel formato utile al frontend per consentire le interazioni con l'interfaccia
 *
 * Input: {
 *    orientations: [],
 *    plant: {},
 *    devices: [],
 *    dataloggers: []
 *  }
 *
 * Output: {
 *    plant: {},
 *    generators: [],
 *    orientations: []
 *  }
 */
export const decodeFromApi = (apiResponse) => {
  // Singoli elementi che serviranno per assemblare l'oggetto ritornato per il frontend
  const { orientations, plant, devices, dataloggers } = apiResponse || {}
  // Estrapolo le informazioni dall'oggetto 'plant' per preparare i dati per lo store redux
  const { name, uuid, metadata } = plant || {}
  const { planimetry, baseline, years, distribution } = metadata

  // Dalla baseline devo ricostruire gli oggetti di baselineConfig e baseline
  // Array di percentageLoss della nuova struttura della baseline
  const percentageLossArray = years ? years.map(el => Number(el.percentageLoss)) : []
  // Primi 12 mesi della baseline
  const firstBaselineYear = baseline.slice(0, 12)
  // Configurazione di baseline
  const baselineConfig = monthsArray.map(singleMonth => {
    const currentMonthElement = firstBaselineYear.find(el => el.month.includes(singleMonth.toLocaleLowerCase()))
    if (currentMonthElement) {
      const currentIrradiationValue = Number(currentMonthElement.irradiation)
      const currentProducibilityValue = Number(currentMonthElement.producibility) / (1 - Number(percentageLossArray[0] || 0) / 100)

      return {
        month: singleMonth,
        irradiation: currentIrradiationValue,
        producibility: Number(currentProducibilityValue.toFixed(2))
      }
    }

    return {
      month: singleMonth,
      irradiation: 0,
      producibility: 0
    }
  })

  const newBaselineMonths = monthsArray.map((singleMonth, monthIndex) => {
    // Elemento del mese i-esimo della configurazione di baseline
    const configProducibility = monthIndex < baselineConfig.length ? baselineConfig[monthIndex] : null
    let producibilityValue = configProducibility ? configProducibility.producibility : 0
    // Array della producibilità per il mese i-esimo, dove ogni suo elemento rappresenta un anno diverso di contratto
    const producibilityArray = configProducibility
      ? percentageLossArray.map(percentageEl => {
          const currentProdElement = Number(producibilityValue) - Number(producibilityValue) * Number(percentageEl) / 100
          producibilityValue = currentProdElement
          return isNaN(currentProdElement) ? 0 : Number(currentProdElement.toFixed(2))
        })
      : [0]

    return {
      month: singleMonth,
      producibility: producibilityArray
    }
  })
  // Nuovo oggetto di baseline che rispecchia il modello dati aggiornato
  const newBaseline = {
    percentageLoss: percentageLossArray,
    months: newBaselineMonths
  }

  // Aggiungo ai dataloggers ricevuti l'informazione dei devices associati ad essi
  const addedDataloggers = dataloggers.map((datalogger) => {
    // Prendo tutti i devices con nodeId uguale all'uuid del datalogger
    const dataloggerDevices = devices.filter((device) => device.nodeId === datalogger.uuid)
    // console.log('decodeFromApi - dataloggerDevices: ', dataloggerDevices)
    return {
      ...datalogger,
      includes: {
        devices: dataloggerDevices
      }
    }
  })

  // Costruisco la lista dei devices con struttura della risorsa sul backend
  const dataloggersDevices = addedDataloggers
    .map((datalogger) => (datalogger.includes && datalogger.includes.devices ? datalogger.includes.devices : []))
    .flat()
  // Creo una copia della distribuzione
  const newDistribution = { ...distribution }
  // Array di tutti i devices di tutti i dataloggers associati all'impianto
  const devicesUuids = dataloggersDevices.map(el => el.uuid)
  // Ricreo un oggetto distribuzione con solo le chiavi presenti nei dataloggers associati all'impianto
  const filteredDistribution = Object.keys(newDistribution).reduce((acc, key) => {
    if (devicesUuids.includes(key)) {
      acc[key] = newDistribution[key]
    }

    return acc
  }, {})

  // Ricostruisco la config della planimetry basandomi sui dati ricevuti dalle risorse primarie
  const newConfig =
    planimetry && planimetry.config && planimetry.config !== undefined
      ? planimetry.config.map((configEl) => {
          let newConfigEl = {}
          const { id, uuid, canvasLibraryType, data } = configEl
          // Scorro gli orientamenti e trovo quelli che in meterIds e generatorIds hanno l'uuid dell'elemento di configurazione corrente
          const orientation = orientations.find(
            (orientationEl) => orientationEl.meterIds.includes(uuid) || orientationEl.generatorIds.includes(uuid)
          )
          // console.log('decodeFromApi - orientation: ', orientation)
          // console.log('decodeFromApi - orientations: ', orientations)
          // se sto ciclando un elemento di config di un generatore
          if (configEl.canvasLibraryType === 'generator') {
          // trovo il device generatore da cui devo ricavarmi additionalData
            const currentDevice = devices.find((device) => device.uuid === uuid)

            // console.log('encode - uuid: ', uuid)
            // console.log('encode - devices: ', devices)
            let newDevices = []
            // console.log('stringsDebug - currentDevice: ', currentDevice)
            if (currentDevice && currentDevice !== undefined) {
            // prendo gli uuid degli inverters per le stringhe di questo generatore
              const inverterIds = [...new Set(currentDevice.metadata.strings.map((string) => string.inverterId))]
              // console.log('stringsDebug - inverterIds: ', inverterIds)
              const generatorInverters = devices.filter(
                (device) => inverterIds.includes(device.uuid) && device.deviceType.category.toLowerCase() !== 'generator'
              )
              // console.log('stringsDebug - generatorInverters: ', generatorInverters)
              // devo costruire l'array rows ed assegnarlo all'additionalData di quell'inverter
              newDevices = generatorInverters.map((inverter) => {
              // prendo tutte le stringhe associate al singolo inverter
                const singleInverterStrings = currentDevice.metadata.strings.filter((el) => el.inverterId === inverter.uuid)

                const rows = []
                // per ogni stringa del singolo inverter
                singleInverterStrings.forEach((el) => {
                // se il numero di stringa è maggiore del numero di righe, popolo le righe con tanti elementi quante sono le stringhe
                  while (parseInt(el.string) > rows.length) {
                  // while (parseInt(el.string + 1) > rows.length) {
                    rows.push([])
                  }

                  // popolo l'array rows con oggetti che contengono l'indice di colonna della tabella distribuzioni per il generatore
                  rows[el.string - 1].push({ colIndex: el.mptt })
                // rows[(el.string)].push({ colIndex: el.mptt })
                })

                // console.log('stringsDebug - rows: ', rows)

                // prendo il modello del device
                const deviceModel = inverter.deviceType.model
                // popolo l'oggetto properties per ogni device partendo dalle properties del device
                const properties = {}
                const libraryDevice = libraryDevices.find((libEl) => libEl.models.includes(deviceModel))
                if (libraryDevice) {
                  const deviceKeys = Object.keys(libraryDevice)
                  deviceKeys
                    .filter((key) => key !== 'models')
                    .forEach((key) => {
                      properties[key] = {
                        label: libraryDevice[key],
                        value: 0
                      }
                    })
                }
                /* const newProperties = Object.keys(inverter.deviceType.properties).reduce((acc, el) => {
acc[el] = {
label: inverter.deviceType.properties[el].displayName,
value: 0
}

return acc
}, {}) */

                const deviceEl = {
                  type: inverter.deviceType.category.toLowerCase(),
                  model: inverter.deviceType.model,
                  deviceId: inverter.uuid,
                  // sto assegnando le properties dai devices, ma credo che per il momento dovrò prenderle dal file di libreria
                  // properties: inverter.deviceType.properties,
                  properties,
                  additionalData: {
                    rows
                  }
                }

                return deviceEl
              })
            }

            newConfigEl = {
              id,
              uuid,
              canvasLibraryType,
              data: {
                draw: (data && data.draw) || {},
                /* orientation: {
name: orientation && orientation !== undefined ? orientation.name : '',
uuid: orientation && orientation !== undefined ? orientation.uuid : ''
} */
                orientation: orientation && orientation !== undefined ? orientation.uuid : ''
              },
              devices: newDevices
            }
          } else if (configEl.canvasLibraryType === 'obstacle') {
            newConfigEl = configEl
          } else {
            const newDevices = devices
            // filtro i devices prendendo solo quelli con uuid uguale all'uuid della config
              .filter((device) => device.uuid === uuid && device.deviceType.category.toLowerCase() !== 'generator')
              .map((device) => {
              // prendo il modello del device
                const deviceModel = device.deviceType.model
                // popolo l'oggetto properties per ogni device partendo dalle properties del device
                const properties = {}
                const libraryDevice = libraryDevices.find((libEl) => libEl.models.includes(deviceModel))
                if (libraryDevice) {
                  const deviceKeys = Object.keys(libraryDevice)
                  deviceKeys
                    .filter((key) => key !== 'models')
                    .forEach((key) => {
                      properties[key] = {
                        label: libraryDevice[key],
                        value: 0
                      }
                    })
                }

                /* const newProperties = Object.keys(device.deviceType.properties).reduce((acc, el) => {
acc[el] = {
label: device.deviceType.properties[el].displayName,
value: 0
}

return acc
}, {}) */

                const deviceEl = {
                  type: device.deviceType.category.toLowerCase(),
                  model: device.deviceType.model,
                  deviceId: device.uuid,
                  // sto assegnando le properties dai devices, ma credo che per il momento dovrò prenderle dal file di libreria
                  properties,
                  // properties: device.deviceType.properties,
                  additionalData: {}
                }

                return deviceEl
              })

            newConfigEl = {
              id,
              uuid,
              canvasLibraryType,
              data: {
                draw: (data && data.draw) || {}
              },
              devices: newDevices
            }

            if (orientation && orientation !== undefined) {
            /* newConfigEl.orientation = {
            name: orientation.name,
            uuid: orientation.uuid
          } */
              newConfigEl.data.orientation = orientation.uuid
            }
          }

          // console.log('decodeFromApi - newConfigEl: ', newConfigEl)
          return newConfigEl
        })
      : []

  const newPlanimetry = {
    draw: (planimetry && planimetry.draw) || [],
    config: newConfig
  }

  // console.log('decodeFromApi - newConfig: ', newConfig)

  const generators = devices.filter((device) => device.deviceType.category.toLowerCase() === 'generator')

  // console.log('decodeFromApi - newPlanimetry: ', newPlanimetry)

  const resultObject = {
    generators,
    orientations,
    plant: {
      ...metadata,
      distribution: filteredDistribution,
      baselineConfig,
      baseline: newBaseline,
      name,
      uuid,
      planimetry: newPlanimetry,
      addedDataloggers
    }
  }

  // console.log('decodeFromApi - resultObject: ', resultObject)

  return resultObject
}

/*
  * Funzione che prende in ingresso l'oggetto 'plant' presente nello store e restituisce un oggetto
  * che potrà essere passato come body alla chiamata POST al backend
  *
  * Input: {
  *    orientations: [],
      generators: [],
      name: '',
      uuid: '', // solo in caso si tratti di una modifica
      addedDataloggers: [],
      address: {}
      baseline: [],
      contractDuration: 0,
      distribution: {},
      endDate: '',
      startDate: '',
      maintenance: true | false,
      manufacturability: {},
      module: {},
      peakPower: 0,
      planimetry: {},
      plantType: '',
      totalDistribution: 0
      years: []
  *  }
  *
  * Output: {
  *    plant: {},
  *    orientations: [],
  *    generators: []
  *  }
*/
export const encodeForApi = (plantStore) => {
  // Estrapolo l'array degli orientamenti, l'array dei generatori e l'oggetto impianto dallo store
  const { orientations, ...plant } = plantStore

  // console.log('encodeForApi - plantStore: ', plantStore)
  const { distribution } = plant
  // const planimetry = JSON.parse(JSON.stringify(plant.planimetry))
  const planimetry = { ...plant.planimetry }
  // console.log('encodeForApi - planimetry: ', planimetry)
  // console.log('encodeForApi - orientations: ', orientations)

  // Dobbiamo prendere tutti i generatori, le centraline meteo e i solarimetri dalla config che abbiano un orientamento
  const objectsWithOrientation = planimetry.config.filter(
    (el) =>
      (el.canvasLibraryType === 'generator' ||
        el.canvasLibraryType === 'solar' ||
        el.canvasLibraryType === 'sunmeter' ||
        el.canvasLibraryType === 'weather') &&
      el.data &&
      el.data.orientation &&
      el.data.orientation !== undefined
    // el.data.orientation !== undefined &&
    // Object.keys(el.data.orientation).length > 0)
  )

  // console.log('encodeForApi - objectsWithOrientation: ', objectsWithOrientation)

  // Bisogna ricomporre l'array orientations aggiungendo gli array
  // meterIds = [<solarimeterId> | <weatherStationId>]
  // generatorIds = [<generatorId>]
  const newOrientations = orientations
    ? orientations.map((singleOrientation) => {
      // console.log('encodeForApi: ', singleOrientation)
      // Prendo tutte le centraline, i solarimetri e i generatori che hanno l'i-esimo orientamento
        const objectsInOrientation = objectsWithOrientation
        // .filter(el => el.data.orientation.uuid === singleOrientation.uuid)
          .filter((el) => el.data.orientation === singleOrientation.uuid)
        // creo l'array degli id dei generatori
        const generatorIds = objectsInOrientation.filter((el) => el.canvasLibraryType === 'generator').map((el) => el.uuid)
        // creo l'array degli id dei solarimetri e delle centraline meteo
        const meterIds = objectsInOrientation.filter((el) => el.canvasLibraryType !== 'generator').map((el) => el.uuid)

        return {
          ...singleOrientation,
          meterIds,
          // panelIds: generatorIds
          generatorIds
        }
      })
    : []

  // Bisogna ricomporre l'array di generatori
  const generators = planimetry.config.filter((el) => el.canvasLibraryType === 'generator')

  // Costruisco la lista dei devices con struttura della risorsa sul backend
  const dataloggersDevices = plant.addedDataloggers
    .map((datalogger) => (datalogger.includes && datalogger.includes.devices ? datalogger.includes.devices : []))
    .flat()

  // Per ogni generatore bisogna ricomporre l'oggetto strings
  generators.forEach((gen) => {
    const strings = []
    // se esistiono devices (inverters) associati all'i-esimo generatore
    if (gen.devices && gen.devices.length > 0) {
      // Controllo per ogni device se ha un attributo additionalData e se questo attributo contiene rows
      gen.devices.forEach((device) => {
        const currentDevice = dataloggersDevices.find((el) => el.uuid === device.deviceId)
        const currentDistribution = distribution[device.deviceId] || {}
        if (currentDevice && currentDevice !== undefined) {
          if (device.additionalData && device.additionalData.rows && device.additionalData.rows.length > 0) {
            device.additionalData.rows.forEach((row, rowIndex) => {
              // console.log('encodeForApi - generators - row: ', row)
              row.forEach((col) => {
                if (currentDistribution?.rows[rowIndex]?.[col?.colIndex - 1] !== 0) {
                  // console.log('encodeForApi - device: ', device)
                  const stringEl = {
                    mptt: col.colIndex,
                    string: rowIndex + 1,
                    inverterId: device.deviceId,
                    inverter: currentDevice.metadata.inverterNum
                  }
                  strings.push(stringEl)
                }
              })
            })
          }
        }
      })
    }

    gen.strings = strings
    gen.metadata = {
      strings
    }
  })

  // console.log('encodeForApi - generators: ', generators)

  // Bisogna cancellare i campi 'devices' e 'data.orientations' dai singoli elementi di config
  const newConfig = planimetry.config.map((configEl) => {
    // console.log('encodeForApi - configEl: ', configEl)
    // Se l'elemento di config ha un orientamento lo cancello
    if (
      configEl.data &&
      configEl.data !== undefined &&
      configEl.data.orientation &&
      configEl.data.orientation !== undefined
    ) {
      // configEl.data.orientation = {}
      configEl.data.orientation = ''
    }

    // se l'elemento di config ha devices li cancello
    if (configEl.devices && configEl.devices !== undefined) {
      configEl.devices = []
    }

    return configEl
  })

  // console.log('encodeForApi - newConfig: ', newConfig)

  // creo il nuovo oggetto planimetry con la nuova config senza orientamenti e devices
  const newPlanimetry = {
    draw: planimetry.draw,
    config: newConfig
  }

  // Rimuovo i campi che non servono al backend da plant
  delete plant.dataloggers
  delete plant.plants
  delete plant.panels
  delete plant.activeStep
  delete plant.currentBaselinePage
  delete plant.selectedDatalogger
  delete plant.selectedDistributionDatalogger
  delete plant.selectedDataloggerDistribution
  delete plant.selectedManufacturability
  delete plant.year

  // Trasformo le date da stringhe formattate a ISOString per passarle al backend
  if (plant.startDate) {
    plant.startDate = new Date(
      plant.startDate
        .split('/')
        .reverse()
        .join('-')
    ).toISOString()
  }
  if (plant.endDate) {
    plant.endDate = new Date(
      plant.endDate
        .split('/')
        .reverse()
        .join('-')
    ).toISOString()
  }
  // Converto la potenza di picco in numero per poter eseguire i calcoli più agilmente lato backend
  if (plant.peakPower) {
    plant.peakPower = Number(plant.peakPower)
  }

  const { name, uuid, ...metadata } = plant
  const { baseline, baselineConfig, startDate } = metadata

  const newBaseline = []
  if (startDate) {
    // Numero di anni di durata del contratto estratto dal numero di anni della baseline
    const contractDuration = (baseline.percentageLoss && baseline.percentageLoss.length) || 0
    const delta = (contractDuration) * 12
    const startDateCopy = moment(startDate)

    startDateCopy.add(newBaseline.length, 'M')
    for (let s = 0; s < delta; s++) {
      // Indice dell' elemento della configurazione di baseline del mese corrente
      const elementConfigIndex = baselineConfig.findIndex(el => el.month.toLowerCase() === startDateCopy.format('MMMM'))
      if (elementConfigIndex > -1) {
        // Valore dell'irraggiamento di baseline per il mese corrente
        const irradiationValue = baselineConfig[elementConfigIndex] && baselineConfig[elementConfigIndex].irradiation ? baselineConfig[elementConfigIndex].irradiation : 0
        // Valore dell'indice corrispondente all'anno che sto considerando
        const yearIndex = Math.floor(s / 12)
        // Valore di perdita percentuale per l'anno che sto considerando
        const currentPercentageLoss = Number(baseline.percentageLoss[yearIndex])
        // Valore di producibilità del mese nell'anno precedente (se l'annp precedente non esiste, mi rifaccio alla configurazione)
        const currentProducibilityValue = yearIndex === 0
          ? baselineConfig[elementConfigIndex] && baselineConfig[elementConfigIndex].producibility ? Number(baselineConfig[elementConfigIndex].producibility) : 0
          : baseline &&
            baseline.months &&
            baseline.months[elementConfigIndex] &&
            baseline.months[elementConfigIndex].producibility &&
            baseline.months[elementConfigIndex].producibility[yearIndex - 1]
            ? baseline.months[elementConfigIndex].producibility[yearIndex - 1]
            : null

        const producibilityValue =
          currentProducibilityValue
            ? (Number(currentProducibilityValue) - (Number(currentProducibilityValue) * currentPercentageLoss / 100))
            : 0

        newBaseline.push({
          month: startDateCopy.format('MMMM YYYY'),
          producibility: isNaN(producibilityValue) ? 0 : Number(producibilityValue.toFixed(2)),
          irradiation: irradiationValue
        })
      }
      startDateCopy.add(1, 'M')
    }
  }

  alog('baseline', baseline, 'plantAdapter')
  alog('newBaseline', newBaseline, 'plantAdapter')

  // array che va a sostituire years (struttura della vecchia baseline) in caso di nuova baseline
  const newYears = baseline.percentageLoss.map(el => ({ percentageLoss: el }))

  // Passo solo id e nome dei dataloggers associati all'impianto
  const addedDataloggers = metadata.addedDataloggers.map(el => ({ uuid: el.uuid, name: el.name }))

  // Elimino la configurazione della baseline dall'oggetto da mandare al backend
  delete metadata.baselineConfig

  const newPlant = {
    plant: {
      name,
      metadata: {
        ...metadata,
        addedDataloggers,
        baseline: newBaseline,
        years: newYears,
        anagraphic: {
          peakPower: '',
          plantCode: '',
          contractType: '',
          referentName: '',
          referentClient: '',
          referentRole: '',
          referentEmail: '',
          referentPhone: '',
          monitoringName: '',
          monitoringEmail: '',
          monitoringPhone: '',
          operationAndMaintenaceName: '',
          operationAndMaintenacePhone: '',
          operationAndMaintenaceEmail: '',
          ...metadata.anagraphic
        },
        planimetry: newPlanimetry
      }
    },
    orientations: newOrientations,
    generators
  }

  // Se l'impianto ha un uuid lo inserisco nell'oggetto plant
  if (uuid && uuid !== undefined) {
    newPlant.plant.uuid = uuid
  }

  return newPlant
}
