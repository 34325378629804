import { useState, useCallback, useEffect } from 'react'
import clsx from 'clsx'
import { Box, Grid, makeStyles, MenuItem, SvgIcon, TextField, Tooltip, Typography } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { useSelector } from 'src/store'
import { Formik } from 'formik'
import { changeAnagraphicValue, changeContractValue, setAddress, updateLogo } from 'src/slices/pv/plant'
import { customFilterContractTypes } from 'src/views/overview/MapsView/filtersUtils'
import { plantTypes } from 'src/utils/plantOperations'
import { Alert, AlertTitle, Autocomplete } from '@material-ui/lab'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import api from 'src/utils/api'
import Map from 'src/components/Map'
import { useClientRect } from 'src/hooks/useClientRect'
import { deleteLogo, loadLogo } from 'src/views/systems/SystemsListView/AddPlantDialog/shared/utils'
import { useSnackbar } from 'notistack'
import LogoLoader from 'src/components/LogoLoader'
import { Info as InfoIcon } from 'react-feather'

const useStyles = makeStyles(theme => ({
  root: {},
  stepDescription: {
    color: theme.palette.text.secondary
  }
}))

function PlantInfo ({ className, ...rest }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  // Ref per calcolare l'altezza dei grafici
  const [rect, ref] = useClientRect()

  const { enqueueSnackbar } = useSnackbar()

  const { uuid, address, anagraphic, plantType, name, logoURL } = useSelector(state => state.pvPlant)

  // const [value, setValue] = React.useState(typeof address === 'object' ? { display_name: address.inputValue, lat: address.coordinates[1], lon: address.coordinates[0] } : null)
  const [value, setValue] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState(typeof address === 'object' ? [{ display_name: address.inputValue, lat: address.coordinates[1], lon: address.coordinates[0] }] : [])

  useEffect(() => {
    if (typeof address === 'object') {
      setValue({ display_name: address.inputValue, lat: address.coordinates[1], lon: address.coordinates[0] })
    }
  }, [address])
  // Funzione che si occupa di fare la chiamata per avere la lista di indirizzi tra cui scegliere
  const fetchStreets = useCallback(async (req) => {
    const { input } = req

    if (input) {
      const results = await api.getResource('openstreetmap', { path: `/search?q=${input}&format=json`, disableAuth: true, fullResponse: true })
      const placeOptions = results.data
      if (placeOptions) {
        setOptions(placeOptions)
      }
    }
  }, [])

  // UseEffect che gestisce la frequenza con cui viene rigenerata la lista di indirizzi tra i quali selezionare
  useEffect(() => {
    if (inputValue === '') {
      setOptions(value ? [value] : [])
    }
    const delayDebounceFn = setTimeout(() => {
      fetchStreets({ input: inputValue })
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [inputValue, value, fetchStreets])
  // Funzione che gestisce l'aggiornamento dei dati di anagrafica impianto al cambio degli input
  const handleChange = (event, name) => {
    const value = event.target.value

    dispatch(changeAnagraphicValue(value, name))
  }

  // Funzione che gestisce il cambio di nome dell'impianto
  const handleNameChange = (event, name) => {
    dispatch(changeContractValue(event.target.value, name))
  }

  // Funzione che elimina il logo di un impianto
  const deleteImage = async () => {
    if (uuid) {
      try {
        await deleteLogo(uuid)
        const logoUrl = null
        dispatch(updateLogo(logoUrl))
        enqueueSnackbar('Logo eliminato correttamente', { variant: 'success' })
      } catch (e) {
        enqueueSnackbar('Errore durante la cancellazione del logo', { variant: 'error' })
      }
    }
  }

  // Funzione che carica il logo di un impianto, prende in ingresso il logo
  const loadImage = async (logo) => {
    if (logo && uuid) {
      try {
        const updatedPlant = await loadLogo(logo, uuid)
        const logoUrl = updatedPlant && updatedPlant.metadata && updatedPlant.metadata.logoURL
        if (logoUrl) {
          dispatch(updateLogo(logoUrl))
          enqueueSnackbar('Logo caricato correttamente', { variant: 'success' })
        } else {
          enqueueSnackbar('Errore durante il caricamento del logo', { variant: 'error' })
        }
      } catch (e) {
        enqueueSnackbar('Errore durante il caricamento del logo', { variant: 'error' })
      }
    }
  }

  const mapHeight = rect ? rect.height - 80 : '280px'

  return (
    <Formik>
      {({ errors, touched }) => (
        <form
          noValidate
          {...rest}
          className={clsx(classes.root, className)}
        >
          <Box my={3}>
            <Box mb={1}>
              <Typography className={classes.stepDescription} variant='body2'>
                Inserisci i dati di anagrafica dell&apos;impianto
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={2}>
                <LogoLoader image={logoURL} deleteData={deleteImage} loadData={loadImage} disabled={!uuid} />
              </Grid>
              <Grid item xs={12} lg={10}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={4}>
                    <TextField
                      inputProps={{ 'data-testid': 'plant-name' }}
                      fullWidth
                      size='small'
                      name='name'
                      label='Nome Impianto'
                      required
                      value={name || ''}
                      variant='outlined'
                      onChange={e => handleNameChange(e, 'name')}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <TextField
                      fullWidth
                      size='small'
                      name='peakPower'
                      type='number'
                      label='Potenza di picco (kWp)'
                      value={(anagraphic && anagraphic.peakPower) || ''}
                      variant='outlined'
                      onChange={e => handleChange(e, 'peakPower')}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <TextField
                      inputProps={{ 'data-testid': 'plant-type' }}
                      error={Boolean(touched.plantType && errors.plantType)}
                      fullWidth
                      size='small'
                      select
                      required
                      disabled
                      name='plantType'
                      helperText={touched.plantType && errors.plantType}
                      label='Tipo Impianto'
                      value={plantType || ''}
                      variant='outlined'
                    >
                      {plantTypes.map(type => (
                        <MenuItem key={type.value} value={type.value}>
                          {type.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      fullWidth
                      size='small'
                      name='plantCode'
                      label='Codifica Impianto'
                      value={(anagraphic && anagraphic.plantCode) || ''}
                      variant='outlined'
                      onChange={e => handleChange(e, 'plantCode')}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      fullWidth
                      size='small'
                      name='contractType'
                      label='Tipologia Contrattuale'
                      value={(anagraphic && anagraphic.contractType) || ''}
                      variant='outlined'
                      select
                      onChange={e => handleChange(e, 'contractType')}
                    >
                      {
                        customFilterContractTypes.map(el => (
                          <MenuItem key={el.value} value={el.value}>
                            {el.label}
                          </MenuItem>
                        ))
                      }
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box mt={4} mb={2}>
              <Typography variant='body2' className={classes.stepDescription}>
                <b>Referente Cliente</b>
              </Typography>
            </Box>
            <Grid ref={ref} container spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <Box my={1}>
                  <TextField
                    fullWidth
                    size='small'
                    name='referentName'
                    label='Nominativo'
                    value={(anagraphic && anagraphic.referentName) || ''}
                    variant='outlined'
                    onChange={e => handleChange(e, 'referentName')}
                  />
                </Box>
                <Box my={1}>
                  <TextField
                    fullWidth
                    size='small'
                    name='referentClient'
                    label='Referente Cliente'
                    value={(anagraphic && anagraphic.referentClient) || ''}
                    variant='outlined'
                    onChange={e => handleChange(e, 'referentClient')}
                  />
                </Box>
                <Box my={1}>
                  <TextField
                    fullWidth
                    size='small'
                    name='referentRole'
                    label='Ruolo'
                    value={(anagraphic && anagraphic.referentRole) || ''}
                    variant='outlined'
                    onChange={e => handleChange(e, 'referentRole')}
                  />
                </Box>
                <Box my={1}>
                  <TextField
                    fullWidth
                    size='small'
                    name='referentEmail'
                    label='Email'
                    value={(anagraphic && anagraphic.referentEmail) || ''}
                    variant='outlined'
                    onChange={e => handleChange(e, 'referentEmail')}
                  />
                </Box>
                <Box my={1}>
                  <TextField
                    fullWidth
                    size='small'
                    type='number'
                    name='referentPhone'
                    label='Telefono'
                    value={(anagraphic && anagraphic.referentPhone) || ''}
                    variant='outlined'
                    onChange={e => handleChange(e, 'referentPhone')}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={9}>
                <Box my={1} display='flex' alignItems='center'>
                  <Tooltip
                    placement='top'
                    title={(
                      <Alert style={{ margin: -16 }} severity='info'>
                        <AlertTitle>Indirizzo Impianto</AlertTitle>
                        Una volta che l&apos;indirizzo dell&apos;impianto è stato inserito e salvato, non potrà essere più cancellato ma solo modificato.
                        In caso di rimozione dell&apos;indirizzo durante la modifica dell&apos;impianto, rimarrà memorizzato l&apos;ultimo indirizzo utile.
                      </Alert>)}
                  >
                    <SvgIcon style={{ marginRight: 16 }} fontSize='small'>
                      <InfoIcon />
                    </SvgIcon>
                  </Tooltip>
                  <Autocomplete
                    fullWidth
                    getOptionLabel={option => (typeof option === 'string' ? option : option.display_name)}
                    filterOptions={x => x}
                    options={options}
                    value={value}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    onChange={(event, newValue) => {
                      setOptions(newValue ? [newValue, ...options] : options)
                      setValue(newValue)
                      if (newValue) {
                        dispatch(setAddress({
                          type: 'point',
                          inputValue: newValue.display_name,
                          // coordinates: [newValue.lat, newValue.lon]
                          coordinates: [newValue.lon, newValue.lat]
                        }))
                      }
                    }}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name='address'
                        size='small'
                        label='Cerca Indirizzo'
                        variant='outlined'
                        fullWidth
                      />
                    )}
                    renderOption={(option) => {
                      return (
                        <Grid container alignItems='center'>
                          <Grid item>
                            <LocationOnIcon className={classes.icon} />
                          </Grid>
                          <Grid item xs>
                            <Typography variant='body1' color='textPrimary'>
                              {option.display_name}
                            </Typography>
                          </Grid>
                        </Grid>
                      )
                    }}
                  />
                </Box>
                {value
                  ? (
                    <Box width='100%' my={1}>
                      <Map
                        style={{ height: mapHeight }}
                        draggable
                        mapEventHandlers={({ lat, lng }) => {
                          // console.log('mapEventHandlers: ', lat, lng)
                          setValue(prevValue => ({
                            ...prevValue,
                            lon: lng,
                            lat: lat
                          }))
                          dispatch(setAddress({
                            type: 'point',
                            inputValue: value.display_name,
                            coordinates: [lng, lat]
                          }))
                        }}
                        lng={Number(value.lon)}
                        lat={Number(value.lat)}
                      />
                    </Box>)
                  : null}
              </Grid>
            </Grid>
            <Box mt={4} mb={2}>
              <Typography variant='body2' className={classes.stepDescription}>
                <b>Referente AES</b>
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box mb={1}>
                  <Typography variant='body2' className={classes.stepDescription}>
                    Monitoraggio
                  </Typography>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      size='small'
                      name='monitoringName'
                      label='Referente Nominativo'
                      value={(anagraphic && anagraphic.monitoringName) || ''}
                      variant='outlined'
                      onChange={e => handleChange(e, 'monitoringName')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      size='small'
                      name='monitoringEmail'
                      label='Email'
                      value={(anagraphic && anagraphic.monitoringEmail) || ''}
                      variant='outlined'
                      onChange={e => handleChange(e, 'monitoringEmail')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      size='small'
                      type='number'
                      name='monitoringPhone'
                      label='Telefono'
                      value={(anagraphic && anagraphic.monitoringPhone) || ''}
                      variant='outlined'
                      onChange={e => handleChange(e, 'monitoringPhone')}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box mb={1}>
                  <Typography variant='body2' className={classes.stepDescription}>
                    O&M
                  </Typography>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      size='small'
                      name='operationAndMaintenaceName'
                      label='Referente Nominativo'
                      value={(anagraphic && anagraphic.operationAndMaintenaceName) || ''}
                      variant='outlined'
                      onChange={e => handleChange(e, 'operationAndMaintenaceName')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      size='small'
                      name='operationAndMaintenaceEmail'
                      label='Email'
                      value={(anagraphic && anagraphic.operationAndMaintenaceEmail) || ''}
                      variant='outlined'
                      onChange={e => handleChange(e, 'operationAndMaintenaceEmail')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      size='small'
                      type='number'
                      name='operationAndMaintenacePhone'
                      label='Telefono'
                      value={(anagraphic && anagraphic.operationAndMaintenacePhone) || ''}
                      variant='outlined'
                      onChange={e => handleChange(e, 'operationAndMaintenacePhone')}
                    />
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
          </Box>
        </form>
      )}
    </Formik>
  )
}
export default PlantInfo
