import { useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import { Dialog, List, Box, DialogContent, DialogContentText, Grid, Link, IconButton, makeStyles, MenuItem, TextField, Typography, useMediaQuery, DialogActions, Button, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Tooltip } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { useDropzone } from 'react-dropzone'
import bytesToSize from 'src/utils/bytesToSize'
import { deleteDatasheet, loadDatasheet } from './utils'
import { useSnackbar } from 'notistack'
import { Eye as ViewIcon, Trash2 as DeleteIcon, DownloadCloud as DownloadIcon } from 'react-feather'
import { useDispatch } from 'src/store'
import ConfirmationDialogRaw from 'src/components/ConfirmationDialogRaw'

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogRoot: {
    margin: 0,
    padding: theme.spacing(3)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  dropZone: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(4),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      opacity: 0.5,
      cursor: 'pointer'
    }
  },
  dragActive: {
    backgroundColor: theme.palette.action.active,
    opacity: 0.5
  },
  image: {
    width: 80
  },
  list: {
    marginTop: theme.spacing(1),
    maxHeight: 200,
    overflowY: 'auto'
  },
  documentsList: {
    maxHeight: 200,
    overflowY: 'auto'
  },
  deleteButton: {
    color: theme.palette.error.main
  }
}))

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props
  const classes = useStyles()
  return (
    <MuiDialogTitle disableTypography className={classes.dialogRoot} {...other}>
      <Typography variant='h3'>{children}</Typography>
      {onClose
        ? (
          <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
          )
        : null}
    </MuiDialogTitle>
  )
}

// Deve accettare application/PDF e .pdf files
function LoadDatasheetDialog ({ devices = [], viewFile = null, onLoad = null, open, onClose, className, ...rest }) {
  // console.log('devices: ', devices)
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const [selectedComponent, setSelectedComponent] = useState('')
  const [files, setFiles] = useState([])
  const [isUploading, setIsUploading] = useState(0)
  const [currentDeviceFiles, setCurrentDeviceFiles] = useState(null)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [documentIndexToDelete, setDocumentIndexToDelete] = useState(null)

  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('sm'))

  // funzione che gestisce il drop del file
  const handleDrop = useCallback((acceptedFiles) => {
    // console.log('acceptedFiles =>', acceptedFiles)
    setFiles((prevFiles) => [...prevFiles].concat(acceptedFiles))
    // setFiles(acceptedFiles)
  }, [])

  // funzione che si occupa del caricamento dell'upload del file sul server
  const loadData = async () => {
    setIsUploading(1)
    try {
      const response = await loadDatasheet(files, selectedComponent)
      // Prendo il device modificato che arriva come risposta
      const newDevice = response.length > 0 ? response[0] : null
      if (newDevice && onLoad) {
        dispatch(onLoad(newDevice.nodeId, newDevice))
        // console.log('datasheet: ', newDevice)
      }
      setIsUploading(2)
      setSelectedComponent('')
      setFiles([])
      enqueueSnackbar('Datasheet caricato con successo!', {
        variant: 'success'
      })
      onClose()
    } catch (err) {
      console.error('datasheet - error: ', err)
      setIsUploading(0)
      enqueueSnackbar('Errore durante il caricamento del datasheet', {
        variant: 'error'
      })
    }
  }

  // Funzione che rimuove i file caricati
  const handleRemoveFile = () => {
    setFiles([])
  }

  // Funzione che elimina il datasheet dal bucket e dal db
  const handleDeleteDatasheet = async (confirmed, fileIndex) => {
    if (confirmed) {
      try {
        const response = await deleteDatasheet(fileIndex, selectedComponent)
        if (response && onLoad) {
          // Aggiorno il datalogger nello store
          dispatch(onLoad(response.nodeId, response))
          // Aggiorno anche lo stato attuale della dialog dato che durante la cancellazione non viene smontata
          const currentFiles = response.metadata && response.metadata.documents ? response.metadata.documents : null
          setCurrentDeviceFiles(currentFiles)
          setDocumentIndexToDelete(null)
        }
        enqueueSnackbar('Datasheet eliminato con successo!', {
          variant: 'success'
        })
      } catch (err) {
        enqueueSnackbar('Errore durante la cancellazione del datasheet', {
          variant: 'error'
        })
      }
    }
    setShowConfirmDialog(false)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    multiple: false,
    accept: '.pdf, application/pdf'
  })

  useEffect(() => {
    if (selectedComponent && selectedComponent !== '') {
      const currentDevice = devices.find(el => el.uuid === selectedComponent)
      if (currentDevice && currentDevice !== undefined) {
        const currentFiles = currentDevice.metadata && currentDevice.metadata.documents ? currentDevice.metadata.documents : null
        setCurrentDeviceFiles(currentFiles)
      }
    }
  }, [selectedComponent, devices])

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {showConfirmDialog
        ? (
          <ConfirmationDialogRaw
            open={showConfirmDialog}
            onClose={(confirmed) => handleDeleteDatasheet(confirmed, documentIndexToDelete)}
            title='Elimina Documento'
            description='Sei sicuro di voler eliminare il documento?'
          />
          )
        : null}
      <Dialog
        fullScreen={fullScreen}
        maxWidth='sm'
        fullWidth
        open={open}
        onClose={onClose}
        aria-labelledby='add-datalogger-dialog'
      >
        <DialogTitle id='add-datalogger-dialog' onClose={onClose}>
          Carica i datasheet dei componenti
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            Seleziona il componente per cui caricare il datasheet
          </DialogContentText>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='component'
                select
                disabled={isUploading !== 0}
                margin='normal'
                label='Componente*'
                value={selectedComponent}
                onChange={(event) => {
                  // console.log('components changed, event.target.value => ', event.target.value)
                  setSelectedComponent(event.target.value)
                }}
                variant='outlined'
              >
                {devices.map((component, index) => (
                  <MenuItem key={index} value={component.uuid}>
                    {component.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {currentDeviceFiles && currentDeviceFiles.length > 0
              ? (
                <Grid item xs={12} style={{ marginTop: 16 }}>
                  <List className={classes.documentsList}>
                    {currentDeviceFiles.map((currentDeviceFile, index) => (
                      <ListItem key={currentDeviceFile.url}>
                        <ListItemText primaryTypographyProps={{ noWrap: true, style: { maxWidth: '70%' } }}>
                          <Tooltip title={currentDeviceFile.name}>
                            <span>{currentDeviceFile.name}</span>
                          </Tooltip>
                        </ListItemText>
                        <ListItemSecondaryAction>
                          {viewFile
                            ? (
                              <Tooltip title='Visualizza datasheet'>
                                <span>
                                  <IconButton
                                    onClick={() => viewFile({ name: currentDeviceFile.name, url: currentDeviceFile.url })}
                                    fontSize='small'
                                    disabled={!(currentDeviceFile.url && currentDeviceFile.url !== '')}
                                  >
                                    <ViewIcon
                                      fontSize='small'
                                    />
                                  </IconButton>
                                </span>
                              </Tooltip>
                              )
                            : null}
                          <Tooltip title='Scarica datasheet'>
                            <span>
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation()
                                  if (currentDeviceFile.url && currentDeviceFile.url !== '') {
                                    const a = document.createElement('a')
                                    document.body.appendChild(a)
                                    a.style = 'display: none'
                                    a.href = currentDeviceFile.url
                                    a.click()
                                    window.URL.revokeObjectURL(currentDeviceFile.url)
                                  }
                                }}
                                color='primary'
                                fontSize='small'
                                disabled={!(currentDeviceFile.url && currentDeviceFile.url !== '')}
                              >
                                <DownloadIcon
                                  fontSize='small'
                                />
                              </IconButton>
                            </span>
                          </Tooltip>
                          <Tooltip title='Elimina Datasheet'>
                            <IconButton
                              className={classes.deleteButton}
                              onClick={() => {
                                setDocumentIndexToDelete(index)
                                setShowConfirmDialog(true)
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
                )
              : null}
            <Grid item xs={12} style={{ marginTop: 16 }}>
              <div
                className={clsx({
                  [classes.dropZone]: true,
                  [classes.dragActive]: isDragActive
                })}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <Grid container>
                  <Grid item xs={12} sm={3}>
                    <div style={{ textAlign: 'center', padding: '-16px' }}>
                      <img
                        alt='Seleziona il Datasheet'
                        className={classes.image}
                        src='/static/images/undraw_add_file2_gvbb.svg'
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <div>
                      <Typography gutterBottom variant='h4'>
                        Seleziona il Datasheet
                      </Typography>
                      <Box mt={2}>
                        <Typography color='textPrimary' variant='body1'>
                          Trascina i file o clicca qui per <Link underline='always'>sfogliare</Link> nelle tue cartelle
                        </Typography>
                      </Box>
                    </div>
                  </Grid>
                </Grid>
              </div>
              {files.length > 0 && (
                <>
                  <List className={classes.list}>
                    {files.map((file, i) => (
                      <ListItem divider={i < files.length - 1} key={i}>
                        <ListItemIcon>
                          <FileCopyIcon />
                        </ListItemIcon>
                        <Tooltip title={file.name}>
                          <ListItemText
                            primary={file.name}
                            primaryTypographyProps={{ noWrap: true, style: { maxWidth: '80%' }, variant: 'h5' }}
                            secondary={bytesToSize(file.size)}
                          />
                        </Tooltip>
                      </ListItem>
                    ))}
                  </List>
                </>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {files.length > 0 && (
            <Button onClick={handleRemoveFile} color='primary' style={{ marginRight: 'auto' }} disabled={isUploading !== 0}>
              Rimuovi file
            </Button>
          )}
          <Button
            onClick={loadData}
            disabled={!files.length > 0 || !selectedComponent || isUploading !== 0}
            color='secondary'
            variant='contained'
            style={{
              backgroundColor: isUploading === 2 ? 'green' : '',
              color: isUploading === 2 ? 'white' : ''
            }}
          >
            {isUploading === 0 && 'Carica i file'}
            {isUploading === 1 && 'Inviando i file...'}
            {isUploading === 2 && 'File caricati!'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
export default LoadDatasheetDialog
