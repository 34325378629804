import { useEffect, useState, memo } from 'react'
import { Box, Button, Dialog, DialogActions, Grid, Paper, useMediaQuery, useTheme } from '@material-ui/core'
import PlanimetryView from './PlanimetryView'
import PlantContractDataTable from './PlantContractDataTable'
import { useDispatch, useSelector } from 'react-redux'
import { updatedPlantInfo } from 'src/slices/industryConsumption/plantView'
import { useParams } from 'react-router-dom'
import AnomaliesStatusBar from '../../shared/AnomaliesStatusBar'
import PlantModuleTable from './PlantModuleTable'

// COMPONENTE PRINCIPALE
const Overview = () => {
  const { plantId } = useParams()
  const theme = useTheme()
  const { hasError, anomaliesBar } = useSelector((state) => state.industryConsumptionPlantView)
  const dispatch = useDispatch()

  const [showAnagraphic, setShowAnagraphic] = useState(false)

  const isExtraSmall = useMediaQuery(theme => theme.breakpoints.down('xs'))
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))

  useEffect(() => {
    const overviewInterval = setInterval(() => {
      console.log('dentro interval')
      dispatch(updatedPlantInfo({ plantId }))
    }, 60000)
    return () => {
      clearInterval(overviewInterval)
    }
  }, [dispatch, plantId, hasError])

  const computedContainerHeight = `calc(100% - 48px - ${theme.spacing(2)}px - 80px)`

  return (
    <Box mt={1} style={{ height: computedContainerHeight, width: '100%' }}>
      {
        showAnagraphic
          ? (
            <Dialog
              fullWidth
              fullScreen={isSmall}
              maxWidth='sm'
              open={showAnagraphic}
              onClose={() => setShowAnagraphic(false)}
            >
              <PlantContractDataTable />
              {isSmall && (
                <DialogActions>
                  <Button variant='outlined' fullWidth onClick={() => setShowAnagraphic(false)}>Close</Button>
                </DialogActions>
              )}
            </Dialog>)
          : null
      }
      <Grid container spacing={1} style={{ height: '100%', maxHeight: '100%' }}>
        <Grid item xs={12} sm={7} md={8} xl={9} style={{ height: 'Calc(100% - 26px)' }}>
          <AnomaliesStatusBar anomaliesBar={anomaliesBar} />
          <PlanimetryView />
        </Grid>
        {!isExtraSmall && (
          <Grid item xs={12} sm={5} md={4} xl={3} style={{ height: '100%' }}>
            <Grid container direction='row' style={{ maxHeight: '100%', overflowY: 'scroll', paddingBottom: 10 }}>
              <Grid item xs={12} style={{ marginBottom: 8 }}>
                <PlantModuleTable />
              </Grid>
              <Grid item xs={12}>
                <Paper>
                  <Button size='small' fullWidth onClick={() => setShowAnagraphic(true)} color='primary'>Vedi Anagrafica</Button>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default memo(Overview)
