import { memo } from 'react'
import clsx from 'clsx'
import moment from 'moment'
import PropTypes from 'prop-types'
import Chart from 'react-apexcharts'
// import ApexCharts from 'apexcharts'
// import PerfectScrollbar from 'react-perfect-scrollbar'
// import { fixedNum, europeNum } from 'src/utils/general'
import { basicColors } from 'src/theme'
import LoadingCard from 'src/components/LoadingCard'
import {
  Box,
  // Button,
  Tooltip,
  Card,
  SvgIcon,
  CardHeader,
  // CardContent,
  // Typography,
  makeStyles
  // withStyles
} from '@material-ui/core'
// import { useSnackbar } from 'notistack'

import {
  Info as InfoIcon
} from 'react-feather'

// variaible con le dimenzioni del grafico
const graphSize = {
  height: 170,
  width: 300
}

// stile CSS generale
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    maxHeight: '100%',
    width: '100%'
  },
  boxContainer: {
    width: '100%',
    height: '82%',
    // maxHeight: 'calc(100% - 47px)',
    paddingLeft: 12,
    paddingRight: 12,
    paddingBottom: 16,
    // display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'center',
    // justifyContent: 'center',
    zIndex: 1000
  }
}))

// componente principale
const DisserviceHoursGraphView = ({ noCard = false, width = null, height = null, className, disserviceHours, anomaliesCategoryStatus, ...rest }) => {
  // console.log('showTable =>', showTable)
  const classes = useStyles()
  // const { enqueueSnackbar } = useSnackbar()
  // mi preparo il dataset
  const dataSet = {
    series: [
      {
        data: []
      }
    ],
    labels: [],
    colors: []
  }

  // costruisco l'array dei dati che mi tornano dalla query
  // mi ordino i dati della serie
  const queryData = disserviceHours[0] ? JSON.parse(JSON.stringify(disserviceHours)) : []
  const sortedQueryData = queryData.sort(function (a, b) {
    return b.value - a.value
  })
  // mi creo la variabili delle label e dei colori
  sortedQueryData.forEach((data) => {
    // cerco il riferimento alla categoria e sotto categoria
    const thisCategory = anomaliesCategoryStatus.find((category) => {
      return category.name === data.category
    })
    if (thisCategory) {
      const thisSubCategory = thisCategory.subCategory.find((subCategory) => {
        return subCategory.name === data.subCategory
      })
      // controllo se ho trovato la categoria
      if (thisSubCategory) {
        // pusho i dati
        const thisHours = moment.duration(data.value).asHours()
        dataSet.series[0].data.push(Number(thisHours.toFixed(0)))
        dataSet.labels.push(thisSubCategory.label)
        dataSet.colors.push(thisSubCategory.color)
      }
    }
  })

  const chartData = {
    series: dataSet.series,
    chart: {
      height: graphSize.height,
      width: graphSize.width,
      type: 'bar'
    },
    options: {
      chart: {
        id: 'barDisserviceHours',
        toolbar: {
          show: false
        }
        // redrawOnParentResize: false
        // animations: {
        //   enabled: false
        // },
        // width: '100%',
        // height: 'auto'
      },
      labels: dataSet.labels,
      colors: dataSet.colors,
      fill: {
        opacity: 0.9
      },
      plotOptions: {
        bar: {
          horizontal: true,
          distributed: true,
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        enabled: true,
        textAnchor: 'start',
        offsetX: 20
      },
      legend: {
        show: false,
        fontSize: '11px'
      },
      tooltip: {
        theme: 'dark',
        y: {
          title: {
            formatter: (seriesName) => ''
          },
          formatter: (value) => value + ' ore'
        }
      },
      xaxis: {
        labels: {
          show: false,
          style: {
            colors: basicColors.whiteText,
            fontSize: '11px'
          }
        },
        axisBorder: {
          show: false,
          color: basicColors.blueGrayMedium,
          height: 1,
          width: '100%',
          offsetX: 0,
          offsetY: 0
        },
        axisTicks: {
          show: false,
          borderType: 'solid',
          color: basicColors.blueGrayMedium,
          height: 6,
          offsetX: 0,
          offsetY: 0
        }
      },
      yaxis: {
        show: true,
        labels: {
          style: {
            colors: basicColors.whiteText,
            fontSize: '11px'
          }
        }
      },
      grid: {
        show: false
      }
    }
  }
  // console.log('chartData => ', chartData)
  // console.log('width => ', width)
  // console.log('height => ', height)

  return noCard
    ? disserviceHours[0] !== '-'
        ? (
          <Chart
            id={chartData.options.chart.id}
            options={chartData.options}
            series={chartData.series}
            type={chartData.chart.type}
            width={width || '100%'}
            height={height || '100%'}
          />
          )
        : <LoadingCard />
    : (
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader
          title='Durata Totale nel periodo'
          // subheader='September 14, 2016'
          style={{ paddingBottom: 2 }}
          action={
            <Tooltip
              title='Nel grafico viene contata solo la durata totale delle anomalie nel periodo selezionato'
              aria-label='Durata totale nel periodo'
              interactive
            >
              <SvgIcon fontSize='small'>
                <InfoIcon color={basicColors.primary} />
              </SvgIcon>
            </Tooltip>
          }
        />
        <Box className={classes.boxContainer} display='flex' alignItems='center' justifyContent='center'>
          {disserviceHours[0] !== '-'
            ? (
              <Chart
                id={chartData.options.chart.id}
                options={chartData.options}
                series={chartData.series}
                type={chartData.chart.type}
                width='100%'
                height='100%'
              />
              )
            : <LoadingCard />}
        </Box>
      </Card>
      )
}

DisserviceHoursGraphView.propTypes = {
  className: PropTypes.string,
  disserviceHours: PropTypes.array,
  anomaliesCategoryStatus: PropTypes.array
  // setRefreshUsers: PropTypes.func
}

DisserviceHoursGraphView.defaultProps = {
  disserviceHours: [],
  anomaliesCategoryStatus: []
}

export default memo(DisserviceHoursGraphView)
